import React, { useEffect, useMemo, useState } from 'react'
import { Tabs as AntdTabs } from 'antd';
import { useLocation, useHistory } from 'react-router-dom'
import style from './index.module.less';
import { keys, paths } from "../../constants";
import { useSelector } from 'react-redux';
import { totalTdLhState } from 'ducks/slices/QlTiepdon/TotalTdLh.slice';


const TabHeader = ({ items = [], isHeader = false }) => {

  const { pathname } = useLocation()
  const history = useHistory()
  const [activeKey, setActiveKey] = useState(pathname)

  const { qlTiepDon, qlLichHen } = useSelector(totalTdLhState);
  const { qlDVCD, qlKhamBenh } = useSelector((state) => state.khamBenh);

  useEffect(() => {
    setActiveKey(pathname)
  }, [pathname])

  const onTabClick = (path) => {
    if (path === "https://live.draid.ai/") window.open(path, "_blank")
    else history?.push(path)
  }

  const itemsFormat = useMemo(() => {
    const data = [...items]
    if (data) {
      data.forEach((element, index) => {
        if (element && element.key && element.key.includes(paths.quan_ly_tiep_don)) {
          data[index].total = qlTiepDon?.totalMain?.toString();
        }
        if (element && element.key && element.key.includes(paths.quan_ly_cuoc_hen_sap_toi)) {
          data[index].total = qlLichHen?.totalMain ? qlLichHen?.totalMain?.toString() : "0";
        }
        if (element && element.key && element.key.includes(paths.quan_ly_kham_benh)) {
          data[index].total = qlKhamBenh?.totalMain ? qlKhamBenh?.totalMain?.toString() : "0";
        }
        if (element && element.key && element.key.includes(paths.quan_ly_dv_chi_dinh)) {
          data[index].total = qlDVCD?.totalMain ? qlDVCD?.totalMain?.toString() : "0";
        }
      });
    }
    return data
  })

  if (isHeader) {
    return (
      <AntdTabs
        className={style['style-tabs']}
        onTabClick={onTabClick}
        defaultActiveKey={activeKey}
        activeKey={activeKey}
        items={itemsFormat.map((obj, i) => {
          return {
            label: (
              <span className={style.labelHeader}>
                {obj.label}
                {obj?.total && (<span className={style.total}>{obj.total}</span>)}
              </span>
            ),
            key: obj.key,
          };
        })}
      // onChange={(key) => setActiveKey(key)}
      />
    )
  }

  return (
    <AntdTabs
      className={style['style-tabs']}
      onTabClick={onTabClick}
      items={items}
      defaultActiveKey={activeKey}
      activeKey={activeKey}
    // onChange={(key) => setActiveKey(key)}
    />
  )
}

export default TabHeader