import { createSlice } from "@reduxjs/toolkit"

const initState = {
    data: []
}

const servicePackageUsageInfoSlice = createSlice({
    name: 'servicePackageUsageInfoSlice',
    initialState: initState,
    reducers: {
        getServicePackageUsageInfo: () => {},
        getServicePackageUsageInfoSuccess: (state, payload) => {
            state.data = payload;
        }
    }

})

export const {getServicePackageUsageInfo, getServicePackageUsageInfoSuccess} = servicePackageUsageInfoSlice.actions;
// export const getServicePackageUsageInfoData = useSelector
export default servicePackageUsageInfoSlice.reducer