import { Divider, Modal, Row, Col, Button } from "antd";
import i18n, { languageKeys } from "../../i18n";
import React, { useMemo, useState } from "react";
import { useImperativeHandle } from "react";
import { forwardRef } from "react";
import cn from "classnames";
import style from "./style.module.less";
import { useEventListener } from "hooks";
import { useEffect } from "react";
import moment from "moment";

const ModalTTBN = ({ 
    title=i18n.t(languageKeys.common_Canh_bao),
    message=i18n.t(languageKeys.label_sd_thong_tin_kh_cu),
    width=460,
    onOk=()=>{},
    onCancel=()=>{}
}, ref) => {
  const [visible, setVisible] = useState(false);
  const [patientData, setPatientData] = useState();
  const handleClose = () => {
    setVisible(false);
    setPatientData();
    onCancel();
  };

  function keydownEvent(event) {
    if (!visible) {
      return;
    }

    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  useEventListener("keydown", keydownEvent, window.document, visible, 500);

  useImperativeHandle(ref, () => ({
    open(data) {
      setVisible(true);
      setPatientData(data);
    },
    close: handleClose,
    setVisible,
  }));

  return (
    <Modal visible={visible} onCancel={handleClose} onOk={() => onOk(patientData)} width={width} closable={false} footer={null} className={style["modal"]}>
      <div style={{ textAlign: "center" }}>
        <div className={cn(style["title-content"])}>
          <h1>{title}</h1>
        </div>
      </div>
      <div className={style["content"]}>
        <p style={{textAlign: 'center', marginBottom: '10px'}}>{message}</p>
        <Row gutter={[24, 0]}>
            <Col span={24}>{`${i18n.t(languageKeys.ho_va_ten)}: ${patientData?.TEN}`}</Col>
            <Col span={12}>
                {`${i18n.t(languageKeys.so_dien_thoai)}: ${patientData?.SO_DIEN_THOAI}`}
            </Col>
            <Col span={12}>
                {`${i18n.t(languageKeys.ngay_sinh)}: ${patientData?.NGAY_SINH?.length <= 4 ? patientData?.NGAY_SINH : moment(patientData?.NGAY_SINH, 'YYYYMMDD').format('DD/MM/YYYY')}`}
            </Col>
        </Row>
      </div>
      <Row gutter={10} justify="center" style={{ marginTop: "16px" }}>
        <Col>
          <Button type="primary" ghost onClick={handleClose}>
            {i18n.t(languageKeys.huy)} {`(Esc)`}
          </Button>
        </Col>

        <Col>
          <Button type="primary" onClick={() => onOk(patientData)} className={style["ok-btn"]}>
            {i18n.t(languageKeys.title_Xac_nhan)} {`(Enter)`}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(ModalTTBN);
