import { PlusCircleFilled } from "@ant-design/icons";
import { Button, Col, Drawer, Row, Spin, Tooltip } from "antd";
import { BackCircle, NewBackIcon } from "../../assets/svg";
import i18n, { languageKeys } from "../../i18n";
import formDrawer from "./formDrawer.module.less";
import { DeleteWhite } from "../../assets/svg";
import cn from "classnames";

const FormDrawer = ({
  children,
  back = true,
  onClickBack = () => { },
  width = 800, // Chiều rộng của Drawer
  title = "Form Drawer", // Tiêu đề của Drawer, có thể là string hoặc Component
  onOk = () => { }, // Hàm xử lý khi bấm OK
  onCancel = () => { }, // Hàm xử lý khi bấm Cancel
  okText = (
    <>
      {i18n.t(languageKeys.common_Luu)}
      {" (Ctrl + S)"}
    </>
  ), // Text của nút OK
  cancelText = i18n.t(languageKeys.common_Thoat), // Text của nút Cancel
  deleteText = i18n.t(languageKeys.common_Xoa), // Text của nút Xoá
  showPlusIcon = false, // Hiển thị icon dấu cộng trước tiêu đề (VD là trường hợp tạo mới)
  visible = false, // Hiển thị modal
  pending = false,
  loading = false, // Loading nút submit
  addOnActions,
  footer,
  footerClass = "",
  hiddenTopAction = false,
  deleteTopAction = false,
  buttonsAtBottomOfContent = false,
  className = "",
  headerClass = "",
  addBtnAction = () => { }, // Hiển thị hành động thêm nút
  addBtnText = i18n.t(languageKeys.field_In_phieu), // hiển thị nút thêm
  disableAddBtn = true,
  disableOkBtn = false,
  addBtn = false,
  footerAddon,
  tooltipWhenDisabled = i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay),
  classNameContainer = "",
  newBackIcon = true,
  maskClosable = false,
  extraTitle,
  ...props
}) => {
  return (
    <Drawer
      open={visible}
      width={width}
      closable={false}
      maskClosable={maskClosable}
      onClose={onCancel}
      className={className}
      {...props}
      title={
        typeof title === "string" ? (
          <Row justify="space-between" align="middle" className={cn(formDrawer["header"], headerClass)} wrap={false}>
            {/* =================== Tiêu đề Drawer =================== */}
            <Row align="middle" gutter={10}>
              {showPlusIcon && (
                <Col>
                  <PlusCircleFilled className={formDrawer["icon"]} />
                </Col>
              )}
              {back && (
                newBackIcon ? (
                  <Col style={{ paddingTop: 3 }}>
                    <NewBackIcon className={formDrawer["icon"]} onClick={onCancel} style={{ cursor: "pointer" }} />
                  </Col>
                ) : (
                  <Col>
                    <BackCircle className={formDrawer["icon"]} onClick={onCancel} style={{ cursor: "pointer" }} />
                  </Col>
                )
              )}

              <Col>
                <h1 style={{ marginBottom: 0 }}>{title}</h1>
              </Col>
              {
                extraTitle && <Col>
                  {extraTitle}
                </Col>
              }
            </Row>

            <Row gutter={10} align="middle">
              {!!addOnActions && <Col>{addOnActions}</Col>}

              {!hiddenTopAction && deleteTopAction && (
                <Col className={formDrawer["button-row"]}>
                  {/* =================== Nút Cancel =================== */}
                  <Button
                    type="primary"
                    ghost
                    onClick={onCancel}
                    style={{
                      backgroundColor: "#ED6B69",
                      color: "#FFF",
                      border: "0",
                    }}
                    icon={<DeleteWhite style={{ marginRight: "8px" }} />}
                    className={formDrawer["button"]}
                  >
                    {deleteText}
                  </Button>
                </Col>
              )}

              {!buttonsAtBottomOfContent && !hiddenTopAction && (
                <Col>
                  {/* =================== Nút Cancel =================== */}
                  <Button type="primary" ghost onClick={onCancel} className={formDrawer["button"]}>
                    {cancelText}
                  </Button>
                </Col>
              )}

              {!buttonsAtBottomOfContent && !hiddenTopAction && (
                <Col>
                  {/* =================== Nút OK =================== */}
                  <Button type="primary" loading={loading} onClick={onOk} className={formDrawer["button"]}>
                    {okText}
                  </Button>
                </Col>
              )}
            </Row>
          </Row>
        ) : (
          title
        )
      }
      footer={footer}
    >
      <div className={`${formDrawer["container"]} ${classNameContainer}`}>{children}</div>

      {buttonsAtBottomOfContent && (
        <Row gutter={10} align="middle" justify="end" style={{ padding: "15px 25px", margin: "0px" }} className={footerClass}>
          {!!footerAddon && <>{footerAddon}</>}

          {!hiddenTopAction && (
            <Col>
              {/* =================== Nút Cancel =================== */}
              <Button type="primary" ghost onClick={onCancel} className={formDrawer["button"]}>
                {cancelText} (ESC)
              </Button>
            </Col>
          )}

          {!hiddenTopAction && (
            <Col>
              {/* =================== Nút OK =================== */}
              <Tooltip title={disableAddBtn ? tooltipWhenDisabled : ''} placement="topRight">
                <Button type="primary" loading={loading} onClick={onOk} className={formDrawer["button"]} disabled={disableOkBtn}>
                  {okText}
                </Button>
              </Tooltip>
            </Col>
          )}
          {addBtn && (
            <Col>
              {/* =================== Nút Thêm =================== */}
              <Tooltip title={disableAddBtn ? tooltipWhenDisabled : ''} placement="topRight">
                <Button type="primary" loading={loading} onClick={addBtnAction} className={formDrawer["button"]} disabled={disableAddBtn}>
                  {addBtnText}
                </Button>
              </Tooltip>
            </Col>
          )}
        </Row>
      )}
      {pending && (
        <div className={formDrawer["pending_screen"]}>
          <Spin />
        </div>
      )}
    </Drawer>
  );
};

export default FormDrawer;
