import i18n, { languageKeys } from "../../i18n";
import { Button, Input, Row, Col, Tree, Spin, Tooltip, Dropdown } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Search } from "assets/svg";
import style from "./styles/style.module.less";
import { handleCheckPermissions, HLog, locdau } from "helpers";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DkyGoiLieuTrinh from "pages/QuanLyGoiDichVu/GoiLieuTrinh/DkyGoiLieuTrinh";
import { useSelector } from "react-redux";
import { featureKeys } from "constants/keys";

// Sider danh sách dịch vụ
export const SiderIngredient = ({
  open,
  options = [],
  onOpenFastRegister = () => {},
  onSelectService = () => {},
  checkedKeys = [],
  expandedKeys = [],
  disableKeys = [],
  loading,
  lichKhamInfo = {},
  handleReloadPack = () => {},
}) => {

  const subRef = useRef()

  const userProfile = useSelector(state => state.auth.user)

  const [filterString, setFilterString] = useState("");

  const roles = useMemo(() => {
    return handleCheckPermissions(userProfile, featureKeys.quan_ly_dk_goi_dv);
  }, [userProfile]);

  useEffect(() => {
    if (!open) {
      setFilterString("");
    }
  }, [open]);

  let jsonCheckOptions = (() => {
    try {
      return JSON.stringify(options);
    } catch (error) {
      return options;
    }
  })();

  const pickChild = (arg, key) => {
    try {
      return arg.map((child) => {
        return {
          ...child,
          title: child.TEN_DV,
          children: Array.isArray(child.children) ? child.children.map((c) => ({ ...c, title: c.TEN_DV })) : [],
        };
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  // memo giá trị danh sách lựa chọn (re-render tree select khi có thay đổi từ key giá trị);
  const renderOptions = useMemo(() => {
    try {
      let res = [];
      res = options.map((op) => {
        //layer gói => danh sách dịch vụ trong gói
        return { ...op, title: op.TEN_GOI_DV, children: pickChild(op.children, "children") };
      });

      return res;
    } catch (error) {
      console.log(error);
      return [];
    }
  }, [jsonCheckOptions]);

  const handleSelectPack = (e) => {
    onSelectService(e);
  };

  const checkDisabled = (dv) => {
    // try {
    //   let check = false;
    //   if (disableKeys.includes(dv.key)) {
    //     check = true;
    //   }
    //   if (dv.PHAN_LOAI === keys.COMBO_DV) {
    //     if (dv.children.every((v) => disableKeys.includes(v.key))) {
    //       check = true;
    //     } else {
    //       check = false;
    //     }
    //   }
    //   return check;
    // } catch (error) {
    //   return false;
    // }
    return dv.CON_LAI < 1
  };

  const checkFilter = useCallback(
    (opts = []) => {
      try {
        let rs = opts;
        const handleString = (str) => {
          try {
            return locdau(str)?.trim()?.toLowerCase();
          } catch (error) {
            return str;
          }
        };

        if (filterString && filterString.length) {
          if (filterString.includes(",")) {
            let arrStr = filterString.split(",");
            rs = renderOptions.filter((op) => {
              return arrStr.some((v) => handleString(op.TEN_GOI_DV).includes(handleString(v)));
            });
          } else {
            rs = renderOptions.filter((i) => {
              let filterCheck = handleString(i.TEN_GOI_DV).includes(handleString(filterString));
              if (i.children && Array.isArray(i.children)) {
                let name_arr = [];
                // let concat_arr = [];
                i.children.forEach(c => {
                  if (c.children) {
                    let concat_arr = [...c.children.map(c => c.TEN_DV)];
                    name_arr = [...name_arr, ...concat_arr]
                  }
                  if (c.TEN_DV) {
                    name_arr = [...name_arr, c.TEN_DV]
                    return c.TEN_DV
                  }
                });
                let checked = (name_arr).some(name => handleString(name).includes(handleString(filterString)))
                return filterCheck || checked;
              }
            });
          }
        } else {
          //   throw "Kết quả mặc định";
        }
        return rs;
      } catch (error) {
        HLog(error);
        return renderOptions;
      }
    },
    [filterString, renderOptions]
  );


  const items = [
    {
      label: i18n.t(languageKeys.dk_goi_dv),
      key: 'GOI_DV',
      onClick: () => onOpenFastRegister(),
      disabled: !roles?.THEM_MOI
    },
    {
      label: i18n.t(languageKeys.dky_lieu_trinh),
      key: 'GOI_LT',
      onClick: () => subRef?.current.openFast(lichKhamInfo.MA_BENH_NHAN),
      disabled: !roles?.THEM_MOI_LIEU_TRINH
    },
  ];


  return (
    <>
      <Row gutter={[10, 10]} justify={"center"} style={{ marginTop: 12 }}>
        <Col span={18}>
          {/* <Button type="primary" block icon={<PlusOutlined />} onClick={onOpenFastRegister}>
            {i18n.t(languageKeys.dang_ky_goi_lieu_trinh)}
          </Button> */}
          <Dropdown
            menu={{ items }}
            trigger={['click']}
          >
            <Button type="primary" icon={<PlusOutlined />} style={{ width: '100%' }}>
              {i18n.t(languageKeys.dang_ky_goi_lieu_trinh)}
            </Button>
          </Dropdown>
        </Col>
        <Col span={18}>
          <Tooltip title="Từ khóa ngăn cách bằng dấu ','" placement="right">
            <Input prefix={<Search />} placeholder={i18n.t(languageKeys.common_Tim_kiem)} onChange={(e) => setFilterString(e.target.value)} />
          </Tooltip>
        </Col>
      </Row>

      <div className={style["wrapper-overflow-opt"]}>
        <Spin spinning={loading}>
          <Tree
            checkable
            defaultExpandAll
            checkedKeys={checkedKeys}
            selectable={false}
            autoExpandParent
            virtual
            onSelect={(checked_keys, event) => onSelectService(checked_keys, event)}
            onCheck={(_, e) => handleSelectPack(e)}
          >
            {checkFilter(renderOptions).map((i) => (
              <Tree.TreeNode key={i.key} title={i.TEN_GOI_DV} style={{ opacity: i.IS_ACTIVE ? 1 : 0.5 }} aria-active={i.IS_ACTIVE}>
                {!!i.children &&
                  i.children.map((dv) => {
                    return (
                      <Tree.TreeNode
                        key={dv.key}
                        title={dv.TEN_DV}
                        disabled={checkDisabled(dv)}
                        style={{ opacity: i.IS_ACTIVE ? 1 : 0.5 }}
                        aria-active={i.IS_ACTIVE}
                      >
                        {!!dv.children &&
                          dv.children?.map((cb_dv) => (
                            <Tree.TreeNode
                              title={cb_dv.TEN_DV}
                              key={cb_dv.key}
                              disabled={disableKeys?.includes(cb_dv.key)}
                              style={{ opacity: i.IS_ACTIVE ? 1 : 0.5 }}
                              aria-active={i.IS_ACTIVE}
                            ></Tree.TreeNode>
                          ))}
                      </Tree.TreeNode>
                    );
                  })}
              </Tree.TreeNode>
            ))}
          </Tree>
        </Spin>
      </div>

      <DkyGoiLieuTrinh
        ref={subRef}
        resetFilter={handleReloadPack}
        isFast
      />
    </>
  );
};

export default SiderIngredient;
