import { createSlice } from "@reduxjs/toolkit";

const initState = {
  selectedPatient: undefined,
  visible: false,
  currentTab: undefined,
  lichKhamInfo: undefined,
  dsDoiTuong: [],
  dsNguonKhach: [],
  dsGoiDvBenhNhan: [],
  siderLoading: false,
  selectedPacket: undefined,
  dsPhongTheoDichVu: [],
  dsBacSiTheoPhong: [],
  chiTietDsGoiDv: undefined,
  dsPhongKemTheo: [],
  visibleSubActions:false,
  thongTinThanhToan : undefined,
  duplicateService: {
    list:[],
    finishProps: {},
    contentWarning: '',
    hightLighter: false,
    openWarnModal: false,
    loading:false,
  }
};

const tiepDonSlice = createSlice({
  name: "tiepDonSlice",
  initialState: initState,
  reducers: {
    doFetchDsGoiDvBenhNhan: (state, action) => {},
    doGetChiTietLichKhamGoi: (state, action) => {},
    
    setSelectedPatient: (state, action) => {
      state.selectedPatient = action.payload;
    },
    setSelectedLichKham: (state, action) => {
      state.lichKhamInfo = action.payload;
    },
    setSiderLoading: (state, action) => {
      state.siderLoading = action.payload;
    },

    setDsGoiDvBenhNhan: (state, action) => {
      state.dsGoiDvBenhNhan = action.payload;
    },

    setSelectedPacket: (state, action) => {
      state.selectedPacket = action.payload;
    },
    updateDsBacSi: (state, action) => {
      state.dsBacSiTheoPhong = [...state.dsBacSiTheoPhong, ...action.payload];
    },
    setDsPhong: (state, action) => {
      state.dsBacSiTheoPhong = action.payload;
    },
    updateDsPhong: (state, action) => {
      state.dsPhongTheoDichVu = [...state.dsPhongTheoDichVu, ...action.payload];
    },
    setChiTietDsGoiDv: (state, action) => {
      state.chiTietDsGoiDv = action.payload;
    },
    resetState() {
      return initState;
    },
    updateDsPhongKt: (state, action) => {
      state.dsPhongTheoDichVu = [...state.dsPhongKemTheo, ...action.payload];
    },

    setVisibleSubActions: (state, action) => {
      state.visibleSubActions = action.payload;
    },
    setChiTietLichKham: (state ,action) => {
      state.chiTietLichKham = action.payload;
    },
    setDuplicateService: (state,action) => {
      state.duplicateService = action.payload;
    },
    setThongTinThanhToan: (state, action) => {
      state.thongTinThanhToan = action.payload;
    },
    
  },
});

export const TiepDonActions = tiepDonSlice.actions;
export const tiepDonState = (state) => state.receipt.tiepDon;

export default tiepDonSlice.reducer;
