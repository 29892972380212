import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataListServicePack: {
    data: [],
    loading: false,
  },
  dataPackageSubscriptionList: {
    data: [],
    loading: false,
    limit: 15,
    page: 1,
    total: 0,
    isReload: false,
  },
  detailDataPackageSubscription: {
    data: {},
    packageSubscription: {},
    loading: false,
  },
  commonLoading : false,
};

const SignUpForAServicePack = createSlice({
  name: "SignUpForAServicePack",
  initialState,
  reducers: {
    resetListServicePack: (state, value) => {
      state.dataListServicePack = initialState.dataListServicePack;
    },
    getListServicePack: (state, value) => {
      state.dataListServicePack = {
        ...state.dataListServicePack,
        loading: true,
      };
    },
    listServicePackSuccess: (state, { payload }) => {
      state.dataListServicePack = {
        data: payload,
        loading: false,
      };
    },

    listServicePackFalse: (state, { payload }) => {
      state.dataListServicePack = {
        data: [],
        loading: false,
      };
    },

    register: (state, value) => {console.log("value",value);},
    registerSuccess: (state, { payload }) => {
      state.dataPackageSubscriptionList = {
        ...state.dataPackageSubscriptionList,
        isReload: true
      };
    },

    editRegistration: (state, value) => {},
    editRegistrationSuccess: (state, { payload }) => {
      state.dataPackageSubscriptionList = {
        ...state.dataPackageSubscriptionList,
        isReload: true
      };
    },

    packageSubscriptionList: (state, value) => {
      state.dataPackageSubscriptionList= {
        ...state.dataPackageSubscriptionList,
        loading: true,
      }
    },
    packageSubscriptionListSuccess: (state, { payload }) => {
      state.dataPackageSubscriptionList = {
        data: payload.data,
        loading: false,
        limit: payload.limit,
        page: payload.page,
        total: payload.total,
        isReload: false
      };
    },

    detailPackageSubscription: (state, value) => {
        state.detailDataPackageSubscription = {
            ...state.detailDataPackageSubscription,
            loading: true,
          };
    },
    detailPackageSubscriptionSuccess: (state, { payload }) => {
      state.detailDataPackageSubscription = {
        data: payload.data,
        packageSubscription:payload.packageSubscription,
        loading: false,
      };
    },

    packageSubscriptionListFalse: (state, { payload }) => {
      state.dataPackageSubscriptionList = {
        data: [],
        loading: false,
        limit: 20,
        page: 1,
      };
    },

    pausePackage: (state, value) => {},
    pausePackageSuccess: (state, { payload }) => {},

    deletePackage: (state, value) => {
      console.log(`value`, value);
    },
    deletePackageSuccess: (state, { payload }) => {
      state.dataPackageSubscriptionList = {
        ...state.dataPackageSubscriptionList,
        isReload: true
      };
    },
    setCommonLoadingState: (state, action) => {
      state.commonLoading = action.payload;
    },

    subcribeBundles: () => {},
  },
});

export const {
  detailPackageSubscription,
  detailPackageSubscriptionSuccess,
  getListServicePack,
  listServicePackSuccess,
  register,
  registerSuccess,
  editRegistration,
  editRegistrationSuccess,
  packageSubscriptionList,
  packageSubscriptionListSuccess,
  pausePackage,
  pausePackageSuccess,
  deletePackage,
  deletePackageSuccess,
  setCommonLoadingState,
  subcribeBundles
} = SignUpForAServicePack.actions;
export default SignUpForAServicePack.reducer;
