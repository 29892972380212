import React from "react";
import style from "./style.module.less";
import i18n, { languageKeys } from "../../../i18n";
import { Row, Col, Button } from "antd";
import Table from "../../Table/Table";
import { common_post, convertDateToValue } from "../../../helpers";
import { PrinterOutlined } from "@ant-design/icons";
import { rid } from "../../../helpers";

const ItemDonThuoc = ({ item, onPrint }) => {
  return (
    <div className={style["container"]}>
      <Row className={style["date"]} gutter={10}>
        <Col span={5}>
          <p>{i18n.t(languageKeys.field_Ngay_kham)}: {convertDateToValue(item.NGAY_Y_LENH)}</p>
        </Col>
        <Col span={7}>
          <p>{i18n.t(languageKeys.field_Noi_kham)}: {item?.TEN_CSKCB}</p>
        </Col>
        <Col span={12}>
          <p>{i18n.t(languageKeys.field_Dia_chi)}: {item?.DIA_CHI_CSKCB}</p>
        </Col>
      </Row>
      <Table columns={columns} dataSource={item.CHI_TIET_THUOC?.map((item) => ({ ...item, key: rid(9) }))} key={rid()} />
      <Row justify={"end"} style={{ marginTop: 20, marginBottom: 10, marginRight: 10 }}>
        <Button type="primary" icon={<PrinterOutlined />} style={{ marginLeft: 15 }} onClick={() => onPrint(item.DON_THUOC_ID)}>
          {i18n.t(languageKeys.button_In_don_thuoc)}
        </Button>
      </Row>
    </div>
  );
};
export default React.memo(ItemDonThuoc);

const columns = [
  {
    title: i18n.t(languageKeys.field_Ma_thuoc),
    key: "MA_THUOC",
    dataIndex: "MA_THUOC",
    width: 120,
  },
  {
    title: i18n.t(languageKeys.field_ten_thuoc),
    key: "TEN_THUOC",
    dataIndex: "TEN_THUOC",
    width: 200,
  },
  {
    title: i18n.t(languageKeys.field_Hoat_chat),
    key: "TEN_HOAT_CHAT",
    dataIndex: "TEN_HOAT_CHAT",
    width: 150,
  },
  {
    title: i18n.t(languageKeys.field_Don_vi),
    key: "TEN_DVT",
    dataIndex: "TEN_DVT",
    width: 80,
  },

  {
    title: i18n.t(languageKeys.sl),
    key: "SO_LUONG",
    dataIndex: "SO_LUONG",
    width: 80,
    align: "center",
  },
  {
    title: i18n.t(languageKeys.so_ngay_sd),
    key: "SO_NGAY_SD",
    dataIndex: "SO_NGAY_SD",
    align: "center",
    width: 120,
  },
  {
    title: i18n.t(languageKeys.huong_dan_su_dung),
    key: "CHI_DAN",
    dataIndex: "CHI_DAN",
    width: 200,
  },
];
