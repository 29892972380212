import { useKeycloak } from "@react-keycloak/web";
import { Result, Spin } from "antd";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { actionUrls, keys, paths } from "../constants";
import { HLog, isAccessAllowed } from "../helpers";
import { Suspense, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useQuery } from "hooks";
import i18n,{ languageKeys } from "../i18n";

export const PublicRoute = ({ component: Component, ...rest }) => {
  const { keycloak } = useKeycloak();
    
  let keyUrl = localStorage.getItem(keys.REDIRECT);

  const checkingStoreUrl = keyUrl === actionUrls.logout;

  const isAuth = !!keycloak.authenticated && !checkingStoreUrl;
  return <Route {...rest} render={(props) => (isAuth ? <Redirect to={paths.main} /> : <Component {...props} />)} />;
};

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const query = useQuery();
    const queryLang = query.get(keys.lang);
  const { keycloak } = useKeycloak();
  const tokens = useSelector((state) => state.auth.tokens);

  
  let keyUrl = localStorage.getItem(keys.REDIRECT);

  const checkingStoreUrl = keyUrl === actionUrls.logout;

  const isAuth = !!keycloak.authenticated && !checkingStoreUrl;
  return <Route {...rest} render={(props) => (isAuth ? <Component {...props} /> : <Redirect to={paths.auth} />)} />;
};

export const FeatureRoute = ({ component: Component, featureKey, path, ...remainingProps }) => {
  const userProfile = useSelector((state) => state.auth.user);

  return (
    <Route
      {...remainingProps}
      render={(props) => {
        return isAccessAllowed(userProfile, featureKey, true) ? (
          <Suspense
            fallback={
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 24,
                      position: "fixed",
                      top: "50vh",
                      left: "50%",
                    }}
                    spin
                  />
                }
              />
            }
          >
            <Component {...props} />
          </Suspense>
        ) : (
          <Result status="403" title={i18n.t(languageKeys.noti_truy_cap_bi_tu_choi)} subTitle={i18n.t(languageKeys.noti_permission_route_deny)} />
        );
      }}
    />
  );
};
