import { memo } from "react";
import Icon, { EllipsisOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button, notification, Popover, Row, Space, Switch, Tooltip } from "antd";
import i18n, { languageKeys } from "../../i18n";
import actionButton from "./actionButton.module.less";
import { getErrorMessage, HLog } from "../../helpers";
import { CopyIcon, Delete, Edit, EditAlt, MoneyWithdrawal } from "../../assets/svg";
import classNames from "classnames";
import { Robot } from "../../assets/svg";

export const ActionButton = ({
  record = {}, // Bản ghi
  onDelete = () => {}, // Hàm xóa bản ghi
  onEdit = () => {}, // Hàm sửa bản ghi
  showEdit = true,
  showPrint = false,
  onPrint = () => {}, // Hàm in bản ghi
  showDelete = true,
  showLock = false,
  onLock = () => {},
  onPay = () => {},
  deleteDisabled = false,
  popupVersion = false,
  mapCateKey,
  disabledPrint = false,
  disabledLock = false,
  disabledPay = false,
  onToggle,
  cateKey,
  editDisabled = false,
  deleteTip = i18n.t(languageKeys.tip_xoa_ban_ghi),
  lockTip = i18n.t(languageKeys.khoa_ba),
  fixedTip = i18n.t(languageKeys.tip_sua_ban_ghi),
  printTip = i18n.t(languageKeys.tip_in_ban_ghi),
  copyTip = i18n.t(languageKeys.tip_copy_ban_ghi),
  payTip = i18n.t(languageKeys.thanh_toan),
  multipleDeleteTip = i18n.t(languageKeys.tip_xoa_nhieu),
  aiTip = i18n.t(languageKeys.tip_chat_ai),
  style = {},
  tooltipInTheoQuyen = true,
  tooltipXoaTheoQuyen = true,
  tooltipKhoaTheoQuyen = true,
  tooltipSuaTheoQuyen = true,
  isLocked,
  tooltipCopyTheoQuyen = true,
  showCopy = false,
  isEnableCopy = false,
  showPay = false,
  showAi = false,
  firstDynamicSlot = {
    show: false,
    title: 'firstDynamicSlot',
    content: 'firstDynamicSlot',
    buttonProps: {},
    className: '',
  },
  onCopy = () => {}
}) => {
  // Hàm xử lý bật/tắt Khóa
  const handleToggle = async (val) => {
    try {
      const key_hien_thi = mapCateKey()[cateKey].fieldData.hien_thi;
      const bitVal = !!val ? 1 : 0;
      const res = await onToggle({ ...record, [key_hien_thi]: bitVal });

      if (res.status === "OK") {
        record[key_hien_thi] = bitVal;
        return res;
      }

      return res;
    } catch (error) {
      HLog("Handle toggle trang thai hien thi ERROR", error);
    }
  };

  // Hàm xử lý khi bật/tắt thành công
  const onToggleSuccess = () => {
    notification.success({
      message: i18n.t(languageKeys.cap_nhat_thanh_cong),
      placement: "bottomLeft",
    });
  };

  // Hàm xử lý khi bật/tắt thất bại
  const onToggleFail = (res) => {
    let error_msg = getErrorMessage(res.error_code, i18n.t(languageKeys.cap_nhat_that_bai));

    if (!!mapCateKey()[res.field]) {
      error_msg += `: ${i18n.t(languageKeys.cate_Danh_muc)} ${i18n.t(mapCateKey()[res.field].langKey)}`;
    }

    notification.error({
      message: error_msg,
      placement: "bottomLeft",
    });
  };

  if (popupVersion) {
    return (
      <Popover
        content={
          /* ================= Khóa Bật/Tắt ================== */
          <div onClick={(e) => e.stopPropagation()}>
            {!!onToggle && (
              <div className={actionButton["wrapper"]}>
                <div className={actionButton["label"]}>{i18n.t(languageKeys.field_Hien_thi)}</div>

                <div className={actionButton["inner"]}>
                  <Switch
                    onToggle={handleToggle}
                    initValue={record[mapCateKey()[cateKey].fieldData.hien_thi]}
                    onSuccess={onToggleSuccess}
                    onFail={onToggleFail}
                  />
                </div>
              </div>
            )}

            <div className={actionButton["wrapper"]}>
              <div className={actionButton["label"]}>{i18n.t(languageKeys.field_Thao_tac)}</div>
              {showPay && (
                <Tooltip title={payTip}>
                  <Button
                    icon={<MoneyWithdrawal className={actionButton[disabledPay ? "icon-money-disabled" : "icon-money"]} />}
                    type="link"
                    ghost
                    disabled={disabledPay}
                    onClick={() => onPay(record)}
                  />
                </Tooltip>
              )}
              {firstDynamicSlot.show && (
                <Tooltip title={firstDynamicSlot.title}>
                  <Button
                    icon={<MoneyWithdrawal className={firstDynamicSlot.className} />}
                    {...firstDynamicSlot.buttonProps}
                  >{firstDynamicSlot.content}</Button>
                </Tooltip>
              )}
              {/* ================= Xóa bản ghi ================== */}
              {showDelete && (
                <Tooltip placement="topLeft" title={deleteTip}>
                  <Row className={actionButton["item"]} onClick={() => onDelete(record)}>
                    <Delete /> <div className={actionButton["txt"]}>{i18n.t(languageKeys.common_Xoa)}</div>
                  </Row>
                </Tooltip>
              )}

              {/* ================= Sửa bản ghi ================== */}
              {showEdit && (
                <Tooltip placement="topLeft" title={fixedTip}>
                  <Row className={actionButton["item"]} onClick={() => onEdit(record)}>
                    <Edit /> <div className={actionButton["txt"]}>{i18n.t(languageKeys.common_Chinh_sua)}</div>
                  </Row>
                </Tooltip>
              )}

              {showPrint && (
                <Tooltip placement="topLeft" title={printTip}>
                  <Row className={actionButton["item"]} onClick={() => onPrint(record)}>
                    <PrinterOutlined style={{ color: "#999", fontSize: 20, marginLeft: 2 }} />{" "}
                    <div className={actionButton["txt"]}>{i18n.t(languageKeys.field_In_phieu)}</div>
                  </Row>
                </Tooltip>
              )}
              {showAi && (
                <Tooltip placement="topLeft" title={printTip}>
                  <Button
                    icon={<Icon component={Robot} style={{ color: "#999", fontSize: 20, marginLeft: 2 }} />}
                    type="link"
                    ghost
                  // disabled={disabledPay}
                  // onClick={() => onPay(record)}
                  >
                    {i18n.t(languageKeys.tro_ly_ai)}
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
        }
        trigger="click"
        placement="bottomRight"
        overlayClassName={actionButton["popup"]}
      >
        {/* ================= Nút \dấu 3 chấm dọc\ ================== */}
        <Button icon={<EllipsisOutlined />} type="link" className={actionButton["action-btn"]} onClick={(e) => e.stopPropagation()}></Button>
      </Popover>
    );
  }

  return (
    <div style={{ display: "flex", gap: 10,alignItems:"center", ...style }} onClick={(e) => e.stopPropagation()}>
      {showLock && (
        <Row className={actionButton["item"]} align={"middle"}>
          <Tooltip title={!tooltipKhoaTheoQuyen ? i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay) : lockTip}>
            <Switch disabled={disabledLock} checked={isLocked} onClick={() => onLock(record)} />
          </Tooltip>
        </Row>
      )}

      {showPay && (
        <Tooltip title={payTip}>
          <Button
            icon={<MoneyWithdrawal className={actionButton[disabledPay ? "icon-money-disabled" : "icon-money"]} />}
            type="link"
            ghost
            disabled={disabledPay}
            onClick={() => onPay(record)}
            style={{ width: 'unset' }}
          />


        </Tooltip>
      )}
      {firstDynamicSlot.show && (
        <Tooltip title={firstDynamicSlot.title}>
          <Button {...firstDynamicSlot.buttonProps}>{firstDynamicSlot.content}</Button>
        </Tooltip>
      )}
      {showPrint && (
        <Tooltip placement="topLeft" title={!tooltipInTheoQuyen ? i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay) : printTip}>
          <Button
            type="link"
            icon={<PrinterOutlined className={actionButton["icon"]} />}
            onClick={() => onPrint(record)}
            disabled={disabledPrint}
            className={classNames(actionButton["print-btn-icon"], disabledPrint && actionButton["disabled-print"])}
          >
            {/* {i18n.t(languageKeys.field_In_phieu)} */}
          </Button>
        </Tooltip>
      )}

      {showEdit && (
        <Tooltip placement="topLeft" title={tooltipSuaTheoQuyen ? fixedTip : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
          <Button type="link" onClick={() => onEdit(record)} style={{ padding: 0, display: "flex", alignItems: "center" }} disabled={editDisabled}>
            {/* {i18n.t(languageKeys.common_Chinh_sua)} */}
            <EditAlt className={actionButton[editDisabled ? "icon-edit-btn-dis" : "icon-edit-btn"]} style={{ transform: "scale(1.2)" }} />
          </Button>
        </Tooltip>
      )}

      {showCopy && (
        <Tooltip placement="topLeft" title={!tooltipCopyTheoQuyen ? i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay) : copyTip}>
          <Button type="link" style={{ padding: 0, display: "flex", alignItems: "center" }} disabled={isEnableCopy} onClick={(e) => onCopy(e, record)}>
            {/* {i18n.t(languageKeys.common_Chinh_sua)} */}
            <CopyIcon className={actionButton[isEnableCopy ? "icon-copy-btn-dis" : "icon-edit-btn"]} style={{ transform: "scale(1.2)" }} />
          </Button>
        </Tooltip>
      )}

      {showDelete && (
        <Tooltip placement="topLeft" title={tooltipXoaTheoQuyen ? deleteTip : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>

          <Button
            type="link"
            onClick={() => onDelete(record)}
            disabled={deleteDisabled}
            style={{ padding: 0, display: "flex", alignItems: "center" }}
            tabIndex="-1"
          >
            {/* {i18n.t(languageKeys.common_Xoa)} */}

            <Delete style={{ width: 20 }} className={actionButton[deleteDisabled ? "disable-icon-delete-btn" : "icon-delete-btn"]} />
          </Button>
        </Tooltip>
      )}
      {showAi && (
        <Tooltip placement="topLeft" title={aiTip}>
          <Button
            icon={<Icon component={Robot} className={actionButton['svg-fill-white']} style={{ fontSize: 20 }} />}
            type="primary"
          // ghost
          // disabled={disabledPay}
          // onClick={() => onPay(recoard)}
          >
            {i18n.t(languageKeys.tro_ly_ai)}
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

export default memo(ActionButton);
