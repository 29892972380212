const { createSlice } = require("@reduxjs/toolkit")

const initState = {
    data: [],
}

const examHistorySlice = createSlice({
    name: 'examHistorySlice',
    initialState: initState,
    reducers: {
        getExamHistory: () => {},
        getExamHistorySuccess: (state, action) => {
            const {result} = action.payload;
            state.data = result
        },
    }
})

export const {getExamHistory, getExamHistorySuccess, updateDetailData} = examHistorySlice.actions;
export const getExamHistoryData = (state) => state.servicePacket.ExamHistory;
export default examHistorySlice.reducer;