import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { paths } from "../constants";
import { HomeLogin } from "../pages";

export const AuthRoute = () => {
  return (
    <Switch>
      
      <Route exact path={paths.dang_nhap} component={HomeLogin} />

      <Redirect exact path={paths.auth} to={paths.dang_nhap} />
    </Switch>
  );
};
