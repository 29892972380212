import { put } from 'redux-saga/effects';
import { setSelectLoading } from 'ducks/slices/loadingSlice';
import { takeLatest } from '@redux-saga/core/effects';
import { common_post } from 'helpers';
import { apis } from "../../../constants";
import { getCity, getCitySuccess, getDistrict, getDistrictSuccess, getWard, getWardSuccess } from 'ducks/slices/ListData/CityDistrictWard.slice';

export function* watchDoCityDistrictWard() {
    yield takeLatest(getCity, handleFetchCity)
    yield takeLatest(getDistrict, handleFetchDistrict)
    yield takeLatest(getWard, handleFetchWard)
}

function* handleFetchCity() {
    try {
        yield put(setSelectLoading(true));
        const res = yield common_post(apis.dm_tinhthanh_lay_ds, {})
        if (res) {
            if (res.status === 'OK') {
                yield put(getCitySuccess(res));
            }
        }
    } catch (err) {
        console.log(err.message);
    } finally {
        yield put(setSelectLoading(false));
    }
}

function* handleFetchDistrict(action) {
    try {
        yield put(setSelectLoading(true));
        const res = yield common_post(apis.dm_quanhuyen_lay_ds, action.payload)
        if (res) {
            if (res.status === 'OK') {
                yield put(getDistrictSuccess(res));
            }
        }
    } catch (err) {
        console.log(err.message);
    } finally {
        yield put(setSelectLoading(false));
    }
}

function* handleFetchWard(action) {
    try {
        yield put(setSelectLoading(true));
        const res = yield common_post(apis.dm_xaphuong_lay_ds, action.payload)
        if (res) {
            if (res.status === 'OK') {
                yield put(getWardSuccess(res));
            }
        }
    } catch (err) {
        console.log(err.message);
    } finally {
        yield put(setSelectLoading(false));
    }
}