import moment from "moment";
import React from "react";
import { layDiaChi, getSexFromString, rid } from "../../../helpers";
import { useSelector } from "react-redux";
import i18n, { languageKeys, languages } from "../../../i18n";
import Images from "components/Images/Images";
import { enDateFormat } from "constants/keys";

export const styleTienTrinhKhoiTao = `
#tien-trinh-khoi-tao * { font-size: 12px; line-height: 1rem; font-family: "Times New Roman", Times, serif; }

#tien-trinh-khoi-tao .column {
  float: left;
  width: 33.33%;
}

#tien-trinh-khoi-tao .row:after {
  content: "";
  display: table;
  clear: both;
}

#tien-trinh-khoi-tao .container-row {
  margin-top: 8px;
}

#tien-trinh-khoi-tao .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#tien-trinh-khoi-tao .header { display: flex; gap: 10px; }
#tien-trinh-khoi-tao .flex {
    display: flex;
  }
#tien-trinh-khoi-tao .flex.justify {
    justify-content: space-around;
  }

#tien-trinh-khoi-tao .text-bold {
    font-size: 12px;
    font-weight: bold !important;
  }

  #tien-trinh-khoi-tao .table table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
  }

  #tien-trinh-khoi-tao td, #tien-trinh-khoi-tao th {
    border: 1px solid #000;
    // text-align: center;
    padding: 6px;
    // color: #2c3782;
  }

  #tien-trinh-khoi-tao .table th:first-child {
    border: 1px solid #000;
    text-align: left;
    padding: 6px;
  }

#tien-trinh-khoi-tao .tel {
    margin-right: 50px;
  }
#tien-trinh-khoi-tao .txt-700 {
    font-weight: 700;
  }
#tien-trinh-khoi-tao .container-title {
  text-transform: uppercase; font-size: 14px; font-weight: bold; text-align: center; width: 90%; margin: 2rem auto 1rem auto;
  }
#tien-trinh-khoi-tao .container__title--main {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }
#tien-trinh-khoi-tao .container__ttbn--wrapper {
  margin-top: 6px;
}
#tien-trinh-khoi-tao .container__ttbn--wrapper>div {
    margin: 4px 0 0 0;
  }
#tien-trinh-khoi-tao .container-wrapper-flex {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  #tien-trinh-khoi-tao .container-wrapper-flex .col-3{
    width: 30%;
  }

  #tien-trinh-khoi-tao .container-wrapper-flex .col-4{
    width: 40%;
  }

  #tien-trinh-khoi-tao .thongtin-khambenh {
    width: 100%;
    display: flex;
    margin-top: 12px;
  }

  #tien-trinh-khoi-tao .thongtin-khambenh .left {
    // width: 65%;
    display: flex;
    flex-direction: column;
  }

  #tien-trinh-khoi-tao .thongtin-khambenh .right {
    width: 35%;
  }

  #tien-trinh-khoi-tao .table-border {
    padding: 6px 0;
    border: 1px solid #000;
  }

  #tien-trinh-khoi-tao .table-item{
    padding-left:12px;
  }

  #tien-trinh-khoi-tao hr.solid {
    border-top: 1px solid #000;
  }

  #tien-trinh-khoi-tao .footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 40px;
  }

  #tien-trinh-khoi-tao .footer .footer-confirm {
    margin-right: 44px;
    text-align: center;
  }

  #tien-trinh-khoi-tao .name-space {
    height: 80px;
  }

  #tien-trinh-khoi-tao .txt-size-m{
    font-size: 12px;
  }

  #tien-trinh-khoi-tao .upperTxt{
    text-transform: uppercase;
  }

  #tien-trinh-khoi-tao .phone-website { 
    display: flex; gap: 24px; align-items: center 
  }

@media print {
#tien-trinh-khoi-tao .foot-break { break-inside: avoid; }
}

#tien-trinh-khoi-tao .table { margin-block: 10px; }
#tien-trinh-khoi-tao .table * { font-size: 12px; }
#tien-trinh-khoi-tao .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#tien-trinh-khoi-tao .table th, .table td { padding: 5px 10px; }
#tien-trinh-khoi-tao .table th { text-align: start; }
`;

const PhieuTienTrinhKhoiTao = ({ data }) => {

  const userProfile = useSelector((state) => state.auth.user);
  let { benh_nhan, hoi_benh, kham_benh, danh_sach_dich_vu, danh_sach_vat_tu } = data;

  return (
    <div id="tien-trinh-khoi-tao">
      <div className="header">
        {!!userProfile.benh_vien.ANH_DAI_DIEN && (
          <div className="img-header">
            <Images className="logo" imgId={userProfile.benh_vien.ANH_DAI_DIEN} />
          </div>
        )}
        <div className="content-header">
          <h2 style={{ margin: 0, fontSize: 10 }} className="upperTxt">
            {userProfile?.benh_vien?.TEN_CSKCB}
          </h2>
          <div>
            <span className="txt-700">{i18n.t(languageKeys.dia_chi)}:</span> {layDiaChi(userProfile.benh_vien)}
          </div>
          <div className="flex">
            <div className="tel">
              <span className="txt-700"> {i18n.t(languageKeys.dien_thoai)}: </span> {convertString(userProfile.benh_vien.SO_DIEN_THOAI)}
            </div>
            <div>
              <span className="txt-700">Website:</span> {convertString(userProfile.benh_vien.WEBSITE)}
            </div>
          </div>
          <div>
            <span className="txt-700">Email:</span> {convertString(userProfile.benh_vien.EMAIL)}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="container-title">
          {i18n.t(languageKeys.phieu_thong_tin_tu_van_khach_hang)}
        </div>
        <div className="container__chapter--1">
          <div className="container__title--main">I. {i18n.t(languageKeys.field_Thong_tin_hanh_chinh)?.toUpperCase()}</div>
          <div className="container__ttbn--wrapper">
            <div className="container-wrapper-flex">
              <div className="col-3">
                <div>
                  {i18n.t(languageKeys.field_Ma_benh_nhan)}: <span className="txt-700 txt-size-m">{convertString(benh_nhan.ma_benh_nhan)}</span>
                </div>
                <div>
                  {i18n.t(languageKeys.field_Gioi_tinh)}: {getSexFromString(benh_nhan.gioi_tinh)}
                </div>
              </div>
              <div className="col-4">
                <div>
                  {i18n.t(languageKeys.ten_benh_nhan)}:{" "}
                  <span className="txt-700 txt-size-m"> {convertString(benh_nhan.ten_benh_nhan)?.toUpperCase()} </span>
                </div>
                <div>
                  {i18n.t(languageKeys.field_Doi_tuong)}: {convertString(benh_nhan.doi_tuong)}
                </div>
              </div>
              <div className="col-3">
                <div>
                  {benh_nhan.ngay_sinh.trim().length > 4 ? i18n.t(languageKeys.field_Ngay_sinh) : i18n.t(languageKeys.field_Nam_sinh)}:{" "}
                  {benh_nhan.ngay_sinh}
                </div>
                <div>
                  {i18n.t(languageKeys.field_So_dien_thoai)}: <span className="txt-700">{convertString(benh_nhan.sdt)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="dia-chi">
            {i18n.t(languageKeys.dia_chi)}: {convertString(benh_nhan.dia_chi)?.slice(-1) === ","
              ? convertString(benh_nhan.dia_chi).slice(0, -1).replaceAll(",", ", ")
              : convertString(benh_nhan.dia_chi).replaceAll(",", ", ")
            }
          </div>
        </div>

        {/* <div className="thongtin-khambenh" style={{ display: "flex", flexDirection: "column" }}>
          <div className="container__title--main">II. {i18n.t(languageKeys.hoi_benh)?.toUpperCase()}</div>
          <div className="container-row">
            <div className="row">
              <div>
                <span className="txt-700"> 1. {i18n.t(languageKeys.field_Ly_do_kham)}: </span> {hoi_benh.ly_do_kham}
              </div>
              <div style={{ marginTop: '6px' }}>
                <span className="txt-700"> 2. {i18n.t(languageKeys.field_Qua_trinh_benh_ly)}: </span> {hoi_benh.qua_trinh_benh_ly}
              </div>
              <div style={{ marginTop: '6px' }}>
                <span className="txt-700"> 3. {i18n.t(languageKeys.trieu_chung_lam_sang)}: </span> {hoi_benh.trieu_chung_lam_sang}
              </div>
              <div style={{ marginTop: '6px' }}>
                <div className="txt-700"> 4. {i18n.t(languageKeys.tien_su_benh)}: </div>
                <ul style={{ margin: 0 }}>
                  {!!hoi_benh.tien_su_ban_than && (
                    <li>
                      {i18n.t(languageKeys.field_Tien_su_ban_than)} : {hoi_benh.tien_su_ban_than}
                    </li>
                  )}
                  {!!hoi_benh.tien_su_san_khoa && (
                    <li>
                      {i18n.t(languageKeys.tien_su_san_khoa)}: {hoi_benh.tien_su_san_khoa}
                    </li>
                  )}
                  {!!hoi_benh.tien_su_di_ung && (
                    <li>
                      {i18n.t(languageKeys.tien_su_di_ung)}: {hoi_benh.tien_su_di_ung}
                    </li>
                  )}
                  {!!hoi_benh.tai_nan_thuong_tich && (
                    <li>
                      {i18n.t(languageKeys.tai_nan_thuong_tich)}: {hoi_benh.tai_nan_thuong_tich}
                    </li>
                  )}
                  {!!hoi_benh.tien_su_gia_dinh && (
                    <li>
                      {i18n.t(languageKeys.field_Tien_su_gia_dinh)}: {hoi_benh.tien_su_gia_dinh}
                    </li>
                  )}
                  {!!hoi_benh.tien_su_benh_khac && (
                    <li>
                      {i18n.t(languageKeys.tien_su_benh_khac)}: {hoi_benh.tien_su_benh_khac}
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="thongtin-khambenh">
          <div className="left">
            <div className="container__title--main" >III. {i18n.t(languageKeys.kham_benh)?.toUpperCase()}</div>
            <div style={{ marginTop: '6px' }}>
              <span className="txt-700"> 1. {i18n.t(languageKeys.toan_than)}: </span> {kham_benh.toan_than}
            </div>
            <div style={{ marginTop: '6px' }}>
              <div className="txt-700"> 2. {i18n.t(languageKeys.cac_bo_phan)}: </div>
              <ul style={{ margin: 0 }}>
                {!!kham_benh.tuan_hoan && (
                  <li>
                    {i18n.t(languageKeys.tuan_hoan)} : {kham_benh.tuan_hoan}
                  </li>
                )}
                {!!kham_benh.ho_hap && (
                  <li>
                    {i18n.t(languageKeys.ho_hap)}: {kham_benh.ho_hap}
                  </li>
                )}
                {!!kham_benh.tieu_hoa && (
                  <li>
                    {i18n.t(languageKeys.tieu_hoa)}: {kham_benh.tieu_hoa}
                  </li>
                )}
                {!!kham_benh.than_tiet_nieu && (
                  <li>
                    {i18n.t(languageKeys.than_tiet_nieu)}: {kham_benh.than_tiet_nieu}
                  </li>
                )}
                {!!kham_benh.noi_tiet && (
                  <li>
                    {i18n.t(languageKeys.noi_tiet)}: {kham_benh.noi_tiet}
                  </li>
                )}
                {!!kham_benh.co_xuong_khop && (
                  <li>
                    {i18n.t(languageKeys.co_xuong_khop)}: {kham_benh.co_xuong_khop}
                  </li>
                )}
                {!!kham_benh.than_kinh && (
                  <li>
                    {i18n.t(languageKeys.than_kinh)}: {kham_benh.than_kinh}
                  </li>
                )}
                {!!kham_benh.tam_than && (
                  <li>
                    {i18n.t(languageKeys.mental)}: {kham_benh.tam_than}
                  </li>
                )}
              </ul>
            </div>
            <div style={{ marginTop: '6px' }}>
              <span className="txt-700"> 3. {i18n.t(languageKeys.field_Chan_doan_benh_chinh)}: </span> {kham_benh.chan_doan_benh_chinh}
            </div>
            <div style={{ marginTop: '6px' }}>
              <span className="txt-700"> 4. {i18n.t(languageKeys.field_Ma_benh_kem_theo)}: </span> {kham_benh.chan_doan_kem_theo}
            </div>
            <div style={{ marginTop: '6px' }}>
              <span className="txt-700"> 5. {i18n.t(languageKeys.field_Chan_doan_xac_dinh)}: </span> {kham_benh.chan_doan_xac_dinh}
            </div>
            <div style={{ marginTop: '6px' }}>
              <span className="txt-700"> 6. {i18n.t(languageKeys.Ket_qua_CLS)}: </span> {kham_benh.ket_luan}
            </div>
          </div>
        </div> */}

        <div className="container__title--main" style={{ margin: "10px 0" }}>
          {/* IV. {i18n.t(languageKeys.phac_do_dieu_tri).toUpperCase()} */}
          II. {i18n.t(languageKeys.goi_dich_vu).toUpperCase()}
        </div>
        <div style={{ margin: "0 0 10px 10px" }}>
          <div>
            {i18n.t(languageKeys.ten_goi_dv)}: {data.ten_goi_dv}
          </div>
          {/* <div>
            {i18n.t(languageKeys.so_buoi_sd)}: {data.so_buoi_su_dung}
          </div> */}
        </div>


        {/* <span className="txt-700 txt-size-m">{i18n.t(languageKeys.lieu_trinh_dieu_tri)}</span> */}
        <table style={{ width: "100%" }} className="table">
          <thead>
            <tr>
              <th style={{ width: "28px", textAlign: 'center' }}>{i18n.t(languageKeys.field_Stt)}</th>
              <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_Ten_dich_vu)}</th>
              <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.txt_So_luong)}</th>
            </tr>
          </thead>
          <tbody>
            {danh_sach_dich_vu.map((item, index) => {
              return (
                <tr key={rid()}>
                  <td style={{ textAlign: 'center' }}>{index + 1 >= 10 ? index + 1 : `0${index + 1}`}</td>
                  <td>{item.TEN_DICHVU}</td>
                  <td style={{ textAlign: 'center' }}>{item.SO_LUONG}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* {danh_sach_vat_tu?.[0] && (
          <>
            <span className="txt-700 txt-size-m">{i18n.t(languageKeys.thuoc_dieu_tri)}</span>
            <table style={{ width: "100%" }} className="table">
              <thead>
                <tr>
                  <th style={{ width: "28px", textAlign: 'center' }}>{i18n.t(languageKeys.field_Stt)}</th>
                  <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_Ten_thuoc)}</th>
                  <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.ham_luong_nong_do)}</th>
                  <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.txt_So_luong)}</th>
                  <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.duong_dung)}</th>
                  <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.huong_dan_su_dung)}</th>
                </tr>
              </thead>
              <tbody>
                {danh_sach_vat_tu.map((item, index) => {
                  return (
                    <tr key={rid()}>
                      <td style={{ textAlign: 'center' }}>{index + 1 >= 10 ? index + 1 : `0${index + 1}`}</td>
                      <td>{item.TEN_VAT_TU}</td>
                      <td>{item.HAM_LUONG || item.NONG_DO}</td>
                      <td style={{ textAlign: 'center' }}>{item.SO_LUONG}</td>
                      <td>{item.DUONG_DUNG}</td>
                      <td>{item.HUONG_DAN_SU_DUNG}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )} */}
      </div>

      <div className="container__title--main">
        <i>{i18n.t(languageKeys.toi_da_duoc_tu_van_va_dong_y)}.</i>
      </div>

      <div className="footer foot-break">

        <div style={{ marginTop: 16, textAlign: 'center', marginLeft: 44 }}>
          <div className="txt-700">{i18n.t(languageKeys.khach_hang)}</div>
          <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
          <div className="name-space" />
          <div className="txt-700">{benh_nhan.ten_benh_nhan}</div>
        </div>

        <div className="footer-confirm">
          {i18n.language === languages.tieng_viet ? (
            <i>
              {moment().format("HH:mm") + ", "}
              Ngày {moment().date()},tháng {moment().month() + 1}, năm {moment().year()}
            </i>
          ) : (
            <i> {moment().format(enDateFormat)}</i>
          )}
          <div className="txt-700">
            {userProfile.partner_code === 'cellab' ? "Trợ lý bác sĩ" : i18n.t(languageKeys.field_Nguoi_tu_van)}
          </div>
          <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
          <div className="name-space" />
          <div className="txt-700">{data.tu_van_vien}</div>
        </div>

      </div>
    </div>
  );
};

let convertString = (field) => {
  let text = "";

  if (!!field && typeof field === "string") {
    text = field;
  } else if (!!field && typeof field === "number") {
    text = `${field}`;
  }
  return text;
};

export default PhieuTienTrinhKhoiTao;
