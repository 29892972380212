import { chatKeys } from "constants/keys";

const initScriptChat = [
    {
        id:1,
        user:chatKeys.ai,
        content:'<p>Xin chào !</p>'
    },
    {
        id:2,
        user:chatKeys.ai,
        content:'<p>Tôi là trợ lý hỏi đáp thông tin y tế được phát triển trên công nghệ trí tuệ nhân tạo.</p>'
    },
    {
        id:3,
        user:chatKeys.ai,
        content:'<p>Những thông tin mà tôi cung cấp chỉ nhằm mục đích cho bác sĩ tham khảo, chúng tôi không chịu trách nhiệm về bất kỳ tình huống hay rủi ro nào phát sinh. Vui lòng tham vấn thêm với chuyên gia nếu cần.</p>'
    },
    {
        id:4,
        user:chatKeys.ai,
        content:'<p>Vui lòng đặt câu hỏi của bạn.</p>'
    },

];

export default initScriptChat;