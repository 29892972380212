import { createSlice } from "@reduxjs/toolkit";

const initState = {
    data: [],
    currentPage: 1,
    filters: {},
    limit: 15,
    totalResult: 0,
}

const servicePackageUsageSlice = createSlice({
    name: 'servicePackageSlice',
    initialState: initState,
    reducers: {
        getServicePackageUsage: () => {},
        getServicePackageUsageSuccess: (state, action) => {
            const {result, page, filters, limit, total} = action.payload;
            state.data = result;
            state.currentPage = page;
            state.filters = filters;
            state.limit = limit;
            state.totalResult = total;
        },
        lockServicePackageUsage: () => {},
        finishServicePackageUsage: () => {},
        doSettleBundleUsage: () => {},
    }
})

export const { getServicePackageUsage, getServicePackageUsageSuccess, lockServicePackageUsage, finishServicePackageUsage, doSettleBundleUsage } = servicePackageUsageSlice.actions;
export const getServicePacketUsageData = (state) => state.servicePacket.ServicePacketUsage;
export default servicePackageUsageSlice.reducer;