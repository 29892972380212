import { common_post } from "helpers";
import apis from "../../../constants/apis";

export const layDsGoiDv = (dataRequest) => common_post(apis.lay_ds_goi_dv, dataRequest);
export const layDsSdGoiDv = (dataRequest) => common_post(apis.get_packet_service_usage, dataRequest);
export const tamNgungSdGoiDv = (dataRequest) => common_post(apis.lock_packet_service_usage, dataRequest);
export const layChiTietSdGoiDv = (dataRequest) => common_post(apis.get_detail_packet_service_usage, dataRequest);
export const suaChiTietSdGoiDv = (dataRequest) => common_post(apis.update_detail_packet_service_usage, dataRequest)
export const getLSSDGoiDv = (dataRequest) => common_post(apis.get_packet_service_usage_history, dataRequest)
export const layLSKhamBenh = (dataRequest) => common_post(apis.khambenh_lay_lskb_benh_nhan, dataRequest)
export const layDonThuocTheoCa = (dataRequest) => common_post(apis.get_prescription_by_examination, dataRequest)
export const hoanThanhSDGoiDv = (dataRequest) => common_post(apis.finish_service_usage, dataRequest)
export const layLSThanhToanSDGoiDv = (dataRequest) => common_post(apis.get_payment_history_service_usage, dataRequest)