import React,{ memo } from "react";
import { useSelector } from "react-redux";
import { rid, formatNumberToPrice, getFullName, layDiaChi } from "../../../helpers";
import moment from "moment";
import i18n, { languageKeys, languages } from "../../../i18n";
import { enDateFormat } from "constants/keys";
import Images from "components/Images/Images";

export const styleChiTietSoThuHoan = `
#phieu-thu-hoan * { font-size: 12px; line-height: 1rem; font-family: "Times New Roman", Times, serif; }
#phieu-thu-hoan .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#phieu-thu-hoan .header { display: flex; gap: 10px; font-size: 10px;}
#phieu-thu-hoan .hospital-name { text-transform: uppercase; font-weight: bold; }
#phieu-thu-hoan .thong-tin-phieu { display:flex ; }
#phieu-thu-hoan .title { text-transform: uppercase; font-size: 14px; font-weight: bold; text-align: center; width: 90%; margin: 2.5rem auto 0.25rem auto; }
#phieu-thu-hoan .table { margin-block: 0.75rem; }
#phieu-thu-hoan .table * { font-size: 12px; }
#phieu-thu-hoan .table { page-break-inside:auto; }
#phieu-thu-hoan tr    { page-break-inside:avoid; page-break-after:auto; }
#phieu-thu-hoan .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#phieu-thu-hoan .table th, .table td { padding: 5px 10px; }
#phieu-thu-hoan .table th { text-align: start; }
@media print {
   #phieu-thu-hoan, #phieu-thu-hoan .foot-break { break-inside: avoid; }
   #phieu-thu-hoan{page-break-after: always;}
}
#phieu-thu-hoan .loai { text-transform: uppercase; font-size: 14px; font-weight: bold; text-align: center; width: 90%; margin: 2.5rem auto 1rem auto;}
#phieu-thu-hoan .time {  font-size: 12px; margin-bottom : 10px;width : 100%;  text-align: center; display:flex,flex-direction: row; justify-content : center}
#phieu-thu-hoan .time-bold {  font-size: 12px; margin-bottom : 10px, font-weight: bold;}
#phieu-thu-hoan .text--bold{    font-weight: bold !important;   }
#phieu-thu-hoan .align-center{ text-align: center!important; }
#phieu-thu-hoan .align-right{ text-align: right!important; }
#phieu-thu-hoan .phone-website { display: flex; gap: 24px; align-items: center }
`;


export const styleChiTietSoThuHoanGL = `
#phieu-thu-hoan * { font-size: 14pt; font-family: "Times New Roman", Times, serif; }
#phieu-thu-hoan .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#phieu-thu-hoan .header { display: flex; gap: 10px; font-size: 10px;}
#phieu-thu-hoan .hospital-name { text-transform: uppercase; font-weight: bold;font-size: 16pt;  }
#phieu-thu-hoan .thong-tin-phieu { display:flex ; }
#phieu-thu-hoan .title { text-transform: uppercase; font-size: 14px; font-weight: bold; text-align: center; width: 90%; margin: 2.5rem auto 0.25rem auto; }
#phieu-thu-hoan .table { margin-block: 0.75rem; }
#phieu-thu-hoan .table * { font-size: 14pt; }
#phieu-thu-hoan .table { page-break-inside:auto; }
#phieu-thu-hoan tr    { page-break-inside:avoid; page-break-after:auto; }
#phieu-thu-hoan .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#phieu-thu-hoan .table th, .table td { padding: 5px 10px; }
#phieu-thu-hoan .table th { text-align: start; }
@media print {
   #phieu-thu-hoan, #phieu-thu-hoan .foot-break { break-inside: avoid; }
   #phieu-thu-hoan{page-break-after: always;}
}
#phieu-thu-hoan .loai { text-transform: uppercase; font-size: 14pt; font-weight: bold; text-align: center; width: 90%; margin: 2.5rem auto 1rem auto;}
#phieu-thu-hoan .time {  font-size: 14pt; margin-bottom : 10px;width : 100%;  text-align: center; display:flex,flex-direction: row; justify-content : center}
#phieu-thu-hoan .time-bold {  font-size: 12px; margin-bottom : 10px, font-weight: bold;}
#phieu-thu-hoan .text--bold{    font-weight: bold !important;   }
#phieu-thu-hoan .align-center{ text-align: center!important; }
#phieu-thu-hoan .align-right{ text-align: right!important; }
#phieu-thu-hoan .phone-website { display: flex; gap: 24px; align-items: center }
`;


const ChiTietSoThuHoan = ( dataExport ) => {
  const userProfile = useSelector((state) => state.auth.user);

  const { data, from, to, ten_so, loai_so } = dataExport.data;
  const getTotalMoney = () => {
    let totalMoney = 0;
    if(data && data.length){
        data?.forEach(ca => {
            totalMoney += (ca?.hoa_don && ca?.hoa_don.length) ? ca.hoa_don?.reduce((total, item) => 
                (!!item.TONG_CHI_PHI ? (total += Number(item.TONG_CHI_PHI)) : total)
            , 0) : 0
        })
    }
    return totalMoney;
  }
  const getTotalExemption = () => {
      let totalExemption = 0;
      if(data && data.length){
          data?.forEach(ca => {
              totalExemption += (ca?.hoa_don && ca?.hoa_don.length) ? ca.hoa_don?.reduce((total, item) => 
                  (!!item.MIEN_GIAM ? (total += Number(item.MIEN_GIAM)) : total)
              , 0) : 0
          })
      }
      return totalExemption;
  }

  const getTotalPayment = () => {
      let totalPayment = 0;
      if(data && data.length){
          data?.forEach(ca => {
              totalPayment += (ca?.hoa_don && ca?.hoa_don.length) ? ca.hoa_don?.reduce((total, item) => 
                  (!!item.TONG_THANH_TOAN ? (total += Number(item.TONG_THANH_TOAN)) : total)
              , 0) : 0
          })
      }
      return totalPayment;
  }
  return (
    <div id="phieu-thu-hoan">
      <div className="header">

        {!!userProfile.benh_vien.ANH_DAI_DIEN && (
          <Images className="logo" imgId={userProfile.benh_vien.ANH_DAI_DIEN}/>
        )}
        <div>
          <div className="hospital-name">{userProfile.benh_vien.TEN_CSKCB}</div>
          <div>
            {i18n.t(languageKeys.dia_chi)}: {`${layDiaChi(userProfile.benh_vien)}`}
          </div>
          <div className="phone-website">
            <div>
                {i18n.t(languageKeys.dien_thoai)}: {userProfile.benh_vien.SO_DIEN_THOAI}
            </div>
        </div>
        <div>
                Website: {userProfile?.benh_vien?.WEBSITE || ''}
            </div>
        <div>
            Email: {userProfile?.benh_vien?.EMAIL || ''}
        </div>
      </div>
    </div>

      {/* <div className="title">{'Sổ chẩn đoán hình ảnh'.toUpperCase()}</div> field_Ten_so*/}
      <div className="loai">{`${ten_so}`.toUpperCase()}</div>
      <div className="time">{i18n.t(languageKeys.time)} <span className="time-bold">
        {`${i18n.t(languageKeys.field_Tu)} ${from} ${i18n.t(languageKeys.field_Den)} ${to}`}
        </span></div>

      <table style={{ width: "100%" }} className="table">
        <thead>
          <tr>
            <th className="align-center" style={{ width: "28px" }}>{i18n.t(languageKeys.field_Stt)}</th>
            <th className="align-center">{i18n.t(languageKeys.field_Ma_phieu)}</th>
            <th className="align-center">{i18n.t(languageKeys.ten_bn)}</th>
            <th className="align-center">{i18n.t(languageKeys.field_Ngay_tao)}</th>
            <th className="align-center">{i18n.t(languageKeys.nguoi_tao)}</th>
            <th className="align-center">{i18n.t(languageKeys.tong_tien)}</th>
            <th className="align-center">{i18n.t(languageKeys.field_Mien_giam)}</th>
            <th className="align-center">{i18n.t(languageKeys.tong_tt)}</th>
            <th className="align-center">{i18n.t(languageKeys.loai_phieu)}</th>
            <th className="align-center">{i18n.t(languageKeys.field_hinh_thuc_thanh_toan)}</th>
            <th className="align-center">{i18n.t(languageKeys.field_Ly_do_mien_giam)}</th>
            <th className="align-center">{i18n.t(languageKeys.field_Ghi_chu)}</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            return (
                <React.Fragment key={rid()}>
                    <tr key={rid()}>
                        <th colSpan={12} style={{borderRight: 'none', textAlign: 'left'}}>
                            <>
                                <span className='text--bold'>{`${i18n.t(languageKeys.common_ngay)}: ${moment(item?.NGAY, 'YYYYMMDD').format('DD/MM/YYYY')} - ${i18n.t(languageKeys.field_Ca)} ${item?.CA || ''}`}</span>
                                <span className='text--bold' style={{display: 'inline-block', margin: '0 40px 0 30px'}}>{`${i18n.t(languageKeys.tien_mat_dau_ca)}: ${formatNumberToPrice(item?.TIEN_MAT_DAU_CA, 'đ')}`}</span>  
                                <span className='text--bold'>{`${i18n.t(languageKeys.tien_mat_cuoi_ca)}: ${formatNumberToPrice(item?.TIEN_MAT_CUOI_CA, 'đ')}`}</span>  
                            </>
                        </th>
                    </tr>
                    {  
                      (item?.hoa_don && item?.hoa_don?.length) ? 
                          item?.hoa_don?.map((phieu, idx) => {
                            return (        
                              <tr key={rid()}>
                                  <td className="align-center">{idx + 1 >= 10 ? idx + 1 : `0${idx + 1}`}</td>
                                  <td>{phieu?.SO_PHIEU}</td>
                                  <td>{phieu?.TEN}</td>
                                  <td>{moment(item?.NGAY,"YYYYMMDD").format("DD/MM/YYYY")}</td>
                                  <td>{phieu?.NGUOI_TAO}</td>
                                  <td className="align-right">{formatNumberToPrice(phieu?.TONG_CHI_PHI)}</td>
                                  <td className="align-right">{formatNumberToPrice(phieu?.MIEN_GIAM)}</td>
                                  <td className="align-right">{formatNumberToPrice(phieu?.TONG_THANH_TOAN)}</td>
                                  <td>{phieu?.NHOM === 'THANH_TOAN' ? i18n.t(languageKeys.phieu_thu) : phieu?.NHOM === 'HOAN_TIEN' ? i18n.t(languageKeys.phieu_hoan) : i18n.t(languageKeys.phieu_huy)}</td>
                                  <td>{(phieu?.HINH_THUC_THANH_TOAN?.length && Array.isArray(phieu?.HINH_THUC_THANH_TOAN)) ? phieu?.HINH_THUC_THANH_TOAN?.map(item => Object.keys(item))?.join(', ') : ""}</td>
                                  <td>{phieu?.LY_DO_MIEN_GIAM}</td>
                                  <td>{phieu?.GHI_CHU} </td>
                              </tr>
                      )}) : <></>
                    }
                </React.Fragment>
            );
          })}
          <tr>
            <td className="align-right text--bold" colSpan={5}>{i18n.t(languageKeys.common_Tong)}</td>
            <td className="align-right text--bold">{formatNumberToPrice(getTotalMoney())}</td>
            <td className="align-right text--bold">{formatNumberToPrice(getTotalExemption())}</td>
            <td className="align-right text--bold">{formatNumberToPrice(getTotalPayment())}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <div className="foot-break" style={{marginTop: '20px'}}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {i18n.language === languages.tieng_viet ? (
            <i>
              {moment().format("HH:mm") + ", "}
              {i18n.t(languageKeys.common_ngay)} {moment().date()},{i18n.t(languageKeys.common_thang)} {moment().month() + 1}, {i18n.t(languageKeys.common_nam)} {moment().year()}
            </i>
          ) : (
            <i style={{ marginRight: "40px" }}> {moment().format(enDateFormat)}</i>
          )}
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ textAlign: "center", marginLeft: 40 }}>
            <div>
              <b>{i18n.t(languageKeys.nguoi_lap_bang)}</b>
            </div>
            <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
            <div style={{ marginTop: 80, fontWeight: "bold" }}></div>
            <div style={{ marginTop: 80, fontWeight: "bold" }}>{userProfile.CHU_KY}</div>

          </div>

          <div style={{ textAlign: "center", marginLeft: 40 }}>
            {/* <div>
              <b>{i18n.t(languageKeys.truong_khoa_chan_doan_hinh_anh)}</b>
            </div>
            <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
            <div style={{ marginTop: 80, fontWeight: "bold" }}></div> */}
          </div>

          <div style={{ textAlign: "center", marginRight: 40 }}>
            <div>
              <b>{i18n.t(languageKeys.txt_ke_toan)}</b>
            </div>
            <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
            {/* <div style={{ marginTop: 80, fontWeight: "bold" }}>{userProfile.CHU_KY}</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ChiTietSoThuHoan);
