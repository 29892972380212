import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  patientPackageList: {
    patient: {},
    dataSource: [],
  },
  loadingPack: false,
  submitData: {
    selectedService: [],
  },
};

const packageSlice = createSlice({
  name: "packageSlice",
  initialState,
  reducers: {
    doRegisterPackge() {},
    /**
     * @description Lưu trữ danh sách gói dịch vụ của 1 bệnh nhân
     * @param {{ patient: Object, dataSource: Array<any> }} arg { patient: Object, dataSource: Array<any> }
     */
    setPatientPackageList(state, action) {
      state.patientPackageList = action.payload;
    },

    resetSlice() {
      return initialState;
    },
    setLoading(state, action) {
      state.loadingPack = action.payload;
    },
    setSelectedService(state, action) {
      state.submitData = { ...state.submitData, selectedService: action.payload };
    },
    modifyEachRow(state, { payload }) {
      let { key, data, dataKey } = payload;
      let selectedService = [...state.submitData.selectedService];
      let selectedServiceIndex = state.submitData.selectedService.findIndex((c) => c.key == key);
      selectedService[selectedServiceIndex] = { ...selectedService[selectedServiceIndex], [dataKey]: data };
      state.submitData = { ...state.submitData, selectedService };
    },
  },
});

export const PackageSliceAction = packageSlice.actions;
export const PackageState = (state) => state.features.package;

export default packageSlice.reducer;
