import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from "react";
import { Col, Input, Row } from "antd";
import { HLog } from "../../helpers";
import style from "./style.module.less";
var regexNumber = /^[0-9]*$/;

export const DateInput = forwardRef(({ value, readOnly, readOnlyInput, onChange, tabIndex = [], disable, ...props }, ref) => {
  const dateRef = useRef();
  const monthRef = useRef();
  const yearRef = useRef();

  const [date, setDate] = useState([]);

  useImperativeHandle(ref, () => ({
    setDate: (value)=>{
        setDate(value)
    }
  }));

  const handleOnlyNumber = (e) => {
    if( (e.key.length == 1) && Number.isNaN(+e.key)) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  const onEnchanceChange = (string, type) => {
    try {
      let dateString = "";
      if (type === "day") {
        setDate((c) => {
          let a = [...c];
          if (!string) {
            a[0] = null;
          } else {
            a[0] = string;
          }
          dateString = a.join("");
          return a;
        });
      } else if (type === "month") {
        setDate((c) => {
          let b = [...c];
          if (!string) {
            b[1] = null;
          } else {
            b[1] = string;
          }
          dateString = b.join("");
          return b;
        });
      } else if (type === "year") {
        setDate((c) => {
          let d = [...c];
          d[2] = string;
          if (!d[1] || !d[0]) {
            dateString = string;
          } else {
            dateString = d.join("");
          }
          return d;
        });
      }
      onChange(dateString);
    } catch (error) {
      HLog(error);
    }
  };

  return (
    <Row gutter={10}>
      <>
        <Col span={8}>
          <Input
            disabled={disable}
            ref={dateRef}
            readOnly={readOnly || readOnlyInput}
            className={style["input"]}
            placeholder={"DD"}
            onKeyDown={handleOnlyNumber}
            onChange={(e) => {
              let { value } = e.target;
              if (value.match(regexNumber) && +value <= 31) {
                onEnchanceChange(value, "day");
                if (value.length === 2) {
                  monthRef.current.focus();
                }
              }
            }}
            value={date[0]}
            tabIndex={tabIndex[0]}
            onBlur={(e) => {
              if (e.target.value.length === 1) {
                onEnchanceChange(`0${e.target.value}`, "day");
              }
            }}
            minLength={2}
            maxLength={2}
            {...props}
          />
        </Col>

        <Col span={8}>
          <Input
            disabled={disable}
            ref={monthRef}
            readOnly={readOnly || readOnlyInput}
            className={style["input"]}
            placeholder={"MM"}
            onChange={(e) => {
              let { value } = e.target;
              if (value.match(regexNumber) && +value <= 12) {
                onEnchanceChange(value, "month");
                if (value.length === 2) {
                  yearRef.current.focus();
                }
              }
            }}
            value={date[1]}
            onBlur={(e) => {
              if (e.target.value.length === 1) {
                onEnchanceChange(`0${e.target.value}`, "month");
              }
            }}
            minLength={2}
            maxLength={2}
            onKeyDown={(e) => {
              handleOnlyNumber(e)
              let { value } = e.target;
              if (e.code === "Backspace") {
                if (value.length === 0) {
                  dateRef.current.focus();
                }
              }
            }}
            tabIndex={tabIndex[1]}
            {...props}
          />
        </Col>
      </>
      <Col span={8}>
        <Input
          disabled={disable}
          ref={yearRef}
          readOnly={readOnly || readOnlyInput}
          className={style["input"]}
          placeholder={"YYYY"}
          onChange={(e) => {
            let { value } = e.target;
            if (value.match(regexNumber)) {
              onEnchanceChange(value, "year");
            }
          }}
          value={date[2]}
          minLength={4}
          maxLength={4}
          tabIndex={tabIndex[2]}
          {...props}
          onKeyDown={(e) => {
            let { value } = e.target;
            if (e.code === "Backspace") {
              if (value.length === 0) {
                monthRef.current.focus();
              }
            }
          }}
        />
      </Col>
    </Row>
  );
});
