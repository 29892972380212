import { createSlice } from "@reduxjs/toolkit";

const initState = {
  data: null,
  template: null,
};

const PDFSlice = createSlice({
  name: "pdf",
  initialState: initState,
  reducers: {
    onPrint: (state, { payload }) => {
      console.log("print::: ", payload);
      state.template = payload.template;
      state.data = payload.data;
    },

    onResetPrint: (state, { payload }) => {
      state.template = null;
      state.data = null;
    },
  },
});

export const { onPrint, onResetPrint } = PDFSlice.actions;

export default PDFSlice.reducer;
