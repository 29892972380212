import { Layout, Spin } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { paths } from "../constants";
import { featureKeys } from "../constants/keys";
import { rid } from "../helpers";
import { useEventAppointment, useIndexedDb } from "../hooks";
import i18n, { languageKeys } from "../i18n";
import { BanThuoc, NhaCungCap, NotFound, TonKho, NhapXuat, lazyLienThongDonThuoc as LienThongDonThuoc } from "../pages";
import EventSourceClient from "../routes/eventSource/EventSourceClient";
import { FeatureRoute } from "./route";
import SubHeader from "layouts/SubHeader/SubHeader";
import RightMenu from "layouts/RightMenu";
import { routeApp } from "../constants/keys";
import { useEffect } from "react";
import { doCheckUser } from "ducks/slices/authSlice";

const DuocMenuItems = [
  {
    featureKey: `${featureKeys.quan_ly_duoc_ngoai_tru}.${featureKeys.ban_thuoc}`,
    key: paths.duoc_ban_thuoc,
    icon: <></>,
    label: i18n.t(languageKeys.menu_Ban_thuoc),
  },
  {
    featureKey:  `${featureKeys.quan_ly_duoc_ngoai_tru}.${featureKeys.ton_kho}`,
    key: paths.duoc_ton_kho,
    icon: <></>,
    label: i18n.t(languageKeys.ton_kho),
  },
  {
    featureKey: `${featureKeys.quan_ly_duoc_ngoai_tru}.${featureKeys.phieu_xuat_nhap}`,
    key: paths.duoc_quan_ly_phieu_nhap_xuat,
    icon: <></>,
    label: i18n.t(languageKeys.menu_Phieu_xuat_nhap),
  },
  {
    featureKey:  `${featureKeys.quan_ly_duoc_ngoai_tru}.${featureKeys.nha_cung_cap}`,
    key: paths.duoc_nha_cung_cap,
    icon: <></>,
    label: i18n.t(languageKeys.nha_cung_cap),
  },
  {
    featureKey:  `${featureKeys.quan_ly_duoc_ngoai_tru}.${featureKeys.lien_thong_don_thuoc}`,
    key: paths.lien_thong_don_thuoc,
    icon: <></>,
    label: i18n.t(languageKeys.lien_thong_don_thuoc),
  },
];

export const DuocRoute = () => {
  const dispatch = useDispatch();
  const { user, tokens } = useSelector((state) => state.auth);
  const { loadingInitData } = useIndexedDb();
  const { eventClient, reloadAppointment } = useEventAppointment();
  const history = useHistory();

  const featureRoutes = [
    {
      key: `${featureKeys.quan_ly_duoc_ngoai_tru}.${featureKeys.ban_thuoc}`,
      path: paths.duoc_ban_thuoc,
      component: BanThuoc,
    },
    {
      key: `${featureKeys.quan_ly_duoc_ngoai_tru}.${featureKeys.phieu_xuat_nhap}`,
      path: paths.duoc_quan_ly_phieu_nhap_xuat,
      component: NhapXuat,
    },
    {
      key: `${featureKeys.quan_ly_duoc_ngoai_tru}.${featureKeys.nha_cung_cap}`,
      path: paths.duoc_nha_cung_cap,
      component: NhaCungCap,
    },
    {
      key: `${featureKeys.quan_ly_duoc_ngoai_tru}.${featureKeys.ton_kho}`,
      path: paths.duoc_ton_kho,
      component: TonKho,
    },
    {
      key: `${featureKeys.quan_ly_duoc_ngoai_tru}.${featureKeys.lien_thong_don_thuoc}`,
      path: paths.lien_thong_don_thuoc,
      component: LienThongDonThuoc,
    },
  ];

  const handleNavigate = ({ keyPath, key }) => {
    history.push(keyPath.pop());

  };

  useEffect(() => {
    if (!user && !!tokens) {
      dispatch(doCheckUser(tokens));
    }
  }, [dispatch, tokens?.token]);
  
  if (!user) {
    return <LoadingComponent />;
  }
  
  return (
    <Spin
      spinning={loadingInitData}
      tip={
        <div style={{ textAlign: "center" }}>
          <div>{i18n.t(languageKeys.text_khoi_tao_du_lieu)}</div>
        </div>
      }
    >
      <Layout>
        <SubHeader menuItem={DuocMenuItems} onNavigate={handleNavigate} module={routeApp.DUOC} />
        <Layout>
          <Layout.Content>
            <Switch>
              {featureRoutes.map((route) => (
                <FeatureRoute exact path={route.path} component={route.component} featureKey={route.key} key={route.key} />
              ))}
              <Redirect exact from={paths.duoc} to={paths.duoc_ban_thuoc} />
              <Route component={NotFound} />
            </Switch>
          </Layout.Content>
          <RightMenu currentFeature = {routeApp.DUOC} />
        </Layout>
      </Layout>

      <EventSourceClient ref={eventClient} callEventHandlers={{ reloadAppointment }} />
    </Spin>
  );
};


const LoadingComponent = () => (
  <div style={{ width: "100vw", height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
    <Spin spinning />
    <h3 className="blue-txt">{i18n.t(languageKeys.updatingAccInfo)}...</h3>
  </div>
);



