import { createSlice } from "@reduxjs/toolkit";

const initState = {
  event: {},
  eventUpdate:{},
};

const eventSourceSlice = createSlice({
  name: "eventSource",
  initialState: initState,
  reducers: {
    sendReloadAppointment(state, action) {
      return { ...state, ...action.payload };
    },
    resetEvent(state) {
      return {...state, event: {}};
    },
    updateReload:(state,{payload}) => {
      state.eventUpdate = payload
    },
    resetEventUpdate:(state,action) => {
      state.eventUpdate = {}
    }
  },
});

export const { sendReloadAppointment, resetEvent, updateReload, resetEventUpdate} = eventSourceSlice.actions;

export default eventSourceSlice.reducer;
