import { Collapse as AntdCollapse } from "antd";
import { ArrowDownCollapse, ArrowRightCollapse } from "assets/svg";
import styles from "./index.module.less";
import classNames from "classnames";

const { Panel } = AntdCollapse;

export const Collapse = ({ panels = [], defaultActiveKey, customClassnames, ...propsCollapse }) => {
  return (
    <AntdCollapse
      defaultActiveKey={defaultActiveKey}
      expandIcon={({ isActive }) => (isActive ? <ArrowDownCollapse /> : <ArrowRightCollapse />)}
      className={classNames(styles["collapse"], customClassnames)}
      collapsible="header"
      {...propsCollapse}
    >
      {panels.map((panel) => {
        return (
          <Panel header={panel.header} key={panel.key} extra={panel?.extra || ""}>
            {panel.children}
          </Panel>
        );
      })}
    </AntdCollapse>
  );
};
