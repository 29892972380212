import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import i18n, { languageKeys } from "../../i18n";
import ConfirmModal from "./ConfirmModal";
import styles from "./modal.module.less";
import { Checkbox } from "antd";

const CheckAllModal = ({ onOk = () => {}, title = "", desc = "", width = 340 }, ref) => {

  const modalRef = useRef()

  const [options, setOptions] = useState([]);
  const [checkedList, setCheckedList] = useState([]);

  const checkAll = options.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < options.length;

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: () => modalRef.current.close()
  }))

  const handleOpen = (data, opts) => {
    setOptions(opts)
    setCheckedList(opts.flatMap(i => i.value))
    modalRef?.current?.open(data)
  }

  const handelClose = () => {
    setOptions([])
    setCheckedList([])
  }

  const onChange = (list) => {
    setCheckedList(list);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? options.flatMap(i => i.value) : []);
  };

  return (
    <ConfirmModal
      ref={modalRef}
      title={title}
      content={""}
      cancelText={i18n.t(languageKeys.huy)}
      width={width}
      onOk={(data) => onOk(data, checkedList)}
      onCancel={handelClose}
      onPressCancelSuccess={handelClose}
    >
      <div className={styles.modalCheckAll}>
        <p>{desc}</p>
        <div className={styles.modalChild}>
          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
            {i18n.t(languageKeys.tat_ca)}
          </Checkbox>
          <Checkbox.Group options={options} value={checkedList} onChange={onChange} />
        </div>
      </div>
    </ConfirmModal>
  )
}

export default forwardRef(CheckAllModal);