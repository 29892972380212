import moment from "moment";
import React from "react";
import { layDiaChi, getSexFromString, rid } from "../../../helpers";
import { useSelector } from "react-redux";
import i18n, { languageKeys, languages } from "../../../i18n";
import { enDateFormat } from "constants/keys";
import Images from "components/Images/Images";

export const styleLieuTrinhDeXuat = `
#lieu-trinh-de-xuat * { font-size: 12px; line-height: 1rem; font-family: "Times New Roman", Times, serif; }

#lieu-trinh-de-xuat .column {
  float: left;
  width: 33.33%;
}

#lieu-trinh-de-xuat .row:after {
  content: "";
  display: table;
  clear: both;
}

#lieu-trinh-de-xuat .container-row {
  margin-top: 8px;
}

#lieu-trinh-de-xuat .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#lieu-trinh-de-xuat .header { display: flex; gap: 10px; }
#lieu-trinh-de-xuat .flex {
    display: flex;
  }
#lieu-trinh-de-xuat .flex.justify {
    justify-content: space-around;
  }

#lieu-trinh-de-xuat .text-bold {
    font-size: 12px;
    font-weight: bold !important;
  }

  #lieu-trinh-de-xuat .table table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
  }

  #lieu-trinh-de-xuat td, #lieu-trinh-de-xuat th {
    border: 1px solid #000;
    // text-align: center;
    padding: 6px;
    // color: #2c3782;
  }

  #lieu-trinh-de-xuat .table th:first-child {
    border: 1px solid #000;
    text-align: left;
    padding: 6px;
  }

#lieu-trinh-de-xuat .tel {
    margin-right: 50px;
  }
#lieu-trinh-de-xuat .txt-700 {
    font-weight: 700;
  }
#lieu-trinh-de-xuat .container-title {
  text-transform: uppercase; font-size: 14px; font-weight: bold; text-align: center; width: 90%; margin: 2rem auto 1rem auto;
  }
#lieu-trinh-de-xuat .container__title--main {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }
#lieu-trinh-de-xuat .container__ttbn--wrapper {
  margin-top: 6px;
}
#lieu-trinh-de-xuat .container__ttbn--wrapper>div {
    margin: 4px 0 0 0;
  }
#lieu-trinh-de-xuat .container-wrapper-flex {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  #lieu-trinh-de-xuat .container-wrapper-flex .col-3{
    width: 30%;
  }

  #lieu-trinh-de-xuat .container-wrapper-flex .col-4{
    width: 40%;
  }

  #lieu-trinh-de-xuat .container-wrapper-flex .col-5{
    width: 50%;
  }

  #lieu-trinh-de-xuat .thongtin-khambenh {
    width: 100%;
    display: flex;
    margin-top: 12px;
  }

  #lieu-trinh-de-xuat .thongtin-khambenh .left {
    // width: 65%;
    display: flex;
    flex-direction: column;
  }

  #lieu-trinh-de-xuat .thongtin-khambenh .right {
    width: 35%;
  }

  #lieu-trinh-de-xuat .table-border {
    padding: 6px 0;
    border: 1px solid #000;
  }

  #lieu-trinh-de-xuat .table-item{
    padding-left:12px;
  }

  #lieu-trinh-de-xuat hr.solid {
    border-top: 1px solid #000;
  }

  #lieu-trinh-de-xuat .footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
  }

  #lieu-trinh-de-xuat .footer .footer-confirm {
    margin-right: 44px;
    text-align: center;
  }

  #lieu-trinh-de-xuat .name-space {
    height: 80px;
  }

  #lieu-trinh-de-xuat .txt-size-m{
    font-size: 12px;
  }

  #lieu-trinh-de-xuat .upperTxt{
    text-transform: uppercase;
  }

  #lieu-trinh-de-xuat .phone-website { 
    display: flex; gap: 24px; align-items: center 
  }

@media print {
#lieu-trinh-de-xuat .foot-break { break-inside: avoid; }
}

#lieu-trinh-de-xuat .table { margin-block: 10px; }
#lieu-trinh-de-xuat .table * { font-size: 12px; }
#lieu-trinh-de-xuat .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#lieu-trinh-de-xuat .table th, .table td { padding: 5px 10px; }
#lieu-trinh-de-xuat .table th { text-align: start; }
`;

const PhieuLieuTrinhDeXuat = ({ data }) => {

  const userProfile = useSelector((state) => state.auth.user);
  let { benh_nhan, danh_sach_dich_vu, danh_sach_vat_tu } = data;


  return (
    <div id="lieu-trinh-de-xuat">
      <div className="header">
        {!!userProfile.benh_vien.ANH_DAI_DIEN && (
          <div className="img-header">
            <Images className="logo" imgId={userProfile.benh_vien.ANH_DAI_DIEN}/>
          </div>
        )}
        <div className="content-header">
          <h2 style={{ margin: 0, fontSize: 10 }} className="upperTxt">
            {userProfile?.benh_vien?.TEN_CSKCB}
          </h2>
          <div>
            <span className="txt-700">{i18n.t(languageKeys.dia_chi)}:</span> {layDiaChi(userProfile.benh_vien)}
          </div>
          <div className="flex">
            <div className="tel">
              <span className="txt-700"> {i18n.t(languageKeys.dien_thoai)}: </span> {convertString(userProfile.benh_vien.SO_DIEN_THOAI)}
            </div>
            <div>
              <span className="txt-700">Website:</span> {convertString(userProfile.benh_vien.WEBSITE)}
            </div>
          </div>
          <div>
            <span className="txt-700">Email:</span> {convertString(userProfile.benh_vien.EMAIL)}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="container-title">
          {i18n.t(languageKeys.phieu_thong_tin_tu_van_cua_bac_si)}
        </div>
        <div className="container__chapter--1">
          <div className="container__title--main">I. {i18n.t(languageKeys.field_Thong_tin_hanh_chinh)?.toUpperCase()}</div>
          <div className="container__ttbn--wrapper">
            <div className="container-wrapper-flex">
              <div className="col-3">
                <div>
                  {i18n.t(languageKeys.field_Ma_benh_nhan)}: <span className="txt-700 txt-size-m">{convertString(benh_nhan.ma_benh_nhan)}</span>
                </div>
                <div>
                  {i18n.t(languageKeys.field_Gioi_tinh)}: {getSexFromString(benh_nhan.gioi_tinh)}
                </div>
              </div>
              <div className="col-4">
                <div>
                  {i18n.t(languageKeys.ten_benh_nhan)}:{" "}
                  <span className="txt-700 txt-size-m"> {convertString(benh_nhan.ten_benh_nhan)?.toUpperCase()} </span>
                </div>
                <div>
                  {i18n.t(languageKeys.field_Doi_tuong)}: {convertString(benh_nhan.doi_tuong)}
                </div>
              </div>
              <div className="col-3">
                <div>
                  {benh_nhan.ngay_sinh.trim().length > 4 ? i18n.t(languageKeys.field_Ngay_sinh) : i18n.t(languageKeys.field_Nam_sinh)}:{" "}
                  {benh_nhan.ngay_sinh}
                </div>
                <div>
                  {i18n.t(languageKeys.field_So_dien_thoai)}: <span className="txt-700">{convertString(benh_nhan.sdt)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="dia-chi">
            {i18n.t(languageKeys.dia_chi)}: {convertString(benh_nhan.dia_chi)?.slice(-1) === ","
              ? convertString(benh_nhan.dia_chi).slice(0, -1).replaceAll(",", ", ")
              : convertString(benh_nhan.dia_chi).replaceAll(",", ", ")
            }
          </div>
          <div className="container-wrapper-flex">
            <div className="col-5">
              {i18n.t(languageKeys.title_phong_khám)}: {convertString(data.phong_in)}
            </div>
            <div className="col-5">
              {i18n.t(languageKeys.bac_si_tu_van)}: {convertString(data.tu_van_vien)}
            </div>
          </div>
        </div>

        <div className="container__title--main" style={{ margin: "10px 0" }}>
          II. {i18n.t(languageKeys.lieu_trinh_de_xuat).toUpperCase()}
        </div>

        {/* <span className="txt-700 txt-size-m">{i18n.t(languageKeys.lieu_trinh_de_xuat)}</span> */}
        <table style={{ width: "100%" }} className="table">
          <thead>
            <tr>
              <th style={{ width: "28px", textAlign: 'center' }}>{i18n.t(languageKeys.field_Stt)}</th>
              <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_Ten_dich_vu)}</th>
              <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.txt_So_luong)}</th>
            </tr>
          </thead>
          <tbody>
            {danh_sach_dich_vu.map((item, index) => {
              return (
                <tr key={rid()}>
                  <td style={{ textAlign: 'center' }}>{index + 1 >= 10 ? index + 1 : `0${index + 1}`}</td>
                  <td>{item.TEN_DICHVU}</td>
                  <td style={{ textAlign: 'center' }}>{item.SO_LUONG}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* {danh_sach_vat_tu?.[0] && (
          <>
            <span className="txt-700 txt-size-m">{i18n.t(languageKeys.thuoc_dieu_tri_de_xuat)}</span>
            <table style={{ width: "100%" }} className="table">
              <thead>
                <tr>
                  <th style={{ width: "28px", textAlign: 'center' }}>{i18n.t(languageKeys.field_Stt)}</th>
                  <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_Ten_thuoc)}</th>
                  <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.ham_luong_nong_do)}</th>
                  <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.txt_So_luong)}</th>
                  <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.duong_dung)}</th>
                  <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.huong_dan_su_dung)}</th>
                </tr>
              </thead>
              <tbody>
                {danh_sach_vat_tu.map((item, index) => {
                  return (
                    <tr key={rid()}>
                      <td style={{ textAlign: 'center' }}>{index + 1 >= 10 ? index + 1 : `0${index + 1}`}</td>
                      <td>{item.TEN_VAT_TU}</td>
                      <td>{item.HAM_LUONG || item.NONG_DO}</td>
                      <td style={{ textAlign: 'center' }}>{item.SO_LUONG}</td>
                      <td>{item.DUONG_DUNG}</td>
                      <td>{item.HUONG_DAN_SU_DUNG}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )} */}
      </div>

      <div>
        {i18n.t(languageKeys.so_buoi_sd)}: {data.so_buoi_su_dung}
      </div>
      <div>
        {i18n.t(languageKeys.ghi_chu)}: {data.ghi_chu}
      </div>

      <div className="footer foot-break">

        <div />

        <div className="footer-confirm">
          {i18n.language === languages.tieng_viet ? (
            <i>
              {moment().format("HH:mm") + ", "}
              Ngày {moment().date()},tháng {moment().month() + 1}, năm {moment().year()}
            </i>
          ) : (
            <i> {moment().format(enDateFormat)}</i>
          )}
          <div className="txt-700">
            {i18n.t(languageKeys.field_Bac_si)}
          </div>
          <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
          <div className="name-space" />
          <div className="txt-700">{data.tu_van_vien}</div>
        </div>

      </div>
    </div>
  );
};

let convertString = (field) => {
  let text = "";

  if (!!field && typeof field === "string") {
    text = field;
  } else if (!!field && typeof field === "number") {
    text = `${field}`;
  }
  return text;
};

export default PhieuLieuTrinhDeXuat;
