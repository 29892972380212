import moment from "moment";
import React from "react";
import {
  layDiaChi,
  rid as _rid,
  formatCurrency,
  formatDateOfBirth,
  getSexFromString,
} from "../../../helpers";
import { useSelector } from "react-redux";
import i18n, { languageKeys, languages } from "../../../i18n";
import JsBarcode from "jsbarcode";
import { uniq } from "lodash";
import Constants from "constants/Constants";
import Images from "components/Images/Images";
import { enDateFormat } from "constants/keys";

export const styleChiDinhGreenLab = `
#phieu-chi-dinh-greenlab * { font-size: 14pt; font-family: "Times New Roman", Times, serif; }
#phieu-chi-dinh-greenlab .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4pt;
}
#phieu-chi-dinh-greenlab .header { display: flex; gap: 10pt; position:relative; font-size: 14pt;}
#phieu-chi-dinh-greenlab .content-header { position:relative; width:70% }
#phieu-chi-dinh-greenlab .sub-barcode-text { font-size:14pt; position:absolute; top:50pt; left:50%; line-height: 1.2em; transform: translateX(-50%);}
#phieu-chi-dinh-greenlab .flex {
    display: flex;
  }
#phieu-chi-dinh-greenlab .flex.justify {
    justify-content: space-around;
  }

#phieu-chi-dinh-greenlab .tel {
    margin-right: 50px;
  }
#phieu-chi-dinh-greenlab .txt-700 {
    font-weight: 700;
  }
#phieu-chi-dinh-greenlab .column {
  float: left;
  width: 33.33%;
}
#phieu-chi-dinh-greenlab .container-title {
    font-weight: 700;
    line-height: 18pt;
    text-align: center;
    text-transform: uppercase;
    font-size: 16pt;
    width: 90%;
    margin: 1.8rem auto 1.4rem auto;
  }
#phieu-chi-dinh-greenlab .container__title--main {
    font-weight: 700;
    font-size: 14pt;
    line-height: 16pt;
  }
#phieu-chi-dinh-greenlab .container-wrapper-flex {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  #phieu-chi-dinh-greenlab .container-wrapper-flex .col-2{
    width: 20%;
  }

  #phieu-chi-dinh-greenlab .container-wrapper-flex .col-25{
    width: 25%;
  }

  #phieu-chi-dinh-greenlab .container-wrapper-flex .col-3{
    width: 30%;
  }

  #phieu-chi-dinh-greenlab .container-wrapper-flex .col-4{
    width: 40%;
  }

  #phieu-chi-dinh-greenlab .container-wrapper-flex .col-5{
    width: 50%;
  }

  #phieu-chi-dinh-greenlab .thongtin-khambenh {
    width: 100%;
    display: flex;
    margin-top: 12px;
  }

  #phieu-chi-dinh-greenlab .thongtin-khambenh .left {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  #phieu-chi-dinh-greenlab .thongtin-khambenh .right {
    width: 35%;
  }

  #phieu-chi-dinh-greenlab .table {
    margin-block: 0.6rem;
    width: 100%;
}

  #phieu-chi-dinh-greenlab .table * {
    font-size: 14pt;
}

#phieu-chi-dinh-greenlab .table,
.table th, 
.table td {
    border: 1px solid black;
    border-collapse: collapse;
}

#phieu-chi-dinh-greenlab .table th,
.table td {
    padding: 5px 10px;
}

  #phieu-chi-dinh-greenlab hr.solid {
    border-top: 1px solid #000;
  }

  #phieu-chi-dinh-greenlab .footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
  }

  #phieu-chi-dinh-greenlab .footer .footer-confirm {
    margin-right: 44px;
    text-align: center;
  }

  #phieu-chi-dinh-greenlab .name-space {
    height: 80px;
  }
  
  #phieu-chi-dinh-greenlab .upperTxt{
    text-transform: uppercase;
  }
  #phieu-chi-dinh-greenlab .txt-size-m{
    font-size: 14pt;
  }

  #phieu-chi-dinh-greenlab .barcode-top-right {
    position: absolute;
    width:30%;
    right:0;
    top: 0;
  }

  #phieu-chi-dinh-greenlab .footer .signature {
    margin-right: 44px;
    text-align: center;
  }

@media print {
  #phieu-chi-dinh-greenlab, #phieu-chi-dinh-greenlab .foot-break { break-inside: avoid; }
  #phieu-chi-dinh-greenlab { page-break-after: always;}
}
`;

const arrayPartnerCode = ['dth']

const PhieuChiDinhGreenLab = ({ data, rid, chiDinhCls }) => {
  const userProfile = useSelector((state) => state.auth.user);
  let { dich_vu, extraData } = data;

  const checkHoanThanh = (value) => {
    return [...Constants.ARRAY_TRANG_THAI_XONG, "DANG_THUC_HIEN", "DANG_KHAM"].includes(value || "")
  }
  React.useEffect(() => {
    let MA_PHIEU = null; //lấy ra mã phiếu để kiểm tra, nếu có mã phiếu thì in mã phiếu, ngược lại in mã bệnh nhân
    if (arrayPartnerCode.includes(userProfile.partner_code)) {
      MA_PHIEU = data?.MA_PHIEU
    } else {
      MA_PHIEU = data?.MA_CUOC_KHAM || dich_vu?.[0]?.data?.[0]?.data?.[0]?.MA_CUOC_KHAM
    }
    if (!!rid && !!MA_PHIEU) {
      //in mã phiếu
      JsBarcode(`#barcode-${rid}`)
        .options({ font: "OCR-B" })
        .CODE128(MA_PHIEU, {
          text: ` `,
          fontSize: 14,
          width: 1.2,
          height: 40,
          textMargin: 0,
          margin: 0,
          padding: 0,
        })
        .render();
    }
  }, [data.MA_PHIEU, data?.MA_CUOC_KHAM, dich_vu?.[0]?.data?.[0]?.data?.[0]?.MA_CUOC_KHAM, rid]);

  const calcTotal = (childService) => {
    return childService.SO_LUONG * (childService.DON_GIA || childService.GIA_DICH_VU)
  }

  const calcSvExp = (childService) => {
    return childService.MIEN_GIAM_TIEP_DON
      ? (childService.MIEN_GIAM_TIEP_DON > 100
        ? childService.MIEN_GIAM_TIEP_DON
        : (calcTotal(childService) / 100 * childService.MIEN_GIAM_TIEP_DON))
      : 0
  }

  const calcExemp = (childService) => {
    const MGTD = calcSvExp(childService)
    const TTT = calcTotal(childService) - MGTD
    return data.mien_giam_tong_hoa_don
      ? data.mien_giam_tong_hoa_don > 100
        ? MGTD + (TTT * data.mien_giam_tong_hoa_don / tong_sau_mien_giam_dv)
        : MGTD + (TTT / 100 * data.mien_giam_tong_hoa_don)
      : MGTD
  }

  const TOTAL = dich_vu
    ?.reduce((prev, curr) => prev + curr?.data
      ?.reduce((acc, obj) => acc + obj?.data
        ?.reduce((last, now) => last + calcTotal(now)
          , 0), 0), 0)

  // const tong_sau_mien_giam_dv = userProfile.config.NHIEU_PHIEU_CHIDINH_CLS
  //   ? data.tong_sau_mien_giam_dv
  //   : TOTAL - dich_vu
  //     ?.reduce((prev, curr) => prev + curr?.data
  //       ?.reduce((acc, obj) => acc + obj?.data
  //         ?.reduce((last, now) => last + calcSvExp(now)
  //           , 0), 0), 0)
  const tong_sau_mien_giam_dv = data.tong_sau_mien_giam_dv || 0

  const EXEMP = dich_vu
    ?.reduce((prev, curr) => prev + curr?.data
      ?.reduce((acc, obj) => acc + obj?.data
        ?.reduce((last, now) => last + calcExemp(now)
          , 0), 0), 0)

  const dv_tach = dich_vu?.[0]?.data?.flatMap(i => i?.data) || []

  return (
    <div id="phieu-chi-dinh-greenlab">
      <div className="header">
        {!!userProfile.benh_vien.ANH_DAI_DIEN && (
          <div className="img-header">
            <Images className="logo" imgId={userProfile.benh_vien.ANH_DAI_DIEN} />
          </div>
        )}
        <div className="content-header">
          <p style={{ margin: 0, fontSize: "16pt" }} className="upperTxt txt-700">
            {userProfile?.benh_vien?.TEN_CSKCB}
          </p>
          <div style={{ wordWrap: 'break-word' }}>
            <span className="txt-700">{i18n.t(languageKeys.dia_chi)}:</span> {layDiaChi(userProfile.benh_vien)}
          </div>
          <div className="flex">
            <div className="tel">
              <span className="txt-700">{i18n.t(languageKeys.sdt)}: </span> {convertString(userProfile.benh_vien.SO_DIEN_THOAI)}
            </div>
          </div>
          <div>
            <span className="txt-700">Website:</span> {userProfile?.benh_vien?.WEBSITE || ''}
          </div>
          <div>
            <span className="txt-700">Email:</span> {convertString(userProfile.benh_vien.EMAIL)}
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start', textAlign: 'center', position: 'relative', minWidth: '150px' }}>
          {(((data.MA_CUOC_KHAM || dich_vu?.[0]?.data?.[0]?.data?.[0]?.MA_CUOC_KHAM) && !arrayPartnerCode.includes(userProfile.partner_code)) || (data.MA_PHIEU && arrayPartnerCode.includes(userProfile.partner_code))) && <span><svg id={`barcode-${rid}`}></svg></span>}
          <div className="sub-barcode-text">
            {((data.MA_CUOC_KHAM || dich_vu?.[0]?.data?.[0]?.data?.[0]?.MA_CUOC_KHAM) && !arrayPartnerCode.includes(userProfile.partner_code)) && (
              <div style={{ width: 300, textAlign: 'center' }}>
                {i18n.t(languageKeys.field_Ma_CK)}: <b>{data.MA_CUOC_KHAM || dich_vu?.[0]?.data?.[0]?.data?.[0]?.MA_CUOC_KHAM}</b>
              </div>
            )}
            {(data.MA_PHIEU && arrayPartnerCode.includes(userProfile.partner_code)) && (
              <div style={{ width: 300, textAlign: 'center' }}>
                {i18n.t(languageKeys.field_So_phieu)}: <b>{data.MA_PHIEU}</b>
              </div>
            )}
            <div style={{ width: 300, textAlign: 'center' }}>
              {i18n.t(languageKeys.ma_kh)}:<b> {data.benh_nhan.ma_benh_nhan}</b>
            </div>
          </div>
        </div>

      </div>
      <div className="container">
        <div className="container-title">{i18n.t(languageKeys.title_phieu_chi_dinh_dich_vu)}</div>
        <div className="container__chapter--1">
          <div className="container__ttbn--wrapper">
            <div className="container-wrapper-flex">
              <div className="col-5">
                <div>
                  {i18n.t(languageKeys.ho_ten)}: <span className="txt-700 txt-size-m"> {data.benh_nhan?.ten_benh_nhan} </span>
                </div>
                <div>
                  {i18n.t(languageKeys.field_Gioi_tinh)}: {getSexFromString(data.benh_nhan?.gioi_tinh)}
                </div>
              </div>
              <div className="col-25">
                <div>
                  {i18n.t(languageKeys.sdt)}: <span className="txt-700">{data.benh_nhan?.sdt}</span>
                </div>
                <div>
                  {/* {i18n.t(languageKeys.field_Doi_tuong)}: {data.bao_hiem?.ten_doi_tuong} */}
                  {i18n.t(languageKeys.field_Doi_tuong)}: {data?.resDT
                    ? uniq(data?.resDT?.flatMap(i => i?.TEN_DOI_TUONG)).join(", ")
                    : uniq(data?.dich_vu?.flatMap(i => i?.data)?.flatMap(i => i?.data)?.map(i => i?.TEN_DOI_TUONG))?.join(", ")
                  }
                </div>
              </div>
              <div className="col-25">
                <div>
                  {i18n.t(languageKeys.field_Ngay_sinh)}: {formatDateOfBirth(data.benh_nhan?.ngay_sinh)}
                </div>
                <div>
                  {/* {i18n.t(languageKeys.field_So_dien_thoai)}:<span className="txt-700"> {` ${data.benh_nhan?.sdt}`}</span> */}
                </div>
              </div>
            </div>
          </div>
          <div className="dia-chi">
            {i18n.t(languageKeys.dia_chi)}: {data.benh_nhan?.dia_chi}
          </div>
          {extraData?.isTiepDon ? <></> : (
            <>
              <div className="container-wrapper-flex">
                <div className="col-5">
                  {i18n.t(languageKeys.nguoi_chi_dinh)}: {data.nguoi_chi_dinh}
                </div>
                <div className="col-5">
                  {i18n.t(languageKeys.noi_chi_dinh)}: {!data.ten_phong ? chiDinhCls ? "" : i18n.t(languageKeys.bo_phan_tiep_don) : data.ten_phong}
                </div>
              </div>
              <div>
                {i18n.t(languageKeys.chan_doan)}: {data.chan_doan_lam_sang}
              </div>
            </>
          )}
        </div>

        <div>
          <table className="table">
            <thead>
              <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.stt)}</th>
              <th style={{ textAlign: "start", width: "25%", maxWidth: "30%" }}>{i18n.t(languageKeys.field_Ten_dich_vu)}</th>
              <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_So_luong)}</th>
              <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_don_gia)}</th>
              <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_tong_tien)}</th>
              <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_doi_tuong_mien_giam)}</th>
              <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.vien_phi_thanh_tien)}</th>
              <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.TT_cho)}</th>
              <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_Da_TT)}</th>
              <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.dang_TH)}</th>
            </thead>
            <tbody>
              {userProfile.config.NHIEU_PHIEU_CHIDINH_CLS
                // Tách phiếu
                ? (
                  <>
                    <tr key={_rid()}>
                      <td colSpan={4} style={{ textAlign: "start", fontWeight: 700 }}>
                        {`1. ${dich_vu[0].PHONG_THUC_HIEN}`}
                      </td>
                      <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                        {formatCurrency(dv_tach.reduce((acc, curr) => acc + calcTotal(curr), 0), "", true)}
                      </td>
                      <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                        {formatCurrency(Math.round(dv_tach.reduce((acc, curr) => acc + calcExemp(curr), 0)), "", true)}
                      </td>
                      <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                        {formatCurrency(Math.round(dv_tach.reduce((acc, curr) => acc + (calcTotal(curr) - calcExemp(curr)), 0)), "", true)}
                      </td>
                      <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                      </td>
                      <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                      </td>
                      <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                      </td>
                    </tr>
                    {dv_tach.map((childService, id) => {
                      return (
                        <>
                          <tr key={_rid()}>
                            <td style={{ textAlign: 'center' }}>{id + 1}</td>
                            <td style={{ width: "30%" }}>{childService.TEN_DICHVU || childService?.TEN_DICH_VU_KEM_THEO}</td>
                            <td style={{ textAlign: "center" }}>{childService.SO_LUONG}</td>
                            <td style={{ textAlign: "end" }}>
                              {childService.DON_GIA
                                ? formatCurrency(childService.DON_GIA, "", true)
                                : null || formatCurrency(childService.GIA_DICH_VU, "", true)}
                            </td>
                            <td style={{ textAlign: "end" }}>{formatCurrency(calcTotal(childService), "", true)}</td>
                            <td style={{ textAlign: "end" }}>{formatCurrency(Math.round(calcExemp(childService)), "", true)}</td>
                            <td style={{ textAlign: "end" }}>
                              {formatCurrency(calcTotal(childService) - Math.round(calcExemp(childService)), "", true)}
                            </td>
                            <td style={{ textAlign: "center", fontWeight: 700 }}>{childService.STT_VAO_KHAM}</td>
                            <td style={{ textAlign: "center", fontWeight: 700 }}>{(childService.TRANG_THAI_TT == 1 || childService.THANH_TOAN == 1) && "✔"}</td>
                            <td style={{ textAlign: "center", fontWeight: 700 }}>{checkHoanThanh(childService?.TRANG_THAI_TH) && "✔"}</td>
                          </tr>
                          {(childService.DV_KEM_THEO?.length && userProfile.config.HIEN_THI_KEM_THEO)
                            ? childService.DV_KEM_THEO.map((dvkt, num) => (
                              <tr key={num}>
                                <td></td>
                                <td style={{ width: "30%" }}>{dvkt.TEN_DICHVU || dvkt?.TEN_DICH_VU_KEM_THEO}</td>
                                <td style={{ textAlign: "center" }}>-</td>
                                <td style={{ textAlign: "center" }}>-</td>
                                <td style={{ textAlign: "center" }}>-</td>
                                <td style={{ textAlign: "center" }}>-</td>
                                <td style={{ textAlign: "center" }}>-</td>
                                <td style={{ textAlign: "center", fontWeight: 700 }}>{dvkt.STT_VAO_KHAM}</td>
                                <td style={{ textAlign: "center", fontWeight: 700 }}>{(dvkt.TRANG_THAI_TT == 1 || dvkt.THANH_TOAN == 1) && "✔"}</td>
                                <td style={{ textAlign: "center", fontWeight: 700 }}>{checkHoanThanh(dvkt?.TRANG_THAI_TH) && "✔"}</td>
                              </tr>
                            )) : <></>}
                        </>
                      );
                    })}
                  </>
                )
                // Gộp phiếu
                : dich_vu.map((dv, num) => {
                  const dsdv = dv.data?.flatMap(i => i?.data) || []
                  return (
                    <>
                      <tr key={_rid()}>
                        <td colSpan={4} style={{ textAlign: "start", fontWeight: 700 }}>
                          {`${num + 1}. ${dv.PHONG_THUC_HIEN}`}
                        </td>
                        <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                          {formatCurrency(dsdv.reduce((acc, curr) => acc + calcTotal(curr), 0), "", true)}
                        </td>
                        <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                          {formatCurrency(Math.round(dsdv.reduce((acc, curr) => acc + calcExemp(curr), 0)), "", true)}
                        </td>
                        <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                          {formatCurrency(Math.round(dsdv.reduce((acc, curr) => acc + (calcTotal(curr) - calcExemp(curr)), 0)), "", true)}
                        </td>
                        <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                        </td>
                        <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                        </td>
                        <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                        </td>
                      </tr>
                      {dsdv.map((childService, id) => {
                        return (
                          <>
                            <tr key={_rid()}>
                              <td style={{ textAlign: "center" }}>{id + 1}</td>
                              <td style={{ width: "30%" }}>{childService.TEN_DICHVU || childService?.TEN_DICH_VU_KEM_THEO}</td>
                              <td style={{ textAlign: "center" }}>{childService.SO_LUONG}</td>
                              <td style={{ textAlign: "end" }}>
                                {childService.DON_GIA
                                  ? formatCurrency(childService.DON_GIA, "", true)
                                  : null || formatCurrency(childService.GIA_DICH_VU, "", true)}
                              </td>
                              <td style={{ textAlign: "end" }}>{formatCurrency(calcTotal(childService), "", true)}</td>
                              <td style={{ textAlign: "end" }}>{formatCurrency(Math.round(calcExemp(childService)), "", true)}</td>
                              <td style={{ textAlign: "end" }}>
                                {formatCurrency(calcTotal(childService) - Math.round(calcExemp(childService)), "", true)}
                              </td>
                              <td style={{ textAlign: "center", fontWeight: 700 }}>{childService.STT_VAO_KHAM}</td>
                              <td colSpan={1} style={{ textAlign: "center", fontWeight: 700 }}>{(childService.TRANG_THAI_TT == 1 || childService.THANH_TOAN == 1) && "✔"}</td>
                              <td colSpan={1} style={{ textAlign: "center", fontWeight: 700 }}>{checkHoanThanh(childService.TRANG_THAI_TH) && "✔"}</td>
                            </tr>
                            {(childService.DV_KEM_THEO?.length && userProfile.config.HIEN_THI_KEM_THEO)
                              ? childService.DV_KEM_THEO.map((dvkt, num) => (
                                <tr key={num}>
                                  <td></td>
                                  <td style={{ width: "30%" }}>{dvkt.TEN_DICHVU || dvkt?.TEN_DICH_VU_KEM_THEO}</td>
                                  <td style={{ textAlign: "center" }}>-</td>
                                  <td style={{ textAlign: "center" }}>-</td>
                                  <td style={{ textAlign: "center" }}>-</td>
                                  <td style={{ textAlign: "center" }}>-</td>
                                  <td style={{ textAlign: "center" }}>-</td>
                                  <td style={{ textAlign: "center", fontWeight: 700 }}>{dvkt.STT_VAO_KHAM}</td>
                                  <td colSpan={1} style={{ textAlign: "center", fontWeight: 700 }}>{(dvkt.TRANG_THAI_TT == 1 || dvkt.THANH_TOAN == 1) && "✔"}</td>
                                  <td colSpan={1} style={{ textAlign: "center", fontWeight: 700 }}>{checkHoanThanh(dvkt.TRANG_THAI_TH) && "✔"}</td>
                                </tr>
                              )) : <></>}
                          </>
                        );
                      })}
                    </>
                  );
                })
              }
              {/* {dich_vu[0].data.map((item, index) => {
                // HLog("item: ", item)
                return (
                  <>
                    <tr key={_rid()}>
                      <td colSpan={4} style={{ textAlign: "start", fontWeight: 700 }}>
                        {`${index + 1}. ${item.TEN_LOAI_DV}`}
                      </td>
                      <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                        {formatCurrency(item.THANH_TIEN, "", true)}
                      </td>
                    </tr>
                    {!!item.data &&
                      item?.data?.map((childService, id) => {
                        return (
                          <tr key={_rid()}>
                            <td>{id + 1}</td>
                            <td style={{ width: "30%" }}>{childService.TEN_DICHVU}</td>
                            <td style={{ textAlign: "center" }}>{childService.SO_LUONG}</td>
                            <td style={{ textAlign: "end" }}>
                              {childService.DON_GIA
                                ? formatCurrency(childService.DON_GIA, "", true)
                                : null || formatCurrency(childService.GIA_DICH_VU, "", true)}
                            </td>
                            <td style={{ textAlign: "end" }}>{formatCurrency(childService.THANH_TIEN, "", true)}</td>
                          </tr>
                        );
                      })}
                  </>
                );
              })} */}
              <tr key={_rid()}>
                <td colSpan={4} style={{ textAlign: "end", fontWeight: 700 }}>
                  {i18n.t(languageKeys.tong)}:
                </td>
                <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>{formatCurrency(TOTAL, "", true)}</td>
                <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>{formatCurrency(Math.round(EXEMP), "", true)}</td>
                <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>{formatCurrency(TOTAL - Math.round(EXEMP), "", true)}</td>
                <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}></td>
                <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <strong style={{ fontStyle: 'italic' }}>{i18n.t(languageKeys.da_duoc_tu_van_day_du_v2)}</strong>
      <div className="footer">
        <div style={{ textAlign: "center" }}>
          <div></div>
        </div>
        <div className="signature">
          {i18n.language === languages.tieng_viet ? (
            data?.ngay_tao ? (
              <i>
                {data?.gio}, {data.ngay_tao}
              </i>
            ) : (
              <i>
                {moment().format("HH:mm") + ", "}
                Ngày {moment().date()},tháng {moment().month() + 1}, năm {moment().year()}
              </i>
            )
          ) : (
            <i> {moment().format(enDateFormat)}</i>
          )}
          <div className="txt-700">{i18n.t(languageKeys.nguoi_chi_dinh)}</div>
          <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
          <div className="name-space"></div>
          <div className="txt-700">{data.nguoi_hien_thi || data.bac_si_chi_dinh || data.nguoi_tiep_don}</div>
        </div>
      </div>
    </div>
  );
};

export const convertString = (field) => {
  let text = "";

  if (!!field && typeof field === "string") {
    text = field;
  } else if (!!field && typeof field === "number") {
    text = `${field}`;
  }
  return text;
};

export default PhieuChiDinhGreenLab;

var ChuSo = new Array(" không ", " một ", " hai ", " ba ", " bốn ", " năm ", " sáu ", " bảy ", " tám ", " chín ");
var Tien = new Array("", " nghìn", " triệu", " tỷ", " nghìn tỷ", " triệu tỷ");

var digit = new Array(" zero ", " one ", " two ", " three ", " four ", " five ", " six ", " seven ", " eight ", " nine ");
var money = new Array("", " thousand", " milion", " bilion", " thousand bilion", " milion bilion");

//1. Hàm đọc số có ba chữ số;
function DocSo3ChuSo(baso) {
  var tram;
  var chuc;
  var donvi;
  var KetQua = "";
  tram = parseInt(baso / 100);
  chuc = parseInt((baso % 100) / 10);
  donvi = baso % 10;
  if (tram === 0 && chuc === 0 && donvi === 0) return "";
  if (tram !== 0) {
    KetQua += ChuSo[tram] + " trăm ";
    if (chuc === 0 && donvi !== 0) KetQua += " linh ";
  }
  if (chuc !== 0 && chuc !== 1) {
    KetQua += ChuSo[chuc] + " mươi";
    if (chuc === 0 && donvi !== 0) KetQua = KetQua + " linh ";
  }
  if (chuc === 1) KetQua += " mười ";
  switch (donvi) {
    case 1:
      if (chuc !== 0 && chuc !== 1) {
        KetQua += " mốt ";
      } else {
        KetQua += ChuSo[donvi];
      }
      break;
    case 5:
      if (chuc === 0) {
        KetQua += ChuSo[donvi];
      } else {
        KetQua += " lăm ";
      }
      break;
    default:
      if (donvi !== 0) {
        KetQua += ChuSo[donvi];
      }
      break;
  }
  return KetQua;
}

//2. Hàm đọc số thành chữ (Sử dụng hàm đọc số có ba chữ số)
function DocTienBangChu(SoTien) {
  SoTien = Number(SoTien);
  var lan = 0;
  var i = 0;
  var so = 0;
  var KetQua = "";
  var tmp = "";
  var ViTri = new Array();
  if (SoTien < 0) return `${i18n.t(languageKeys.noti_so_tien_am)} !`;
  if (SoTien === 0) return `${i18n.t(languageKeys.noti_khong_dong)} !`;
  if (SoTien > 0) {
    so = SoTien;
  } else {
    so = -SoTien;
  }
  if (SoTien > 8999999999999999) {
    //SoTien = 0;
    return "Số quá lớn!";
  }
  ViTri[5] = Math.floor(so / 1000000000000000);
  if (isNaN(ViTri[5])) ViTri[5] = "0";
  so = so - parseFloat(ViTri[5].toString()) * 1000000000000000;
  ViTri[4] = Math.floor(so / 1000000000000);
  if (isNaN(ViTri[4])) ViTri[4] = "0";
  so = so - parseFloat(ViTri[4].toString()) * 1000000000000;
  ViTri[3] = Math.floor(so / 1000000000);
  if (isNaN(ViTri[3])) ViTri[3] = "0";
  so = so - parseFloat(ViTri[3].toString()) * 1000000000;
  ViTri[2] = parseInt(so / 1000000);
  if (isNaN(ViTri[2])) ViTri[2] = "0";
  ViTri[1] = parseInt((so % 1000000) / 1000);
  if (isNaN(ViTri[1])) ViTri[1] = "0";
  ViTri[0] = parseInt(so % 1000);
  if (isNaN(ViTri[0])) ViTri[0] = "0";
  if (ViTri[5] > 0) {
    lan = 5;
  } else if (ViTri[4] > 0) {
    lan = 4;
  } else if (ViTri[3] > 0) {
    lan = 3;
  } else if (ViTri[2] > 0) {
    lan = 2;
  } else if (ViTri[1] > 0) {
    lan = 1;
  } else {
    lan = 0;
  }
  for (i = lan; i >= 0; i--) {
    tmp = DocSo3ChuSo(ViTri[i]);
    KetQua += tmp;
    if (ViTri[i] > 0) KetQua += Tien[i];
    if (i > 0 && tmp.length > 0) KetQua += ","; //&& (!string.IsNullOrEmpty(tmp))
  }
  if (KetQua.substring(KetQua.length - 1) === ",") {
    KetQua = KetQua.substring(0, KetQua.length - 1);
  }
  KetQua = KetQua.substring(1, 2).toUpperCase() + KetQua.substring(2);

  return KetQua + " vnđ"; //.substring(0, 1);//.toUpperCase();// + KetQua.substring(1);
}

function numToWords(n) {
  const arr = (x) => Array.from(x);
  const num = (x) => Number(x) || 0;
  const str = (x) => String(x);
  const isEmpty = (xs) => xs.length === 0;
  const take = (n) => (xs) => xs.slice(0, n);
  const drop = (n) => (xs) => xs.slice(n);
  const reverse = (xs) => xs.slice(0).reverse();
  const comp = (f) => (g) => (x) => f(g(x));
  const not = (x) => !x;
  const chunk = (n) => (xs) => isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];
  let a = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  let b = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];
  let g = ["", "thousand", "million", "billion", "trillion", "quadrillion", "quintillion", "sextillion", "septillion", "octillion", "nonillion"];
  // this part is really nasty still
  // it might edit this again later to show how Monoids could fix this up
  let makeGroup = ([ones, tens, huns]) => {
    return (
      [num(huns) === 0 ? "" : a[huns] + " hundred ", num(ones) === 0 ? b[tens] : (b[tens] && b[tens] + "-") || "", a[tens + ones] || a[ones]].join(
        ""
      ) + " dong"
    );
  };
  // "thousands" constructor; no real good names for this, i guess
  let thousand = (group, i) => (group === "" ? group : `${group} ${g[i]}`);
  // execute !
  if (typeof n === "number") return numToWords(String(n));
  if (n === "0") return "zero";
  return comp(chunk(3))(reverse)(arr(n)).map(makeGroup).map(thousand).filter(comp(not)(isEmpty)).reverse().join(" ") + " dong";
}
