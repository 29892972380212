import { getServicePacketTypeList, getServicePacketTypeListSuccess } from "ducks/slices/GoiDichVu/LoaiGoiDvSlice";
import { put, takeLatest } from "redux-saga/effects";
import { apis } from "../../../constants";
import { common_post } from "../../../helpers";
import { setSelectLoading, setTableLoading } from "ducks/slices/loadingSlice";

function* handleGetServicePacketTypeList({ payload }) {
  try {
    payload?.isTableLoading ? yield setTableLoading(true) : yield setSelectLoading(true);
    const res = yield common_post(apis.lay_ds_loai_goi_dv, payload);
    if (res.status === "OK") {
      yield put(getServicePacketTypeListSuccess({ data: res.result }));
    }
  } catch (error) {
    console.log(error);
  } finally {
    payload?.isTableLoading ? yield setTableLoading(false) : yield setSelectLoading(false);
  }
}

export function* watchServicePackType() {
  yield takeLatest(getServicePacketTypeList, handleGetServicePacketTypeList);
}
