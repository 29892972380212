const { createSlice } = require("@reduxjs/toolkit");

const initState = {
    data: []
}

const PackageUsageHistorySlice = createSlice({
    name: 'PackageUsageHistorySlice',
    initialState: initState,
    reducers: {
        fetchPacketUsageHistory: () => {},
        fetchPacketUsageHistorySuccess: (state, action) => {
            const { result } = action.payload;
            state.data = result;
        },
        clearPacketUsageHistory: (state) => {
            state.data = []
        },
        doSaveDevelopTxt: () => {},
    }
})

export const { fetchPacketUsageHistory, fetchPacketUsageHistorySuccess, clearPacketUsageHistory, doSaveDevelopTxt } = PackageUsageHistorySlice.actions;
export const getPackageUsageHistoryData = (state) => state.servicePacket.PacketUsageHistorySlice;
export default PackageUsageHistorySlice.reducer;