import { apis, keys } from "./constants";
import cryptoRandomString from "crypto-random-string";
import axios from "axios";
import sha256 from "sha256";
import moment from "moment";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { notification } from "antd";
import i18n, { languageKeys, languages } from "./i18n";
import printJS from "print-js";
import apiServices from "./config/apiServices";
import { featureKeys, keyLoaiNhanVien, localKeyNotChange, localWhitelist } from "./constants/keys";
import _ from "lodash";
import { mainInstance } from "./libs/apiInstances";
import Constants from "./constants/Constants";
import {partnerCodeNoShowAllDaiThamChieu,partnerCodeNoShowTuoiDaiThamChieu,partnerCodeNoShowGoiTinhDaiThamChieu, regexBiggerThanSmaller, maxValue } from "./constants/data";
import imageCompression from "browser-image-compression";
import Resizer from "react-image-file-resizer";
import { renderToString } from 'react-dom/server';


//hàm xử lí url để gọi đúng service
export function getUrlApi(stringUrl) {
  return stringUrl;
}

export const localGet = (key, fallback = "") => {
  const val = localStorage.getItem(key);
  if (!val || val === "undefined" || val === "") return fallback;
  return JSON.parse(val);
};

export const localSet = (key, val) => {
  HLog("Auth Saga setLocal key:", key, "with data", val);
  return localStorage.setItem(key, JSON.stringify(val));
};

export const localRemove = (key) => {
  return localStorage.removeItem(key);
};

// random id (default: 5 chữ cái)
export const rid = (length = 5, type) => cryptoRandomString({ length: length, type });

export function HLog() {
  if (apiServices.enable_app_log === "dev") {
    console.log.apply(this, arguments);
  }
}

HLog.render = (string) => {
  if (apiServices.enable_app_log === "dev") {
    console.log(`%cRender`, "color: #e7eb0a", string);
  }
};

// lấy url ảnh để Preview
export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

// Hàm POST
export const common_post = async (url, body,config) => {
  try {
    const response = await mainInstance.post(url, body,config);
    let result = response.data;
    return result;
  } catch (error) {
    HLog(error.message);

    if (error.message.includes("timeout")) {
      notification.error({
        message: i18n.t(languageKeys.app_loi_ket_noi),
        placement: "bottomLeft",
      });
    }

    return null;
  }
};
export const common_get = async (url, body, headers = {}) => {
  try {
    const response = await mainInstance.get(url, body);
    let result = response.data;
    return result;
  } catch (error) {
    HLog(error.message);

    if (error.message.includes("timeout")) {
      notification.error({
        message: i18n.t(languageKeys.app_loi_ket_noi),
        placement: "bottomLeft",
      });
    }

    return null;
  }
};
//đổi millisecond sang date DD/mm/yyy
export function convertMillisecondToDate(duration, separator = "/", dateOnly = false) {
  if (duration == null || duration === "") {
    return "";
  }
  let outPut = "";
  duration = Number(duration);
  var isoFormat = new Date(duration);
  let year = isoFormat.getFullYear();
  let month = isoFormat.getUTCMonth() + 1;
  let date = isoFormat.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (date < 10) {
    date = "0" + date;
  }
  if (dateOnly) {
    outPut = date + separator + month;
  } else {
    outPut = date + separator + month + separator + year;
  }
  return outPut;
}

// đổi milisecond sang giờ
export function convertTimeUTCToTime(time) {
  let date = new Date();
  date.setUTCHours(Number(time.split(":")[0]));
  date.setUTCMinutes(Number(time.split(":")[1]));
  let hours = date.getHours();
  let minutes = date.getMinutes();
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let result = hours + ":" + minutes;
  return result;
}

//đổi millisecond dạng UTC sang thời gian
export function convertMilliUTCToTime(duration) {
  if (duration == null || duration === "") return "";
  var minutes = parseInt((duration / (1000 * 60)) % 60),
    hours = parseInt((duration / (1000 * 60 * 60)) % 24);
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let time = hours + ":" + minutes;
  let result = convertTimeUTCToTime(time);
  return result;
}

// chuyển từ DD/MM/YYY sang MMMMYYDD
export const convertDateToValue = (dateString) => {
  if (dateString && dateString !== "") {
    let year = dateString.slice(0, 4);
    let moth = dateString.slice(4, 6);
    let date = dateString.slice(6, 8);
    return date + "/" + moth + "/" + year;
  }
  return "";
};

// chuyển từ MMMMYYDD sang DD//MM/YYY
export const convertValueToDate = (string) => {
  if (string && string !== "") {
    let arr = string.split("/");
    if (arr[1].length === 1) {
      arr[1] = "0" + arr[1];
    }
    if (arr[0].length === 1) {
      arr[0] = "0" + arr[0];
    }
    return arr[2] + arr[1] + arr[0];
  }
};

// chuyển từ MMMMYYDD sang YYYY
export const convertValueToYear = (dateString) => {
  if (dateString && dateString !== "") {
    let year = dateString.slice(0, 4);
    return year;
  }
  return "";
};

// chuyển từ MMMMYYDD sang DD//MM/YYY
export const convertValueToMilisecond = (string) => {
  let milisecond;
  if (string && string !== "") {
    let year = string.slice(0, 4);
    let moth = string.slice(4, 6);
    let date = string.slice(6, 8);
    milisecond = new Date(year, moth, date).getTime();
  }
  return milisecond;
};

//hàm format đơn vị tiền tệ thêm 3 số 0 vào cuối (isFull = false thì thêm .000)
export function formatCurrency(num, currency = "", isFull = false) {
  if (!num || num === "") {
    let response = "0 " + currency;
    return response;
  }
  num = Number(num);
  if (num === 0) {
    return "0 " + currency;
  }
  if (num.length === 2) {
    if (num === "00") {
      num = num.replace("00", "0");
    }
  }
  if (num.length > 1) {
    let first = num.substring(0, 1);
    if (first === "0") {
      num = num.substring(1, num.length);
    }
  }
  let result = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  if (!isFull) {
    result = result + ".000 ";
  }
  return result + currency;
}

//format định dạng giá khi thao tác ô input giá
export const formatNumberToPrice = (x, currency = "") => {
  //HLog("Helpers formatNumberToPrice before x: " + x + " currency: " + currency);
  if (!x || x === "" || x === 0) {
    return 0;
  }
  x = x.toString();
  x = x.replace(/ /g, "");
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, "$1.$2");
  if (typeof currency === "string") {
    x = currency !== "" ? x + " " + currency : x;
  }

  //HLog("Helpers formatNumberToPrice after x: " + x + " currency: " + currency);
  return x;
};

export const formatPriceForExcel = (value) => {
  try {
    let result = 0;
    result = Number(value);
    if (_.isNaN(result)) throw new Error();

    return result;
  } catch (error) {
    HLog(error);
    return "";
  }
};

export const formatPriceToNumber = (x) => {
  try {
    x = x.toString();
    if (x === 0 || x === "") {
      return 0;
    }
    while (x.charAt(0) === 0 && x.length > 1) {
      x = x.substr(1);
    }
    x = x.replace(/ /g, "");
    return Number(x.replace(/[.]+/g, "").trim());
  } catch (error) {
    HLog(error);
  }
};

//hàm validate giá nhập vào và trả lại theo dạng 123.455.232
export const validatePriceInput = (input) => {
  if (!input) {
    return 0;
  }
  HLog("onchangePrice", input);
  input = input.replace(/[^0-9.]/g, "");
  input = formatPriceToNumber(input);
  input = formatNumberToPrice(input);
  HLog("new", input);
  return input;
};

export const encrypt256 = async (key) => {
  HLog("my key: " + key);
  try {
    const encrypted = await sha256(key);
    return encrypted;
  } catch (error) {
    HLog("cant encrypt key...", error);
    return null;
  }
};

//kiểm tra đối tượng object có rỗng hay không
export function isEmptyObject(obj) {
  return JSON.stringify(obj) === "{}";
}

//in hoa chữ cái đầu mỗi từ
export function capital_letter(str) {
  let result = "";
  if (str) {
    str = str.replace(/\s{2,}/g, " ");
    str = str.split(" ");
    // HLog(str);

    for (var i = 0, x = str.length; i < x; i++) {
      if (!!str[i][0]) {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1);
      }
    }
    result = str.join(" ");
  }

  return result;
}

//hàm chuyển đổi firstName, lastName thành fullTime theo ngôn ngữ
export function getFullName(objectInfo) {
  // HLog("Utils getFullName objectInfo: " + JSON.stringify(objectInfo));
  if (objectInfo != null && !isEmptyObject(objectInfo)) {
    // let lang = getLocale();
    let firstName = objectInfo?.HO || "";
    let lastName = objectInfo?.TEN || "";
    let userName = capital_letter(firstName) + " " + capital_letter(lastName);
    return userName.trim();
  } else {
    return "";
  }
}

//hàm trả về tuổi của người dùng
export function convertMillisecondToAge(millisecond, format) {
  // Dành cho trường hợp giá trị đầu vào là dd/mm/yyyy @.@
  if (typeof millisecond === "string" && millisecond.includes("/")) {
    const arrNoSlash = millisecond.split("/");

    if ([...arrNoSlash].length === 3) {
      //millisecond = moment(millisecond, "DD/MM/YYYY").valueOf();
      millisecond = moment(millisecond, format).valueOf();
    }
  }

  if (moment(millisecond, "YYYYMMDD", true).isValid()) {
    millisecond = moment(millisecond, "YYYYMMDD").valueOf();
  }

  millisecond = Number(millisecond); // đổi input thành number

  // nếu milli = NaN hoặc milli = 0 thì return "--"
  if (isNaN(millisecond) || millisecond === 0) {
    return "--";
  }

  // đổi input (milli) về gap giữa hiện tại và input
  let duration = Math.floor(moment.duration(moment().diff(moment(millisecond))).asYears());

  // nếu gap > 0 thì trả về số tuổi (gap được làm tròn xuống)
  if (duration > 0) {
    return `${duration}T`;
  }
  // ngược lại thì trả về số tháng tuổi (gap cũng được làm tròn xuống)
  else {
    duration = Math.ceil(moment.duration(moment().diff(moment(millisecond))).asMonths());
    return `${duration} tháng`;
  }
}

//hàm trả về tuổi của người dùng
export function convertMillisecondToAgeNotT(millisecond, format) {
  // Dành cho trường hợp giá trị đầu vào là dd/mm/yyyy @.@
  if (typeof millisecond === "string" && millisecond.includes("/")) {
    const arrNoSlash = millisecond.split("/");

    if ([...arrNoSlash].length === 3) {
      //millisecond = moment(millisecond, "DD/MM/YYYY").valueOf();
      millisecond = moment(millisecond, format).valueOf();
    }
  }

  millisecond = Number(millisecond); // đổi input thành number

  // nếu milli = NaN hoặc milli = 0 thì return "--"
  if (isNaN(millisecond) || millisecond === 0) {
    return "--";
  }

  // đổi input (milli) về gap giữa hiện tại và input
  let duration = Math.floor(moment.duration(moment().diff(moment(millisecond))).asYears());

  // nếu gap > 0 thì trả về số tuổi (gap được làm tròn xuống)
  if (duration > 0) {
    return `${duration}`;
  }
  // ngược lại thì trả về số tháng tuổi (gap cũng được làm tròn xuống)
  else {
    duration = Math.ceil(moment.duration(moment().diff(moment(millisecond))).asMonths());
    return `${duration} tháng`;
  }
}

// lay gioi tinh
export function getSex(value) {
  let str_return = "";
  if (!value || value === 0 || value === "0") {
    //str_return = t(languageKeys.PSO__MODAL_DETAIL__SEX_FEMALE);
    str_return = i18n.t(languageKeys.gioi_tinh_Nu);
  } else if (value === 1 || value === "1") {
    // str_return = t(languageKeys.PSO__MODAL_DETAIL__SEX_MALE);
    str_return = i18n.t(languageKeys.gioi_tinh_Nam);
  } else if (value === 2) {
    // str_return = t(languageKeys.PSO__MODAL_DETAIL__SEX_OTHER);
    str_return = i18n.t(languageKeys.gioi_tinh_Khac);
  }
  return str_return;
}

// lay gioi tinh
export function getSexFromString(value) {
  switch (locdau(value)) {
    case "nam":
    case "male":
      return i18n.t(languageKeys.gioi_tinh_Nam);
    case "nu":
    case "female":
      return i18n.t(languageKeys.gioi_tinh_Nu);
    default:
      return i18n.t(languageKeys.gioi_tinh_Khac);
  }
}

export function convertGender(value) {
  switch (value) {
    case "nam":
      return i18n.t(languageKeys.gioi_tinh_Nam);
    case "nu":
      return i18n.t(languageKeys.gioi_tinh_Nu);
    case "khac":
      return i18n.t(languageKeys.gioi_tinh_Khac);
    default:
      return value;
  }
}

// hàm lấy danh sách tỉnh thành
export const getAllProvinces = async () => {
  const response = await axios.get("https://api.deepcare.vn/address/getAllProvince");

  const { status, data } = response.data;

  if (status === "OK") {
    return data;
  }

  return [];
};

// hàm lấy danh sách quận huyện
export const getAllDistricts = async (code) => {
  const response = await axios.post("https://api.deepcare.vn/address/searchByCode", {
    address_type: 1,
    code: code,
  });
  const { status, data } = response.data;

  if (status === "OK") {
    return data;
  }

  return [];
};

// hàm lấy danh sách xã phường
export const getAllWards = async (code) => {
  const response = await axios.post("https://api.deepcare.vn/address/searchByCode", {
    address_type: 2,
    code: code,
  });

  const { status, data } = response.data;

  if (status === "OK") {
    return data;
  }

  return [];
};

//hàm lấy thời gian hiển thị cho cuộc hẹn tùy theo type cuộc hẹn
export function getTimeScheduleByType(record) {
  if (!record || !record.schedule_type) {
    return "";
  }

  if (record.schedule_type === keys.section) {
    return convertMilliSlotToTime(record.gio_bat_dau) + " - " + convertMilliSlotToTime(record.gio_ket_thuc);
  } else {
    return convertMilliSlotToTime(record.gio_kham);
  }
}

//hàm đổi thời gian khám millisecond UTC sang thời gian hiện tại
export function convertMilliSlotToTime(milli) {
  if (milli === null || milli === undefined || milli === "") {
    return;
  }
  if (typeof milli == "string") {
    return milli;
  }
  let duration = Number(milli);
  var //   milliseconds = Number((duration % 1000) / 100),
    //     seconds = Number((duration / 1000) % 60),
    minutes = Number((duration / (1000 * 60)) % 60),
    hours = Number((duration / (1000 * 60 * 60)) % 24);

  let newhours = hours < 10 ? "0" + hours : hours;
  let newminutes = minutes < 10 ? "0" + minutes : minutes;
  //   let newseconds = seconds < 10 ? "0" + seconds : seconds;
  let times = newhours + ":" + newminutes;
  return convertTimeUTCToTime(times);
}

//lấy ký tự đầu tiên của học hàm, học vị bác sĩ
export function getEducation(item) {
  if (item == null || isEmptyObject(item)) {
    return "";
  }
  let education = "";
  let academic_rank_name = item.academic_rank_name;
  let degree_name = item.degree_name;
  //học hàm giáo sư, phó giáo sư, tiến sĩ
  if (academic_rank_name != null && academic_rank_name !== "") {
    education = getFirstCharactor(academic_rank_name);
  }
  //học vị, thạc sĩ, bác sĩ...
  if (degree_name != null && degree_name !== "") {
    let result = getFirstCharactor(degree_name);
    if (degree_name.includes("Thạc")) {
      result = "ThS";
    } else if (degree_name.includes("Cử nhân")) {
      result = "BS";
    }
    if (education !== "") {
      education = education + "." + result;
    } else education = result;
  }
  if (education === "") {
    return "BS";
  }
  return education;
}

//lấy ký tự đầu tiên của học hàm, học vị
function getFirstCharactor(txtCharactor) {
  let result = "";
  let arrCharactor = txtCharactor.toLocaleUpperCase().split(" ");
  for (let i = 0; i < arrCharactor.length; i++) {
    let text = arrCharactor[i];
    //nếu là bác sĩ chuyên khoa 2
    if (text === "II") {
      return "BS.CKII";
    } else if (text === "I") {
      //nếu là bác sĩ chuyên khoa 1
      return "BS.CKI";
    }
    result = result + (text ? text.charAt(0).toUpperCase() : "");
  }
  return result;
}

//lấy ra millisecond thời gian theo giờ GMT
export function getTimeMillisecondUTC() {
  let date = new Date();
  let formatdateUTC = date.toUTCString();
  let output = new Date(formatdateUTC).getTime();
  return output;
}

// hàm đọc file excel
export const readExcel = (file, onOk = () => {}) => {
  const promise = new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.onload = (e) => {
      const bufferArray = e.target.result;

      const wb = XLSX.read(bufferArray, { type: "buffer" });

      const wsname = wb.SheetNames[0];

      const ws = wb.Sheets[wsname];

      const data = XLSX.utils.sheet_to_json(ws);

      resolve(data);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });

  promise.then((d) => {
    HLog("excel data", d);
    onOk(d);
  });
};

export const exportToCSV = async (csvData = [], fileName = "data", offsetRow = 0, columnWidths = []) => {
  try {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(csvData);

    if(columnWidths?.length) ws['!cols'] = columnWidths;

    let offColumns = [];

    if (csvData.length > 0) {
      const firstData = csvData[0];
      const keyLength = Object.keys(firstData).length;
      offColumns = new Array(keyLength).fill(null).map(() => [""]);
    }

    XLSX.utils.sheet_add_aoa(
      ws,
      new Array(offsetRow).fill(null).map(() => offColumns),
      { origin: 0 }
    );

    let wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = await XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  } catch (error) {
    HLog("[ERROR] export csv error");
  }
};

// upload ảnh lên server
export const uploadImageBase64 = async (BASE64, ID) => {
  try {
    const accessToken = localStorage.getItem(keys.access_token);
    const response = await common_post(apis.upload_imge_base64, {
      BASE64,
      ID,
    },{ headers: { Authorization: `Bearer ${accessToken}` } });
    

    HLog("upload image respone 11", response);
    if (response.status === "OK") {
      return response.ID;
    }
    return null;
  } catch (error) {
    HLog("upload image fail ", error);
    return null;
  }
};

export const getImageFromSever = (ID) => {
  if (ID && ID !== "") {
    return `${apiServices.GATEWAY_CIS}public/file?ID=${ID}`;
  }
  return "";
};
export const getImageFromSever2 = (ID) => {
  if (ID && ID !== "") {
    return `${apiServices.GATEWAY_CIS_IMAGE}public/file?ID=${ID}`;
  }
  return "";
};

export const beforeUpload = (file) => {
  const isImage = file.type.indexOf("image/") === 0;
  if (!isImage) {
    notification.error({
      message: i18n.t(languageKeys.noti_Chi_duoc_upload_anh),
    });
  }

  const isLt5M = file.size / 1024 / 1024 < 5;
  if (!isLt5M) {
    notification.error({
      message: i18n.t(languageKeys.noti_Kich_thuoc_anh_be_hon_5mb),
    });
  }
  return isImage && isLt5M;
};
export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

// xử lý lấy giá trị object
export function handleGetProperty(object, props, default_value, ignore_value) {
  let result = default_value;
  if (object) {
    result = object[`${props}`];
  }
  if (typeof result != "number" && typeof result != "boolean") {
    if (!result) {
      result = default_value;
    }
  }
  if (result === ignore_value) {
    return default_value;
  }

  return result;
}
//lấy ra thứ trong tuần
export function getWeekdays(strDate) {
  if (!strDate || strDate === "") {
    return "";
  }
  let result = "";
  let days = [
    i18n.t(languageKeys.chu_nhat),
    i18n.t(languageKeys.thu_2),
    i18n.t(languageKeys.thu_3),
    i18n.t(languageKeys.thu_4),
    i18n.t(languageKeys.thu_5),
    i18n.t(languageKeys.thu_6),
    i18n.t(languageKeys.thu_7),
  ];
  let date = new Date(parseInt(strDate));
  result = days[date.getDay()];

  return result;
}

export const getErrorMessage = (error_code, fallback = `ERROR: ${error_code}`, res = {}) => {
  if (!error_code) return fallback;

  switch (error_code.toString()) {
    case "001":
      return i18n.t(languageKeys.error_loi_ton_tai);
    case "002":
      return i18n.t(languageKeys.error_loi_khong_nhap_gia_tri);
    case "003":
      return i18n.t(languageKeys.error_gia_tri_khong_hop_le);
    case "004":
      return i18n.t(languageKeys.error_khong_trung_khop);
    case "paid_service":
    case "009":
      return i18n.t(languageKeys.error_dich_vu_da_thanh_toan);
    case "007":
      return i18n.t(languageKeys.error_Rang_buoc_ton_tai);
    case "008":
      return i18n.t(languageKeys.error_Khong_sua_duoc_lich_kham);
    case "012":
      return (
        i18n.t(languageKeys.error_loi_ton_tai) +
        ` ${i18n.t(languageKeys.error_body__at_column)} ${res.field} ${i18n.t(languageKeys.error_body__and_duplicate_value)} ${res.Dupllicate_value}`
      );
    case "015":
      return i18n.t(languageKeys.error_khong_the_khoa_phong_do_co_benh_nhan_cho_kham);
    default:
      return fallback;
  }
};

export const getDayName = (day) => {
  switch (day) {
    case 0:
      return i18n.t(languageKeys.data_Chu_nhat);
    case 1:
      return i18n.t(languageKeys.data_Thu_2);
    case 2:
      return i18n.t(languageKeys.data_Thu_3);
    case 3:
      return i18n.t(languageKeys.data_Thu_4);
    case 4:
      return i18n.t(languageKeys.data_Thu_5);
    case 5:
      return i18n.t(languageKeys.data_Thu_6);
    case 6:
      return i18n.t(languageKeys.data_Thu_7);
    default:
      return "";
  }
};

export function printPDF(filePath = "") {
  return printJS({
    printable: filePath,
    type: "pdf",
    showModal: true,
    base64: true,
  });
}

export const removeVietnameseTones = (str) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
  return str;
};

/* userProfile: thông tin người dùng chứa QUYEN
/* featureKey: key quyền cần check( định nghĩa trong file keys)
/* strict: có cần check chính xác key hay không hay chỉ cần include ( ví dụ key QL_NS.QL_TK chứa QL_TK)
*/
export const isAccessAllowed = (userProfile, featureKey = "", strict = false) => {
  if (featureKey === featureKeys.ho_tro_khach_hang) return true;
  let { QUYEN_MODULE = [] } = userProfile;

  if (!QUYEN_MODULE) return false;

  let featureList = [];

  if (typeof QUYEN_MODULE === "string" && QUYEN_MODULE.includes("[")) {
    let parseList = JSON.parse(QUYEN_MODULE);
    if (Array.isArray(parseList)) {
      featureList = [...parseList];
    }
  } else if (Array.isArray(QUYEN_MODULE)) {
    featureList = [...QUYEN_MODULE];
  }

  if (typeof featureList !== "object") {
    return false;
  }
  const isAdmin = userProfile.NHOM_NHAN_SU === keyLoaiNhanVien.quan_tri;
  // check config nội trú
  const isNoiTru = userProfile.config && userProfile.config.NOI_TRU ? userProfile.config.NOI_TRU : false;
  if (!isNoiTru && featureKey === featureKeys.quan_ly_noi_tru) {
    return false;
  }

  ///CHECK CONFIG KHO THUỐC
  const isKhoThuoc = userProfile.config.KHO_THUOC || null;
  // nếu viện không có config kho thuốc thì ẩn quản lý dược vậy tư
  if (!isKhoThuoc && featureKey === featureKeys.quan_ly_duoc_ngoai_tru) {
    return false;
  }
  // nếu viện không có config kho thuốc thì ẩn báo cáo dược
  if (!isKhoThuoc && featureKey === `${featureKeys.bao_cao}.${featureKeys.bao_cao_duoc}`) {
    return false;
  }

  // config cổng dược
  if (!userProfile?.config?.DON_THUOC_CONG_DUOC && featureKey === `${featureKeys.quan_ly_duoc_ngoai_tru}.${featureKeys.lien_thong_don_thuoc}`) {
    return false
  }
  // nếu là admin thì mặc định là hết quyền
  if (isAdmin) return true;

  if (!featureList || (Array.isArray(featureList) && featureList.length === 0)) {
    return false;
  }
  if ((featureList && Array.isArray(featureList)) && featureList.find((f) => f.includes("DUOC.QL_DUOC_NGOAITRU.BAO_CAO"))) {
    featureList.push(`${featureKeys.bao_cao}.${featureKeys.bao_cao_duoc_ngoai_tru}`);
  }
  // const checkResult = featureList.some((feature) => (!!strict ? feature === featureKey : feature.includes(featureKey)));
  const checkResult = featureList.some((feature) => feature.includes(featureKey));

  return checkResult;
};

export const currencyParser = (val) => {
  try {
    // for when the input gets clears
    if (typeof val === "string" && !val.length) {
      val = null;
    }
    if (val != null) {
      // detecting and parsing between comma and dot
      var group = new Intl.NumberFormat("vi-VN").format(1111).replace(/1/g, "");
      var decimal = new Intl.NumberFormat("vi-VN").format(1.1).replace(/1/g, "");
      var reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
      reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");

      // removing everything except the digits and dot
      // reversedVal = reversedVal.replace(/[^0-9.]/g, "");
      //  => 1232.21

      // appending digits properly
      const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
      const needsDigitsAppended = digitsAfterDecimalCount > 2;

      if (needsDigitsAppended) {
        reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
      }

      return Number.isNaN(reversedVal) ? 0 : reversedVal;
    }
  } catch (error) {
    console.error(error);
  }
};

export const currencyParserNotAlowNegativeNum = (val) => {
  try {
    // for when the input gets clears
    if (typeof val === "string" && !val.length) {
      val = null;
    }
    if (val != null) {
      // detecting and parsing between comma and dot
      var group = new Intl.NumberFormat("vi-VN").format(1111).replace(/1/g, "");
      var decimal = new Intl.NumberFormat("vi-VN").format(1.1).replace(/1/g, "");
      var reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
      reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");

      // removing everything except the digits and dot
      reversedVal = reversedVal.replace(/[^0-9.]/g, "");
      //  => 1232.21

      // appending digits properly
      const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
      const needsDigitsAppended = digitsAfterDecimalCount > 2;

      if (needsDigitsAppended) {
        reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
      }

      return Number.isNaN(reversedVal) ? 0 : reversedVal;
    }
  } catch (error) {
    console.error(error);
  }
};
export const getDateBefore = (value) => {
  let today = new Date();
  let before = new Date().getTime() - value * 86400000;
  console.log(before);
  let year = new Date(before).getFullYear().toString();
  let month = new Date(before).getMonth() + 1;
  console.log("MONTH", typeof month);
  let newMonth = () => {
    let monthVal = month.toString();
    if (monthVal === 1) {
      return `0${monthVal}`;
    } else {
      return monthVal;
    }
  };
  let date = new Date(before).getDate().toString();
  let newDate = moment();
  return year + newMonth() + date;
};

//Lấy các chữ cái đầu tiên của từng từ
export const getFirstLetters = (str = "") => {
  const firstLetters = str
    .split(" ")
    .map((word) => (!isNaN(Number(word[0])) ? word : word[0]))
    .join("");

  return locdau(firstLetters);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// lọc dấu
export function locdau(str) {
  if (!str) {
    return "";
  } else {
    str = str.toLocaleLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "");
    return str;
  }
}

export const getDateTimeTextVi = (date = moment()) => {
  return;
};
export const getDateBefore2 = (value) => {
  let myCurrentDate = new Date();
  let myPastDate = new Date(myCurrentDate);
  myPastDate.setDate(myPastDate.getDate() - value);
  return moment(myPastDate).format("YYYY/MM/DD").replaceAll("/", "");
};

export const getTextAge = (value) => {
  switch (value) {
    case "DUOI_MOT_TUOI":
      return `<1 ${i18n.t(languageKeys.tuoi)}`;
    case "TU_MOT_DEN_MUOI_HAI_TUOI":
      return `1 - 11 ${i18n.t(languageKeys.tuoi)}`;
    case "TU_MUOI_HAI_DEN_MUOI_TAM_TUOI":
      return `12-17 ${i18n.t(languageKeys.tuoi)}`;
    case "TU_MUOI_TAM_DEN_SAU_TU_TUOI":
      return `18 - 64 ${i18n.t(languageKeys.tuoi)}`;
    case "TREN_SAU_TU_TUOI":
      return `>64 ${i18n.t(languageKeys.tuoi)}`;
    default:
      return "";
  }
};

//hàm format đơn vị tiền tệ thêm 3 số 0 vào cuối (isFull = false thì thêm .000)
export function formatCurrency2(num, currency = "", isFull = false) {
  if (!num || num === "") {
    let response = "0 " + currency;
    return response;
  }
  num = Math.round(Number(num));
  if (num === 0) {
    return "0 " + currency;
  }

  if (num.length === 2) {
    if (num === "00") {
      num = num.replace("00", "0");
    }
  }
  if (num.length > 1) {
    let first = num.substring(0, 1);
    if (first === "0") {
      num = num.substring(1, num.length);
    }
  }
  let result = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  if (!isFull) {
    result = result + ".000 ";
  }
  return result + " " + currency;
}

export const getTenLoaiDoiTuong = (key) => {
  switch (key) {
    case "":
      return i18n.t(languageKeys.option_Tat_ca);
    case "VIEN_PHI":
      return i18n.t(languageKeys.field_doi_tuong_vien_phi);
    case "NUOC_NGOAI":
      return i18n.t(languageKeys.field_doi_tuong_nuoc_ngoai);
    default:
      return i18n.t(languageKeys.option_Tat_ca);
  }
};

export function genIDOnlyNumber(maxSize = 10, prefix) {
  if (prefix) {
    maxSize = maxSize - prefix.length - 1;
  }
  let current_date = new Date();
  let current_time = current_date.getTime();
  let current_day = current_date.getDate();
  current_day = current_day < 10 ? "0" + current_day : `${current_day}`;
  let rand = Math.floor(Math.random() * (Math.floor(Math.random() * 1000) - Math.floor(Math.random() * 100) + 600) + Math.floor(Math.random() * 100));

  var s = 1234567890 * rand + current_time * rand;
  s += "";
  let result;

  // var str = Array(maxSize).join().split(',').map(function () { return s.charAt(Math.floor(Math.random() * s.length)); }).join('');
  let [_d1, _d2] = current_day;
  var str = [_d1, _d2];
  while (str.length < maxSize) {
    str.push(s.charAt(Math.floor(Math.random() * s.length)));
  }
  str = str.join("");
  if (!!prefix) {
    result = `${prefix.toLocaleUpperCase()}_${str}`;
  } else {
    result = `${str}`;
  }

  return result;
}
/**
 * @name validFragment
 * @description pick properties for insert input data into database
 * @param {Array<Object>} data raw data
 * @param { Array<string> | Object} columnStandard Object {"NHANSU_ID": "* removeAfterValid"}
 * param {* required require} {removeAfterValid}
 */
// chú ý với data[key] là json
export const validFragment = async (data, columnStandard) => {
  let result = [];
  try {
    let internalColumns = [];
    if (Array.isArray(columnStandard)) {
      internalColumns = columnStandard;
    } else {
      internalColumns = Object.keys(columnStandard);
    }
    for (let i = 0; i < data.length; i++) {
      result[i] = _.pick(data[i], internalColumns);
    }

    return result;
  } catch (error) {
    HLog("[ERROR] function validFragment()", error?.message);
  }
};

export function docTenThuocTheoThongTu52(data_thuoc) {
  // let {TEN_THUOC , HAM_LUONG, NONG_DO, HOAT_CHAT} = data_thuoc;
  let TEN_THUOC = data_thuoc.TEN_THUOC || data_thuoc.Thuoc_Ten;
  let HOAT_CHAT = data_thuoc.HOAT_CHAT || data_thuoc.Thuoc_HoatChat;
  let NONG_DO = data_thuoc.NONG_DO;
  let HAM_LUONG = data_thuoc.HAM_LUONG;

  if (!!HOAT_CHAT) {
    if (HOAT_CHAT.includes("+") || HOAT_CHAT.includes(";")) {
      //thuốc có nhiều hoạt chất , trả về tên thương mại (các hoạt chất được phân cách bằng dấu + hoặc ; )
      return TEN_THUOC;
    }

    let array_name = HOAT_CHAT.split(" ");
    //kiểm tra tên hoạt chất có chứa hàm lượng không, hàm lượng viết cuối và có số
    let ham_luong = array_name[array_name.length - 1].replace(/[^0-9]/g, "");

    //nếu có tên thương mại (tên thuốc), thì ghép vào không thì bỏ qua
    let ten_thuong_mai = !!TEN_THUOC ? " (" + TEN_THUOC + ") " : "";

    //nếu từ cuối có số => có hàm lượng, cộng thêm tên thương mại vào trước hàm lượng
    if (ham_luong !== "") {
      array_name[array_name.length - 1] = ten_thuong_mai + array_name[array_name.length - 1];
      return array_name.join("");
    }

    //Nếu không có hàm lượng trong hoạt chất, ghép thêm tên thương mại +  HAM_LUONG hoặc NONG_DO vào cuối, tùy theo cái nào có giá trị
    let hl = !!HAM_LUONG ? HAM_LUONG : "";
    if (hl === "" && !!NONG_DO) {
      hl = NONG_DO;
    }
    return HOAT_CHAT + ten_thuong_mai + hl;
  }

  //Không có tên hoạt chất, trả về tên thương mại
  return TEN_THUOC;
}

export function handleErrorHuyPhieu(error_code) {
  let string_error = "";
  switch (error_code) {
    case "016":
      string_error = i18n.t(languageKeys.noti_khong_the_huy_phieu_vi_co_thuoc_da_ke) + " !";
      break;
    default:
      string_error = i18n.t(languageKeys.noti_co_loi_xay_ra_khong_the_huy_phieu);
      break;
  }
  return string_error;
}
// làm phẳng object
export const flattenMessages = (nestedMessages, prefix = "") => {
  if (nestedMessages == null) {
    return {};
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    // value của object
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "string" || typeof value === "number") {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

export const getTrangThaiPhieuThuoc = (status) => {
  switch (status) {
    case "HUY":
      return i18n.t(languageKeys.status_Da_huy);
    case "HOAN_THANH":
      return i18n.t(languageKeys.da_HT);
    case "CHO_DUYET":
      return i18n.t(languageKeys.cho_duyet);
    default:
      return "";
  }
};

export function removeTags(str = "") {
  // Create a new div element
  var tempDivElement = document.createElement("div");

  // Set the HTML content with the given value
  tempDivElement.innerHTML = str;

  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || "";
}

// Convert nested HTML info flatten array
export function flattenHtml(node, flat = [], tagsList = []) {
  // Add the current tag
  tagsList.push(node);

  // Check if it is a leaf or not
  if (!node.childNodes.length) {
    // Calculate the node index
    let index = flat[flat.length - 1] === undefined ? 0 : flat[flat.length - 1].index + flat[flat.length - 1].length;
    // Push the node in the array
    flat.push({ index: index, length: node.length ?? 1, text: node.wholeText ?? "", parents: [...tagsList] });
  } else {
    // Call the function recursively on each child
    node.childNodes.forEach((child) => {
      flat = flattenHtml(child, flat, tagsList);
    });
  }
  // Remove the current tag
  tagsList.splice(tagsList.indexOf(node), 1);
  return flat;
}

export function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
}
export function isObject(object) {
  return object != null && typeof object === "object";
}

export function layDiaChi(benh_vien) {
  let string = "";
  if (benh_vien.DIA_CHI_CHI_TIET) {
    string = benh_vien.DIA_CHI_CHI_TIET;
  }
  if (benh_vien.TEN_PHUONG_XA) {
    string = string + `, ${benh_vien.TEN_PHUONG_XA}`;
  }
  if (benh_vien.TEN_QUAN_HUYEN) {
    string = string + `, ${benh_vien.TEN_QUAN_HUYEN}`;
  }
  if (benh_vien.TEN_TINH_THANH) {
    string = string + `, ${benh_vien.TEN_TINH_THANH}`;
  }
  return string;
}

/**
 *
 * @param {*} arr  là mảng các quyền con (giới hạn ở lớp 3)
 * @returns mảng gồm các quyền gán kèm quyền cha
 */

export function addParentPermissionKey(arr) {
  if (isJsonString(arr)) {
    arr = JSON.parse(arr);
  }

  if (!Array.isArray(arr)) return arr;

  let result = [...arr];
  let primary = [];
  let secondary = [];
  let third = [];

  result.forEach((item, index) => {
    if (item.split(".").length < 2) primary.push(item);
    let split_item = item.split(".");

    if (split_item.length > 1) {
      primary.push(split_item[0]);
      secondary.push([split_item[0], split_item[1]].join("."));
      third.push(split_item.join("."));
    }
  });
  result = [...primary, ...secondary, ...third];
  result = _.uniq(result);
  return result;
}

export function removeParentPermissionKey(arr) {
  if (!Array.isArray(arr)) return arr;
  let result = [...arr];
  let primary = [];
  let secondary = [];
  let third = [];
  result.forEach((item, index) => {
    if (item.includes(".")) {
      secondary.push(item);
    } else {
      primary.push(item);
    }
  });
  secondary.forEach((item, index) => {
    for (let i = 0; i < primary.length; i++) {
      const e = primary[i];
      if (item.includes(e)) {
        delete primary[i];
      }
    }
    if (item.split(".").length === 3) {
      third.push(item);
    }
  });

  third.forEach((item, index) => {
    for (let i = 0; i < secondary.length; i++) {
      const e = secondary[i];
      if (item.includes(e)) {
        delete secondary[i];
      }
    }
  });
  result = [...primary, ...secondary, ...third].filter((item) => !!item);

  return result;
}

export const formPattern = {
  numberOnly: /^[0-9]*$/,
  email: /\S+@\S+\.\S+/,
  // phone: /^[0-9]*$/,
  phone: /^[0]?[35789]\d{8}$/,
  fullName: /[a-zA-Z]+\s+[a-zA-Z]+/g,
  numberDecimalPositive: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
  huyet_ap: /^\d{1,3}\/\d{1,3}$/,
};

export function formatDateOfBirth(string) {
  // nếu là năm thì hiển thị năm
  // nếu là YYYYMMDD thì fomat lại dạng DD/MM/YYYY
  let result = "";
  if (!!string) {
    result = string.length > 4 ? moment(string, "YYYYMMDD").format("DD/MM/YYYY") : string;
  }
  return result;
}

export const getFullDiaChi = (record = {}) => {
  let result = "";

  if (!!record.DIA_CHI_CHI_TIET) result += `${record.DIA_CHI_CHI_TIET}`;
  if (!!record.TEN_PHUONG_XA) result += `, ${record.TEN_PHUONG_XA}`;
  if (!!record.TEN_QUAN_HUYEN) result += `, ${record.TEN_QUAN_HUYEN}`;
  if (!!record.TEN_TINH_THANH) result += `, ${record.TEN_TINH_THANH}`;

  return result;
};

export const clearLocalData = () => {
  const DBDeleteRequest = window.indexedDB.deleteDatabase(Constants.INDEX_DB_NAME);

  DBDeleteRequest.onerror = (e) => {
    HLog("Error deleting database.");
    window.location.reload();
  };

  DBDeleteRequest.onsuccess = (e) => {
    HLog("Database deleted successfully");
  };

  let arrLocalKey = Object.keys(localStorage).filter(i => localWhitelist.includes(i));

  for (let i = 0; i < arrLocalKey.length; i++) {
    if (!localKeyNotChange.includes(arrLocalKey[i])) {
      localStorage.removeItem(arrLocalKey[i]);
    }
  }
  // localStorage.clear();
};

export function genCharArray(charA, charZ) {
  var a = [],
    i = charA.charCodeAt(0),
    j = charZ.charCodeAt(0);
  for (; i <= j; ++i) {
    a.push(String.fromCharCode(i));
  }
  return a;
}

export function getSizePayload(payload) {
  const jsonString = JSON.stringify(payload);
  const byteSize = new TextEncoder().encode(jsonString).length;
  return byteSize / 1048576;
}

export const getDataJson = async (dataString, field) => {
  try {
    let data = await JSON.parse(dataString);
    if (_.isObject(data)) {
      if (!!field) {
        return data[field];
      } else {
        return data;
      }
    } else {
      return dataString;
    }
  } catch (error) {
    return dataString;
  }
};

/**
 * @name compareObject
 * @description method compare two Object
 * @param {Object} data raw data object
 * @param {String} type 'soft' : soft compare,'strict': hard compare
 * @param {Function} callback callback enhance function (value of data, value of compareData)
 * @param {Object} compareData data to compare
 */

export const compareObject = (data, compareData, type = "strict") => {
  if (!data || !compareData) return true;

  const arrRawKeyVal = Object.entries(data);

  const arrCompKeyVal = Object.entries(compareData);

  return {
    deepCompare: (callback) => {
      let diffCheck = false; // nếu true = khác nhau
      if ((type === "strict", arrCompKeyVal.length !== arrCompKeyVal.length)) {
        return true;
      } else {
        for (let i = 0; i < arrRawKeyVal.length; i++) {
          const ele = arrRawKeyVal[i];
          if (ele[0] in compareData) {
            if (callback(ele[1], compareData[ele[0]])) {
              diffCheck = true;
              break;
            }
          }
        }
        return diffCheck;
      }
    },
    shallowCompare: (callback) => {
      const dataString = JSON.stringify(data);
      const compareDataString = JSON.stringify(compareData);
      if (callback) {
        return callback(dataString, compareDataString);
      } else {
        return dataString !== compareDataString;
      }
    },
  };
};

export const formatDay = (day) => moment(day).format("DD/MM/YYYY");

export const handleRedirect = (url) => {
  window.open(url, "_blank");
};

// calculate index of current table item
export const calcIndex = (cur_page, index, limit) => {
  let result = 1 + index + (cur_page - 1) * limit;

  [cur_page, index, limit].forEach((i) => {
    if (typeof i !== "number") return undefined;
    else if (i < 1) return 0;
  });

  return result < 10 ? `0${result}` : result;
};

export const base64ImageToBlob = (stringBase64) => {

  const base64String = stringBase64;
  const mimeType = "image/*";

  const base64Data = base64String.split(",")[1];
  const byteCharacters = atob(base64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: mimeType });
  return blob
}

export const base64ToBlob = (base64, contentType) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
};

export const base64ToBlob2 = (base64, contentType) => {
  console.log(`base64`, base64);
  // Kiểm tra xem chuỗi Base64 có chứa dấu phẩy không
  const base64Data = base64.includes(",") ? base64.split(",")[1] : base64;

  // Nếu có phần tiền tố, cố gắng lấy kiểu MIME từ chuỗi
  const mimeType = contentType || (base64.match(/data:(.*?);base64/) && base64.match(/data:(.*?);base64/)[1]) || 'application/octet-stream';

  const byteCharacters = atob(base64Data);
  const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
  
  return new Blob([new Uint8Array(byteNumbers)], { type: mimeType });
};

export const resizeFile = async (file, typeOutput) => {

  let resizedFile = null;

  if (file.size <= 500000) {

    const promise = new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300, 
        300,
        'JPEG', 
        100,
        0,
        (result) => {
          resolve(result);
        },
        typeOutput ? typeOutput : 'file'
      );
    });

    resizedFile = await promise;
    return resizedFile; 
  }

  while (!resizedFile || resizedFile.size > 500000) {
    let quality = 90; // Chất lượng ban đầu

    // Giảm chất lượng và thử lại
    const promise = new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300, // Chiều rộng mới
        300, // Chiều cao mới
        'JPEG', // Định dạng đầu ra
        quality, // Chất lượng
        0, // Độ quay
        (result) => {
          resolve(result);
        },
        typeOutput ? typeOutput : 'file'
      );
    });

    resizedFile = await promise;
    quality = quality - 5; // Giảm chất lượng xuống 5 đơn vị mỗi lần
  }

  console.log(`resizedFile`, resizedFile);

  return resizedFile
}

export const resizeFile2 = async (file, typeOutput) => {
  let resizedFile = file;
  if (file.size <= 300000) {
    if (typeOutput === "base64") {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(file);
      });
    }

    return file;
  }

  // Hàm để xử lý resize
  const resizeImage = async (inputFile, quality) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        inputFile,
        300, // Chiều rộng mới
        300, // Chiều cao mới
        'JPEG', // Định dạng đầu ra
        quality, // Chất lượng
        0, // Độ quay
        (result) => {
          resolve(result);
        },
        typeOutput ? typeOutput : 'file'
      );
    });
  };

  let quality = 98; // Chất lượng ban đầu
  while (resizedFile && resizedFile.size > 300000) {
    resizedFile = await resizeImage(resizedFile, quality);
    quality -= 5; // Giảm chất lượng xuống 5 đơn vị mỗi lần
  }
  return resizedFile;
};


export const compressionImage = async (image) => {
  const options = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 500,
    useWebWorker: true,
  };
  // console.log(`originalFile size ${image.size / 1024 / 1024} MB`);
  try {
    const currentSize = image.size / 1024 / 1024;
    if (currentSize < 0.5) {
      return image;
    }
    const compressedFile = await imageCompression(image, options);
    // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
    return compressedFile;
  } catch (error) {
    throw error;
  }
};
/**
 *
 * @param {Object} userProfile user props from store
 * @param {String} phan_he  featureKeys from keys.js
 * @returns {PermissionObj} list of permissions for module
 */
export const handleCheckPermissions = (userProfile = {}, phan_he) => {
  try {
    const { QUYEN_MODULE, NHOM_NHAN_SU } = userProfile;
    const isAccessPermission = {
      XEM: false,
      THEM: false,
      SUA: false,
      XOA: false,
      TAI_XUONG: false,
      HUY: false,
      IN_PDF: false,
      XUAT_EXCEL: false,
      XAC_NHAN: false,
      THUC_HIEN: false,
      THEM_MOI: false,
      SUA_DICH_VU: false,
      SUA_BENH_NHAN: false,
      THANH_TOAN: false,
      HOAN_TIEN: false,
      DUYET_XUAT_THANH_TOAN: false,
      BAN_VANG_LAI: false,
      IN_THE: false,
      XUAT_THE: false,
      TAO_PHIEU_NHAP: false,
      TAO_PHIEU_XUAT: false,
      DUYET_PHIEU: false,
      SUA_PHIEU_NHAP: false,
      HUY_DUYET: false,
      IN_PHIEU: false,
      DIEU_CHINH: false,
      IN: false,
      XOA_DICH_VU: false,
      THUC_HIEN_TRA_KQ: false,
      IN_KQ: false,
      THEM_MAU: false,
      XOA_MAU: false,
      DUYET: false,
      KHOA: false,
      COPY: false,
      ACTIVE: false,
      COMPLETE: false,
      IN_FILE: false,
      HUY_THUC_HIEN: false,
      TIEP_DON: false,
      XEM_TT: false,
      TAO_PHIEU_THU: false,
      HUY_PHIEU_THU: false,
      THEM_MOI_LIEU_TRINH: false,
      SUA_KE_HOACH_SD: false,
      SUA_LIEU_TRINH: false,
      TAT_TOAN: false,
      CHI_DINH_VAT_TU: false,
      HOAN_NO: false,
    };

    if (phan_he && QUYEN_MODULE) {
      let arr_permissions_module = QUYEN_MODULE.filter((quyen) => quyen.includes(phan_he));
      if (NHOM_NHAN_SU === "QUAN_TRI") {
        return _.mapValues(isAccessPermission, () => true);
      }
      arr_permissions_module.forEach((per) => {
        let curr = per.split(".").reverse()[0];
        if (curr in isAccessPermission) {
          isAccessPermission[curr] = true;
        }
      });
      return isAccessPermission;
    }

    return false;
  } catch (error) {
    HLog(error);
    return {};
  }
};


export const convertEmptyStringToNull = (obj) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'string' && obj[key]?.trim() === "") {
        obj[key] = null;
      }
    }
  }
  return obj
}

export const filterOption = (input, option) => {
  return (
    option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    option?.value?.toString().toLowerCase()?.indexOf(input.toLowerCase()) >= 0
  );
};

/**
 * block character input
 * @param {KeyboardEvent} e Input keyboard Event
 * @param {Function} expandCondition return boolean condition
 */
export const handleOnlyNumber = (e, expandCondition) => {
  if ((e.key.length == 1) && Number.isNaN(+e.key) && (expandCondition ? expandCondition(e) : !!1)) {
    e.stopPropagation();
    e.preventDefault();
  }
}
/**
 * @name renderAddress
 * @param {Object} userProfile 
 * @returns {String} địa chỉ phòng khám
 */
export const renderAddress = (user) => {
  if (user && user.config && user.config.CUSTOM_DOMAIN) {
    let { DIA_CHI } = user.config.CUSTOM_DOMAIN;

    if (DIA_CHI) {
      if (typeof DIA_CHI === "string") {
        return DIA_CHI;
      } else {
        return `${i18n.t(languageKeys.dia_chi)}: ` + i18n.language === languages.tieng_viet ? DIA_CHI["vi"] : DIA_CHI["en"];
      }
    } else {
      return `${i18n.t(languageKeys.dia_chi)}: ` + i18n.t(languageKeys.dia_chi_deepcare);
    }
  } else {
    return "";
  }
};

export const convertListName = (string, name = i18n.t(languageKeys.tiep_tan)) => {
  let _string = string;
  let result = "";

  if (!string.includes(";") && string.length === 0) return name;
  if (!string.includes(";") && !!string.length) return string;

  if (_string === "") {
    return result;
  }

  if (string.includes(";")) {
    _string = _string.split(";");
  }
  if (!!_string.length && _string.length < 2) {
    result = !_string[0] ? `${name}` : _string[0].join("");
  } else if (!!_string.length && _string.length >= 2) {
    for (let i = 0; i < _string.length; i++) {
      const element = _string[i];
      if (element.length == 0) {
        _string[i] = name;
      }
    }
    result = _string.join(";");
  }
  return result;
};

export const formatPhoneNumber = (phoneNumber = "") => {
  if (!_.isString(phoneNumber)) return phoneNumber;

  if (phoneNumber.length === 8) {
    return phoneNumber.replace(/\D*(\d{4})\D*(\d{4})\D*/, "$1 $2");
  }

  return phoneNumber.replace(/\D*(\d{4})\D*(\d{3})\D*(\d{3})\D*/, "$1 $2 $3");
};

export const getBase64FromUrl = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export const getBase64FromUrlWithCompression = async (url) => {
    const access_token = localStorage.getItem(keys.access_token);
    const response = await fetch(url,{headers: {
      Authorization:`Bearer ${access_token}`
    }});
  
    const blob = await response.blob();
    const dataResize = await resizeFile(blob, "base64");

    // return new Promise((resolve, reject) => {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     const base64data = reader.result;
    //     resolve(base64data);
    //   };
    //   reader.onerror = reject;
    //   reader.readAsDataURL(dataResize);
    // });
    return dataResize

}

export const formatNumberToPrice2 = (x) => {
  if (!x || x === "" || x === 0) {
    return 0;
  }
  if (x === 1) {
    return 1;
  }
  x = x.toString();
  x = x.replace(/ /g, "");
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, "$1.$2");
  return x;
};


/**
 *
 * @param {[]} roomList : Dữ liệu phòng từ DB
 * @param {[]} roomIds : List phòng ID gắn theo dịch vụ
 * @description : Lấy phòng thực hiện được gắn với dịch vụ
 * @returns {Array} Danh sách phòng từ initDB
 */
export const getRoomWithServiceId = (roomList, roomIds) => {
  let result = [];
  try {
    result = roomList.filter(r => roomIds.includes(r.ID));
    return result;

  } catch (error) {
    HLog(error)
  } finally {
    // HLog('choosen room::',result);
  }
};

export const getFirstRoomId = (roomList, roomIds = []) => {
  try {
    const result = roomList.filter(r => roomIds.includes(r.ID));
    return result?.[0]?.ID;
  } catch (error) {
    HLog(error)
  } finally {
    // HLog('choosen room::',result);
  }
};

export const havePermissionAi = (userProfile) => {
  try {
    let { config } = userProfile;
    if (!config) return false;
    let { MODULES } = config;
    if (Array.isArray(MODULES)) {
      if (config.USE_AI) return true;
      // chưa có key phân hệ đang hardcode
      if (MODULES.includes('AI')) {
        return true;
      } else {
        return false;
      }
    }
  } catch (error) {
    HLog('error havePermissionAi: ', error);
    return false;
  }
}
export const handleGlobalKeyPressEnterToTab = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    const focusableElements = document.querySelectorAll('input, select:not(:disabled)');
    const focusableArray = Array.from(focusableElements);
    const sortedElements = focusableArray.sort((a, b) => {
      return (a.tabIndex || 0) - (b.tabIndex || 0);
    });
    const currentElement = e.target;
    const currentIndex = sortedElements.indexOf(currentElement);
    let nextIndex = currentIndex + 1;

    while (nextIndex < sortedElements.length) {
      const nextElement = sortedElements[nextIndex];
      if (nextElement !== null && nextElement !== undefined && !nextElement.disabled) {
        nextElement.focus();
        break;
      }
      nextIndex++;
    }
  }
};

export const blurAll = () => {

  const focusableElements = document.querySelectorAll("input, select");
  const focusableArray = Array.from(focusableElements);

  focusableArray.forEach((element) => {
    if (element instanceof HTMLElement) {
      element?.blur();
    }
  });
}


export const getTextThCLS = (value) => {
  if (value == "DANG_THUC_HIEN") {
    return i18n.t(languageKeys.tra_kq)
  }

  return undefined
}

/**
 * @name convertString
 * @param {*} data nguồn dữ liệu
 * @returns JSON string
 * 
 * @description sử dụng với useEffect check dữ liệu có cần render không
 */
export const getJSONstring = (data) => {
  try {
    return JSON.stringify(data);
  } catch (error) {
    return '';
  }
}
export const checkIsNameLeTanThuNgan = (string) => {
  return (locdau(string?.toLocaleLowerCase() || "")?.includes('tiep tan') || locdau(string?.toLocaleLowerCase() || "")?.includes('thu ngan') || locdau(string?.toLocaleLowerCase() || "")?.includes('le tan' || "") || string?.includes('Lễ  Tân'))
}

/**
 * @description Kiểm tra trùng lặp của mảng Object theo key, nếu trùng thì trả về giá trị bị trùng + giá trị mới sau khi xử lý trùng
 * @param {Array<any>} values 
 * @param {string} identifyKey
 * @returns  {{hasDuplicated: boolean ,duplicatedItems : Array<any>,uniqueValues: Array<any>}}
 */
export const hasDuplicateValues = (values, identifyKey) => {
  try {
    let uniqueValues = _.uniqBy(values, identifyKey);
    const hasDuplicated = uniqueValues.length !== values.length;
    const duplicatedItems = [];

    if (hasDuplicated) {
      const uniqueValuesSet = new Set();
      values.forEach(item => {
        const dataCheck = item?.[identifyKey];
        if (uniqueValuesSet.has(dataCheck)) {
          duplicatedItems.push(item);
        } else {
          uniqueValuesSet.add(dataCheck);
        }
      });

      console.log("Các phần tử trùng lặp:", duplicatedItems);
    }
    return {
      hasDuplicated,
      duplicatedItems,
      uniqueValues,
    }
  } catch (error) {
    console.log(error);
    return {};
  }
}

export const calcTotal = (arr = [], field = '') => {
  return arr.reduce((total, curr) => total + Number(curr[field]), 0)
}

/**
 * @namespace PermissionObj
 * @typedef {Object} PermissionObj
 * @property {boolean} XEM
 * @property {boolean} THEM
 * @property {boolean} SUA
 * @property {boolean} XOA
 * @property {boolean} TAI_XUONG
 * @property {boolean} HUY
 * @property {boolean} IN_PDF
 * @property {boolean} XUAT_EXCEL
 * @property {boolean} XAC_NHAN
 * @property {boolean} THUC_HIEN
 * @property {boolean} THEM_MOI
 * @property {boolean} SUA_DICH_VU
 * @property {boolean} SUA_BENH_NHAN
 * @property {boolean} THANH_TOAN
 * @property {boolean} HOAN_TIEN
 * @property {boolean} DUYET_XUAT_THANH_TOAN
 * @property {boolean} BAN_VANG_LAI
 * @property {boolean} IN_THE
 * @property {boolean} XUAT_THE
 * @property {boolean} TAO_PHIEU_NHAP
 * @property {boolean} TAO_PHIEU_XUAT
 * @property {boolean} DUYET_PHIEU
 * @property {boolean} SUA_PHIEU_NHAP
 * @property {boolean} HUY_DUYET
 * @property {boolean} IN_PHIEU
 * @property {boolean} DIEU_CHINH
 * @property {boolean} IN
 * @property {boolean} XOA_DICH_VU
 * @property {boolean} THUC_HIEN_TRA_KQ
 * @property {boolean} IN_KQ
 * @property {boolean} THEM_MAU
 * @property {boolean} XOA_MAU
 * @property {boolean} DUYET
 * @property {boolean} KHOA
 * @property {boolean} COPY
 * @property {boolean} ACTIVE
 * @property {boolean} COMPLETE
 * @property {boolean} IN_FILE
 * @property {boolean} HUY_THUC_HIEN
 * @property {boolean} TIEP_DON
 * @property {boolean} XEM_TT
 */

export const converGiaTriThamChieu = (subItem, benh_pham) => {
  if (subItem.KIEU_CHI_SO === "SO_THAP_PHAN" || subItem.KIEU_CHI_SO === "SO_NGUYEN") {

    let DO_TUOI = null;
    let ngaySinh

    if (benh_pham?.NGAY_SINH?.length === 4) {
      ngaySinh = moment(benh_pham?.NGAY_SINH).format("YYYY");
      DO_TUOI = moment().diff(ngaySinh, "years");
    }

    if (benh_pham?.NGAY_SINH?.length === 8) {
      ngaySinh = moment(benh_pham?.NGAY_SINH).format("YYYYMMDD");
      DO_TUOI = moment().diff(ngaySinh, "years");
    }

    const gia_tri = subItem?.GIA_TRI?.filter((obj) => {
      if (obj.GIOI_TINH?.toLocaleLowerCase() === benh_pham?.GIOI_TINH?.toLocaleLowerCase() || obj.GIOI_TINH?.toLocaleLowerCase() === "tat_ca") {
        if (DO_TUOI <= 0) {
          const SO_NGAY_TUOI = moment().diff(ngaySinh, "days");
          if ((obj.FROM_TUOI <= SO_NGAY_TUOI) && (SO_NGAY_TUOI <= (obj?.TO_TUOI || 999)) && obj?.DON_VI_TUOI === "DAY") {
            return obj;
          }
        } else {
          if ((obj.FROM_TUOI <= DO_TUOI) && (DO_TUOI <= (obj?.TO_TUOI || 999)) && obj?.DON_VI_TUOI === "YEAR") {
            return obj;
          }
        }
      }
    });

    if (gia_tri?.length > 0) {
      let gia_tri_text = `${gia_tri[0].CAN_DUOI} - ${gia_tri[0].CAN_TREN}`;

      if (gia_tri[0].CAN_DUOI && !gia_tri[0].CAN_TREN) {
        gia_tri_text = ` > ${gia_tri[0].CAN_DUOI}`;
      } else if (!gia_tri[0].CAN_DUOI && gia_tri[0].CAN_TREN) {
        gia_tri_text = ` < ${gia_tri[0].CAN_TREN}`;
      }
      return gia_tri_text;
    }
  }

  if (subItem.KIEU_CHI_SO === "MOT_LUA_CHON" || subItem.KIEU_CHI_SO === "NHIEU_LUA_CHON") {
    return subItem?.KQ_MAC_DINH;
  }
};


export const getGender2 = (key = "") => {
  let result = null;

  if (key.toLocaleLowerCase() === "nam") result = "Nam";
  else if (key.toLocaleLowerCase() === "nu") result = "Nữ";

  return result;
};


const handleRenderThamChieu = (item,value,partner_code) => {
  let details = [];
  let showGtKhoang = true
  let showTuoiKhoang = true

  if (partnerCodeNoShowTuoiDaiThamChieu.includes(partner_code)) {
    showGtKhoang = false
  }
  if (partnerCodeNoShowGoiTinhDaiThamChieu.includes(partner_code)) {
    showTuoiKhoang = false
  }

  if (value.GIOI_TINH && getGender2(item.GIOI_TINH) && showGtKhoang) {
    details.push(` ${getGender2(item.GIOI_TINH)}`)
  }

  let gia_tri_tuoi = `${value.FROM_TUOI} - ${value.TO_TUOI} ${value?.DON_VI_TUOI === "YEAR" ? "tuổi" : "ngày tuổi"}`;
  if ((value.FROM_TUOI || value.FROM_TUOI == 0) && !value.TO_TUOI) {
    gia_tri_tuoi = ` > ${value.FROM_TUOI} ${value?.DON_VI_TUOI === "YEAR" ? "tuổi" : "ngày tuổi"}`;
  } else if (!value.FROM_TUOI && (value.TO_TUOI || value.TO_TUOI == 0)) {
    gia_tri_tuoi = ` < ${value.TO_TUOI} ${value?.DON_VI_TUOI === "YEAR" ? "tuổi" : "ngày tuổi"}`;
  } else if (!value?.FROM_TUOI && !value?.TO_TUOI) {
    gia_tri_tuoi = null
  }
  if (gia_tri_tuoi  && showTuoiKhoang) details.push(gia_tri_tuoi)
  // if (gia_tri_tuoi) details.push(gia_tri_tuoi)


  let gia_tri_khoang = ` ${value.CAN_DUOI} - ${value.CAN_TREN}`;

  if (value.CAN_DUOI && !value.CAN_TREN) {
    gia_tri_khoang = `  > ${value.CAN_DUOI}`;
  } else if (!value.CAN_DUOI && value.CAN_TREN) {
    gia_tri_khoang = `  < ${value.CAN_TREN}`;
  } else if (!value?.CAN_TREN && value?.CAN_DUOI == 0) {
    gia_tri_khoang = "0 - 0"
  } else if (!value?.CAN_TREN && !value?.CAN_DUOI) {
    gia_tri_khoang = null
  }

  if (gia_tri_khoang) details.push(gia_tri_khoang)
  
    return details

}


export const converGiaTriThamChieuV2 = (subItem, benh_pham, partner_code, isPdf) => {
  // let isShowAll = subItem?.SHOW_REF_RANGE == 1 
  let isShowAll =  subItem?.SHOW_REF_RANGE == 1 
  let hiddenDuongTinh = false
  let showTuoiKhoang = true

  // if (partnerCodeNoShowAllDaiThamChieu.includes(partner_code)) {
  //   isShowAll = false
  // }
  
  if(partner_code == "isgisg123" ) {
    showTuoiKhoang = !isPdf
    if(subItem.MA_DICHVU == "VSMD_HCVAb_ÐT" || subItem.MA_DICHVU == "VSMD_HBsAg_ÐT" ) {
      hiddenDuongTinh = true
    }
  }

  let DO_TUOI = null;
  let ngaySinh

  if (benh_pham?.NGAY_SINH?.length === 4) {
    ngaySinh = moment(benh_pham?.NGAY_SINH).format("YYYY");
    DO_TUOI = moment().diff(ngaySinh, "years");
  }

  if (benh_pham?.NGAY_SINH?.length === 8) {
    ngaySinh = moment(benh_pham?.NGAY_SINH).format("YYYYMMDD");
    DO_TUOI = moment().diff(ngaySinh, "years");
  }
  if (subItem?.GIA_TRI && (subItem.KIEU_CHI_SO === "SO_THAP_PHAN" || subItem.KIEU_CHI_SO === "SO_NGUYEN")) {

    const filteredGIA_TRI = subItem.GIA_TRI.filter(value => {
      if (!isShowAll) {
        if (value.GIOI_TINH?.toLocaleLowerCase() === benh_pham?.GIOI_TINH?.toLocaleLowerCase() || value.GIOI_TINH?.toLocaleLowerCase() === "tat_ca") {
          if (DO_TUOI <= 0) {
            const SO_NGAY_TUOI = moment().diff(ngaySinh, "days");
            return (((value.FROM_TUOI || 0) <= SO_NGAY_TUOI) && (SO_NGAY_TUOI <= (value?.TO_TUOI || 999)) && value?.DON_VI_TUOI === "DAY")
          } else {
            return (((value.FROM_TUOI || 0) <= DO_TUOI) && (DO_TUOI <= (value?.TO_TUOI || 999)) && value?.DON_VI_TUOI === "YEAR")
          }
        }
      } else {
        return value
      }
    });

    const result = [];

    const groupByGiaTri = Object.groupBy(filteredGIA_TRI, ({ GIOI_TINH }) => GIOI_TINH);
    const transformedArray = Object.entries(groupByGiaTri).map(([key, value]) => ({
      GIOI_TINH: key,
      GIA_TRI: value
    }));

    transformedArray.forEach(item => {
        item.GIA_TRI.forEach(value => {
          let details = handleRenderThamChieu(item, value, partner_code, isPdf)
          if (details.length > 0) {
            result.push(`&nbsp;  ${details.join(', ')}`);
          }
        });
    });
    return result.join("<br />");

  }

  if (subItem?.GIA_TRI && (subItem.KIEU_CHI_SO === "MOT_LUA_CHON" || subItem.KIEU_CHI_SO === "NHIEU_LUA_CHON")) {
    const filteredGIA_TRI = subItem.GIA_TRI.filter(value => {
      if (!isShowAll) {
        if (value.GIOI_TINH?.toLocaleLowerCase() === benh_pham?.GIOI_TINH?.toLocaleLowerCase() || value.GIOI_TINH?.toLocaleLowerCase() === "tat_ca") {
          if (DO_TUOI <= 0) {
            const SO_NGAY_TUOI = moment().diff(ngaySinh, "days");
            return (((value.FROM_TUOI || 0) <= SO_NGAY_TUOI) && (SO_NGAY_TUOI <= (value?.TO_TUOI || 999)) && value?.DON_VI_TUOI === "DAY")
          } else {
            return (((value.FROM_TUOI || 0) <= DO_TUOI) && (DO_TUOI <= (value?.TO_TUOI || 999)) && value?.DON_VI_TUOI === "YEAR")
          }
        }
      } else {
        return value
      }
    });


    const groupByGiaTri = Object.groupBy(filteredGIA_TRI, ({ TEN_LUA_CHON }) => TEN_LUA_CHON);
    const transformedArray = Object.entries(groupByGiaTri).map(([key, value]) => ({
      TEN_LUA_CHON: key,
      GIA_TRI: value.filter(obj => {
        if(hiddenDuongTinh &&  locdau(obj?.TEN_LUA_CHON || "")?.includes("duong tinh")) {
        } else {
          return obj 
        }
      })
    }));

    const result = [];
    transformedArray.forEach(item => {
      let pushTenLuaChonn = true;
      let GIA_TRI_length = item.GIA_TRI?.length;
      item.GIA_TRI.forEach(value => {
        const details = getDetails(value, showTuoiKhoang);

        if (pushTenLuaChonn && GIA_TRI_length !== 1) {
          pushTenLuaChonn = false;
          result.push(` ${item.TEN_LUA_CHON}`);
        }
        if (details.length > 0) {
          if (GIA_TRI_length === 1) {
            result.push(`${item.TEN_LUA_CHON} &nbsp;  ${details.join(', ')}`);
          } else {
            result.push(`&nbsp;  ${details.join(', ')}`);
          }
        }
      });
    });

    if (result?.length === 0) {
      result.push(subItem?.KQ_MAC_DINH);
    }
    return result.join("<br />");
  }
};


const getDetails = (value, showTuoiKhoang) => {
  const details = [];
  if (value.GIOI_TINH && getGender2(value.GIOI_TINH)) {
    details.push(getGender2(value.GIOI_TINH));
  }

  let gia_tri_tuoi = `${value.FROM_TUOI} - ${value.TO_TUOI} ${value?.DON_VI_TUOI === "YEAR" ? "tuổi" : "ngày tuổi"}`;
  if ((value.FROM_TUOI || value.FROM_TUOI === 0) && !value.TO_TUOI) {
    gia_tri_tuoi = `> ${value.FROM_TUOI} ${value?.DON_VI_TUOI === "YEAR" ? "tuổi" : "ngày tuổi"}`;
  } else if (!value.FROM_TUOI && (value.TO_TUOI || value.TO_TUOI === 0)) {
    gia_tri_tuoi = `< ${value.TO_TUOI} ${value?.DON_VI_TUOI === "YEAR" ? "tuổi" : "ngày tuổi"}`;
  } else if (!value?.FROM_TUOI && !value?.TO_TUOI) {
    gia_tri_tuoi = null;
  }

  let gia_tri_khoang = `${value.CAN_DUOI} - ${value.CAN_TREN}`;
  if (value.CAN_DUOI && !value.CAN_TREN) {
    gia_tri_khoang = `> ${value.CAN_DUOI}`;
  } else if (!value.CAN_DUOI && value.CAN_TREN) {
    gia_tri_khoang = `< ${value.CAN_TREN}`;
  } else if (!value?.CAN_TREN && value?.CAN_DUOI === 0) {
    gia_tri_khoang = "0 - 0";
  } else if (!value?.CAN_TREN && !value?.CAN_DUOI) {
    gia_tri_khoang = null;
  }

  if (gia_tri_tuoi && showTuoiKhoang) {
    details.push(gia_tri_tuoi);
  }
  if (gia_tri_khoang) {
    details.push(gia_tri_khoang);
  }

  return details;
}


export const getResult = (value, record, benh_pham, isHtml) => {
  let message = "";
  let valueInput = "";
  try {
    valueInput = JSON.parse(value).join(", ");
  } catch (error) {
    valueInput = value;
  }
  // if (isNaN(Number(valueInput))) {
    if (isNaN(Number(valueInput)) && !/[<>]/.test(valueInput)) {
    switch (valueInput) {
      // case "KQ_TRA_SAU":
      //   message = i18n.t(languageKeys.kq_tra_sau);
      //   break;

      default:
        message = valueInput;
        break;
    }
  } else {
    message = converKetQuaXnMayV2({ ...record, KET_QUA_XN_MAY: valueInput }, benh_pham, isHtml);
  }

  return message;
};



export const converGiaTriNguyCoV2 = (subItem, benh_pham,partner_code) => {
  let  isShowAll = true 
  if (partner_code == "isgisg123") {
     isShowAll = false
  }

  let DO_TUOI = null;
  let ngaySinh

  if (benh_pham?.NGAY_SINH?.length === 4) {
    ngaySinh = moment(benh_pham?.NGAY_SINH).format("YYYY");
    DO_TUOI = moment().diff(ngaySinh, "years");
  }

  if (benh_pham?.NGAY_SINH?.length === 8) {
    ngaySinh = moment(benh_pham?.NGAY_SINH).format("YYYYMMDD");
    DO_TUOI = moment().diff(ngaySinh, "years");
  }

  if ( subItem?.GIA_TRI_NGUY_CO && (subItem.KIEU_CHI_SO === "SO_THAP_PHAN" || subItem.KIEU_CHI_SO === "SO_NGUYEN")) {

    const result = [];

    const groupByGiaTri = Object.groupBy(subItem?.GIA_TRI_NGUY_CO, ({ GIOI_TINH }) => GIOI_TINH);
    const transformedArray = Object.entries(groupByGiaTri).map(([key, value]) => ({
      GIOI_TINH: key,
      GIA_TRI_NGUY_CO: value
    }));

   transformedArray.forEach(item => {
    if (!isShowAll) {
      item.GIA_TRI.forEach(value => {
        if (value.GIOI_TINH || value.FROM_TUOI || value.TO_TUOI || value.CAN_DUOI || value.CAN_TREN) {
          console.log("value.GIOI_TINH?.toLocaleLowerCase() === tat_ca",value.GIOI_TINH?.toLocaleLowerCase() === "tat_ca")
          if (value.GIOI_TINH?.toLocaleLowerCase() === benh_pham?.GIOI_TINH?.toLocaleLowerCase() || value.GIOI_TINH?.toLocaleLowerCase() === "tat_ca") {
            if (DO_TUOI <= 0) {
              const SO_NGAY_TUOI = moment().diff(ngaySinh, "days");
              if ((value.FROM_TUOI <= SO_NGAY_TUOI) && (SO_NGAY_TUOI <= (value?.TO_TUOI || 999)) && value?.DON_VI_TUOI === "DAY") {
                let details = handleRenderThamChieu(item, value, partner_code)
                if (details.length > 0) {
                  result.push(`&nbsp;  ${details.join(', ')}`);
                }
                
              }
            } else {
              if (((value.FROM_TUOI || 0) <= DO_TUOI) && (DO_TUOI <= ((value?.TO_TUOI || 0) || 999)) && value?.DON_VI_TUOI === "YEAR") {

                let details = handleRenderThamChieu(item, value, partner_code)
                if (details.length > 0) {
                  result.push(`&nbsp;  ${details.join(', ')}`);
                }
              }
            }
          }
        }
      });
    } 
    if (isShowAll) {
     item.GIA_TRI_NGUY_CO.forEach(value => {
       if (value.GIOI_TINH || value.FROM_TUOI || value.TO_TUOI || value.CAN_DUOI || value.CAN_TREN) {
        
        let details = handleRenderThamChieu(item,value, partner_code)
         if (details.length > 0) {
           result.push(`&nbsp;  ${details.join(', ')}`);
         }
       }
     });
   }
  });
  return result.join("<br />");

  }
};


export const getStyles = (data, record, infoBenhNhan, type, color) => {
  if (record?.HIEN_THI_THONG_SO_MAY) {

    switch (record.PHAN_LOAI_KET_QUA) {
      case "NGUY_CO":
        if (type == "fontWeight") {
          return "bold";
        }
        if (type == "textDecoration") {
          return "underline";
        }
        if (type == "size") {
          return 15;
        }

        if (color == "colorless") {
          return "hsl(216 14% 14%)"
        }

        return  "rgb(231 4 240)"

      case "BAT_THUONG":
        if (type == "fontWeight") {
          return "bold";
        }
        if (type == "textDecoration") {
          return "underline";
        }
        if (type == "size") {
          return 15;
        }
        if (color == "colorless") {
          return "hsl(216 14% 14%)"
        }
        return  "#F34946"

      default:

        break;
    }

  } else {
    if(_.isArray(data) && data?.length == 0  ||  data == '[]') {
      return
    }

    let DO_TUOI = null;
      let ngaySinh
  
      if (infoBenhNhan?.NGAY_SINH?.length === 4) {
        ngaySinh = moment(infoBenhNhan?.NGAY_SINH).format("YYYY");
        DO_TUOI = moment().diff(ngaySinh, "years");
      }
  
      if (infoBenhNhan?.NGAY_SINH?.length === 8) {
        ngaySinh = moment(infoBenhNhan?.NGAY_SINH).format("YYYYMMDD");
        DO_TUOI = moment().diff(ngaySinh, "years");
      }
  
    if (record.KIEU_CHI_SO === "SO_THAP_PHAN" || record.KIEU_CHI_SO === "SO_NGUYEN") {
      let arrayValue = [];
      const gia_tri = record?.GIA_TRI?.filter((obj) => {
        let condition =(obj.GIOI_TINH?.toLocaleLowerCase() === infoBenhNhan?.GIOI_TINH?.toLocaleLowerCase()  || obj.GIOI_TINH?.toLocaleLowerCase()  === "tat_ca" )
        if (DO_TUOI <= 0) {
          const SO_NGAY_TUOI = moment().diff(ngaySinh, "days");
          if (
            condition &&
            obj.FROM_TUOI <= SO_NGAY_TUOI && SO_NGAY_TUOI <= (obj?.TO_TUOI || 999) &&
            obj?.DON_VI_TUOI === "DAY"
          ) {
            return obj;
          }
        } else {
          if (
            condition &&
            (obj?.FROM_TUOI || 0) <= DO_TUOI && DO_TUOI <= (obj?.TO_TUOI || 999) &&
            obj?.DON_VI_TUOI === "YEAR"
          ) {
            return obj;
          }
        }
      });

      if (gia_tri?.length > 0) {
        arrayValue[0] = gia_tri[0];
        if (gia_tri[0]?.CAN_TREN && (Number(data) > Number(gia_tri[0].CAN_TREN))) {
          if (type == "fontWeight") {
            return "bold";
          }
          if (type == "textDecoration") {
            return "underline";
          }
          if (type == "size") {
            return 15;
          }
          if (color == "colorless") {
            return "hsl(216 14% 14%)"
          }
          return "red";
        }
        if (gia_tri[0]?.CAN_DUOI && (Number(data) < Number(gia_tri[0].CAN_DUOI))) {
          if (type == "fontWeight") {
            return "bold";
          }
          if (type == "textDecoration") {
            return "underline";
          }
          if (type == "size") {
            return 15;
          }
          if (color == "colorless") {
            return "hsl(216 14% 14%)"
          }

          return "#2C3782";
        }
        return "200";
      }
    }

    if (record.KIEU_CHI_SO === "MOT_LUA_CHON" || record.KIEU_CHI_SO === "NHIEU_LUA_CHON") {
      let result = (data)
   
      let KQ = ""
      try {
        result = JSON.parse((data))
      } catch (error) {}

      if (_.isArray(result) && result?.length == 1 && regexBiggerThanSmaller.test(result[0])) {
        result = checkKetQua(result[0])
      }
      if (!_.isArray(result) && regexBiggerThanSmaller.test(result)) {
        result = checkKetQua(result)
      }

      if (Array.isArray(result)) {
        KQ = result.map(item => {
          return locdau(`${item || " "}` || "")?.trim()?.toLowerCase()
        });
      } else {
        KQ = locdau(`${result || " "}`)?.trim()?.toLowerCase();
      }

      if (result) {
        if(isNumber(result)){
          const gia_tri = record?.GIA_TRI?.filter((obj) => {
            let condition =(obj.GIOI_TINH?.toLocaleLowerCase() === infoBenhNhan?.GIOI_TINH?.toLocaleLowerCase()  || obj.GIOI_TINH?.toLocaleLowerCase()  === "tat_ca" )
            if (DO_TUOI <= 0) {
              const SO_NGAY_TUOI = moment().diff(ngaySinh, "days");
              if (
                condition &&
                obj.FROM_TUOI <= SO_NGAY_TUOI && SO_NGAY_TUOI <= (obj?.TO_TUOI || 999) &&
                obj?.DON_VI_TUOI === "DAY"
              ) {
                return obj;
              }
            } else {
              if (
                condition &&
                obj.FROM_TUOI <= DO_TUOI && DO_TUOI <= (obj?.TO_TUOI || 999) &&
                obj?.DON_VI_TUOI === "YEAR"
              ) {
                return obj;
              }
            }
          });
          const gia_tri_sau_loc = gia_tri?.filter((obj) => {
            return (Number(result) <= Number(obj.CAN_TREN || maxValue ) && Number(result) >= Number(obj.CAN_DUOI || - maxValue) )
          });

          if(gia_tri_sau_loc?.length == 0){
            return getStyle(type, color);
          }
          if ( gia_tri_sau_loc?.length > 0 && (Number(result) <= Number(gia_tri_sau_loc[0].CAN_TREN || maxValue) && Number(result) >= Number(gia_tri_sau_loc[0].CAN_DUOI || - maxValue) )) {
            // if(locdau(gia_tri_sau_loc[0].TEN_LUA_CHON || "").includes("duong tinh") || locdau(gia_tri_sau_loc[0].TEN_LUA_CHON || "").includes("nguy co")) {
              if(!(locdau(gia_tri_sau_loc[0].TEN_LUA_CHON || "") == "negative") && !(locdau(gia_tri_sau_loc[0].TEN_LUA_CHON || "") == "am tinh") && !(locdau(gia_tri_sau_loc[0].TEN_LUA_CHON || "") == "du")){
                return getStyle(type, color);
            }
          }
        
          return ""
        }else{
          if(KQ?.length == 2 || KQ?.length == 6 ){
            if ((!KQ?.includes("negative") &&  !KQ?.includes("am tinh") && ( KQ?.length == 2 && !KQ?.includes("du")) ) ) {
              return getStyle(type, color);
            }
          }else{
            if ((!KQ?.includes("negative") &&  !KQ?.includes("am tinh"))) {
              return getStyle(type, color);
            }
          }
          
          return ""
        }
        }
    }
    return "";
  }
};

const getStyle = (type, color) => {
  if (type == "fontWeight") {
    return "bold";
  }
  if (type == "textDecoration") {
    return "underline";
  }
  if (type == "size") {
    return 15;
  }
  if (color == "colorless") {
    return "hsl(216 14% 14%)"
  }
  return "red";
}




export const getResultColor2 = (data, record, infoBenhNhan) => {
  let DO_TUOI = null;
  let ngaySinh

  if (infoBenhNhan?.NGAY_SINH?.length === 4) {
    ngaySinh = moment(infoBenhNhan?.NGAY_SINH).format("YYYY");
    DO_TUOI = moment().diff(ngaySinh, "years");
  }

  if (infoBenhNhan?.NGAY_SINH?.length === 8) {
    ngaySinh = moment(infoBenhNhan?.NGAY_SINH).format("YYYYMMDD");
    DO_TUOI = moment().diff(ngaySinh, "years");
  }

if (record.KIEU_CHI_SO === 'SO_THAP_PHAN' || record.KIEU_CHI_SO === 'SO_NGUYEN') {
  let arrayValue = []
  const gia_tri = record?.GIA_TRI?.filter((obj)=>{
    let condition =(obj.GIOI_TINH?.toLocaleLowerCase() === infoBenhNhan?.GIOI_TINH?.toLocaleLowerCase()  || obj.GIOI_TINH?.toLocaleLowerCase()  === "tat_ca" )
    if (DO_TUOI <= 0) {
      const SO_NGAY_TUOI = moment().diff(ngaySinh, "days");
      if (
        condition &&
        (obj.FROM_TUOI || 0) <= SO_NGAY_TUOI && SO_NGAY_TUOI <= (obj?.TO_TUOI || 999) &&
        obj?.DON_VI_TUOI === "DAY"
      ) {
        return obj;
      }
    } else {
      if (
        condition &&
        (obj?.FROM_TUOI || 0) <= DO_TUOI && DO_TUOI <= (obj?.TO_TUOI || 999) &&
        obj?.DON_VI_TUOI === "YEAR"
      ) {
        return obj;
      }
    }

  })
  if (gia_tri?.length > 0 ) {

    let dataInNumber = Number(data);

    if (regexBiggerThanSmaller.test(data)) {
      dataInNumber = checkKetQua(data)
    }

    arrayValue[0]=gia_tri[0]
    if (gia_tri[0]?.CAN_TREN && (Number(dataInNumber) > Number(gia_tri[0]?.CAN_TREN))) {
      return "#F34946"
    } 
    if (gia_tri[0]?.CAN_DUOI && (Number(dataInNumber) < Number(gia_tri[0]?.CAN_DUOI))) {
      return "#F6A447";
    } 
    return "hsl(216 14% 14%)";
  }
}
if (record.KIEU_CHI_SO === 'MOT_LUA_CHON' || record.KIEU_CHI_SO === 'NHIEU_LUA_CHON') {
  let result = (data)
   
      let KQ = ""
      try {
        result = JSON.parse((data))
      } catch (error) {}

      if (_.isArray(result) && result?.length == 1 && regexBiggerThanSmaller.test(result[0])) {
        result = checkKetQua(result[0])
      }
      if (!_.isArray(result) && regexBiggerThanSmaller.test(result)) {
        result = checkKetQua(result)
      }

      if (Array.isArray(result)) {
        KQ = result.map(item => {
          return locdau(`${item || " "}` || "")?.trim()?.toLowerCase()
        });
      } else {
        KQ = locdau(`${result || " "}`)?.trim()?.toLowerCase();
      }
      if (result) {
        if(isNumber(result)){
          const gia_tri = record?.GIA_TRI?.filter((obj) => {
            let condition =(obj.GIOI_TINH?.toLocaleLowerCase() === infoBenhNhan?.GIOI_TINH?.toLocaleLowerCase()  || obj.GIOI_TINH?.toLocaleLowerCase()  === "tat_ca" )
            if (DO_TUOI <= 0) {
              const SO_NGAY_TUOI = moment().diff(ngaySinh, "days");
              if (
                condition &&
                obj.FROM_TUOI <= SO_NGAY_TUOI && SO_NGAY_TUOI <= (obj?.TO_TUOI || 999) &&
                obj?.DON_VI_TUOI === "DAY"
              ) {
                return obj;
              }
            } else {
              if (
                condition &&
                obj.FROM_TUOI <= DO_TUOI && DO_TUOI <= (obj?.TO_TUOI || 999) &&
                obj?.DON_VI_TUOI === "YEAR"
              ) {
                return obj;
              }
            }
          });
          const gia_tri_sau_loc = gia_tri?.filter((obj) => {
            return (Number(result) <= Number(obj.CAN_TREN || maxValue) && Number(result) >= Number(obj.CAN_DUOI || - maxValue) )
          });

          if ( gia_tri_sau_loc?.length > 0 && (Number(result) <= Number(gia_tri_sau_loc[0].CAN_TREN || maxValue) && Number(result) >= Number(gia_tri_sau_loc[0].CAN_DUOI || - maxValue) )) {
            // if(locdau(gia_tri_sau_loc[0].TEN_LUA_CHON || "").includes("duong tinh") || locdau(gia_tri_sau_loc[0].TEN_LUA_CHON || "").includes("nguy co")) {
              if(!(locdau(gia_tri_sau_loc[0].TEN_LUA_CHON || "") == "negative") && !(locdau(gia_tri_sau_loc[0].TEN_LUA_CHON || "") == "am tinh") && !(locdau(gia_tri_sau_loc[0].TEN_LUA_CHON || "") == "du")){
                return getStyle("");
            }
          }
        
          return ""
        }else{
          if(KQ?.length == 2 || KQ?.length == 6 ){
            if ((!KQ?.includes("negative") &&  !KQ?.includes("am tinh") && ( KQ?.length == 2 && !KQ?.includes("du")) ) ) {
              return getStyle("");
            }
          }else{
            if ((!KQ?.includes("negative") &&  !KQ?.includes("am tinh"))) {
              return getStyle("");
            }
          }
          return ""
        }
        }

}
return ""

}

export const getResultColor2KqMay = (data, record,infoBenhNhan) => {
  let color = "hsl(216 14% 14%)"
  if (record?.HIEN_THI_THONG_SO_MAY) {
    switch (record.PHAN_LOAI_KET_QUA) {
      case "NGUY_CO":
        color = "rgb(231 4 240)"
        break;

      case "BAT_THUONG":
        color = "#F34946"
        break;

      default:
        break;
    }

  }

  return color
};




 export const converKetQuaXnMay = (record = {}, benh_pham) => {
  if (
    (record.KIEU_CHI_SO === "MOT_LUA_CHON" || record.KIEU_CHI_SO === "NHIEU_LUA_CHON") &&
    _.isNumber(record.KET_QUA_XN_MAY)
  ) {

    let DO_TUOI = null;
    let ngaySinh

    if (benh_pham?.NGAY_SINH?.length === 4) {
      ngaySinh = moment(benh_pham?.NGAY_SINH).format("YYYY");
      DO_TUOI = moment().diff(ngaySinh, "years");
    }

    if (benh_pham?.NGAY_SINH?.length === 8) {
      ngaySinh = moment(benh_pham?.NGAY_SINH).format("YYYYMMDD");
      DO_TUOI = moment().diff(ngaySinh, "years");
    }

    const gia_tri = record?.GIA_TRI?.find((obj) => {
      const condition = (
        obj.CAN_DUOI <= record.KET_QUA_XN_MAY && record.KET_QUA_XN_MAY <= (obj?.CAN_TREN || maxValue)
      )
      if (DO_TUOI <= 0) {
        const SO_NGAY_TUOI = moment().diff(ngaySinh, "days");
        if (
          condition &&
          obj.FROM_TUOI <= SO_NGAY_TUOI && SO_NGAY_TUOI <= (obj?.TO_TUOI || 999) &&
          obj?.DON_VI_TUOI === "DAY"
        ) {
          return obj;
        }
      } else {
        if (
          condition &&
          obj.FROM_TUOI <= DO_TUOI && DO_TUOI <= (obj?.TO_TUOI || 999) &&
          obj?.DON_VI_TUOI === "YEAR"
        ) {
          return obj;
        }
      }
    });
    return gia_tri?.TEN_LUA_CHON || record.KET_QUA_XN_MAY

  } else {
    return record.KET_QUA_XN_MAY
  }
};

function isNumber(value) {
  return value !== null && !isNaN(value);
}

export const matchGiaTriThamChieu = (subItem, benh_pham, data) => {
  if(data == 0) {
    return "0"
  }
  let giaTri = data
  let giaTriCheck = data
  if(!_.isArray(data) &&  !isNumber(data) && !regexBiggerThanSmaller.test(data)) {
   return data
  }


  if(_.isArray(data) && data?.length == 1 ) {
    giaTri = data[0]
    giaTriCheck = data[0]
  }
  if(!giaTri || (_.isArray(data) && data?.length == 0 )) {
    return 
  }

  if ( _.isArray(data) && data?.length == 1 &&  regexBiggerThanSmaller.test(data[0])) {
    giaTriCheck = checkKetQua(data[0])
  }
  if ( !_.isArray(data)  &&  regexBiggerThanSmaller.test(data)) {
    giaTriCheck = checkKetQua(data)
  }

  let DO_TUOI = null;
  let ngaySinh

  if (benh_pham?.NGAY_SINH?.length === 4) {
    ngaySinh = moment(benh_pham?.NGAY_SINH).format("YYYY");
    DO_TUOI = moment().diff(ngaySinh, "years");
  }

  if (benh_pham?.NGAY_SINH?.length === 8) {
    ngaySinh = moment(benh_pham?.NGAY_SINH).format("YYYYMMDD");
    DO_TUOI = moment().diff(ngaySinh, "years");
  }

  if (subItem.KIEU_CHI_SO === "MOT_LUA_CHON" || subItem.KIEU_CHI_SO === "NHIEU_LUA_CHON") {

    const gia_tri = subItem?.GIA_TRI?.filter((obj) => {
      if (obj.GIOI_TINH?.toLocaleLowerCase() === benh_pham?.GIOI_TINH?.toLocaleLowerCase() || obj.GIOI_TINH?.toLocaleLowerCase() === "tat_ca") {
        if (DO_TUOI <= 0) {
          const SO_NGAY_TUOI = moment().diff(ngaySinh, "days");
          if (((obj.FROM_TUOI || 0) <= SO_NGAY_TUOI) && (SO_NGAY_TUOI <= (obj?.TO_TUOI || 999)) && obj?.DON_VI_TUOI === "DAY") {
            return obj;
          }
        } else {
          if (((obj.FROM_TUOI || 0) <= DO_TUOI) && (DO_TUOI <= (obj?.TO_TUOI || 999)) && obj?.DON_VI_TUOI === "YEAR") {
            return obj;
          }
        }
      }
    });


    if (gia_tri?.length > 0) {
      const gia_tri_sau_loc = gia_tri.filter((obj) => {
        const hasCanTren = obj.hasOwnProperty('CAN_TREN');
        const hasCanDuoi = obj.hasOwnProperty('CAN_DUOI');

        if (obj?.CAN_DUOI !== null && obj?.CAN_DUOI !== undefined && obj?.CAN_TREN !== null && obj?.CAN_TREN !== undefined) {
          return (obj?.CAN_DUOI || 0) <= Number(giaTriCheck) && Number(giaTriCheck) <= (obj?.CAN_TREN || maxValue);
        } else if (obj?.CAN_DUOI !== null && obj?.CAN_DUOI !== undefined) {
          return (obj?.CAN_DUOI || 0) <= Number(giaTriCheck);
        } else if (obj?.CAN_TREN !== null && obj?.CAN_TREN !== undefined) {
          return  Number(giaTriCheck) <= (obj?.CAN_TREN || maxValue);
        }
        
        return false; 
      });

      if(gia_tri_sau_loc?.length > 0) {
        return `${giaTri} ${gia_tri_sau_loc?.[0]?.TEN_LUA_CHON || 0}`
      } else  {
        return ""
      }
    }
  
    return subItem?.KQ_MAC_DINH;
  }
  return giaTri
};

export const checkKetQua = (value) => {
  if (value.includes('>')) {
    return Number(value.replace('>', '').trim()) + 0.0000001;
  } else if (value.includes('<')) {
    return Number(value.replace('<', '').trim()) - 0.0000001;
  } else {
    return Number(value);
  }
}

export const converKetQuaXnMayV2 = (record = {}, benh_pham, isHtml) => {
  let data =  record.KET_QUA_XN_MAY
  let giaTri = data
  let giaTriCheck = data
  if(_.isArray(data) && data?.length == 1 ) {
    giaTri = data[0]
    giaTriCheck = data[0]
  }
  if(!giaTri || (_.isArray(data) && data?.length == 0 )) {
    return 
  }

  if ( _.isArray(data) && data?.length == 1 &&  regexBiggerThanSmaller.test(data[0])) {
    giaTriCheck = checkKetQua(data[0])
  }
  if ( !_.isArray(data)  &&  regexBiggerThanSmaller.test(data)) {
    giaTriCheck = checkKetQua(data)
  }

  if (
    (record.KIEU_CHI_SO === "MOT_LUA_CHON" || record.KIEU_CHI_SO === "NHIEU_LUA_CHON") &&
    isNumber(giaTriCheck)
  ) {

    let DO_TUOI = null;
    let ngaySinh

    if (benh_pham?.NGAY_SINH?.length === 4) {
      ngaySinh = moment(benh_pham?.NGAY_SINH).format("YYYY");
      DO_TUOI = moment().diff(ngaySinh, "years");
    }

    if (benh_pham?.NGAY_SINH?.length === 8) {
      ngaySinh = moment(benh_pham?.NGAY_SINH).format("YYYYMMDD");
      DO_TUOI = moment().diff(ngaySinh, "years");
    }

    const gia_tri = record?.GIA_TRI?.find((obj) => {
      const condition = (
        obj.CAN_DUOI <= giaTriCheck && giaTriCheck <= (obj?.CAN_TREN || maxValue)
      )

      if (obj.GIOI_TINH?.toLocaleLowerCase() === benh_pham?.GIOI_TINH?.toLocaleLowerCase() || obj.GIOI_TINH?.toLocaleLowerCase() === "tat_ca") {

        if (DO_TUOI <= 0) {
          const SO_NGAY_TUOI = moment().diff(ngaySinh, "days");
          if (
            condition &&
            obj.FROM_TUOI <= SO_NGAY_TUOI && SO_NGAY_TUOI <= (obj?.TO_TUOI || 999) &&
            obj?.DON_VI_TUOI === "DAY"
          ) {
            return obj;
          }
        } else {
          if (
            condition &&
            obj.FROM_TUOI <= DO_TUOI && DO_TUOI <= (obj?.TO_TUOI || 999) &&
            obj?.DON_VI_TUOI === "YEAR"
          ) {
            return obj;
          }
        }
      }
    });

    if(isHtml){
      return `<p style="
    text-align: center;
    padding: 0;
    margin: 0;">${record?.KET_QUA_XN_MAY} <br/> ${gia_tri?.TEN_LUA_CHON || ""} </p>`
    }
    return  `${record?.KET_QUA_XN_MAY} ${gia_tri?.TEN_LUA_CHON || ""}` || record.KET_QUA_XN_MAY

  } else {

    return record.KET_QUA_XN_MAY
  }
};


export const converGiaTriNguyCo = (subItem, benh_pham) => {

  let arr_gia_tri_nguy_co = []

  if (!subItem?.GIA_TRI_NGUY_CO || subItem?.GIA_TRI_NGUY_CO?.length == 0) {
    return []
  }

  if (subItem.KIEU_CHI_SO === "SO_THAP_PHAN" || subItem.KIEU_CHI_SO === "SO_NGUYEN") {

    let DO_TUOI = null;
    let ngaySinh

    if (benh_pham?.NGAY_SINH?.length === 4) {
      ngaySinh = moment(benh_pham?.NGAY_SINH).format("YYYY");
      DO_TUOI = moment().diff(ngaySinh, "years");
    }

    if (benh_pham?.NGAY_SINH?.length === 8) {
      ngaySinh = moment(benh_pham?.NGAY_SINH).format("YYYYMMDD");
      DO_TUOI = moment().diff(ngaySinh, "years");
    }

    const gia_tri = subItem?.GIA_TRI_NGUY_CO?.filter((obj) => {
      if (obj.GIOI_TINH?.toLocaleLowerCase() === benh_pham?.GIOI_TINH?.toLocaleLowerCase() || obj.GIOI_TINH?.toLocaleLowerCase() === "tat_ca") {
        if (DO_TUOI <= 0) {
          const SO_NGAY_TUOI = moment().diff(ngaySinh, "days");
          if ((obj.FROM_TUOI <= SO_NGAY_TUOI) && (SO_NGAY_TUOI <= (obj?.TO_TUOI || 999)) && obj?.DON_VI_TUOI === "DAY") {
            return obj;
          }
        } else {
          if ((obj.FROM_TUOI <= DO_TUOI) && (DO_TUOI <= (obj?.TO_TUOI || 999)) && obj?.DON_VI_TUOI === "YEAR") {
            return obj;
          }
        }
      }
    });

    if (gia_tri?.length > 0) {
      arr_gia_tri_nguy_co = gia_tri.map(gt => {
        if (gt.CAN_DUOI && gt.CAN_TREN) {
          return `${gt.CAN_DUOI} - ${gt.CAN_TREN}`
        }
        if (gia_tri[0].CAN_DUOI && !gia_tri[0].CAN_TREN) {
          return ` > ${gia_tri[0].CAN_DUOI}`;
        } else if (!gia_tri[0].CAN_DUOI && gia_tri[0].CAN_TREN) {
          return ` < ${gia_tri[0].CAN_TREN}`;
        }
      })
      return arr_gia_tri_nguy_co;
    }
  }

  if (subItem.KIEU_CHI_SO === "MOT_LUA_CHON" || subItem.KIEU_CHI_SO === "NHIEU_LUA_CHON") {
    return subItem?.KQ_MAC_DINH;
  }
};

/**
 * @description Đọc giá tiền bằng chữ tiếng anh
 * @name readPrice
 * @param {*} price 
 * @returns 
 */
export function readPrice(price) {
  const digitWords = {
    '0': 'zero',
    '1': 'one',
    '2': 'two',
    '3': 'three',
    '4': 'four',
    '5': 'five',
    '6': 'six',
    '7': 'seven',
    '8': 'eight',
    '9': 'nine'
  };

  function convertLessThanHundred(number) {
    let result = '';
    if (number >= 20) {
      const tens = Math.floor(number / 10);
      result += (tens === 2 ? 'twenty' :
        tens === 3 ? 'thirty' :
          tens === 4 ? 'forty' :
            tens === 5 ? 'fifty' :
              tens === 8 ? 'eighty' :
                digitWords[tens.toString()] + 'ty');
      const ones = number % 10;
      if (ones !== 0) {
        result += '-' + digitWords[ones.toString()];
      }
    } else {
      result += digitWords[number.toString()];
    }
    return result;
  }

  function convertToWords(number) {
    if (number === 0) {
      return 'zero dong';
    }
    let words = '';
    const billions = Math.floor(number / 1000000000);
    const millions = Math.floor((number % 1000000000) / 1000000);
    let thousands = Math.floor((number % 1000000) / 1000);
    const remaining = number % 1000;

    if (billions > 0) {
      words += convertLessThanHundred(billions) + ' billion ';
    }
    if (millions > 0) {
      words += convertLessThanHundred(millions) + ' million ';
    }
    if (thousands > 0) {
      if (thousands >= 100) {
        words += convertLessThanHundred(Math.floor(thousands / 100)) + ' hundred ';
        thousands %= 100;
      }
      if (thousands >= 10 && thousands <= 19) {
        words += digitWords[thousands.toString()] + ' thousand ';
      } else {
        words += convertLessThanHundred(thousands) + ' thousand ';
      }
    }
    if (remaining > 0) {
      words += convertLessThanHundred(remaining);
    }
    words += ' dong';
    return words.trim();
  }

  return convertToWords(price);
}

export const capital_first_letter = (string) => {
  try {
    let rs = string.toLowerCase()?.trim?.();
    let first_letter = rs.slice(0, 1);
    first_letter = first_letter.toUpperCase();
    rs = first_letter + rs.slice(1, rs.length);
    return rs;
  } catch (error) {
    HLog(error)
    return string
  }
}

const templateEn = {
  "tỉnh": "Province",
  "thành phố": "City",
  "huyện": "District",
  "quận": "District",
  "phường": "Ward",
  "xã": "Commune",
  "thị trấn": "Town",
}

/**
 * 
 * @param {string} string 
 */
export const convertTHXEn = (string) => {
  try {
    if (i18n.language === languages.tieng_viet) return string;
    let cpString = string.toLowerCase();
    for (const key of Object.keys(templateEn)) {
      if (cpString.includes(key)) {
        cpString = cpString.replace(key, '');
        cpString = cpString + " " + templateEn[key];
        cpString = locdau(cpString).trim();
        break;
      }
    }
    return capital_first_letter(cpString);
  } catch (error) {
    return string;
  }
}

export const getPrivateImageLink = (id) => {
  return apis.layAnh + "?" + `ID=${id}`
}