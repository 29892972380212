import { createSlice } from "@reduxjs/toolkit";

const initState = {
  active: false,
  template: "",
  data: [],
};

const pdfSlice = createSlice({
  name: "pdf",
  initialState: initState,
  reducers: {
    doPrintPdf(state, action) {
      return { ...state, ...action.payload };
    },
    resetPdf() {
      return initState;
    },
  },
});

export const { doPrintPdf, resetPdf } = pdfSlice.actions;

export default pdfSlice.reducer;
