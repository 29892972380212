import { all, fork } from "redux-saga/effects";
import { watchDoSharedData } from "./SharedData.saga";
import { watchDoCityDistrictWard } from "./CityDistrictWard.saga";
import { watchDoExemption } from "./Exemption.saga";
import { watchDoSupplies } from "./Supply.saga";

export default function* rootListData() {
    yield all([
        fork(watchDoSharedData),
        fork(watchDoCityDistrictWard),
        fork(watchDoExemption),
        fork(watchDoSupplies)
    ]);
}