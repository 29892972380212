import { Switch, Route, Redirect } from "react-router-dom";
import { actionUrls, apis, keys, paths } from "../constants";
import { PrivateRoute, PublicRoute } from "./route";
import { LoginSuccess, NotFound, SoftwareRoute, TvMonitor } from "./../pages";
import { CisRoute } from "./CisRoute";
import { AuthRoute } from "./AuthRoute";
import { useAuth, useConfigAxios } from "../hooks";
import WarningModal from "./../components/Modal/WarningModal";
import { useRef, useState } from "react";
import i18n, { languageKeys } from "../i18n";
import { Button, Spin } from "antd";
import { RISRoute } from "./RisRoute";
import { DuocRoute } from "./DuocRoute";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateVersionWeb } from "ducks/slices/authSlice";
import { isEmptyObject } from "helpers";
import ForgotPass from "pages/QuanlyTaiKhoan/ForgotPass";
import { useKeycloak } from "@react-keycloak/web";
import CisAccountManagment from "pages/QuanlyTaiKhoan/CisAccountManagment";
import jwtDecode from "jwt-decode";
import Constants from "constants/Constants";
import AppQR from "pages/AppQR";

export const App = () => {
  const refWarning = useRef();
  const { logout } = useAuth();
  useConfigAxios(refWarning);
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const { user, tokens } = useSelector((state) => state.auth);
  const { keycloak } = useKeycloak();

  useEffect(() => {
    // handleGetVersion()
    if (!keycloak?.authenticated) layConfigBenhVien();
  }, [window.location, keycloak?.authenticated])


  useEffect(() => {
    if (keycloak?.authenticated && user) {
      overrideEnvConfiguration()
    }
  }, [keycloak?.authenticated, user]);

  useEffect(() => {
    // const queryLang = query.get(keys.lang);
    if (tokens?.token) handleLanguage(tokens?.token);
  }, [tokens?.token]);

  const handleLanguage = async (token) => {
    try {
      let localLang = i18n.language;
      const rs = await theDecoder(token);
      if (rs?.language && (rs.language !== localLang)) {
        i18n.changeLanguage(rs.language)
        await window.indexedDB.deleteDatabase(Constants.INDEX_DB_NAME);
        window.location.reload();
      }
    } catch (error) {
      console.log(error)
    }
  }

  const theDecoder = async (token) => {
    try {
      if (token)
        return await jwtDecode(token);
      else return undefined;
    } catch (error) {
      console.log(error)
    }
  }

  // const handleGetVersion = async () => {
  //   try {
  //     const res = await axios.post(window.GATEWAY + "cis-public/api/public/version/layVersion", {});
  //     const data = res.data;
  //     if (data && data.status === "OK") {
  //       dispatch(updateVersionWeb(data.version))
  //     }
  //   }catch(e) {

  //   }
  // }
  console.log(`%cDeepcare versions: 1.0.4`,'color:blue');

  const overrideEnvConfiguration = async () => {
    try {
      const { config } = user;
      if (config) {
        let { CUSTOM_DOMAIN } = config;
        overrideProcess(CUSTOM_DOMAIN);
      }
    } catch (error) { console.warn(error) }
  };

  const overrideProcess = (CUSTOM_DOMAIN) => {
    try {
      if (CUSTOM_DOMAIN) {
        const { LOGO_MIN, WEBSITE, GATEWAY } = CUSTOM_DOMAIN;
        if (LOGO_MIN) {
          document.getElementsByTagName("link")[0].href = (GATEWAY || window.GATEWAY) + `cis-public/public/file?ID=${CUSTOM_DOMAIN.LOGO_MIN}`;
        }

        // ghi đè biến môi trường trong trường hợp có config môi trường
        Object.keys(CUSTOM_DOMAIN).forEach((key) => {
          if (key in window) {
            window[key] = CUSTOM_DOMAIN[key];
          }
        });
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const layConfigBenhVien = async () => {
    try {
      let nameWeb = window.location.hostname;
      let res;
      setLoading(true);
      if (nameWeb) {
        res = await axios.post(apis.getDetailConfigWebsite, { website: nameWeb });

        if (res) {
          const data = res.data;
          if (data && data.status === "OK") {
            if (data.result && !isEmptyObject(data.result)) {
              if (data.result.CUSTOM_DOMAIN) {
                let { result } = data;
                localStorage.setItem(keys.configHospital, JSON.stringify(result));
                let { CUSTOM_DOMAIN } = result;
                // chỉnh sửa thông tin trong CIS-RIS-DUOC app khi có config môi trường
                overrideProcess(CUSTOM_DOMAIN);

              }
              // version
            }
            if (data.version) {
              dispatch(updateVersionWeb(data.version));
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let keyUrl = localStorage.getItem(keys.REDIRECT);

    const checkingStoreUrl = keyUrl === actionUrls.logout;

    if (checkingStoreUrl) {
      logout();
      localStorage.removeItem(keys.REDIRECT);
    }

  }, [window.location])

  if (user && user.MAT_KHAU_TAM_THOI === 1) {
    return (
      <ForgotPass
        visible={true}
        text={i18n.t(languageKeys.luu_y_mat_khau_mac_dinh)}
        hideEscape={true}
        doReload={true}
      />
    )
  }

  return (
    <Spin spinning={loading}>
      <Switch>
        <PrivateRoute path={paths.tivi} component={TvMonitor} />

        <Route exact path={paths.authenticated} component={LoginSuccess} />
        <Route exact path={paths.app} component={AppQR} />

        <PublicRoute path={paths.auth} component={AuthRoute} />

        <PrivateRoute exact path={paths.software_route} component={SoftwareRoute} />

        <PrivateRoute path={paths.cis} component={CisRoute} />
        <PrivateRoute path={paths.ris} component={RISRoute} />
        <PrivateRoute path={paths.duoc} component={DuocRoute} />

        <Redirect exact from={paths.main} to={paths.software_route} />

        <Route component={NotFound} />
      </Switch>

      <WarningModal
        ref={refWarning}
        title={i18n.t(languageKeys.warning_url_notfound_title)}
        content={i18n.t(languageKeys.noti_phien_dang_nhap_da_het_han)}
        maskClosable={false}
        children={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Button onClick={logout} type="primary">
              {i18n.t(languageKeys.common_ok)}
            </Button>
          </div>
        }
      />

      <CisAccountManagment />
    </Spin>
  );
};
