export const ds_chuyen_khoa = [
    {
        "TEN": "Nội tiêu hóa",
        "MA_CHUYEN_KHOA": "DCCK03"
    },
    {
        "TEN": "Nội cơ - xương - khớp",
        "MA_CHUYEN_KHOA": "DCCK10"
    },
    {
        "TEN": "Nội tim mạch",
        "MA_CHUYEN_KHOA": "DCCK11"
    },
    {
        "TEN": "Nội thận - tiết niệu",
        "MA_CHUYEN_KHOA": "DCCK31"
    },
    {
        "TEN": "Nội tổng hợp",
        "MA_CHUYEN_KHOA": "DCCK34"
    },
    {
        "TEN": "Nội hô hấp",
        "MA_CHUYEN_KHOA": "DCCK36"
    },
    {
        "TEN": "Nội tiết",
        "MA_CHUYEN_KHOA": "DCCK40"
    },
    {
        "TEN": "Ngoại tiêu hóa",
        "MA_CHUYEN_KHOA": "DCCK20"
    },
    {
        "TEN": "Ngoại tổng hợp",
        "MA_CHUYEN_KHOA": "DCCK28"
    },
    {
        "TEN": "Răng - Hàm - Mặt",
        "MA_CHUYEN_KHOA": "DCCK01"
    },
    {
        "TEN": "Tai - Mũi - Họng",
        "MA_CHUYEN_KHOA": "DCCK18"
    },
    {
        "TEN": "Nhi",
        "MA_CHUYEN_KHOA": "DCCK06"
    },
    {
        "TEN": "Phụ sản",
        "MA_CHUYEN_KHOA": "DCCK24"
    },
    {
        "TEN": "Vật lý trị liệu - Phục hồi chức năng",
        "MA_CHUYEN_KHOA": "DCCK15"
    },
    {
        "TEN": "Chấn thương chỉnh hình",
        "MA_CHUYEN_KHOA": "DCCK22"
    },
    {
        "TEN": "Ung bướu",
        "MA_CHUYEN_KHOA": "DCCK14"
    },
    {
        "TEN": "Da liễu",
        "MA_CHUYEN_KHOA": "DCCK16"
    },
    {
        "TEN": "Dị ứng",
        "MA_CHUYEN_KHOA": "DCCK21"
    },
    {
        "TEN": "Mắt",
        "MA_CHUYEN_KHOA": "DCCK23"
    },
    {
        "TEN": "Y học cổ truyền",
        "MA_CHUYEN_KHOA": "DCCK27"
    },
    {
        "TEN": "Thần kinh",
        "MA_CHUYEN_KHOA": "DCCK35"
    },
    {
        "TEN": "Truyền nhiễm",
        "MA_CHUYEN_KHOA": "DCCK37"
    },
    {
        "TEN": "Đa Khoa",
        "MA_CHUYEN_KHOA": "DCCK39"
    },
    {
        "TEN": "Khám bệnh",
        "MA_CHUYEN_KHOA": "DCCK42"
    },
    {
        "TEN": "Cấp cứu",
        "MA_CHUYEN_KHOA": "DCCK41"
    },
    {
        "TEN": "Hồi sức cấp cứu",
        "MA_CHUYEN_KHOA": "DCCK43"
    },
    {
        "TEN": "Phẫu thuật - Gây mê hồi sức",
        "MA_CHUYEN_KHOA": "DCCK46"
    },
    {
        "TEN": "Y học hạt nhân",
        "MA_CHUYEN_KHOA": "DCCK47"
    },
    {
        "TEN": "Truyền máu",
        "MA_CHUYEN_KHOA": "DCCK48"
    },
    {
        "TEN": "Lọc máu nhân tạo",
        "MA_CHUYEN_KHOA": "DCCK49"
    },
    {
        "TEN": "Huyết học lâm sàng",
        "MA_CHUYEN_KHOA": "DCCK17"
    },
    {
        "TEN": "Huyết học",
        "MA_CHUYEN_KHOA": "DCCK50"
    },
    {
        "TEN": "Sinh hóa",
        "MA_CHUYEN_KHOA": "DCCK51"
    },
    {
        "TEN": "Vi sinh",
        "MA_CHUYEN_KHOA": "DCCK52"
    },
    {
        "TEN": "Xét nghiệm",
        "MA_CHUYEN_KHOA": "DCCK61"
    },
    {
        "TEN": "Giải phẫu bệnh",
        "MA_CHUYEN_KHOA": "DCCK56"
    },
    {
        "TEN": "Chẩn đoán hình ảnh",
        "MA_CHUYEN_KHOA": "DCCK53"
    },
    {
        "TEN": "Sinh học phân tử",
        "MA_CHUYEN_KHOA": "DCCK60"
    },
    {
        "TEN": "Thăm dò chức năng",
        "MA_CHUYEN_KHOA": "DCCK54"
    },
    {
        "TEN": "Nội soi",
        "MA_CHUYEN_KHOA": "DCCK55"
    },
    {
        "TEN": "Chống nhiễm khuẩn",
        "MA_CHUYEN_KHOA": "DCCK57"
    },
    {
        "TEN": "Dược",
        "MA_CHUYEN_KHOA": "DCCK58"
    },
    {
        "TEN": "Dinh dưỡng",
        "MA_CHUYEN_KHOA": "DCCK59"
    },
    {
        "TEN": "Hồi sức tích cực",
        "MA_CHUYEN_KHOA": "DCCK62"
    },
    {
        "TEN": "Chống độc",
        "MA_CHUYEN_KHOA": "DCCK63"
    },
    {
        "TEN": "Thẩm mỹ",
        "MA_CHUYEN_KHOA": "DCCK64"
    },
    {
        "TEN": "Tiêm chủng",
        "MA_CHUYEN_KHOA": "DCCK65"
    }
]