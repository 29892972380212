import { Input as AntdInput } from "antd";
import { useMemo } from "react";

import i18n, { languageKeys } from "../i18n";

let Input = ({ placeholder = "", style = {}, readOnly = false, ...props }) => {
  const formatPlaceholder = useMemo(() => {
    if (readOnly) return placeholder;
    else if (!placeholder) return i18n.t(languageKeys.common_Nhap);
    else return placeholder;
  }, [placeholder, readOnly]);

  return <AntdInput placeholder={formatPlaceholder} readOnly={readOnly} autoComplete="new-password" {...props} />;
};

const Password = ({ placeholder = i18n.t(languageKeys.common_Nhap), ...props }) => (
  <AntdInput.Password placeholder={placeholder} autoComplete="new-password" {...props} />
);

Input.Password = Password;

export { Input };
