import { nanoid } from "@reduxjs/toolkit";
import JsBarcode from "jsbarcode";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { getGioiTinhBN } from "pages/QuanLyTiepDon/columnsTiepDon";

export const styleBarcode4 = `
#barcode-xn * {
  font-size: 12px;
  line-height: 1.5em;
  font-family: "Arial", Arial, san-serif;
  margin:0;
  padding:0;

}

#barcode-xn .barcode-wrapper {
    display:flex;
    flex-wrap:wrap;
    flex-direction: row;
}

#barcode-xn .barcode-items {
    box-sizing:border-box;
    position:relative;
    display:flex;
    flex-start: end;
    flex-direction:column;
    flex: 1 0 25%;
    max-width:24.7%;
    height: 1.9in; 
    overflow: hidden;
    padding-top:4px;
    padding-left:1px;

  }

#barcode-xn .barcode-label {
  position:absolute;
  font-size: 7px;
  font-weight: 700;
  text-align:left;
  width: 1.4in;
  right:0;
  transform: rotate(-90deg);
  transform-origin: bottom right;
}

#barcode-xn .barcode-items-wrapper {
  padding-top:4px;
  width:0.93in;
  height: 1.82in;
  margin:auto;
  position:relative;
}

#barcode-xn .barcode-items::nth-child(4n) {
  page-break-before: always;
  break-inside: avoid;
}

#barcode-xn .barcode-svg {
    width: 100%;
    height: 100%;
    transform: rotate(-90deg) !important;
    transform-origin: center center;
}


#barcode-xn .abs-text {
  left:-38px;
  top:60px;
  width:100px;
  text-align:right;
  text-transform: uppercase;
  font-weight:bold;
}

#barcode-xn .rotate-text {
  transform: rotate(-90deg) !important;
}

#barcode-xn .main-txt {
  font-size:8px;
  position: absolute;
}

#barcode-xn .time-txt {
  left: 20px;
  top :12px;
  text-transform: uppercase;
  font-weight:bold;
}


@media print {
  * {
    margin:0;
    padding:0;
  }
  #barcode-xn .foot-break {
    page-break-after: always;
  }
  #barcode-xn{page-break-after: always}
}
`;

const PdfBarcode = ({ data }) => {
  const { dataSource } = data;

  console.log("dataSource----",dataSource)
  React.useEffect(() => {
    try {
      if (data?.sid) {
        let arrSubffix = _.uniq(dataSource.map(x => x.suffix));
        for (const sub of arrSubffix) {
          let sid = `${data.sid}${sub || ''}`
          JsBarcode(`#barcode-${sub}`)
          .options({ font: "OCR-B" })
          .CODE128(sid, {
            text: `${data?.sid}${sub || ''}`,
            fontSize: 30,
            width: 2.2,
            height: 90,
            textMargin: 0,
            margin: 0,
            padding: 0,
          })
          ?.render?.();
        }
  
      }
    } catch (error) {
      console.log(error);
    }
  }, [data]);

  const result = (() => {
    try {
      let rs = [];
      dataSource.forEach((x) => {
        rs = rs.concat(Array(x.SO_LUONG).fill({ ...x, key: nanoid() }));
      });
      return rs;
    } catch (error) {
      console.log(error);
      return [];
    }
  })();

  return (
    <div id="barcode-xn">
      <div className="barcode-wrapper">
        {result.map((i,ind) => {
          return (
            <div className={"barcode-items" + ` ${(ind + 1)%4===0 ? '.foot-break' : ''}`}>
              <div className="barcode-items-wrapper">
                <div className="main-txt time-txt">{moment().format("DD/MM/YYYY HH:mm")}</div>

                <div className="main-txt abs-text rotate-text">{i.TEN_LOAI_BP}</div>

                <svg id={`barcode-${i.suffix}`} className="barcode-svg"></svg>
              </div>
                <span
                  className="barcode-label"
                >
                  {/* {i.TEN_KHACH_HANG} ({i?.GIOI_TINH ? getGioiTinhBN(i?.GIOI_TINH) : ''}.{i?.NGAY_SINH ? i?.NGAY_SINH?.slice(-4) : ''}) */}
                  {i.TEN_KHACH_HANG} ({i?.GIOI_TINH ? getGioiTinhBN(i?.GIOI_TINH) : ''}.{i?.NGAY_SINH ? i?.NGAY_SINH?.length == 4 ? i?.NGAY_SINH: moment(i?.NGAY_SINH, "YYYYMMDD").format("YYYY") : ''})

                </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PdfBarcode;
