import ResizeObserver from "rc-resize-observer";
import { useEffect, useRef, useState } from "react";
import { Empty, Table } from "antd";
import classNames from "classnames";
import { VariableSizeGrid as Grid } from "react-window";

export const VirtualTable = (props) => {
  const { columns, scroll, ...res } = props;

  const [tableWidth, setTableWidth] = useState(0);

  const widthColumnCount = columns.filter(({ width }) => !width)?.length;

  const mergedColumns = columns.map((column) => {
    if (column.width) {
      return column;
    }

    return { ...column, width: Math.floor(tableWidth / widthColumnCount) };
  });

  const gridRef = useRef();

  const [connectObject] = useState(() => {
    const obj = {};
    Object.defineProperty(obj, "scrollLeft", {
      get: () => {
        if (gridRef.current) {
          return gridRef.current?.state?.scrollLeft;
        }

        return null;
      },
      set: (scrollLeft) => {
        if (gridRef.current) {
          gridRef.current?.scrollTo({
            scrollLeft,
          });
        }
      },
    });
    return obj;
  });

  const resetVirtualGrid = () => {
    gridRef.current?.resetAfterIndices({
      columnIndex: 0,
      shouldForceUpdate: true,
    });
  };

  useEffect(() => resetVirtualGrid, [tableWidth]);

  // const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
  //   ref.current = connectObject;
  //   const totalHeight = rawData?.length * 54;
  //
  //   if (props.dataSource?.length === 0) {
  //     return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  //   }
  //
  //   return (
  //     <Grid
  //       ref={gridRef}
  //       className="virtual-grid"
  //       columnCount={mergedColumns?.length}
  //       columnWidth={(index) => {
  //         const { width } = mergedColumns[index];
  //         return totalHeight > scroll.y && index === mergedColumns?.length - 1 ? width - scrollbarSize - 1 : width;
  //       }}
  //       height={scroll.y}
  //       rowCount={rawData?.length}
  //       rowHeight={() => 54}
  //       width={tableWidth}
  //       onScroll={({ scrollLeft }) => {
  //         onScroll({
  //           scrollLeft,
  //         });
  //       }}
  //     >
  //       {({ columnIndex, rowIndex, style }) => (
  //         <div
  //           className={classNames("virtual-table-cell", {
  //             "virtual-table-cell-last": columnIndex === mergedColumns?.length - 1,
  //           })}
  //           style={style}
  //           onClick={() => props.onClickRow(rawData[rowIndex])}
  //         >
  //           {rawData[rowIndex][mergedColumns[columnIndex].dataIndex]}
  //         </div>
  //       )}
  //     </Grid>
  //   );
  // };

  return (
    <ResizeObserver
      onResize={({ width }) => {
        setTableWidth(width);
      }}
    >
      <Table
        {...res}
        dataSource = {props.dataSource}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => props.onClickRow(record)
          }}}
        className="virtual-table"
        columns={mergedColumns}
        pagination={false}
        scroll={{y: '40vh'}}
        // components={{
        //   body: renderVirtualList,
        // }}
      />
    </ResizeObserver>
  );
};
