import { useDispatch, useSelector } from "react-redux";
import { ConfirmModal, Select } from "../../components";
import { getSharedData } from "ducks/slices/ListData/SharedData.slice";
import { Checkbox } from "antd";
import { snapshotActions } from "ducks/slices/snapshotSlice";
import i18n, { languageKeys } from "../../i18n";
import "./styles.less"

const SelectChangeTarget = ({ id, record, syncTargetRef, handleChangeTarget, dataSource, readOnly }) => {

    const dispatch = useDispatch()
    const { customerTarget } = useSelector(getSharedData)
    const { disabledModalTarget } = useSelector((state) => state.snapShot)

    return (
        <>
            <Select
                className={"selectDT"}
                dataSource={customerTarget}
                titleKey={"TEN_DOI_TUONG"}
                valueKey={"ID"}
                style={{ width: '100%', height: "32px" }}
                size='small'
                onChange={(e) => handleChangeTarget(e, record, dataSource.length > 1 ? disabledModalTarget : true)}
                listHeight={200}
                value={id}
                disabled={readOnly}
            />
            <ConfirmModal
                ref={syncTargetRef}
                title={""}
                content={""}
                okText={i18n.t(languageKeys.sync)}
                cancelText={i18n.t(languageKeys.answer_khong)}
                onOk={({ value }) => handleChangeTarget(value, null, null, true)}
                onCancel={({ value, record }) => handleChangeTarget(value, record, true)}
                onPressCancelSuccess={({ value, record }) => handleChangeTarget(value, record, true)}
            >
                <div style={{ margin: '0 20px', padding: 10, background: 'rgba(245, 247, 251, 1)', borderRadius: 15, color: 'rgba(44, 55, 130, 1)' }}>
                    <p style={{ textAlign: 'center', lineHeight: '17.5px', marginBottom: 5, fontSize: 14 }}>
                        {i18n.t(languageKeys.noti_dong_bo_doi_tuong_kh)}
                    </p>
                    <Checkbox
                        onChange={(e) => dispatch(snapshotActions.setDisabledModalTarget(e.target.checked))}
                        style={{ marginLeft: 18, color: 'rgba(44, 55, 130, 1)', fontSize: 14 }}
                    >
                        {i18n.t(languageKeys.ko_hien_thi_lai)}
                    </Checkbox>
                </div>
            </ConfirmModal>
        </>
    )
}

export default SelectChangeTarget;