import { useState, useEffect } from "react";
import { StarOutlined, StarFilled } from "@ant-design/icons";
import style from "./starCheck.module.less";
import { HLog } from "../../helpers";

const StarCheck = (props) => {

  useEffect(() => {
    
  }, []);

  //hàm xử lí chọn nổi bật hay không
  const handleClick = () => {
    if(props.onClickStar) {
      props.onClickStar(props.dataItem);
    }
    
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <label className={style["wrapper"]}>
        <input
          type="checkbox"
          onChange={handleClick}
        />
        {props.isChecked ? (
          <StarFilled style={{ color: "#FFC727" }} />
        ) : (
          <StarOutlined />
        )}
      </label>
    </div>
  );
};

export default StarCheck;
