const standard_en = {
    "validate_required": "Must not be left blank",
    "validate_type_email": "Wrong format",
    "validate_type_number": "Incorrect number format",
    "validate_number_range": "Not within the range from min to max",
    "validate_type_pattern": "Not in the correct format",
    "validate_number_max": "Maximum character limit",
    "wrong_format": "Not in the correct format",
    "reportsManagement": "Report Management",
    "receptionReport": "Reception report",
    "salesReport": "Revenue Report",
    "commissionReport": "Commission Reporting",
    "pharmacyReport": "Pharmaceutical Report",
    "risReport": "Imaging Diagnostic Report",
    "lisReport": "Test Report",
    "healthInsuranceReport": "Medical Insurance Report",
    "signOut": "Signout",
    "changeLanguage": "Change language",
    "vietnamese": "Vietnamese",
    "english": "English",
    "home": "Home",
    "outpatientPharmacy": "Outpatient Pharmacy",
    "management": "Administration",
    "returnHomepage": "Return Homepage",
    "pageNotExist": "Page not exist",
    "initializingData": "Initializing data",
    "updatingAccInfo": "The system is updating the account information",
    "search": "Seach",
    "searchCustomer": "Searching for customers",
    "printRP": "Print the report",
    "exportExcel": "Export Excel",
    "reset": "Reset",
    "apply": "Apply",
    "previous": "Before",
    "next": "Next",
    "otherOption": "Other option",
    "all": "All",
    "resetFilter": "Reset",
    "time": "Time",
    "field_from": "From",
    "field_to": "To",
    "select": "Select",
    "serviceTypeSummaryReport": "Comprehensive Report on Service Types",
    "imageAnalysationBook": "Customer Revenue Report by Service",
    "imageAnalysationActivityByService": "Imaging Diagnostic Activity Report by Service Type",
    "functionalExploratoryDiagnosticOperationStatistic": "Diagnostic Imaging Activity Statistics, Functional Exploration",
    "serviceType": "Type of service",
    "serviceTypeGroup": "Service category group",
    "implementationRoom": "Implementation room",
    "implementationDoctor": "The doctor performs",
    "designatedDoctor": "Doctor prescribed",
    "designatedDoctorShort": "Doctor prescribes",
    "imageAnalysationBookShort": "Radiology notebook",
    "implementationRoomShort": "Carry out room",
    "imagingAnalysisReport": "Diagnostic activity report",
    "reportTime": "Reporting time",
    "serviceReportByPatient": "Patient-based service statistics",
    "imageDiagnosticBookByPatient": "Patient Imaging Diagnosis Tracking Log",
    "fromDate": "From the day",
    "risReportTitle": "Radiology report",
    "today": "Today",
    "yesterday": "Yesterday",
    "tomorrow": "Tomorrow",
    "next_7_days": "The next 7 days",
    "next_30_days": "The next 30 days",
    "prev_7_days": "The last 7 days",
    "prev_30_days": "The last 30 days",
    "department": "Faculty/Department",
    "receptionist": "The receptionist",
    "paymentStatus": "Payment Status",
    "paid": "Paid",
    "unPaid": "Unpaid",
    "waitConfirm": "Unconfirmed",
    "confirm": "Confirmed",
    "waitForExam": "Waiting for an examination",
    "beingExamined": "Being examined",
    "finishedExam": "Finished the examination",
    "complete": "Complete",
    "cancelled": "Cancelled",
    "examCode": "Examination code",
    "patientCode": "Patient code",
    "patientName": "Patient name",
    "year_of_birth": "Birth year",
    "gender": "Gender",
    "phoneNumber": "Phone number",
    "patientObj": "Object",
    "receptionDay": "Reception Day",
    "idCard": "Identity card",
    "THXorPDW": "P-D-W",
    "province": "Province",
    "district": "District",
    "ward": "Ward",
    "detailedAddress": "Detailed address",
    "nation": "Country",
    "ethnic": "Ethnic",
    "status": "Status",
    "fetchFailed": "Failed to fetch",
    "user_object_group": "User object group",
    "object_code": "Object code",
    "object_name": "Object name",
    "examination_fee_commission": "Medical expenses",
    "other_fee": "Other expenses",
    "commissions_other_fee": "Other commission fee",
    "commissions_for_diagnostic_imaging_fees": "Commission radiology",
    "test_fee_commission": "Testing fees",
    "commissions_test_fee_commission": "Lab test commission fee",
    "drug_commission": "Medicine commission",
    "total_commission": "Total commission",
    "index": "No.",
    "tip_chinh_sua_cot": "Edit columns",
    "total": "Total",
    "diagnostic_imaging_fee": "Imaging diagnostic fee",
    "examination_fee": "Examination fee",
    "examination_fee_2": "Consultation fee",
    "source_client": "Guest sources",
    "object_group": "Target group",
    "designation_code": "Index code",
    "designator": "The designated person",
    "satellite_clinic": "Satellite Clinic",
    "total_money": "Total",
    "drug_fee": "A drug payment",
    "total_visits": "Total visits",
    "filter": "Filter",
    "noti_get_list_failed": "Retrieve the failure list",
    "noti_export_succeed": "File exported successfully",
    "noti_export_failed": "Export file failed",
    "male": "Male",
    "female": "Female",
    "other": "Other",
    "source_client_code": "Customer source",
    "source_client_name": "source of customer",
    "address": "Address",
    "phone": "Phone",
    "reportPerson": "Who create report",
    "day": "Day",
    "month": "Month",
    "year": "Year",
    "quantity": "Quantity",
    "sign_and_write_full_name": "Sign and name",
    "processing": "In processing",
    "exportSuccess": "Export Success",
    "exportFail": "Export excel format fail",
    "printSuccess": "Report success",
    "printFail": "Report fail",
    "the_reporter": "Reporter",
    "download_data_success": "The database update was successful",
    "download_data_failure": "The database update was failed",
    "cashier": "Cashier",
    "paymentType": "Methods of payment",
    "technical_service_money": "Technical service fee",
    "total_number_of_customers": "Total visitor",
    "cancellation_fee": "Reimbursements",
    "selected": "Selected",
    "payment_method_report": "Payment Method Report",
    "customer_revenue_report_service": "Customer Revenue Report by Service",
    "service": "Service",
    "object": "Object",
    "staff": "Staff",
    "field_Nguoi_tu_van": "Mentors",
    "cashier_tracking_report": "Cashier tracking report",
    "staff_name": "Staff name",
    "plus_account": "Including a sum",
    "head_of_finance_and_accounting_department": "Head of the Finance and Accounting Department",
    "signed_and_sealed": "Sign and stamp",
    "paymentMethod": "Payment method",
    "serial": "Index",
    "customerCode": "Customer Code",
    "customerName": "Name of customers",
    "customerNameSort": "Name of customers",
    "serviceName": "Service Name",
    "paymentDate": "Day of payment",
    "billNumber": "Bill number",
    "appointedDoctor": "Doctor prescribed",
    "reception": "Reception",
    "appointedRoom": "Esignated room",
    "appointedTime": "Appointed Time",
    "unitPrice": "Unit price",
    "exemptions": "Exemptions",
    "intoMoney": "Total amount",
    "exemptionReason": "Exemption Reason",
    "note": "note",
    "receptionRoom": "Reception room",
    "totalPayment": "Including a sum",
    "head_of_FaAD": "Head of the Finance and Accounting Department",
    "sign_and_seal": "Sign and stamp",
    "serviceCode": "Service code",
    "quantity_short": "Quantity",
    "price": "Unit price",
    "totalPrice": "Total amount",
    "designatedRoom": "Esignated room",
    "designatedTime": "Appointed Time",
    "resultReturnDr": "The doctor delivers the results",
    "performedDr": "The doctor performs",
    "returnTime": "Time to return",
    "designatedRoomShort": "Diagnostic room",
    "designatedTimeShort": "Diagnosis time",
    "resultReturnDrShort": "The doctor delivers the results",
    "resultReturnTimeShort": "Time to return results",
    "resultTimeShort": "Time to return results",
    "unit": "Unit",
    "customerType": "Customer type",
    "customerBuyByOrder": "Customers buy by order",
    "passersby": "Transient visito",
    "customer": "Customer",
    "medicineSeller": "Cashier",
    "prescribingTime": "Prescription period",
    "detail": "Details",
    "ordinalNum": "No.",
    "designated": "Designate",
    "implemented": "Perform",
    "fullName": "Full name",
    "amountMoney": "Amount",
    "room": "Room",
    "medical_expenses": "Examination fee",
    "unaccomplished": "Not yet performed",
    "accomplished": "Already performed",
    "accomplished_short": "Already performed",
    "unaccomplished_short": "Not yet performed",
    "functionRoom": "Functional room",
    "doctor": "Doctor",
    "designatedDoctorReport": "Doctor's appointment report",
    "common_day_month_year": "Date...month...year",
    "functionRoomReport": "Functional room report",
    "accounting_room": "Finance and accounting department",
    "hospital": "Hospital",
    "payment": "Payment",
    "date_month_year": "Date...month...year",
    "service_catalog_template": "Service DM form",
    "table_maker": "Table creator",
    "department_of_diagnostic_imaging": "Head of diagnostic imaging department",
    "accountant": "Accountant",
    "other_money": "Other money",
    "client_source_report": "Report source",
    "clientSourceCode": "Source code",
    "clientSource": "Source",
    "receptionTurn": "Reception visits",
    "totalReceptionTurn": "Total reception visits",
    "examinationFee": "Examination fee",
    "testFee": "Laboratory fee",
    "imgAnalysisFee": "Imaging fee",
    "day_month_year": "day ....... month ....... year .......",
    "ministryOfHealth": "Ministry of Health (Department of Health)",
    "service_receipt_report": "Service receipt report",
    "createDate": "Creation date",
    "otherFee": "Other fees",
    "examExemptions": "Examination discount",
    "testExemptions": "Laboratory discount",
    "imgAnalysisExemptions": "Imaging discount",
    "totalExemptions": "Total discount",
    "otherExemptions": "Other discount",
    "total_payment": "Total payment",
    "total_revenue": "Total revenue",
    "total_payment_sort": "Total payment",
    "paymentShort": "Payment",
    "noti_select_service_type": "Please select the type of service",
    "noti_select_medicine_type": "Not selected warehouse",
    "noti_please_apply_filter": "Please press apply",
    "executionStatus": "Execution status",
    "chooseMedicineStore": "Select warehouse",
    "chooseMedicine": "Select medicine",
    "pleaseChose": "Please choose",
    "medicineName": "Medicine name",
    "medicineID": "Medicine code",
    "medicineUnit": "Unit of measurement",
    "medicineChemicals": "Active ingredient",
    "lastExistence": "Ending inventory",
    "theNumberOfImport": "Importing quantity",
    "exportQuantity": "Exporting quantity",
    "beginningInventory": "Beginning Inventory",
    "importPrice": "Price input",
    "data": "Data",
    "exportAllDrugs": "Export all drugs",
    "exportDrugsWithImportHistory": "Export drugs with drug import history",
    "existence": "Ending inventory",
    "plzSelectPharmacyStore": "No drug warehouse selected",
    "searchMedicine": "Search for drugs",
    "drugList": "Drug list",
    "drugNotInPrescription": "Drugs not in the doctor's prescription",
    "drugInPrescriptionButNotBuy": "Drugs in the prescription but not purchased by the customer",
    "drugInPrescription": "Drugs in the doctor's prescription",
    "detailMedicineSaleInfo": "Detailed drug sales information",
    "exit": "Exit",
    "lotCode": "Batch code",
    "quantityDrPrescribe": "Number of prescribing doctors",
    "customerReport": "Customer report",
    "summaryReportOfTestType": "Summary report of test types",
    "testSaveReport": "Test retention report",
    "testRevenueReport": "Revenue report for testing",
    "age": "Age",
    "testType": "Test type",
    "have_result_sort": "Already result",
    "no_result_yet_sort": "Not yet result",
    "implement_date": "Date of implementation",
    "test_service": "Test service",
    "parameterName": "Parameter name",
    "designated_faculty": "Designated department",
    "outpatient": "Outpatient",
    "money": "Money",
    "cancel": "Cancel",
    "registrationNumber": "Registration number",
    "contentFull": "Amount",
    "concentration": "Concentration",
    "pleaseChoseMedicine": "Not selected medication",
    "pleaseChoseMedicineStore": "Not selected drug store",
    "createBy": "Creator",
    "licenseImport": "License import",
    "licenseExport": "License export",
    "medicineStore": "Batch of medicine",
    "productionBatch": "Production batch",
    "dueDate": "Expiration date",
    "explain": "Explanation",
    "initialStock": "Beginning inventory quantity",
    "numberImport": "Quantity received",
    "numberExport": "Quantity issued",
    "NumberLastStock": "Ending inventory quantity",
    "cancellationDate": "Cancellation date",
    "canceler": "Cancellation person",
    "canceled": "Canceled",
    "completed": "Completed",
    "waitConfirms": "Waiting for approval",
    "lastStock": "Inventory quantity",
    "noData": "No data available",
    "antibiotic_use_report": "Antibiotic usage report",
    "tien_bnct": "Billing left",
    "head_of_laboratory": "Head of Laboratory",
    "result_has_been_returned": "Results returned",
    "no_result_yet": "Results not yet returned",
    "testName": "Test name",
    "reportCardStock": "Inventory card report",
    "cardStock": "Inventory card",
    "namesOfDrugsChemicalsMedicalConsumables": "Name of medicine, chemical, medical supplies consumed",
    "codeBook": "Code number",
    "barcode": "Barcode",
    "packingInstruction": "Packaging specification",
    "licenseNumber": "Document number",
    "commonImport": "Import",
    "commonExport": "Export",
    "head_of_pharmacy_department": "Head of Pharmacy Department",
    "president": "Director",
    "ministry_of_health": "Ministry of Health (Department of Health)",
    "warehouse": "Warehouse",
    "numberCode": "Code number",
    "number": "Number",
    "drug_name_concentration_strength": "Name of medicine, concentration, content",
    "boarding": "Inpatient",
    "director": "Director",
    "code": "Code",
    "dean": "Head of department",
    "activeIngredient": "Active ingredient",
    "importExportReports": "Inventory import-export report",
    "storage": "Belonging to the warehouse",
    "participants": "Components involved",
    "position": "Position",
    "position": "Position",
    "addNew": "Add new",
    "input": "Import",
    "drug_registration_number": "Drug registration number",
    "manufactureCountry": "Country of manufacture",
    "expiry": "Expiration date",
    "number_of_doc": "Number of books",
    "drug_inventory_record": "Drug inventory report",
    "the_inventory_team_includes": "Inventory team includes",
    "recommendations": "Proposed opinions",
    "member": "Members",
    "hour": "Hour",
    "inventoriedAt": "Checked at",
    "drug_inventory_time_up_to": "Time of drug inventory up to",
    "record_ss": "Books",
    "reality": "Reality",
    "broken_broken": "Damaged",
    "selectPersonnel": "Select personnel",
    "duplicatePersonnel": "Personnel already exists",
    "drug_inventory_time": "Time of drug inventory",
    "inventoryAddress": "Inventory location",
    "drugSaleStatisticalReport": "Drug sales statistics report",
    "antibiotic": "Antibiotics",
    "isNewDrug": "New drugs",
    "insuranceDrugName": "Health insurance drug name",
    "traditionalMedicineProduct": "Traditional medicine products",
    "isPrescribedDrug": "Prescription drug",
    "isFunctionalFood": "Functional foods",
    "selectDrugType": "Select type of medication",
    "labelMedicineSaleStatisticalReport": "Sales statistics report for medication",
    "drugSaleReport": "Revenue report for medication sales",
    "conclude": "Conclusion",
    "billType": "Type of voucher",
    "receipt": "Receipt",
    "refundTicket": "Return voucher",
    "cancellationTicket": "Cancellation voucher",
    "noti_please_select_your_test_type_you_want_to_check": "Please select the type of test you want to check",
    "resultName": "Result name",
    "male_avg": "Male average",
    "female_avg": "Female average",
    "kid_avg": "Children everage",
    "reportCommissionByObject": "Report on commission by target",
    "reportCommissionBySource": "Report on commission by source",
    "reportCommissionByDesignator": "Report on commission by designated person",
    "cashierReport": "Cashier monitoring report",
    "reportToTheAppointedDoctor": "Referring physician report",
    "reportReportFunction": "Functional department report",
    "reportSale": "Revenue report",
    "designatorsName": "Designated person name",
    "noti_permission_route_deny": "You do not have access to this page",
    "noti_permission_deny": "You do not have permission for this function",
    "satelliteRevenueReport": "Revenue report for clinic chain",
    "noti_please_select_cashier": "Please select cashier",
    "maximumPeriod": "Maximum available time is 1 year",
    "revenue_report_by_customer": "Revenue report by customer",
    "diagnostic_imaging_money": "Radiology fee",
    "customer_revenue_report_according_to_doctor_prescription": "Revenue report by assigned doctor",
    "customer_revenue_report_according_to_designator": "Revenue report by assigned personnel",
    "person_in_charge_of_pharmacy": "Department of Pharmacy responsibility",
    "refundTotal": "Total refund",
    "welcome": "Reception",
    "refund": "Refund amount",
    "service_bundle_sales_report": "Service package revenue report",
    "serviceBundle": "Service package",
    "bundleType": "Package type",
    "paid_2": "Paid",
    "debt": "Balance/Debt",
    "serviceBundleType": "Service package type",
    "reportToDesignee": "Assigned personnel report",
    "people": "Personnel",
    "revenue_report_by_people": "Revenue report by assigned personnel",
    "refund_exemption_reason": "Reason for exemption/discount",
    "bad_debt": "Bad debt",
    "revenue_report_by_customer_object": "Report revenue by customer segment",
    "object_customer": "Customer segment",
    "number_of_service_uses": "Service usage count",
    "sum": "Total",
    "detail_information": "Detailed information",
    "revenue_by_object": "Revenue report by segment",
    "ticket_code": "Invoice code",
    "ticket_cancellation_date": "Cancellation date",
    "ticket_canceler": "Canceling person",
    "reason_for_cancellation": "Reason for cancellation",
    "cancellation_ticket_info": "Cancellation details",
    "tool_tip_admin": "Category and subsystem management",
    "tool_tip_cis": "Patient management",
    "tool_tip_lis": "Laboratory management",
    "tool_tip_ris": "Management of Imaging Diagnosis Department",
    "tool_tip_prm": "Customer Care Management",
    "tool_tip_pharmacy": "Outpatient Pharmacy Management",
    "tool_tip_report": "Report and Statistics Management",
    "another_source": "Other Sources",
    "debtS": "Accounts Receivable",
    "totalAdvance": "Total Advance Payment",
    "Bao_cao": "Report",
    "common_ngay": "Date",
    "common_ngay_kham": "Examination Date",
    "common_thang": "Month",
    "common_nam": "Year",
    "common_tuan": "Week",
    "common_khong_co_quyen": "You do not have access rights",
    "common_huy": "Cancel",
    "menu_Bang_dieu_khien": "Dashboard",
    "menu_Quan_ly_tiep_don": "Reception management",
    "menu_Quan_ly_he_thong": "System management",
    "menu_Quan_ly_danh_muc": "Category management",
    "menu_Quan_ly_nguoi_dung": "User management",
    "menu_Thong_tin_cskcb": "Healthcare information",
    "menu_Quan_ly_vien_phi": "Fee management",
    "menu_Quan_ly_thanh_toan": "Payment management",
    "menu_Quan_ly_so_hoa_don": "Summary book management",
    "menu_Quan_ly_kham_benh": "Medical examination management",
    "menu_Quan_ly_chan_doan_hinh_anh_ris": "Imaging diagnosis management",
    "menu_Quan_ly_bhyt": "Health insurance management",
    "menu_Quan_ly_duoc_ngoai_tru": "Outpatient pharmacy",
    "menu_Kho_thuoc": "Medicine warehouse",
    "menu_Kho_duoc_ngoai_tru": "Warehouse",
    "menu_Quan_ly_xep_hang_qms": "QMS - Queue management system",
    "menu_Quan_ly_dich_vu_can_thanh_toan": "Service needs payment",
    "menu_Quan_ly_xet_nghiem_lis": "Manage testing",
    "menu_Lich_lam_viec": "Work schedule",
    "menu_Ban_thuoc": "Sell medicine",
    "menu_Phieu_xuat_nhap": "Export import voucher",
    "app_loi_ket_noi": "Connection error!",
    "menu_Quan_ly_benh_nhan": "Customer management",
    "menu_Bao_cao": "Report",
    "menu_Ho_tro_khach_hang": "Customer support",
    "cate_DM": "Category",
    "cate_Danh_muc": "Category",
    "cate_benh_vien": "Hospital",
    "cate_khoa": "Department",
    "cate_phong": "Room",
    "cate_dich_vu_ki_thuat": "Service",
    "cate_nhom_dv_ki_thuat": "Service group",
    "cate_loai_dv_ki_thuat": "Service type",
    "cate_nhom_hoa_don": "Invoice group",
    "cate_thuoc": "Medicine",
    "cate_nha_thuoc": "Pharmacy",
    "cate_hang_san_xuat": "Manufacturer",
    "cate_duong_dung": "Route of administration",
    "cate_hoat_chat": "Active ingredient",
    "cate_kho_tu_truc": "Emergency cabinet",
    "cate_tinh": "Province",
    "cate_huyen": "District",
    "cate_xa": "Commune",
    "cate_nghe_nghiep": "Occupation",
    "cate_dan_toc": "Ethnicity",
    "cate_quoc_gia": "Nationality",
    "cate_icd10": "ICD-10",
    "cate_nhom_icd10": "ICD-10 group",
    "cate_don_vi_tinh": "unit of measurement",
    "cate_so_hoa_don": "List of summary books",
    "cate_giuong": "Hospital bed",
    "cate_dv_giuong": "Bed service",
    "cate_cach_thuc_PTTT": "Surgical technique",
    "cate_phuong_phap_PTTT": "Surgical method",
    "cate_pp_vo_cam": "Anesthesia method",
    "cate_xuat_khac": "Export",
    "cate_nguon": "Source",
    "cate_kho_thuoc": "Pharmacy",
    "cate_doi_tuong": "Customer target",
    "cate_nguon_khach": "Source of patients",
    "cate_cach_dung": "Usage",
    "field_ma_doi_tuong": "Object code",
    "field_Ma_cskcb": "Healthcare code",
    "field_Ten_benh_vien": "Hospital name",
    "field_Tuyen": "Level",
    "field_Hang": "Rank",
    "field_Dia_chi": "Address",
    "field_Ma_khoa": "Department code",
    "field_Ten_khoa": "Department name",
    "field_Ma_khoa_byt": "Department code of MOH",
    "field_Truong_khoa": "Department head",
    "field_Loai_khoa": "Department type",
    "field_Ma_phong": "Room code",
    "field_Ten_phong": "Room name",
    "field_Khoa": "Department",
    "field_Loai_phong": "Room type",
    "field_Phong_chi_dinh": "Designated room",
    "field_Stt": "No.",
    "field_Ma_dich_vu": "Service Code",
    "field_Ten_dich_vu": "Service Name",
    "field_Ten_dich_vu_theo_byt": "Service Name for Health Insurance",
    "field_Don_vi_tinh": "Unit of measurement",
    "field_Thong_tin_co_so": "Facility information",
    "field_Thong_tin_co_ban": "Basic information",
    "field_Ten_cskcb": "Health facility name",
    "field_Tinh_thanh": "Province/City",
    "field_Quan_huyen": "District",
    "field_Xa_phuong": "Ward/Commune",
    "field_Dia_chi_chi_tiet": "Detailed address",
    "field_Ghi_chu": "Note",
    "field_Hien_thi": "Lock",
    "field_Thao_tac": "Action",
    "field_Nhom_dich_vu": "Service group",
    "field_Loai_dich_vu": "Service type",
    "field_Nhom_bhyt": "Health Insurance cost group",
    "field_nhom_hoa_don": "Group of invoices",
    "field_Gia": "Price",
    "field_Gia_thu_phi": "Fee price",
    "field_Gia_bhyt": "Health insurance price",
    "field_Gia_dich_vu": "Request price",
    "field_Gia_nuoc_ngoai": "Foreign price",
    "field_Thong_tin_khac": "Other information",
    "field_Ti_le_dung_tuyen": "Correct line rate",
    "field_Ti_le_trai_tuyen": "Incorrect line rate",
    "field_So_ngay_tai_chi_dinh": "Number of days for reappointment",
    "field_Phong_thuc_hien": "Performing room",
    "field_Phong_lay_mau": "Sampling room",
    "field_chon_loai_thu_thuat": "Select type of surgery",
    "field_Chi_so_xet_nghiem": "Test index",
    "field_Don_vi_chi_so": "Index unit",
    "field_Giai_ket_qua_chi_so": "Result index resolution",
    "field_Cho_phep_mien_giam": "Allow discount",
    "field_Cho_sua_gia_khi_chi_dinh": "Allow price adjustment when ordering",
    "field_Ma_nhom": "Group code",
    "field_Ten_nhom": "Group name",
    "field_Id_loai_dv_ki_thuat": "Service type code",
    "field_Nhom_dv_ki_thuat": "Service group",
    "field_Ten_loai_dv_ki_thuat": "Service type name",
    "field_Id_nhom": "Group ID",
    "field_Ma_hang_san_xuat": "Manufacturer code",
    "field_Ten_hang_san_xuat": "Manufacturer name",
    "field_Ma_duong_dung": "Route code",
    "field_Ma_duong_dung_theo_byt": "Route code according to Health Insurance",
    "field_ten_duong_dung": "Route name",
    "field_Ma_kho": "Warehouse code",
    "field_Ten_kho": "Warehouse name",
    "field_Thu_kho": "Warehouse keeper",
    "field_Loai_kho": "Warehouse type",
    "field_Kieu_kho": "Warehouse style",
    "field_Ma_tinh": "Province code",
    "field_Ma_tinh_byt": "Province code according to Health Insurance",
    "field_Ten_tinh": "Province/City name",
    "field_Ten_Tinh_thanh": "Province/City",
    "field_Id_huyen": "District code",
    "field_Ma_huyen_byt": "District code according to Health Insurance",
    "field_Ten_huyen": "District name",
    "field_Ten_Quan_huyen": "District/Ward",
    "field_Ma_xa": "Commune code",
    "field_Ma_xa_byt": "Commune code according to Health Insurance",
    "field_Ten_xa": "Commune name",
    "field_Ten_Xa_phuong": "Commune/Ward",
    "field_Ma_nghe_nghiep": "Occupation code",
    "field_Ten_nghe_nghiep": "Occupation name",
    "field_Ma_dan_toc": "Ethnic code",
    "field_Ten_dan_toc": "Ethnic name",
    "field_Ma_icd": "ICD code",
    "field_Ten_icd": "ICD name",
    "field_Nhom_icd": "ICD group",
    "field_Loai_icd": "ICD type",
    "field_Ma_nhom_icd": "ICD group code",
    "field_Ten_nhom_icd": "ICD group name",
    "field_Ma_quoc_gia": "Country code",
    "field_Ten_quoc_gia": "Country name",
    "field_Ten_goi_khac": "Other name",
    "field_Ten_viet_tat": "Abbreviation",
    "field_Benh_nhan": "Patient",
    "field_Dich_vu_su_dung": "Service usage",
    "field_Thanh_toan": "Payment",
    "field_Trang_thai": "Status",
    "field_Thong_tin_hanh_chinh": "Administrative information",
    "field_Tim_kiem_benh_nhan": "Search for Customer",
    "field_Tim_kiem_thuoc": "Search for Medicine",
    "field_Doi_tuong_benh_nhan": "Object",
    "field_Uu_tien": "Priority",
    "field_Ma_benh_nhan": "Customer code",
    "field_Ten_benh_nhan": "Customer name",
    "field_So_dien_thoai": "Phone number",
    "field_Email": "Email",
    "field_Gioi_tinh": "Gender",
    "field_Ngay_sinh": "Birthday",
    "field_Nghe_nghiep": "Occupation",
    "field_Dan_toc": "Ethnicity",
    "field_Quoc_gia": "Country",
    "field_Thong_tin_bhyt": "Health insurance information",
    "field_TE_co_the_bhyt": "Does the patient have a health insurance card?",
    "field_The_bhyt": "Health insurance card",
    "field_Han_the_tu": "Card validity from",
    "field_Han_the_den": "Card validity to",
    "field_Ngay_mien_cung_chi_tra": "Date of exemption from co-payment",
    "field_Ngay_dong_han_du_5_nam": "Date of payment for 5 years",
    "field_Noi_song": "Place of residence",
    "field_Thong_tin_kham": "Medical information",
    "field_Yeu_cau_kham": "Examination requirements",
    "field_Chon_phong_kham": "Select clinic",
    "field_Ma_cccd": "Citizen ID number",
    "field_So_phong": "Room number",
    "field_Benh_nhan_cho_kham": "Waiting patients",
    "field_In_phieu_kham": "Print examination form",
    "field_ton_kho": "Inventory",
    "field_gia_ban": "Selling price",
    "field_gia_nhap": "Purchase price",
    "field_tinh_nang": "Features",
    "field_ma_ly_do": "Reason code",
    "field_ly_do": "Reason",
    "field_ma_nguon": "Source code",
    "field_ma_nguon_khach": "Customer source code",
    "field_ten_nguon_khach": "Customer source name",
    "field_ten_nguon": "Source name",
    "field_so_dang_ky_thuoc": "Drug registration number",
    "field_gio_bat_dau": "Start time",
    "field_gio_ket_thuc": "End time",
    "field_thoi_gian_hoat_dong": "Working hours of the room",
    "field_thoi_gian": "Time",
    "field_chua_cai_dat_thoi_gian": "Not set working time",
    "field_gia_vien_phi": "Fee price",
    "field_Da_TT": "Paid",
    "field_Da_TH": "Carried out",
    "field_Ma_CK": "Examination code",
    "field_Ma_BN": "Patient code",
    "field_SL": "Quantity",
    "field_BS": "Examinated doctor",
    "field_loai_doi_tuong": "Object type",
    "field_ma_thuoc": "Drug code",
    "field_ten_thuoc": "Drug name",
    "field_lo_thuoc": "Drug batch",
    "field_Ten_thuoc_bhyt": "Health insurance drug name",
    "field_ma_thuoc_bhyt": "Health insurance drug code",
    "field_stt_bhyt": "Health insurance serial number",
    "field_ten_hoat_chat": "Active ingredient name",
    "field_don_vi_tinh": "Unit of measurement",
    "field_nong_do": "Concentration",
    "field_the_tich": "Volume",
    "field_ham_luong": "Content",
    "field_ma_hoat_chat": "Active ingredient code",
    "field_nuoc_san_xuat": "Country of manufacture",
    "field_hang_san_xuat": "Manufacturer",
    "field_quy_cach_dong_goi": "Packaging specification",
    "field_ATC_code": "ATC code",
    "field_thuoc_khang_sinh": "Antibiotics",
    "field_che_pham_y_hoc_co_truyen": "Traditional medicine products",
    "field_cho_phep_mien_giam": "Allow reduction",
    "field_thuoc_tan_duoc": "New drugs",
    "field_thuoc_ke_don": "Prescription drugs",
    "field_vi_thuoc_y_hoc": "Traditional medicine remedies",
    "field_thuc_pham_chuc_nang": "Functional foods",
    "field_tam_dung_nhap_NCC": "Temporary suspension of Supplier entry",
    "field_ma_hoat_chat_bhyt": "Active ingredient code",
    "field_doi_tuong_BHYT": "Health insurance",
    "field_doi_tuong_yeu_cau": "Request",
    "field_doi_tuong_vien_phi": "Hospital fee",
    "field_doi_tuong_nuoc_ngoai": "Foreign countries",
    "field_doi_tuong_mien_giam": "Exemption",
    "field_ngay_het_han": "Expiration date",
    "field_loai_thuoc": "Drug type",
    "field_nhom_thuoc": "Drug group",
    "field_tat_ca": "All",
    "field_tat_ca_nhom": "All groups",
    "field_da_het_han": "Expired",
    "field_chua_het_han": "Not expired",
    "field_ma_vach": "Barcode",
    "field_nhom_chi_phi": "Health insurance cost group",
    "field_chung_tu": "Document",
    "field_phuong_thuc": "Method",
    "field_don_gia": "Unit price",
    "field_ton_cuoi": "Ending inventory",
    "field_doi_tac": "Partner",
    "field_so_lo": "Lot number",
    "field_han_su_dung": "Expiration date",
    "field_con_hang": "In stock",
    "field_het_hang": "Out of stock",
    "field_ten_nhom_thuoc": "Drug group name",
    "field_nhom_cha": "Parent group",
    "field_nhom_cls": "Medical group",
    "ten_doi_tuong": "Object name",
    "thu_tu": "No.",
    "field_ma_lo": "Lot code",
    "field_them_lo": "Add new lot",
    "field_chi_tiet_phieu_nhap": "Import voucher details",
    "field_chi_tiet_phieu_xuat": "Export voucher details",
    "field_chi_tiet_phieu_ban_thuoc": "Drug sales voucher details",
    "field_Ten_nhom_thuoc": "Drug group name",
    "field_tao_phieu_nhap": "Create import voucher",
    "field_ten_khach_hang": "Customer name",
    "field_nguoi_ban_thuoc": "Cashier",
    "field_doanh_thu": "Revenue",
    "field_tong_tien": "Total amount",
    "field_shd_loai_so": "Book type",
    "field_shd_ten_so": "Book name",
    "field_shd_ky_hieu": "Symbol",
    "field_shd_tong_so": "Total vouchers",
    "field_shd_ma_so": "Book code",
    "common_trong": "In weight",
    "common_Huy": "Cancel",
    "common_Thoat": "Exit",
    "common_Luu": "Save",
    "common_Luu_va_In": "Save and Print",
    "common_In": "Print",
    "common_Nhap": "Enter",
    "common_Chon": "Select",
    "common_Bat": "Enable",
    "common_Xoa": "Delete",
    "common_huy_don": "Cancel order",
    "common_dong": "Close",
    "common_Chinh_sua": "Edit",
    "common_Them_moi": "Add new",
    "common_Tim_kiem": "Search",
    "common_Tim_kiem_theo_loai": "Search by type",
    "common_Nhap_csv": "Import spreadsheet",
    "common_Xuat_csv": "Export spreadsheet",
    "common_Tat": "Turn off",
    "common_Xem_truoc": "Preview",
    "common_Tai_mau": "Download template",
    "common_Tiep_tuc": "Continue",
    "common_Dang_kham": "Being examined",
    "common_Kham_xong": "Examination completed",
    "common_Canh_bao": "Warning",
    "common_Xuat_file": "Export file",
    "common_ok": "Agree",
    "common_cai_dat_lai": "Reset",
    "common_bo_loc": "Filter",
    "common_tai_lai": "Reload",
    "gioi_tinh_Nam": "Male",
    "gioi_tinh_Nu": "Female",
    "gioi_tinh_Khac": "Other",
    "common_Dang_xuat": "Log out",
    "noi_dung_keo_tha_upload_file": "Click or drop files here!",
    "loai_khoa_kham_benh": "Medical examination",
    "loai_khoa_noi_khoa": "Internal medicine",
    "loai_khoa_duoc": "Pharmacy",
    "loai_khoa_tai_chinh": "Finance",
    "loai_khoa_xet_nghiem": "Testing",
    "loai_khoa_cdha": "Imaging diagnosis",
    "loai_khoa_khac": "Other",
    "label_Da_tiep_nhan": "Received",
    "label_quan_ly_dat_hen": "Appointment management",
    "label_Tiep_don": "Reception",
    "label_Tiep_don_kham_chua_benh": "Reception and treatment",
    "label_So_hoa_don": "General ledger",
    "label_them_so_hoa_don": "Add new general ledger",
    "label_sua_so_hoa_don": "Edit general ledger",
    "label_thong_tin_chi_tiet": "Detailed information",
    "label_phieu_xuat_nhap": "Export, import form",
    "luu_va_in": "Save and print",
    "thong_tin_cskcb": "Information of medical facility",
    "luu_thay_doi": "Save changes",
    "field_ten_CSKCB": "Name of medical care facility",
    "field_don_vi_quan_ly": "Management unit",
    "field_hang_benh_vien": "Hospital rank",
    "field_giam_doc_benh_vien": "Director of medical care facility",
    "them_hinh_anh": "Add image",
    "field_ten_nguoi_dung": "Username",
    "field_phan_quyen": "Permission",
    "txt_da_chon": "Selected",
    "field_ma_cc_hanh_nghe": "Professional Certificate Code",
    "field_thong_tin_cong_viec": "Job Information",
    "field_hoc_ham": "Academic Title",
    "field_chuc_danh": "Job Title",
    "field_ho": "Last Name",
    "field_ten_ten_dem": "First Name",
    "field_mat_khau": "Password",
    "field_ten_danh_nhap": "Username",
    "title_them_moi_nguoi_dung": "Add new user",
    "title_thong_tin_tai_khoan": "Account information",
    "title_chinh_sua_nguoi_dung": "Edit user",
    "title_tong_gia": "Total Price",
    "button_nhap_BHYT": "Enter Health Insurance Card",
    "button_kiem_tra_BHYT": "Check Health Insurance",
    "field_dia_chi_the": "Card Address",
    "short_ma_cc_hanh_nghe": "Certificate Code",
    "nhap_dung_dinh_dang_email": "Please enter a valid email format",
    "nhap_email": "Please enter email",
    "nhap_dung_dinh_dang_sdt": "Please enter a valid phone number format",
    "nhap_sdt": "Please enter phone number",
    "vui_long_nhap": "Please enter!",
    "please_chon": "Please select!",
    "vui_long_chon": "Please select the performing room!",
    "vui_long_nhap_du_ky_tu": "Please enter enough characters!",
    "vui_long_nhap_dung_dinh_dang": "Please enter the correct format!",
    "error_trung_so_dien_thoai": "Duplicate phone number",
    "error_trung_so_user_name": "Duplicate username",
    "error_loi_ton_tai": "Value already exists!",
    "error_loi_khong_nhap_gia_tri": "Do not enter enough values!",
    "error_gia_tri_khong_hop_le": "Invalid value!",
    "error_khong_trung_khop": "Values do not match!",
    "error_dich_vu_da_thanh_toan": "Cannot delete invoices with paid services",
    "error_vien_phi_thieu_phieu": "Total number of tickets lower than the number of invoices created (minimum)",
    "error_thoi_gian_khong_hop_le": "Invalid time",
    "cap_nhat_thanh_cong": "Update successful",
    "cap_nhat_that_bai": "Update failed!",
    "them_moi_thanh_cong": "Successful addition",
    "them_moi_that_bai": "Failed to add!",
    "thao_tac_thanh_cong": "Operation successful",
    "thao_tac_that_bai": "Operation failed",
    "common_Hom_nay": "Today",
    "common_Hom_qua": "Yesterday",
    "Cho_kham": "Waiting for examination",
    "CLS": "Paraclinical",
    "common_Hoan_thanh": "Completed",
    "common_Hoan_thanh_short": "Completed",
    "field_Tuoi": "Age",
    "common_Ap_dung": "Apply",
    "Thong_tin_benh_nhan": "Customer information",
    "Lich_su_kham_benh": "Medical examination history",
    "Chi_dinh_CLS": "Paraclinical indication",
    "Ke_don_thuoc_tu_nguyen": "Prescribe medicine",
    "Don_thuoc_bhyt": "Health insurance prescription",
    "field_Ngay_kham": "Examination date",
    "field_Ngay_bat_dau": "Start date",
    "field_Ngay_ket_thuc": "End date",
    "field_Chan_doan_benh_chinh": "Main diagnosis",
    "field_Chan_doan_benh_phu_kem_theo": "Accompanying secondary diagnosis",
    "field_Bac_si": "Doctor",
    "field_Bac_si_kham": "Examining doctor",
    "field_Khoa_dieu_tri": "Treatment department",
    "Don_thuoc": "Prescription",
    "Ket_qua_CLS": "Lab results",
    "field_CMND_CCCD": "ID card number",
    "limit_thoi_gian_1_nam": "Maximum available time is 1 year",
    "field_Chi_so_sinh_ton": "Survival rate",
    "field_Mach": "Pulse",
    "field_Lan_phut": "(Times/minute)",
    "field_Do_C": "(Degree Celsius)",
    "field_Nhiet_do": "Temperature",
    "field_Nhip_tho": "Respiration rate",
    "field_Nhip_phut": "(Breaths/minute)",
    "field_Huyet_ap": "Blood pressure",
    "field_Ly_do_kham": "Reason for examination",
    "field_Qua_trinh_benh_ly": "Disease process",
    "field_Tien_su_ban_than": "Personal medical history",
    "field_Tien_su_gia_dinh": "Family medical history",
    "field_Kham_lam_sang": "Clinical examination",
    "field_Ma_benh_chinh": "Main disease code",
    "field_Ma_benh_kem_theo": "Accompanying disease code",
    "field_Chan_doan_lam_sang": "Clinical diagnosis",
    "field_Chan_doan_xac_dinh": "Definitive diagnosis",
    "field_Cach_giai_quyet": "Resolution method",
    "field_Ket_qua_dieu_tri": "Treatment outcome",
    "field_Xu_tri": "Handling",
    "field_Tai_kham": "Re-examination",
    "field_Tai_kham_sau": "Subsequent re-examination",
    "Ket_thuc_cuoc_kham": "End of examination session",
    "field_Chon_ngay_ap_dung": "Select application date",
    "bs": "Doctor",
    "txt_phong_tai_chinh_ke_toan": "Financial accounting department",
    "field_ngay_kham": "Examination date",
    "field_Noi_kham": "Examination location",
    "field_ngay_thuc_hien": "Date of execution",
    "field_gio_thuc_hien": "Time of execution",
    "Ke_don_thuoc": "Prescription",
    "field_Thoi_gian_y_lenh": "Order time",
    "field_Them_moi_thuoc": "Add new medicine",
    "field_Kho_thuoc": "Medicine warehouse",
    "field_Ten_thuoc": "Medicine name",
    "field_So_luong": "Quantity",
    "field_Moi_ngay": "Per day",
    "field_Chia_lam": "Divide into",
    "field_Cach_dung": "Usage method",
    "field_Ma_thuoc": "Med. code",
    "field_Hoat_chat": "Active ingredient",
    "field_Don_vi": "Unit",
    "field_In_phieu": "Print form",
    "field_Lan": "Time",
    "field_Ma_don_thuoc": "Prescription code",
    "field_Kho_nhan_thuoc": "Medicine receiving warehouse",
    "Ds_dich_vu_cls": "List of clinical laboratory services",
    "field_Dich_vu_chi_dinh": "Designated services",
    "field_Ma_phieu_chi_dinh": "Assignment code",
    "field_Bac_si_chi_dinh": "Doctor's prescription",
    "Chi_dinh_can_lam_sang": "Prescription for Refraction floor",
    "chi_tiet_phieu_chi_dinh_can_lam_sang": "Details of the refractive floor prescription",
    "chi_tiet_tiep_don_kham_chua_benh": "Details of receiving medical examination and treatment",
    "tao_phieu_chi_dinh_can_lam_sang": "Create a referral for refractive floor",
    "danh_sach_dich_vu_da_chon": "List of selected services",
    "field_Them_moi_can_lam_sang": "Add new refractive floor",
    "field_Chi_tiet_phieu_cls": "List of selected refractive floor services",
    "field_Ket_qua": "Result",
    "luu_va_in_phieu_kham": "Save and Print examination form",
    "luu_va_in_phieu_cls": "Save and Print Refraction floor form",
    "txt_So_luong": "Quantity",
    "txt_nhap_loi_dan": "Enter instructions",
    "field_KQKB_khoi_benh": "Recover",
    "field_KQKB_do": "Relieve",
    "field_KQKB_khong_thay_doi": "No change",
    "field_KQKB_nang": "Severe",
    "field_KQKB_tu_vong": "Deceased",
    "field_cach_xu_tri_ra_vien": "Discharged",
    "field_cach_xu_tri_chuyen_vien": "Transfer to hospital",
    "field_cach_xu_tri_chuyen_vien2": "Transfer to another facility",
    "field_cach_xu_tri_tron_vien": "Escape from hospital",
    "field_cach_xu_tri_xin_ra_vien": "Request discharge",
    // "currency_viet_nam": "currency",
    "field_Tong_gia": "Total price",
    "field_Tong_gia_tien": "Total amount",
    "error_Dich_vu_da_ton_tai": "Service already exists",
    "fallback_Phong_thuc_hien_trong": "(Not selected room)",
    "status_Chua_thanh_toan": "Not paid",
    "status_Da_thanh_toan": "Paid",
    "status_Cho_ket_qua": "Awaiting results",
    "status_Da_huy": "Cancelled",
    "status_Tat_ca": "All",
    "status_Cho_xac_nhan": "Awaiting confirmation",
    "field_Tong_thanh_toan": "Total paid",
    "vien_phi_da_tam_ung": "Advance paid",
    "vien_phi_Tam_ung": "Services to be paid",
    "vien_phi_dich_vu_can_thanh_toan": "Total amount",
    "vien_phi_thanh_tien": "Total amount",
    "vien_phi_mien_giam_chi_phi": "Cost exemption",
    "vien_phi_tong_chi_phi": "Total cost",
    "vien_phi_mien_giam": "Exemption",
    "vien_phi_tong_thanh_toan": "Total payment",
    "vien_phi_Hoan_tien_va_in": "Refund and print",
    "vien_phi_tong_tien_benh_nhan_dua": "Customer gives",
    "vien_phi_tien_tam_ung": "Advance payment",
    "vien_phi_tien_thua": "Excess money",
    "vien_phi_so_hoa_don": "Cash receipt",
    "vien_phi_thanh_toan_va_in": "Payment and print",
    "vien_phi_thanh_toan": "Payment",
    "vien_phi_Tao_tam_ung": "Create advance",
    "vien_phi_Hoan_ung": "Refund",
    "vien_phi_Tao": "Create",
    "vien_phi_Don_gia": "Unit price",
    "vien_phi_warning_khong_the_bo_chon_tat_ca": "Cannot select all",
    "status_Dang_kham": "Being examined",
    "status_Hoan_thanh": "Completed",
    "vien_phi_BN_phai_tra": "Customer must pay",
    "vien_phi_tien_hoan_ung": "Refund",
    "vien_phi_Hoan_tien": "Refund money",
    "noti_vien_phi_ly_do_hoan_tien": "Please enter reason for refund",
    "noti_so_hoa_don": "Please select invoice book",
    "field_Yeu_cau_hoi_chan": "Request for consultation",
    "field_Thong_tin_nguoi_su_dung": "User information",
    "field_Chu_ky_so": "Digital signature",
    "field_Anh_chu_ky_scan": "Scanned signature image",
    "field_Khoa_lam_viec": "Department of work",
    "field_Phong_lam_viec": "Office",
    "field_Ket_qua_tim_kiem": "Search results",
    // "field_Kham_benh": "Medical examination",
    "field_dich_vu_kt": "Technical service",
    "field_Dich_vu": "Service",
    "field_Lua_chon_dich_vu": "Select service",
    "field_Nhom_chi_phi_bhyt": "Health insurance cost group",
    "field_Nhom_danh_muc": "Group of categories",
    "Ban_chua_chon_benh_nhan_nao": "You have not selected any customers. Please select a customer to start examining",
    "data_Tuyen_trung_uong": "Central route",
    "data_Tuyen_tinh": "Provincial route",
    "data_Tuyen_huyen": "District route",
    "data_Tuyen_xa": "Commune route",
    "data_Khong_phan_tuyen": "Non-routed",
    "data_Hang_dac_biet": "Special rank",
    "data_Hang": "Rank",
    "data_Khong_xep_hang": "Unranked",
    "data_Kham_benh": "Examination",
    "data_Kham_cls": "Near-floor examination",
    "data_Noi_khoa": "Internal medicine",
    "data_Duoc": "Pharmacy",
    "data_Tai_chinh": "Finance",
    "data_Xet_nghiem": "Testing",
    "data_thu_thuat": "Surgery",
    "thu_thuat_dac_biet": "Special surgery",
    "thu_thuat_loai_1": "Trick type 1",
    "thu_thuat_loai_2": "Trick type 2",
    "thu_thuat_loai_3": "Trick type 3",
    "error_gioi_han_ky_tu_sdt": "Do not enter more than 11 characters",
    "error_gioi_han_ky_tu_sdt_min": "Do not enter less than 10 characters",
    "error_gioi_han_ky_tu_mst": "Do not enter more than 14 characters",
    "error_zero_only": "Cannot only enter the character 0",
    "data_CDHA": "Imaging diagnosis",
    "data_Khac": "Other",
    "data_Vien_phi": "Medical fee",
    "data_Bhyt": "Health insurance",
    "data_Lay_mau": "Sampling",
    "data_Nha_thuoc": "Pharmacy",
    "data_Kho_bhyt": "Health insurance warehouse",
    "data_Ngay": "Day",
    "data_Tuan": "Week",
    "data_Thang": "Month",
    "data_Nam": "Year",
    "data_Kho_thuoc_tu_nguyen": "Voluntary drug warehouse",
    "data_Kho_thuoc_bhyt": "Health insurance drug warehouse",
    "data_Kho_chan": "Even warehouse",
    "data_Kho_le": "Odd warehouse",
    "data_Tu_truc": "Wardrobe",
    "data_Noi_tru": "Inpatient",
    "data_Ngoai_tru": "Outpatient",
    "data_Noi_tru_Ngoai_tru": "Inpatient and Outpatient",
    "data_buong_dieu_tri": "Treatment",
    "data_Tiep_tan": "Receptionist",
    "data_Nguoi_lay_mau": "Sampler",
    "field_Nguoi_tiep_don": "Receptionist",
    "data_Giao_su": "Professor",
    "data_Pho_giao_su": "Associate Professor",
    "data_Tien_si": "Doctorate",
    "data_Thac_si": "Master's degree",
    "data_Bs_chuyen_khoa": "Specialist Doctor",
    "data_Truong_khoa": "Head of Department",
    "data_Pho_khoa": "Deputy Head of Department",
    "data_Pho_giam_doc": "Deputy Director",
    "data_Giam_doc": "Director",
    "data_Bac_si": "Doctor",
    "data_Quyen": "Right",
    "field_Ma_dvt": "Unit of measure code",
    "field_Ten_dvt": "Unit of measure name",
    "Cai_dat_llv": "Set up work schedule",
    "Tuan_nay": "This week",
    "data_Thu_2": "Monday",
    "data_Thu_3": "Tuesday",
    "data_Thu_4": "Wednesday",
    "data_Thu_5": "Thursday",
    "data_Thu_6": "Friday",
    "data_Thu_7": "Saturday",
    "data_Chu_nhat": "Sunday",
    "field_Ca": "Shift",
    "field_Thoi_gian_lam_viec": "Working hours",
    "field_Tu": "From",
    "field_Den": "To",
    "field_Ngay_ap_dung": "Effective date",
    "field_Thoi_gian_lam_viec_mac_dinh": "Default working hours",
    "field_Benh_nhan_tiep_theo": "Next patient",
    "field_So_benh_nhan_kham_mac_dinh": "Default number of patients per time slot",
    "field_Tai_phong_kham": "At the clinic",
    "field_phut": "minutes",
    "field_Ngay_tao": "Creation date",
    "field_Nguoi_tao": "Creator",
    "field_Tong_chi_phi": "Total cost",
    "field_Mien_giam": "Discount",
    "field_hinh_thuc_thanh_toan": "Payment method",
    "In_hoa_don": "Invoices",
    "field_Ki_hieu": "Symbol",
    "field_Loai_so": "Type of book",
    "status_Moi": "New",
    "status_Gan_day": "Nearly full",
    "status_Da_day": "Full",
    "field_So_phieu": "Receipt code",
    "field_Ma_so": "Book code",
    "field_Ten_so": "Book name",
    "field_Tong_so_phieu": "Total number of vouchers",
    "field_Nguoi_su_dung": "User",
    "field_So_bat_dau": "Starting number",
    "field_So_ket_thuc": "Ending number",
    "field_Phan_quyen_nguoi_su_dung_so": "User permission",
    "noti_Chi_duoc_upload_anh": "Only image upload is allowed",
    "noti_Kich_thuoc_anh_be_hon_5mb": "Image size must be less than 5MB",
    "noti_Kich_thuoc_anh_be_hon_1mb": "Image size must be less than 1MB",
    "noti_Chi_duoc_upload_excel": "You can only upload .xlsx or .xls formats",
    "noti_Dang_nhap_thanh_cong": "Login successful",
    "noti_Dang_nhap_that_bai": "Login failed",
    "noti_Xoa_thanh_cong": "Delete successful",
    "noti_Xoa_that_bai": "Delete failed",
    "noti_Dang_xuat_danh_sach": "Exporting list",
    "noti_Xuat_danh_sach_thanh_cong": "Export list successful",
    "noti_Xuat_danh_sach_that_bai": "Export list failed",
    "noti_Nhap_du_lieu_thanh_cong": "Data entry successful",
    "noti_Nhap_du_lieu_that_bai": "Data entry failed",
    "noti_Xoa_danh_sach_thanh_cong": "Delete list successful",
    "noti_Xoa_danh_sach_that_bai": "Delete list failed",
    "noti_Khoa_danh_sach_thanh_cong": "Update list key successful",
    "noti_Khoa_danh_sach_that_bai": "Update list key failed",
    "noti_Thuoc_da_ton_tai": "Medicine already exists",
    "noti_Chua_chon_thoi_gian_y_lenh": "Not selected valid time or not added medicine",
    "noti_Chua_chon_ngay_tai_kham": "Not selected re-examination date",
    "noti_Chua_co_thong_tin_kham": "No examination information yet",
    "noti_Chua_chon_phong_thuc_hien": "Not selected performing room or service",
    "noti_Luu_lich_kham_thanh_cong": "Successfully save examination schedule",
    "noti_Luu_lich_kham_that_bai": "Failed to save examination schedule",
    "noti_Chua_chon_benh_nhan_dv_thanh_toan": "No customer or service selected for payment",
    "noti_Khong_the_huy_dich_vu_da_thanh_toan": "Cannot cancel paid service",
    "noti_Luu_cuoc_hen_thanh_cong": "Successfully save appointment",
    "noti_Luu_cuoc_hen_that_bai": "Failed to save appointment",
    "noti_lay_danh_sach_lich_kham_that_bai": "Retrieve list of failed examination schedules",
    "noti_vui_long_nhap_du_ngay_thang_nam": "Please enter complete date (day/month/year)",
    "noti_ten_dang_nhap_da_ton_tai": "Username already exists in the system",
    "noti_ma_so_da_ton_tai": "Book number already exists",
    "Dang_nhap": "Log in",
    "Dang_nhap_subtitle": "Log in to continue using H247 Pro services",
    "field_Ten_dang_nhap": "Username",
    "field_Nang_suat_kham": "Examination productivity",
    "field_Danh_sach_thuoc": "List of medicines",
    "field_lich_lam_viec": "Work schedule",
    "error_Chon_toi_thieu_1_ngay": "Please select at least 1 day",
    "error_Chua_co_ca_lam_viec": "Please add at least 1 work shift",
    "field_Don_thuoc_tu_nguyen": "Voluntary prescription",
    "field_Bac_si_ke_don": "Doctor's prescription",
    "button_In_don_thuoc": "Print prescription",
    "field_Loi_dan": "Instructions",
    "cf_Ban_co_chac_chan_xoa": "Are you sure you want to delete?",
    "noti_Chua_chon_dich_vu_kham_benh": "No medical examination service selected",
    "noti_Chua_chon_dich_vu_kham_benh_hoac_cdcls": "No medical examination or clinical service selected",
    "label_Tao_cuoc_hen_moi": "Create appointment",
    "error_view_Chua_co_don_thuoc": "You have not created any prescriptions. Create a new prescription now",
    "error_view_Khong_co_don_thuoc": "No prescription available",
    "field_Doi_tuong": "Subject",
    "field_Gio_kham": "Examination time",
    "title_cuoc_hen_sap_toi": "Upcoming appointments",
    "title_Tao_cuoc_hen": "Create appointment",
    "title_Chinh_sua_cuoc_hen": "Edit appointment",
    "title_Chinh_sua_thong_tin_tiep_don": "Edit reception information",
    "title_Xac_nhan": "Confirm",
    "title_Gia_dich_vu": "Service price",
    "title_phong_khám": "Clinic",
    "noti_Tiep_tuc_khi_chua_ke_don_thuoc": "Patient has not been prescribed medication. Are you sure you want to continue?",
    "noti_Dang_tai_du_lieu": "Initializing data",
    "noti_Tai_du_lieu_thanh_cong": "Data loaded successfully",
    "noti_Tai_du_lieu_that_bai": "Failed to load data",
    "noti_Dang_kiem_tra_trang_thai_don_thuoc": "Checking prescription status",
    // "noti_warning_thay_doi_thoi_gian_phong": "",
    // "    Hiện tại phòng đang có dịch vụ chưa hoàn thành, nếu thay đổi thời gian hoạt động của phòng cuộc hẹn vẫn sẽ được giữ nguyên": "",
    "noti_warning_thuoc": "The medicine",
    "noti_warning_thuoc_co_trong_don": "has been prescribed. Please double check",
    "noti_warning_thuoc_khong_du": "currently not enough medicine in stock. Please double check",
    "noti_Cuoc_hen_khong_phai_hom_nay": "The appointment is not taking place today.\\n Are you sure you want to confirm this appointment?",
    "noti_Dang_Tai_template": "Loading template",
    "noti_Tai_template_thanh_cong": "Template loaded successfully",
    "noti_Tai_template_that_bai": "Template loading failed",
    "common_Doi_ngon_ngu": "Language",
    "field_Ma_cuoc_kham": "Stock code",
    "chi_tiet_cuoc_hen": "Details of the appointment",
    "chinh_sua_cuoc_hen": "Edit appointment",
    "thong_tin_cuoc_kham": "Visit information",
    "thong_tin_kham_benh": "Medical information",
    "thong_tin": "Information",
    "the_kho": "Stock card",
    "xac_nhan_lich_hen": "Confirm appointment schedule",
    "lo_han_su_dung": "Batch - expiry date",
    "title_them_moi_nhom_thuoc": "New drug group added",
    "title_chinh_sua_nhom_thuoc": "Edit drug group",
    "error_Rang_buoc_ton_tai": "Cannot delete/lock this object as it may affect other objects",
    "common_Xuat_hang_cho": "Export pending goods",
    "xac_nhan_tiep_don": "Confirm reception",
    "xac_nhan_va_in_phieu_kham": "Confirm and print examination form",
    "title_Hang_cho_kham_benh": "Pending medical examination",
    "title_Hang_cho": "Pending goods",
    "error_Chua_chon_doi_tuong_benh_nhan": "No customer object selected",
    "error_Ngay_sinh_ko_hop_le": "Invalid date of birth",
    "error_Chua_chon_thong_tin_luot_dieu_tri": "Insufficient treatment information selected",
    "error_Khong_chon_ngay_khac_voi_ngay_truoc_do": "Do not select the same date as the previous one",
    "field_Trang_thai_thanh_toan": "Payment status",
    "field_Trang_thai_cuoc_hen": "Appointment status",
    "title_Phieu_thu_da_tao": "Receipt created",
    "error_Gia_tri_mien_giam_ko_hop_le": "Invalid discount value",
    "error_Khong_sua_duoc_lich_kham": "Cannot edit appointment schedule because an appointment already exists",
    "error_khong_the_khoa_phong_do_co_benh_nhan_cho_kham": "Cannot lock room because a customer has already booked an appointment",
    "thong_tin_dich_vu_cls": "Diagnostic imaging service information",
    "tab_Phieu_mua_thuoc": "Medication sales receipt",
    "tab_Lich_su_phieu": "Receipt history",
    "field_Ma_phieu": "Receipt code",
    "placeholder_Ma_tu_dong_tao": "Automatic code generation",
    "placeholder_Lua_chon_don_thuoc_mau": "Select sample prescription",
    "common_Thay_the": "Replace",
    "txt_Them_lo": "Add batch",
    "txt_Chon_lo": "Select batch",
    "noti_confirm_Huy_dich_vu": "Are you sure you want to cancel the service?",
    "field_Ly_do_hoan_tien": "Refund reason",
    "field_Tong_tien": "Total amount",
    "warning_url_notfound_title": "Access warning",
    "warning_url_notfound": "You are accessing an invalid page, please log in again to continue using it!",
    "warning_change_password": "Your password has been changed, please log in again to continue using it!",
    "field_Ly_do_mien_giam": "Exemption reason",
    "error_body__at_column": "In column",
    "error_body__and_duplicate_value": "And duplicate value",
    "da_chon": "Selected",
    "nha_cung_cap": "Supplier",
    "ma_nha_cung_cap": "Supplier code",
    "ten_nha_cung_cap": "Supplier name",
    "dien_thoai": "Phone",
    "tong_mua": "Total purchase",
    "no_can_tra": "Debt",
    "nhom_ncc": "Supplier group",
    "tat_ca_cac_nhom": "All groups",
    "tu": "From",
    "toi": "To",
    "no_hien_tai": "Current debt",
    "tat_ca": "All",
    "dang_hoat_dong": "Active",
    "khong_hoat_dong": "Inactive",
    "trang_thai": "Status",
    "them_nha_cung_cap": "Add supplier",
    "thong_tin_co_so": "Information",
    "dia_chi": "Address",
    "cong_ty": "Company",
    "ma_so_thue": "Tax ID",
    "tao_moi": "Create new",
    "huy": "Exit",
    "thong_tin_chi_tiet": "Details",
    "thong_tin_camel": "INFORMATION",
    "lich_su_nhap_tra_hang_camel": "IMPORT/RETURN HISTORY",
    "quan_ly_cong_no_camel": "DEBT MANAGEMENT",
    "xoa": "Delete",
    "luu": "Save",
    "ma_phieu": "Code",
    "nguoi_tao": "Creator",
    "loai_phieu": "Type",
    "tong_tien": "Total amount",
    "nhap_gia_tri": "Enter value",
    "gia_tri": "Value",
    "cong_no": "Debt",
    "dieu_chinh": "Adjustment",
    "xuat_file_cong_no": "Export debt file",
    "in_file_cong_no": "Print debt file",
    "no_can_tra_hien_tai": "Current payable debt",
    "cai_dat_ngay_gio": "Date and time settings",
    "ngay_dieu_chinh": "Adjustment date",
    "gia_tri_no_dieu_chinh": "Adjusted debt value",
    "mo_ta": "Description",
    "cap_nhat": "Update",
    "tien_mat": "Cash",
    "chuyen_khoan": "Bank transfer",
    "no_sau": "Remaining debt",
    "tao_phieu_chi": "Create payment voucher",
    "tao_phieu_chi_va_in": "Create payment voucher & print",
    "tra_cho_ncc": "Pay to supplier",
    "hay_chon_ngay": "Please select a date",
    "da_nhap_hang": "Goods received",
    "da_tra_hang": "Goods returned",
    "hay_chon_nhom": "Select group",
    "hay_dien_ma": "Fill in code",
    "hay_dien_ten": "Fill in name",
    "hay_nhap_so_dien_thoai": "Enter phone number",
    "hay_dien_email": "Fill in email",
    "hay_dien_dia_chi": "Fill in address",
    "hay_dien_ten_cong_ty": "Fill in company name",
    "nhap_ten": "Enter name",
    "nhap_dia_chi": "Enter address",
    "doanh_thu_trung_binh": "Average revenue",
    "doanh_thu_trung_binh_moi_cuoc_kham": "Average revenue per visit",
    "cuoc_kham": "Visits",
    "trung_binh_cuoc_kham": "Average visits",
    "so_voi_ky_truoc": "compared to previous period",
    "tuoi": "age",
    "so_benh_nhan_trong_ky": "Number of customers in the period",
    "so_benh_nhan_ky_truoc": "Number of customers in the previous period",
    "so_luong_benh_nhan_theo_thoi_gian": "Number of customers over time",
    "benh_nhan": "Patients",
    "tong_quan_benh_nhan": "Overall customers",
    "ti_le_benh_nhan_theo_gioi_tinh": "Customer gender ratio",
    "ti_le_benh_nhan_theo_do_tuoi": "Customer age ratio",
    // "don_vi_trieu_dong": "One million Vietnamese dong",
    "thoi_gian": "Time",
    "tong_quan_dich_vu": "Total service revenue",
    "tong_so_cuoc_kham": "Total number of visits",
    "tong_so_benh_nhan": "Total number of customers",
    "tong_quan_kinh_doanh": "Overall business summary",
    "tong_quan_tiep_don_kham_chua_benh": "Overall reception and treatment summary",
    "field_Gia_nhap": "Purchase price",
    "field_Gia_ban": "Selling price",
    "chon_phong": "Select room",
    "loai": "Type",
    "phong": "Room",
    "thuoc": "Medicine",
    "doanh_thu_theo_thoi_gian": "Revenue by time",
    "tong_doanh_thu": "Total revenue",
    "dich_vu_cls": "CLS service",
    "truc_tiep": "Directly",
    "qua_app": "Via App",
    "qua_website": "Via Website",
    "qua_zalo": "Via Zalo",
    "ti_le_cuoc_kham_ung_voi_nguon_tiep_nhan": "Conversion rate of visits to the source of reception",
    "so_cuoc_kham_va_so_dich_vu_thoi_gian_ung_voi_nguon_tiep_nhan": "Number of visits and services corresponding to the source of reception",
    "ky_nay": "This period",
    "ky_truoc": "Last period",
    "noti_tao_moi_don_thuoc_thanh_cong": "Successfully create new prescription",
    "noti_tao_moi_benh_nhan_thanh_cong": "Successfully create new customer",
    "noti_tao_moi_benh_nhan_that_bai": "Failed to create new customer",
    "noti_them_vao_hang_cho_ban_thuoc_thanh_cong": "Successfully add medicine to queue for sale",
    "noti_them_vao_hang_cho_ban_thuoc_that_bai": "Failed to add medicine to queue for sale",
    "noti_Tao_phieu_thanh_cong": "Successfully create voucher!",
    "noti_Tao_phieu_that_bai": "Failed to create voucher!",
    "field_chinh_sua_phieu": "Edit voucher",
    "field_chon_phieu_mau": "Choose sample import voucher",
    "field_chon_loai_phieu_mau": "Choose sample voucher",
    "noti_lay_chi_tiet_phieu_mau_that_bai": "Failed to get voucher details!",
    "txt_can_tra_ncc": "Need to pay Supplier",
    "txt_luu_phieu_mau": "Save sample voucher",
    "txt_ten_phieu_mau": "Sample voucher name",
    "txt_tien_tra_ncc": "Money to pay Supplier",
    "txt_tinh_vao_cong_no": "Include in debt",
    "title_phieu_xuat_ban": "Drug sales invoice",
    "title_phieu_xuat_tra": "Supplier return invoice",
    "title_phieu_xuat_huy": "Cancellation invoice",
    "txt_tong_gia_tri_huy": "Total cancellation value",
    "txt_tien_ncc_tra": "Supplier payment",
    "txt_ncc_phai_tra": "Supplier payable",
    "noti_them_thuoc_that_bai": "Failed to add drug",
    "    Ca khám hiện tại đã HOÀN THÀNH. Bạn có chắc chắn muốn chuyển trạng thái lượt khám về ĐANG KHÁM?": "The examination have finished already, do you want to change the status to IN PROGRESS",
    "confirm_Thay_doi_kho_thuoc": "The list of selected medications will be cleared if the pharmacy location is changed",
    "field_Ton": "Are you sure you want to continue?",
    "confirm_Chuyen_cuoc_kham_sang_hoan_thanh": "The examination has been prescribed medication, do you want to confirm the end of the examination?",
    "title_noi_tru": "The prescription has been created for the clinic visit, do you want to confirm the end of the clinic visit?",
    "dm_Giuong": "Inpatient",
    "dm_giuong_ma_giuong": "Bed category",
    "dm_giuong_ma_giuong_bhyt": "Bed code",
    "dm_giuong_ten_giuong": "BHYT bed code",
    "confirm_xoa_don_thuoc": "Bed name",
    "cai_dat_hien_thi": "Are you sure you want to delete this prescription?",
    "field_do_tuoi": "Display settings",
    "field_ngay_kham_gan_nhat": "Age",
    "field_nho_hon_1_tuoi": "Last visit date",
    "field_1_11_tuoi": "< 1 year old",
    "field_12_17_tuoi": "1 - 11 years old",
    "field_18_64_tuoi": "12 - 17 years old",
    "field_lon_hon_64_tuoi": "18 - 64 years old",
    "bao_hiem_y_te": "> 64 years old",
    "danh_sach_lich_su_kham_benh": "History of medical examination",
    "field_chuan_doan_benh_chinh": "Main diagnosis",
    "field_cskcb": "Healthcare facility code",
    "field_khoa_theo_byt": "Department according to MOH",
    "field_co_so_kham_chua_benh": "Medical facility",
    "field_ngay_tai_kham": "Re-examination date",
    "danh_sach_phieu_thu": "List of receipts",
    "field_loai_phieu_thu": "Receipt type",
    "field_phan_loai": "Classification",
    "field_hoan_tien": "Refund",
    "title_Bao_cao_nhanh": "Quick report",
    "title_Danh_sach_phieu_thu": "List of receipts",
    "tab_Bao_cao_phieu_thu": "Receipt report",
    "tab_Bao_cao_vien_phi": "Hospital fee report",
    "tab_Bao_cao_doanh_thu": "Revenue report",
    "tab_Bao_cao_tiep_don": "Reception report",
    "title_Thu_phi": "Fee collection",
    "tag_Ban_thuoc": "Medicine sales",
    "button_Cai_dat_lai": "Reset",
    "button_Xuat_excel": "Export to Excel",
    "button_In_bao_cao": "Print report",
    "label_Thoi_gian": "Time",
    "label_Loai_phieu": "Receipt type",
    "option_Tat_ca": "All",
    "bao_cao_duoc": "Drug report",
    "txt_ngay_gan_nhat": "Nearest date",
    "txt_thang_gan_nhat": "Nearest month",
    "txt_tuy_chon": "Options",
    "loai_phieu_nhap": "Stock receipt",
    "loai_phieu_xuat": "Stock issue voucher",
    "loai_phieu_ban_le": "Retail sales voucher",
    "loai_phieu_dieu_chinh": "Adjustment voucher",
    "loai_phieu_thanh_toan": "Payment voucher",
    "loai_phieu_xuat_huy": "Cancel issue voucher",
    "loai_phieu_xuat_tra_ncc": "Return to supplier voucher",
    "loai_phieu_xuat_ban": "Selling issue voucher",
    "noti_chua_nhap_lo_thuoc": "You have not entered a drug batch!",
    "noti_so_luong_thuoc_toi_da": "The drug quantity has reached the maximum level",
    "noti_lo_khong_con_thuoc": "The batch is out of stock",
    "noti_lo_da_ton_tai": "The batch already exists",
    "noti_chua_co_phieu_ban_thuoc": "You have not created any drug sales vouchers. Please create a drug sales voucher now",
    "title_add": "Add",
    "noti_chua_chon_benh_nhan_ban_thuoc": "You have not selected any prescription to sell!",
    "error_Thoi_gian_khong_hop_le": "Invalid time",
    "tao_moi_benh_nhan": "Create new customer",
    "tab_Phieu_thanh_toan": "Payment voucher",
    "tab_Phieu_hoan_tien": "Refund voucher",
    "cf_Ban_co_chac_chan_khoa": "Are you sure you want to lock?",
    "cf_Ban_co_chac_chan_mo_khoa": "Are you sure you want to unlock?",
    "ma_bn": "Customer code",
    "ma_lk": "Service code",
    "chon_khoa": "Select department",
    "loc_BN": "Filter customers",
    "in_bao_cao": "Print report",
    "dich_vu": "Services",
    "thuoc_vat_tu_mau": "Medicine, supplies, blood",
    "dieu_duong": "Nursing",
    "dieu_tri": "Treatment",
    "xet_nghiem": "Testing",
    "cdha": "Radilogy",
    "pttt": "Minor surgery",
    "thuoc_vat_tu": "Medicine, supplies",
    "dich_vu_khac": "Other services",
    "xu_tri": "Processing",
    "ngay_chi_dinh": "Appointment date",
    "ngay_y_lenh": "Prescription date",
    "noi_chi_dinh": "Designated location",
    "ngay_ket_qua": "Result date",
    "noi_thuc_hien": "Location of performance",
    "ten_chi_so": "Index name",
    "tham_chieu": "Reference",
    "ten_PTTT": "Minor surgery name",
    "loai_PTTT": "Minor surgery type",
    "ten_thuoc_vat_tu": "Medication, supplies",
    "sl": "Quantity",
    "HDSD": "Instructions for use",
    "ngay_vao_giuong": "Admission date",
    "ngay_ra_giuong": "Discharge date",
    "chon_hinh_thuc": "Select form",
    "nhap_PTTT": "Enter PTTT",
    "bat_dau_luc": "Start time",
    "ket_thuc_luc": "End time",
    "chan_doan_truoc_PT": "Preoperative diagnosis",
    "chan_doan_sau_PT": "Postoperative diagnosis",
    "cach_thuc_PT": "Surgical procedure",
    "phuong_phap_PT": "Surgical method",
    "phuong_phap_vo_cam": "Anesthesia method",
    "mo_ta_chi_tiet": "Detailed description",
    "nhom_mau": "Blood group",
    "tai_bien_PTTT": "Minor surgery catastrophe",
    "hinh_anh_PTTT": "Minor surgery image",
    "in_tuong_trinh": "In wall statement",
    "ho_va_ten": "Full name",
    "ket_luan": "Conclusion",
    "chi_tiet": "Details",
    "loai_giuong": "Bed type",
    "giuong": "Bed",
    "benh_kem_theo": "Accompanying disease",
    "benh_chinh": "Main disease",
    "chan_doan": "Diagnosis",
    "da_thuc_hien": "Completed",
    "dang_thuc_hien": "In progress",
    "chua_thuc_hien": "Not yet implemented",
    "dieu_tri_noi_tru": "Inpatient treatment",
    "chuyen_khoa": "Transfer",
    "cach_thuc_pttt": "Surgical procedure",
    "phuong_phap_pttt": "Surgical method",
    "field_Gia_thang_du": "Excess charge",
    "rh_am": "Negative",
    "rh_duong": "Positive",
    "tren_ban_mo": "On the operating table",
    "trong_24h": "Within 24 hours",
    "sau_24h": "After 24 hours",
    "do_phau_thuat": "Due to surgery",
    "do_thu_thuat": "Warehouse",
    "do_gay_me": "Anesthesia",
    "do_nhiem_khuan": "Infection",
    "loai_giuong_thuong": "Regular bed",
    "loai_giuong_ghep_2": "Double bed",
    "loai_giuong_ghep_3": "Triple bed",
    "txt_them_moi_kho_thuoc": "Add new warehouse",
    "field_thoi_gian_tao": "Creation time",
    "con_ton": "Remaining",
    "da_het": "Out of stock",
    "data_Thu_ngan": "Cashier",
    "data_Ky_thuat_vien": "Technician",
    "data_Dieu_duong": "Nurse",
    "data_Thu_kho": "Warehouse keeper",
    "data_Quan_tri": "Administration",
    "field_Nhom_nhan_vien": "Staff group",
    "txt_them_thuoc": "Add medicine",
    "txt_luu_thuoc": "Save medicine",
    "txt_chon_kho": "Select warehouse",
    "txt_chon_thuoc": "Select medicine",
    "txt_ds_thuoc_chi_dinh": "List of selected medicines",
    "txt_thanh_toan": "Payment",
    "txt_tong_tien_truoc_vat": "Total amount before VAT",
    "txt_tong_tien_vat": "Total VAT amount",
    "txt_tong_tien_sau_vat": "Total amount after VAT",
    "txt_chua_ban": "Not sold",
    "txt_da_ban": "Sold",
    "txt_duyet_kho": "Approve warehouse",
    "txt_ngay_ke_don": "Prescription date",
    "txt_luu_mau_don_thuoc": "Save prescription template",
    "txt_ma_mau_don_thuoc": "Order code",
    "txt_ten_mau_don_thuoc": "Order name",
    "txt_placeholder_chon_don_thuoc_mau": "Select prescription template",
    "txt_luu_mau_thong_tin": "Save information template",
    "menu_Nha_cung_cap": "Supplier",
    "So_luong_ton_kho": "Inventory quantity",
    "ton_kho": "Inventory",
    "Phieu_nhap_kho": "Warehouse receipt",
    "Phieu_xuat_kho": "Warehouse delivery note",
    "Van_chuyen_kho": "Warehouse transportation",
    "The_kho": "Warehouse card",
    "Quyen_duyet_phieu": "Approval rights for vouchers",
    "khong_duoc_phep_xoa_lich_kham": "You cannot delete a paid appointment or an appointment with a different status",
    "khong_duoc_phep_xoa_phieu": "Cannot delete a completed voucher!",
    "thu_2": "Monday",
    "thu_3": "Tuesday",
    "thu_4": "Wednesday",
    "thu_5": "Thursday",
    "thu_6": "Friday",
    "thu_7": "Saturday",
    "chu_nhat": "Sunday",
    "error_Rang_buoc_khoa_ton_tai": "You are not allowed to lock this category as it may affect other categories",
    "so_hoa_don_da_day": "Cash book is full",
    "title_phieu_nhap_ncc": "Supplier invoice",
    "title_them_phieu_nhap_ncc": "Add new invoice from supplier",
    "fieldMa_hoa_don": "Invoice code",
    "placeholder_Tao_tu_dong": "Auto generate",
    "info_Chi_xem_va_khong_duoc_thay_doi": "View only",
    "ma_phieu_bi_trung": "Duplicate voucher code",
    "bac_si_chua_co_ma_cchn": "Please update professional certificate",
    "nhap_loi_dan": "Enter instructions",
    "ris_nhap_tay": "RIS",
    "da_nghi": "Already rested",
    "chua_co_phong_thuc_hien": "Not yet implemented room",
    "lich_su_nhap_thuoc": "Drug history",
    "gia_co_vat": "Price after VAT",
    "loai_thuoc": "Drug type",
    "them_moi_kho": "Add new warehouse",
    "kho_chuyen": "Transfer warehouse",
    "duyet_khi_ke_thuoc": "Approve when prescribing drugs",
    "duyet_khi_chuyen_cac_kho": "Approve when transferring warehouse",
    "kho_le": "Retail warehouse",
    "kho_tong": "Total warehouse",
    "nha_thuoc": "Pharmacy",
    "benh_nhan_bhyt": "Health insurance patient",
    "benh_nhan_noi_tru": "Inpatient",
    "benh_nhan_ngoai_tru": "Outpatient",
    "nhap_tu_ncc": "Import from supplier",
    "nhap_tu_kho_khac": "Import from other warehouse",
    "xuat_vo_hong": "Damaged, expired",
    "xuat_het_han_su_dung": "Expired",
    "xuat_khac": "Other exports",
    "ban_benh_nhan": "Sell to customers",
    "tao_phieu": "Create voucher",
    "them": "Add",
    "thong_tin_thuoc": "Drug information",
    "so_benh_nhan": "Medical examination number",
    "thong_tin_kham_nhanh": "Quick examination information",
    "field_Nam_sinh": "Birth year",
    "danh_sach_kho_thuoc": "Drug warehouse category",
    "chi_tiet_kho": "Warehouse details",
    "tip_xoa_nhieu": "Delete multiple",
    "tip_xoa_ban_ghi": "Delete record",
    "tip_sua_ban_ghi": "Edit record",
    "tip_in_ban_ghi": "Print record",
    "tip_copy_ban_ghi": "Copy record",
    "tip_tai_len": "Upload record",
    "tip_tai_xuong": "Download record",
    "tip_tai_xuong_ban_mau": "Download sample record",
    "tip_trang_ke_tiep": "Next page",
    "tip_trang_sau": "Previous page",
    "tip_tai_len_hinh_anh": "Upload image",
    "phan_quyen_nguoi_ban": "Seller permission",
    "cho_phep_ban_gia_thang_du": "Sell at a premium",
    "huy_duyet_phieu": "Cancel voucher approval",
    "huy_phieu": "Cancel voucher",
    "confirm_cancel_ticket": "Are you sure you want to cancel the voucher?",
    "luu_va_duyet": "Save and approve",
    "tong_tt": "Total amount",
    "duyet": "Approve",
    "da_HT": "Completed",
    "phieu_nhap": "Receipt",
    "phieu_chuyen_kho": "Transfer note",
    "field_Ma_may": "Machine code",
    "mau_mo_ta_chan_doan_hinh_anh": "Diagnostic imaging description form",
    "phan_loai_xuat_tra_ncc": "Return to supplier",
    "phan_loai_xuat_vo_hong": "Export broken or damaged",
    "phan_loai_xuat_huy": "Export scrapped",
    "phan_loai_ban_benh_nhan": "Sell to customer",
    "phan_loai_xuat_khac": "Other export",
    "phan_loai_xuat_het_han": "Expired date export",
    "common_Them": "Add",
    "khoa": "Department",
    "cho_duyet": "Wait for approval",
    "danh_sach_thuoc_chi_dinh": "List of prescribed drugs",
    "dm_may": "performing machine",
    "title_page_tra_kq_cdhd": "CĐHA results",
    "cho_TH": "Waiting",
    "dang_TH": "Performing",
    "da_TH": "Performed",
    "chup_anh": "Take a photo",
    "ngay_tra_kq": "Date of result return",
    "nguoi_thuc_hien": "Performer",
    "nguoi_tra_kq": "Result returner",
    "thoi_gian_tra": "Return time",
    // "in_kq": "Print result",
    "huy_tra": "Cancel return",
    "tra_ket_qua": "Return result",
    "may_thuc_hien": "Performing machine",
    "thuc_hien": "Perform",
    "da_tra_kq": "Completed result",
    "da_KQ": "Completed",
    "xem_KQ": "View Result",
    "content_huy_tra_phieu": "Are you sure you want to cancel returning the result of this form?",
    "tra_ket_qua_va_in": "Return Result and Print",
    "chon_mau_mo_ta": "Select Description Template",
    "chon_may_thuc_hien": "Select Execution Machine",
    "noti_vui_long_chon_may_thuc_hien": "Please select the execution machine",
    "noti_vui_long_chon_phong_thuc_hien": "Please select the execution room",
    "noti_so_luong_anh_toi_da": "Maximum number of images",
    "noti_tra_kq_thanh_cong": "Successfully returned result",
    "noti_tra_kq_that_bai": "Failed to return result",
    "title_form_nhap_ket_qua": "Result Input Form",
    "gui_cau_hoi": "Ask a question",
    "nhap_noi_dung": "Enter content",
    "gui": "Send",
    "cau_hoi_cua_toi": "My question",
    "danh_sach_cau_hoi_thuong_gap": "Frequently Asked Questions",
    "phan_quyen_tao_mau_mo_ta": "Create Description Template",
    "phan_quyen_sua_mau_mo_ta": "Edit Description Template",
    "phan_quyen_xoa_mau_mo_ta": "Delete Description Template",
    "phan_quyen_khoa_mau_mo_ta": "Lock Description Template",
    "phan_quyen_tra_ket_qua": "Complete the task",
    "phan_quyen_huy_phieu": "Cancel the vote",
    "chen_anh": "Insert image",
    "so_hoan_tien": "Refund book",
    "noi_dung_phan_hoi": "Feedback content",
    "nhap_noi_dung_phan_hoi": "Provide more detailed information about your questions / issues",
    "cham_soc_khach_hang_context": "We want to hear your questions and feedback. Please let us know your issues!",
    "cham_soc_khach_hang": "Customer care",
    "huong_dan_su_dung_he_thong": "User guide",
    "tai_pdf": "Download PDF",
    "hoi_dap": "FAQ",
    "cong_cu_ho_tro": "Support tool",
    "bc_da_thuc_hien": "Done",
    "bc_chua_thuc_hien": "Not done",
    "trang_thai_thuc_hien": "Implementation status",
    "xac_nhan_cuoc_hen_thanh_cong": "Successful appointment confirmation",
    "field_Ten_may": "Machine name",
    "field_Ma_BHYT": "Health insurance code",
    "field_Loai_may": "Type of machine",
    "title_Danh_sach_nguoi_dung": "User list",
    "menu_mau_mo_ta_cdha": "Description template",
    "menu_tra_kq_cdha": "Return result",
    "bao_cao_cdha": "Report",
    "huy_tra_phieu": "Cancel voucher return",
    "title_Danh_sach_mau_mo_ta": "List of description templates",
    "title_Tao_mau_mo_ta": "Create image diagnosis description template",
    "mau_danh_muc": "Category template",
    "field_ma_kq_dv": "Service result code",
    "field_ten_kq_dv": "Service result name",
    "chon_nhom_dv": "Select service group",
    "chon_loai_dv": "Select service type",
    "chon_dv": "Select Service",
    "btn_mo_file_mo_ta": "Open description file",
    "placeholder_nhap_mo_ta": "Enter description",
    "field_ket_luan": "Conclusion",
    "tu_dong": "Automatically",
    "field_mo_ta": "Description",
    "field_may_thuc_hien": "Machine performs",
    "warning_chon_nhom_dv": "Please select service group",
    "warning_chon_loai_dv": "Please select service type",
    "btn_xem_them": "See more",
    "btn_chen_anh": "Insert image",
    "btn_chup_anh": "Take photo",
    "common_Ngay_mai": "Tomorrow",
    "last_7_days": "Last 7 days",
    "last_30_days": "Last 30 days",
    "other_option": "Other options",
    "tong_tra": "Total paid",
    "title_Danh_sach_thuoc_nhap": "List of imported drugs",
    "khambenh_7ngay_gan_nhat": "Last 7 days",
    "khambenh_30ngay_gan_nhat": "Last 30 days",
    "khambenh_xemthem": "See more",
    "khambenh_phieu_so": "Receipt number",
    "khambenh_err_xoa_dich_vu": "Cannot delete paid or performed services",
    "khambenh_du_kq": "Enough Results",
    "khambenh_cho_kq": "Waiting for Results",
    "khambenh_xoa_ban_ghi": "Delete record",
    "khambenh_confirm_xoa": "Are you sure you want to delete this service?",
    "khambenh_moi_ngay": "Every day",
    "khambenh_chia_lam": "Divide into",
    "khambenh_lan": "Time",
    "khambenh_so_luong_thuoc_lon_hon_0": "The quantity of medicine must be greater than 0",
    "khambenh_so_luong_thuoc_khong_du": "Insufficient quantity of medicine in stock",
    "khambenh_toi_da": "Maximum",
    "khambenh_thuoc": "Medicine",
    "khambenh_chua_dien_cach_dung": "Quantity or usage instructions not provided",
    "khambenh_thuoc_da_het": "Medicine is out of stock",
    "khambenh_sl_toida": "Maximum quantity",
    "khambenh_empty": "Empty",
    "khambenh_khong_huy_don_da_ban": "Unable to cancel approved prescription",
    "khambenh_khong_sua_don_da_ban": "Unable to edit approved prescription",
    "khambenh_cho_thanh_toan": "Waiting for payment",
    "khambenh_da_thanh_toan": "Fully paid",
    "khambenh_homqua": "Yesterday",
    "vienphi_dv_khong_thanh_toan": "Service cannot be paid because it has been performed",
    "vienphi_hoan_tien_dich_vu": "Service refund",
    "vienphi_ds_dv_can_hoan_tien": "List of services to be refunded",
    "vienphi_tong_mien_giam": "Total discount",
    "vienphi_da_thanh_toan": "Paid",
    "vienphi_da_thuc_hien": "Performed",
    "vienphi_chua_thuc_hien": "Not performed",
    "vienphi_hoan_tien": "Refund",
    "vienphi_xoa_dich_vu": "Delete service",
    "vienphi_so_hd_day": "Invoice book is full",
    "vienphi_tong_tt": "Total payment",
    "vienphi_vui_long_chon_so_tt": "Please select the cash receipt book!",
    "vienphi_no_service": "No service",
    "vienphi_dv_da_tt": "Service has been paid",
    "vienphi_chon_so_hoan_tien": "Please select the refund book!",
    "vienphi_da_hoan_tien": "Refunded",
    "vienphi_ds_cac_dichvu": "List of services",
    "vienphi_in_phieu_thu": "Print receipt",
    "vienphi_chua_thanh_toan": "Not paid",
    "vienphi_da_tt": "Paid",
    "vienphi_ngay": "Date",
    "vienphi_ghi_chu": "Note",
    "tiepdon_nguoi_sua": "Editor",
    "tiepdon_ngay_sua": "Date edited",
    "tiepdon_nhap_lien_tuc": "Continuous input",
    "tiepdon_chia_se_thong_tin": "Share health profile",
    "tiepdon_khong_xoa_dv_da_tt": "Cannot delete paid service!",
    "tiepdon_chi_chon_1_ngay": "Only one appointment date can be selected per appointment!",
    "tiepdon_xoa_cuoc_hen": "Delete appointment",
    "tiepdon_huy_cuoc_hen": "Cancel appointment",
    "tiepdon_chinh_sua_cuoc_hen": "Edit appointment",
    "tiepdon_cho_xac_nhan": "WAITING FOR CONFIRMATION",
    "tiepdon_da_xac_nhan": "CONFIRMED",
    "tiepdon_da_huy": "CANCELLED",
    "tiepdon_xac_nhan_xoa_cuoc_kham": "Are you sure you want to delete this appointment?",
    "tiepdon_xac_nhan_xoa_ck_da_chon": "Are you sure you want to delete the selected appointment?",
    "tiepdon_chua_chon_phong": "No room selected!",
    "tiepdon_doi_cuoc_hen_thanh_cong": "Appointment change successful!",
    "tiepdon_doi_cuoc_hen_that_bai": "Appointment change failed!",
    "tiepdon_in_dich_vu": "Print service",
    "tiepdon_da_nghi": "On leave",
    "tiepdon_khong_hoat_dong": "not active",
    "tiepdon_da_kham": "Canceled",
    "tiepdon_xoa_cuoc_kham": "Delete appointments",
    "tiepdon_xac_nhan_huy_ck": "Are you sure you want to cancel these appointments?",
    "tiepdon_in_cuoc_kham": "Print appointments",
    "tiepdon_sua_cuoc_kham": "Edit appointments",
    "tiepdon_xac_nhan_huy_cuoc_kham": "Are you sure you want to cancel this appointment?",
    "tiepdon_xac_nhan_huy_lich_hen": "Are you sure you want to cancel this schedule?",
    "duoc_message_chua_chon_loai_kho": "No warehouse type selected",
    "duoc_chon_nguoi_ban": "Select seller",
    "duoc_ton_kha_dung": "Available stock",
    "duoc_nguoi_huy": "Canceled by",
    "duoc_ngay_huy": "Cancellation date",
    "duoc_thuoc": "Medicine",
    "duoc_message_lay_ds_kho_thuoc_loi": "Failed to retrieve medicine warehouse list",
    "duoc_xoa_nha_cung_cap": "Delete supplier",
    "sua_nha_cung_cap": "Edit supplier",
    "confirm_xoa_nha_cung_cap": "Are you sure you want to delete this supplier?",
    "duoc_hay_nhap_gia_tri_lon_hon_0": "Please enter a value greater than 0",
    "duoc_phieu_dieu_chinh_chi_tiet": "Adjustment details form",
    "duoc_phieu_thanh_toan_chi_tiet": "Payment detail voucher",
    "duoc_ma_ncc_trung": "Supplier code duplicated",
    "duoc_ma_so_thue_trung": "Tax code duplicated",
    "duoc_them_moi_nhom_ncc": "Add new supplier group",
    "duoc_ten_nhom_ncc": "Supplier group name",
    "duoc_nhap_gia_tri_nho_toi_lon": "Please enter values from small to large",
    "da_xac_nhan": "Confirmed",
    "duoc_chua_chon_kho_thuoc": "Warehouse not selected",
    "duoc_so_luong_toi_da": "Maximum quantity",
    "duoc_so_luong_lon_hon_ton_kha_dung": "Quantity greater than available stock",
    "duoc_thuoc_da_ton_tai": "Drug already exists",
    "duoc_so_luong_thuoc_khong_kha_dung": "Quantity of drug unavailable",
    "duoc_chi_tiet_phieu_chuyen_kho": "Warehouse transfer voucher detail",
    "duoc_them_phieu_chuyen_kho": "Add new warehouse transfer voucher",
    "duoc_kho_xuat_thuoc": "Drug export warehouse",
    "duoc_kho_nhap_thuoc": "Drug import warehouse",
    "duoc_chua_chon_kho_xuat": "Export warehouse not selected",
    "duoc_xoa_thuoc": "Delete drug",
    "duoc_gia_nhap_lon_hon_gia_ban": "Purchase price greater than selling price",
    "duoc_chua_nhap_thuoc": "Not yet imported drugs",
    "duoc_gia_sau_vat": "Price after VAT",
    "duoc_chua_chon_thuoc": "Not selected drugs",
    "duoc_phieu_xuat_tra_ncc": "Return NCC export voucher",
    "duoc_phieu_xuat_vo_hong": "Broken, damaged export voucher",
    "duoc_phieu_xuat_huy": "Cancel export voucher",
    "duoc_phieu_xuat_het_han": "Export voucher expired",
    "duoc_phieu_xuat_khac": "Other export vouchers",
    "duoc_phieu_ban_benh_nhan": "Sell to customers voucher",
    "duoc_ly_do_xuat": "Reason for export",
    "duoc_chon_kho_thuoc": "Select drug warehouse",
    "duoc_chon_ncc": "Select supplier",
    "duoc_vui_long_ke_don_thuoc": "Please prescribe drugs",
    "duoc_khong_du_so_luong": "Not enough quantity",
    "duoc_nhap_day_du_thong_tin_benh_nhan": "Enter complete customer information",
    "duoc_chua_ban": "Not sold",
    "duoc_da_ban": "Sold",
    "duoc_nam_sinh_hop_le": "Enter valid birth year",
    "duoc_khach_vang_lai": "Walk-in customer",
    "duoc_them_benh_nhan": "Add customer",
    "duoc_in_phieu_thanh_toan": "Print payment receipt",
    "duoc_huy_don": "Cancel order",
    "duoc_huy_duyet": "Cancel approval",
    "duoc_duyet_don": "Approve order",
    "duoc_huy_thanh_toan": "Cancel payment",
    "duoc_xuat_kho_in_phieu": "Export warehouse + Print voucher",
    "xuat_kho": "Export warehouse",
    "duoc_huy_xuat_kho": "Cancel export",
    "duoc_confirm_xoa_don_thuoc": "Are you sure you want to delete this prescription?",
    "bao_cao_theo_doi_thu_ngan": "Cashier monitoring report",
    "bao_cao_thu_ngan_benh_nhan": "Customer revenue report by service",
    "bao_cao_bac_si_chi_dinh": "Doctor's prescription report",
    "bao_cao_phong_chuc_nang": "Functional room report",
    "bao_cao_doanh_thu": "Revenue report",
    "bao_cao_the_kho": "Inventory card report",
    "bao_cao_su_dung_thuoc_khang_sinh": "Report on the use of antibiotics",
    "bao_cao_bien_ban_kiem_ke_thuoc": "Drug inventory report",
    "bao_cao_xuat_nhap_ton": "Inventory report of imports and exports",
    "bao_cao_phuong_thuc_thanh_toan": "Payment method report",
    "bao_cao_doanh_thu_ban_thuoc": "Drug sales revenue report",
    "bao_cao_tien_kham": "Examination fee",
    "bao_cao_tien_dvkt": "Technical service fee",
    "bao_cao_tien_thuoc": "Medication fee",
    "bao_cao_tien_huy": "Cancellation fee",
    "bao_cao_tien_xet_nghiem": "Laboratory testing fee",
    "bao_cao_tien_khac": "Other fees",
    "bao_cao_tien_cdha": "Imaging diagnosis fee",
    "bao_cao_tong_cong": "Total",
    "bao_cao_tien_mien_giam": "Discounted amount",
    "phong_chuc_nang": "Functional room",
    "bao_cao_xuat_tat_ca_thuoc": "Export all drugs",
    "bao_cao_xuat_tat_ca_thuoc_co_lich_su_nhap_xuat": "Export all drugs with drug import history",
    "bao_cao_sl_so_sach": "Number of books",
    "bao_cao_ngoai_tru": "Outpatient",
    "bao_cao_tien": "fee",
    "bao_cao_ten_thuoc_ham_luong_nong_do": "Drug name, concentration, content",
    "bao_cao_chon_kho_thuoc": "Select drug warehouse",
    "bao_cao_chon_loai_thuoc": "Select drug type",
    "bao_cao_ton_dau_ky": "Beginning inventory",
    "bao_cao_ton_cuoi_ky": "Ending inventory",
    "bao_cao_sl_nhap": "Quantity in",
    "bao_cao_sl_xuat": "Quantity out",
    "bao_cao_khac": "Other",
    "bao_cao_sct_nhap": "",
    "bao_cao_sct_xuat": "",
    "bao_cao_lo_sx": "Production Lot",
    "bao_cao_han_dung": "Expiration date",
    "bao_cao_dien_giai": "Explanation",
    "bao_cao_sl_ton_dau": "Beginning inventory quantity",
    "bao_cao_sl_ton_cuoi": "Ending inventory quantity",
    "bao_cao_hl": "Content",
    "bao_cao_thoi_gian_365_ngay": "Invalid or over 365 days search time",
    "bao_cao_khong_co_thuoc": "No medicine",
    "bao_cao_chon_nhan_su": "Select personnel",
    "bao_cao_nhan_su_da_ton_tai": "Personnel already exists",
    "bao_cao_thanh_phan_tham_gia": "Participating components",
    "bao_cao_them_thanh_phan_tham_gia": "Add participating components",
    "bao_cao_chon_kho_xuat_bao_cao": "Select warehouse for report export",
    "bao_cao_vui_long_nhap_thong_tin_thanh_phan_tham_du": "Please enter information of participating components",
    "bao_cao_chuc_vu": "Position",
    "bao_cao_sdkt": "",
    "bao_cao_hinh_thuc_thanh_toan": "Payment form report",
    "common_lua_chon_khac": "Other options",
    "common_xuat_file_thanh_cong": "Export file successful",
    "common_co_loi_xay_ra": "An error occurred!",
    "commom_khong_co_du_lieu": "No data",
    "phan_mem_ho_tro": "Software supports remote device access",
    "mau_dm_khoa": "Department category template",
    "mau_dm_phong": "Room category template",
    "mau_dm_don_vi_tinh": "Unit category template",
    "mau_dm_nhom_dich_vu": "Group of services category template",
    "mau_dm_loai_dich_vu": "Type of services category template",
    "mau_dm_dich_vu": "Service category template",
    "mau_dm_thuoc": "Drug category template",
    "mau_dm_hang_san_xuat": "Pharmaceutical provider category template",
    "mau_dm_hoat_chat": "Active drug ingredients category tempalte",
    "mau_dm_duong_dung": "How-to-use of drug category template",
    "tong_hop_template_mau_danh_muc": "Consolidated template category",
    "tai_tat_ca_cac_mau": "Download all templates",
    "nguoi_lap_bao_cao": "Report creator",
    "so_dang_ky": "Registration number",
    "so_chung_tu": "Document number",
    "so_luong_dau_ky": "Opening quantity",
    "stt": "No.",
    "STT": "No.",
    "Stt": "No.",
    "ten_bs_chi_dinh": "Assigned Doctor's Name",
    "bac_si": "Doctor",
    "cong_khoan": "Total amount",
    "truong_phong_tckt": "Chief Financial Accountant",
    "ky_ten_va_dong_dau": "(Signature and stamp)",
    "ky_ten_va_ghi_ro_ho_ten": "Signature and full name",
    "ten_bn": "Customer Name",
    "ma": "Code",
    "truong_khoa_duoc": "Head of Pharmacy Department",
    "to_kiem_ke_gom_co": "Inventory team includes",
    "thoi_gian_kiem_ke_thuoc": "Drug inventory time",
    "dia_chi_kiem_ke": "Inventory address",
    "hong_vo": "Damaged",
    "han_dung": "Expiration date",
    "ghi_nho": "Remember",
    "so_sach": "Ledger",
    "thuc_te": "Reality",
    "y_kien_de_xuat": "Suggested feedback",
    "thanh_vien": "Member",
    "sl_nhap": "Quantity in",
    "sl_xuat": "Quantity out",
    "thuoc_kho": "Belong to warehouse",
    "ten_nhan_vien": "Employee name",
    "so_nhan_vien": "Employee",
    "nhan_vien": "Employee",
    "thong_ke_phong_chuc_nang": "Functional department statistics",
    "xuat": "Export",
    "nguoi_lap_bang": "Table creator",
    "hoa_don_ban_hang": "Sales invoice",
    "khach_hang": "Customer",
    "khach_mua_theo_don": "Customer by order",
    "khach_vang_lai": "Walk-in customer",
    "ten_san_pham": "Product name",
    "tong_tien_hang": "Total amount",
    "tong_thanh_toan": "Total payment",
    "chiet_khau_hoa_don": "Invoice discount",
    "chiet_khau": "Discount",
    "cam_on_va_hen_gap_lai": "Thank you and see you again",
    "phieu_nhap_hang": "Purchase order",
    "nguoi_lap": "Creator",
    "chi_nhanh": "Branch",
    "ma_hang": "Item code",
    "ten_hang": "Item name",
    "giam_gia": "Discount",
    "tong_thanh_tien": "Total amount",
    "mien_giam_hoa_don": "Exempt invoice",
    "tien_ncc_can_tra": "Supplier payment amount",
    "ky_va_ghi_ro_ho_ten": "Sign and print name",
    "gia_tri_huy": "Cancel value",
    "sl_huy": "Cancel quantity",
    "ma_so": "Code",
    "so": "Number",
    "ten_thuoc_hoa_chat": "Name of consumed drugs, chemicals, medical supplies",
    "ham_luong_nong_do": "Concentration, dosage, packaging",
    "ki_thuat_chi_dinh": "Prescribed technique",
    "loi_dan_cua_bac_si_khoa": "Doctor's instructions",
    "bac_si_phu_trach_kham": "Attending doctor",
    "do_tuoi": "Age",
    "kham_lai_theo_don_thuoc_nay": "Re-examination according to this prescription",
    "bang_chu": "In words",
    "thong_tin_bo_loc": "Information filter",
    "ten_benh_nhan": "Customer name",
    "ma_don_thuoc_mau_da_ton_tai": "Sample prescription code already exists, do you want to replace the saved prescription template?",
    "ma_khach_hang": "Customer code",
    "kham_ngay": "Examination date",
    "Phieu_co_gia_tri_trong_ngay": "Valid voucher for the day",
    "title_Phieu_thu_phi_dich_vu": "Service receipt",
    "title_Phieu_tom_tat_kham": "Summary examination voucher",
    "nguoi_tao_bao_cao": "Report creator",
    "sdt": "Phone number",
    "field_doi_tuong_bn": "Customer object",
    "cong_hoa_xa_hoi_chu_nghia": "Socialist Republic of Vietnam",
    "doc_lay_tu_do_hanh_phuc": "Independence - Freedom - Happiness",
    "txt_ngay_nhap": "Import date",
    "txt_hoa_don_so": "Invoice number",
    "txt_nhap_tai_kho": "Imported at warehouse",
    "common_da": "has",
    "txt_nguoi_giao": "Delivery person",
    "txt_ke_toan": "Accountant",
    "txt_Thu_truong_don_vi": "Unit leader",
    "txt_tu_choi": "Refuse",
    "noti_so_tien_am": "Negative amount",
    "noti_khong_dong": "Not the same",
    "title_Chi_tiet_cong_no_ncc": "Details of supplier debts",
    "no_dau_ky": "Beginning balance",
    "phat_sinh_trong_ky": "Incurred during the period",
    "tong_hoa_don": "Total invoices",
    "txt_cong_khoan": "Total amount",
    "txt_nguoi_tao_phieu": "Creator",
    "title_phieu_thanh_toan": "Payment voucher",
    "txt_tm_cong_ty": "TM company",
    "common_Tong": "Total",
    "txt_phieu_chi": "Payment voucher",
    "txt_nguoi_nhan_tien": "Recipient",
    "txt_ly_do_nhan": "Reason for receiving",
    "txt_So_tien": "Amount",
    "txt_thu_quy": "Cashier",
    "txt_nguoi_lap_phieu": "Creator",
    "txt_nguoi_nhan": "Recipient",
    "txt_phong_tai_chinh": "Finance and Accounting Department",
    "phieu_co_gia_tri_trong_ngay": "Valid on the day voucher",
    "phieu_thu_phi_dich_vu": "Service fee receipt",
    "lien_0_dau": "Link",
    "thu_ngan_giu": "Cashier",
    "khach_hang_giu": "Customer",
    "thong_tin_khach_hang": "Customer information",
    "ten_khach_hang": "Customer name",
    "thanh_tien": "Total amount",
    "tong_cong": "Total",
    "tong_so_tien": "Total amount",
    "phieu_tom_tat_kham": "Summary examination form",
    "loi_nhap_form": "Input error",
    "status_Da_hoan_thanh": "Completed",
    "text_khoi_tao_du_lieu": "System is initializing data, please wait...",
    "noti_dang_chuyen_doi_file": "Converting file...",
    "noti_chuyen_doi_file_thanh_cong": "File conversion successful",
    "noti_chuyen_doi_file_that_bai": "File conversion failed",
    "noti_dinh_dang_file_word": "Please upload file in doc, docx format",
    "noti_vui_long_nhap_mo_ta": "Please enter description",
    "noti_vui_long_nhap": "Please enter",
    "thuoc_thuong": "Regular medicine",
    "thuoc_nha_thuoc": "Pharmacy medicine",
    "title_thong_bao_chuyen_kho": "Warehouse transfer notification",
    "prefix_so_luong_thuoc_trong_kho_hien_khong_du": "The quantity of medicine in stock is currently insufficient. Do you want to change it?",
    "suffix_so_luong_thuoc_trong_kho_hien_khong_du": "Quantity of transfer",
    "sl_chuyen_kho": "Approved quantity cannot be greater than available quantity!",
    "noti_so_luong_duyet_khong_lon_hon_ton_kha_dung": "The quantity checked is not greater than the available loss!",
    "xuat_ban_dau_sac": "Export sales",
    "duoc_them_moi_nhom": "Add new group",
    "tao_moi_mau_mo_ta": "Create new template",
    "noti_chua_nhap_mo_ta": "Description not entered",
    "noti_chua_nhap_ket_luan": "Conclusion not entered",
    "error_khoa_da_duoc_chon": "Department has been selected!",
    "prefix_xoa_lich": "Delete schedule",
    "noti_chua_hoan_thanh_dich_vu": "Service not completed, do you want to leave?",
    "file_hdsd": "User Guide File",
    "cai_dat": "Settings",
    "chi_tiet_cuoc_kham": "Details of examination",
    "duoc_sua_nha_cung_cap": "Edit supplier",
    "tim_kiem_lich_lam_viec": "Search work schedule",
    "dat_cau_hoi_thanh_cong": "Question successfully asked",
    "dong_y": "Agree",
    "content_sau_khi_dat_cau_hoi_thanh_cong": "",
    "common_benh_vien": "Hospital",
    "common_bo_y_te": "Ministry of Health (Department of Health)",
    "common_tt": "Circular",
    "common_ngay_thang_nam": "Date... month... year",
    "common_tu_ngay": "From date",
    "common_den": "to",
    "common_gio": "Time",
    "ten_thuoc_hoa_chat_vat_tu_y_te_tieu_hao": "Name of medicine, chemical, medical supplies consumed",
    "ham_luong_nong_do_quy_cach_dong_goi": "Concentration, dosage, packaging specifications",
    "ky_ghi_ro_ho_ten": "(Sign and clearly state full name)",
    "nguoi_tao_phieu": "Issuer",
    "tm_cong_ty": "On behalf of the company",
    "chi_tiet_cong_no": "Details of debt",
    "so_kiem_soat": "Control number",
    "bien_ban_kiem_nhap_thuoc": "Drug entry record",
    "bs_chi_dinh": "Prescribing physician",
    "phong_tai_chinh_ke_toan": "FINANCE - ACCOUNTING DEPARTMENT",
    "mau_bao_cao_ve_khang_sinh": "Antibiotic reporting form",
    "so_giuong_benh_ke_hoach_thuc_ke": "Planned/actual bed occupancy",
    "cong_suat_su_dung_giuong_benh": "Bed occupancy rate",
    "tt_hoat_chat": "Active ingredient information",
    "tt_biet_duoc": "Drug information",
    "nong_do_ham_luong": "Concentration, quantity",
    "ma_atc": "ATC code",
    "ten_biet_duoc": "Drug name",
    "don_vi_don_goi": "Packaging unit",
    "duong_dung": "Route of administration",
    "bao_cao_su_dung_thuoc": "Drug usage report",
    "thoi_gian_kiem_ke_thuoc_tinh_den": "Drug inventory as of",
    "lich_su_nhap_tra_ncc": "Supplier's purchase and return history",
    "nguoi_thu_tien": "Cashier",
    "no_cuoi_ky": "End-of-period debt",
    "txt_can_tra": "Amount to be paid",
    "txt_thuc_tra": "Amount paid",
    "txt_ghi_no": "Debit note",
    "txt_ghi_co": "Credit note",
    "da_kiem_ke_tai": "Checked at",
    "ho_ten": "Full name",
    "ten_nguoi_than": "Relative's name",
    "quan_he_gia_dinh": "Family relationship",
    "tong_chi_phi_KCB": "Total medical examination and treatment cost",
    "bh_thanh_toan": "Insurance payment",
    "benh_nhan_da_tt": "Patient has paid",
    "benh_nhan_chua_tt": "Patient has not paid",
    "can_nang": "Weight",
    "chieu_cao": "Height",
    "tien_su_benh": "Medical history",
    "tien_sua_benh_ban_than": "Personal medical history",
    "benh_su": "Disease history",
    "kham_benh": "Medical examination",
    "tien_su_san_khoa": "Obstetric history",
    "tien_su_benh_gia_dinh": "Family medical history",
    "tien_su_di_ung": "Allergy history",
    "trieu_chung_lam_sang": "Clinical symptoms",
    "tai_nan_thuong_tich": "Injury accident",
    "toan_than": "Whole body",
    "cac_bo_phan": "Body parts",
    "tuan_hoan": "Circulation",
    "ho_hap": "Respiration",
    "tieu_hoa": "Digestion",
    "than_tiet_nieu": "Kidney, urinary",
    "noi_tiet": "Endocrine",
    "co_xuong_khop": "Musculoskeletal",
    "than_kinh": "Nervous system",
    "bs_kham": "Doctor examination",
    "chan_doan_so_bo": "Preliminary diagnosis",
    "icd_benh_chinh": "ICD of main disease",
    "icd_yhct_chinh": "ICD of tranditional medicine (TM) main disease",
    "icd_benh_kem_theo": "ICD of comorbidities",
    "idc_yhct_kem": "ICD of tranditional medicine (TM) comorbidities",
    "chan_doan_xac_dinh": "Definitive diagnosis",
    "huong_xu_tri": "Treatment direction",
    "loi_dan": "Instructions",
    "ngay_tai_kham": "Follow-up date",
    "hoi_benh": "Medical history",
    "kham_tong_quan": "General examination",
    "chan_doan_xu_tri": "Diagnostic treatment",
    "confirm_ket_thuc_cuoc_kham": "Are you sure you want to end the examination session?",
    "ke_don": "Please enter a preliminary diagnosis",
    "vui_long_nhap_chan_doan_so_bo": "Information form",
    "confirm_huy_xuat_kho": "Are you sure you want to cancel the prescription payment?",
    "confirm_huy_thanh_toan_don_thuoc": "Are you sure you want to cancel the prescription approval?",
    "confirm_huy_duyet_don_thuoc": "Customer source",
    "nguon_khach": "Relative's phone number",
    "sdt_nguoi_than": "Relative's address",
    "toi_da_ky_tu": "Subject information",
    "thong_tin_doi_tuong": "Failed to retrieve the list",
    "noti_lay_danh_sach_that_bai": "Add new usage method",
    "them_moi_cach_dung": "Usage method name",
    "ten_cach_dung": "Please enter the name of the usage method",
    "vui_long_nhap_ten_cach_dung": "Enter the template name",
    "nhap_ten_mau": "Enter the name of the template",
    "lich_su_kham_benh": "Medical examination history",
    "thong_tin_ck": "Transfer information",
    "don_thuoc_cu": "Old prescription",
    "ket_qua_cls": "Results of the close-up floor",
    "xem_nhanh": "Quick view",
    "xem_chi_tiet": "View details",
    "ngay_gio_y_lenh": "Date and time of medical orders",
    "so_ngay_sd": "Used time (days)",
    "huong_dan_su_dung": "Instructions for use",
    "xem_nhanh_lich_su_kham_benh": "Quick view of medical examination history",
    "tien_su": "Medical history",
    "new_so_ngay_sd": "Used time (days)",
    "new_moi_ngay": "Daily use",
    "tan_suat_sd": "Frequency of use",
    "txt_ngay": "day",
    "chan_doan_hinh_anh": "Imaging diagnosis",
    "phieu_kham_benh": "Medical examination form",
    "don_thuoc_dich_vu": "Prescription",
    "ma_benh_nhan": "Customer code",
    "chan_doan_chinh": "Main diagnosis",
    "chan_doan_phu": "Secondary diagnosis",
    "ngay_dung": "Date of use",
    "chi_dan_dung_thuoc": "Medication instructions",
    "loi_dan_bac_sy": "Doctor's advice",
    "kham_lai_xin_mang_theo_don": "Please bring this prescription for re-examination",
    "ten_bo_hoac_me_cua_tre": "Name of the child's father or mother or person bringing the child for medical treatment",
    "title_phieu_chi_dinh_cho_bn_ngoai_bh": "PRESCRIPTION FOR NON-INSURED CUSTOMERS",
    "title_phieu_chi_dinh_dich_vu": "Service prescription",
    "title_phieu_bao_cao_y_te": "Medical report",
    "khoa_phong": "Department",
    "tong_tien_nguoi_benh_phai_tra": "Total amount paid by the patient",
    "da_duoc_tu_van_day_du": "The customer has been fully consulted and agreed to perform the services at the clinic at the price listed on the healthcare service price list.",
    "da_duoc_tu_van_day_du_v2": "The customer has been fully consulted and agreed to perform the services at the clinic at the price listed on the Y healthcare service price list",
    "error_loi_ket_noi": "Connection error. Please log in again!",
    "cho_thuc_hien": "Waiting to perform",
    "Dang_thuc_hien": "Performing",
    "ban_than": "Self",
    "gia_dinh": "Family",
    "vui_long_chon_thuoc": "Please select medication",
    "don_thuoc_mau": "Sample prescription",
    "vui_long_chon_kho": "Please select a warehouse",
    // "ma_don": "Order code",
    // "ten_don": "Order name",
    "danh_sach_thuoc_chi_dinh_trong_don": "List of prescribed drugs in the prescription",
    "in_don_tu_van": "Print consultation form",
    "phong_tiep_don": "Reception area",
    "noti_chua_luu_thay_doi_don_thuoc_khi_bam_in": "You have not saved changes to the prescription. Please click save prescription to continue printing",
    "phieu_tu_van": "Consultation prescription",
    "noti_don_thuoc_khong_co_thuoc_byt": "Prescription without essential drugs",
    "noti_don_thuoc_khong_co_thuoc_tpcn": "Prescription without OTC drugs",
    "noti_vui_long_nhap_day_du_cac_truong_bat_buoc": "Please enter all required fields",
    "noti_vui_long_nhap_day_du_cac_truong_bat_buoc_cua_thuoc_trong_don": "Please enter all required fields for drugs in the prescription",
    "noti_vui_long_nhap_dung_dinh_dang": "Please enter the correct format!",
    "nhap_gia_tri_lon_hon": "Enter a value greater than",
    "va_nho_hon_hoac_bang": "and less than or equal to",
    "chua_co_lich_su": "No history yet",
    "chua_chon_benh_nhan_vao_kham": "No customer selected for examination",
    "ttk_tieu_de": "List of templates",
    "ttk_tao_moi": "Add new template",
    "luu_mau": "Save template",
    "sua_mau": "Edit template",
    "msg_ten_mau": "Please enter the template name",
    "msg_xoa_mau_thanh_cong": "Successfully deleted examination information template",
    "tooltip_sua_mau": "Edit examination information template",
    "txt_xac_nhan_xoa_mau": "Are you sure you want to delete this template?",
    "msg_luu_mau_thanh_cong": "Template saved successfully!",
    "msg_sua_mau_thanh_cong": "Template edited successfully!",
    "ket_qua_xet_nghiem": "Test results",
    "thong_tin_ket_qua_xn": "Information of test results",
    "file_dinh_kem": "Attached file",
    "ten_xet_nghiem": "Test name",
    "gia_tri_tham_chieu": "Reference value",
    "ngay_nhan_kq": "Date of receiving results",
    "thiet_bi": "Equipment",
    "so_chay": "Run number",
    "ngay_chay": "Date of run",
    "question_1": "Do you have any allergies to food, medication, or materials?",
    "question_2": "Have you ever been hospitalized or had surgery before?",
    "question_3": "Have you ever had a blood transfusion?",
    "question_4": "Are you currently undergoing treatment or taking any medication?",
    "question_family": "Has anyone in your family had any previous illnesses?",
    "question_info_1": "How did you hear about our clinic?",
    "question_sinh_con": "Have you ever given birth?",
    "question_nhap_vien": "Have you ever been hospitalized or treated for injuries?",
    "nguyen_vong_khac": "Other requests",
    "lich_kham_chua_hoan_thanh": "Uncompleted appointment schedule",
    "huyet_ap_cao": "High blood pressure",
    "tieu_duong": "Diabetes",
    "tai_bien_nao": "Stroke",
    "benh_tim_mach": "Heart disease",
    "benh_than": "Kidney disease",
    "benh_kawasaki": "Kawasaki disease",
    "sot_xuat_huyet": "Dengue fever",
    "benh_lao": "Tuberculosis",
    "viem_da_di_ung": "Allergic skin inflammation",
    "ung_thu": "Cancer",
    "dau_nua_dau": "Migraine",
    "bao_chi": "Media",
    "cong_ty_ban_be": "Company/Friends and relatives",
    "save_image": "Save photos",
    "error_khong_co_dich_vu": "No service",
    "action_dung": "Stop",
    "chup_tu_dong": "Automatic capture",
    "so_luong_anh_toi_da": "Maximum number of photos is 8",
    "thoi_gian_dem_lui": "Countdown time",
    "tiep_tuc_tai_anh_len": "Continue uploading photos",
    "chinh_sua_anh": "Edit photos",
    "chon_anh": "Select image",
    "tai_len_hinh_anh": "Upload image",
    "ma_loai_dich_vu": "Service type code",
    "nhom_loai_dich_vu": "Service type group",
    "truong_khoa_chan_doan_hinh_anh": "Head of imaging diagnosis department",
    "thong_ke_hoat_dong_chan_doan_tham_do_chuc_nang": "Statistics of imaging diagnosis activities, function survey",
    "thoi_gian_chi_dinh": "Prescription time",
    "ngay_thanh_toan": "Payment date",
    "ma_kh": "Customer code",
    "bao_cao_doanh_thu_kh": "Customer revenue report",
    "bao_cao_phieu_thu_dich_vu": "Service receipt report",
    "phieu_dich_vu": "Service receipt",
    "noti_khong_the_xoa_cuoc_kham_co_dich_vu_da_thanh_toan": "Cannot delete examination session because it has at least one paid service",
    "CIS_ql_pk": "CIS Clinic Management",
    "LIS_ql_xn": "LIS - Laboratory Information System",
    "RIS_ql_cdha": "RIS - Radiology Information System",
    "PRM_ql_cskh": "PRM - Customer Service Management",
    "home_vtc": "Home - Back to homepage",
    "khuyen_nghi": "Recommendations",
    "cap_nhat_phan_mem": "Software Update",
    "content_update_version": "Currently, the system has a new update version, please log in to experience the new features",
    "content_update_version_reload": "Currently, the system has a new update version, please refresh the page to experience the new features",
    "thu_gon": "Collapse",
    "mo_rong": "Expand",
    "trang_thai_kham": "Examination Status",
    "trang_thai_thanh_toan": "Payment Status",
    "field_Dat_kham": "Medical examination",
    "so_chan_doan_hinh_anh": "Imaging diagnosis book",
    "hd_chan_doan_hinh_anh_theo_loai_dv": "Imaging diagnosis activities by service type",
    "thong_ke_dv_cdha_theo_bn": "Statistics of CDHA, TDCN services by customer",
    "confirm_huy_thuc_hien_khi_co_dv_da_tra": "Service form has been completed. Do you want to continue?",
    "confirm_huy_thuc_hien": "Are you sure you want to cancel the execution?",
    "da_thanh_toan": "Paid",
    "can_tao_lich_lam_viec": "Need to create a work schedule",
    "menu_phauthuat_thuthuat": "Surgery, operation",
    "dm_thuthuat_ma_cttt": "Payment method code",
    "dm_thuthuat_ten_cttt": "Payment method name",
    "dm_thuthuat_ma_pptt": "Payment method code",
    "dm_thuthuat_ten_pptt": "Payment method name",
    "field_thuthuat_ma_cttt": "Surgical method code",
    "field_thuthuat_ten_cttt": "Surgical method",
    "field_thuthuat_ma_pptt": "Method code",
    "field_thuthuat_ten_pptt": "Method name",
    "tong_kh_thanh_toan": "Total payment amount",
    "tong_tien_hoan": "Total refund amount",
    "common_noi_dung": "Content",
    "title_khai_thac_thong_tin_kb": "Medical examination information exploitation",
    "them_moi_thong_tin_khai_thac_kham_benh": "Add new medical examination exploitation information",
    "confirm_xoa_lich_su": "Are you sure you want to delete this history?",
    "don_chi_dinh": "Prescription",
    "don_vang_lai_khach_le": "Outpatient prescription, walk-in customer",
    "da_xac_thuc": "Verified",
    "chuyen_huong_toi_trang_chu": "Redirect to homepage",
    "quan_ly_tai_khoan": "Account management",
    "thong_tin_tai_khoan": "Account information",
    "doi_mat_khau": "Change password",
    "mat_khau_cu": "Old password",
    "mat_khau_moi": "New password",
    "nhap_lai_mat_khau_moi": "Please enter your new password again",
    "content_doi_mat_khau": "Enter your password again to change to a new password",
    "vui_long_xac_nhapn_mat_khau": "Please confirm your password",
    "xac_nhan_mat_khau_khong_dung": "Password confirmation is incorrect",
    "thoi_gian_thuc_hien": "Execution time",
    "xem_tuong_trinh": "View log",
    "nhap_thong_tin_thu_thuat": "Enter medical information",
    "luu_va_in_tuong_trinh": "Save and print log",
    "khai_thac_benh_su": "Medical history",
    "lam_moi": "Refresh",
    "field_bs_chi_dinh": "Doctor's prescription",
    "field_thoi_gian_ke_don": "Prescription time",
    "lable_thong_tin_chi_tiet_ban_thuoc": "Detailed drug information",
    "label_thuoc_co_trong_don_BS_ke": "Drugs in the doctor's prescription",
    "label_thuoc_co_tron_don_nhung_BN_khong_mua": "Drugs in the prescription but not purchased by the customer",
    "label_thuoc_ko_co_trong_don_BS_ke": "Drugs not in the doctor's prescription",
    "label_bao_cao_thong_ke_ban_thuoc": "Drug sales statistics report",
    "thoi_gian_bat_dau": "Start time",
    "thoi_gian_ket_thuc": "End time",
    "nguoi_chi_dinh": "Designator",
    "chon_phan_he_RIS": "RIS",
    "chon_phan_he_LIS": "LIS",
    "chon_phan_he_PRM": "PRM",
    "chon_phan_he_MANAGEMENT": "Category",
    "chon_phan_he_CIS": "CIS",
    "noti_ban_khong_co_quyen_cho_chuc_nang_nay": "You have no permission to carry out this feature",
    "khong_co_quyen": "No permission",
    "nhap_day_du_thong_tin_thu_thuat": "FIll all information of service",
    "huy_thuc_hien": "Cancel",
    "tra_kq": "Result",
    "nhap_thong_tin": "Enter Infos",
    "da_hoan_thanh": "Finished",
    "error_thoi_gian": "Start time must be less than end time",
    "trang_thai_ca_kham": "Examination room status",
    "phieu_hoan": "Refund form",
    "phieu_thu": "Receipt",
    "lua_chon_phan_he": "Select working subsystem",
    "home_intro": "Efficient, convenient, optimized smart clinic management solution, specialized clinic chain, comprehensive healthcare, anytime, anywhere",
    "bao_cao_thong_ke": "Reporting, statistics",
    "sub_title_danh_muc": "System administration, staff team configuration",
    "sub_title_cis": "Management of clinic examination and treatment activities",
    "sub_title_duoc_ngoai_tru": "Pharmacy management, drug counter",
    "sub_title_lis": "Laboratory management, testing center",
    "sub_title_ris": "Management of imaging diagnostic center",
    "sub_title_prm": "Customer care management, increasing sales",
    "sub_title_bao_cao": "Management of reporting and statistics of medical activities",
    "sub_title_pacs": "Image storage and transmission system",
    "dia_chi_deepcare": "35 Le Van Thiem, Thanh Xuan Trung Ward, Thanh Xuan District, Hanoi",
    "luu_y_mat_khau_mac_dinh": "The password currently in use is a temporary password, please change to a new password",
    "noti_mat_khau_ko_ok": "Please enter a password that meets the standards!",
    "dk_do_dai_mat_khau": "Password must be from 8 - 24 characters",
    "dk_mat_khau_bao_gom": "Including numbers, uppercase letters, lowercase letters",
    "dk_mat_khau_ky_tu": "Includes at least one special character !@#$^*()_",
    "cs_dat_kham": "Clinic location",
    "tong": "Total",
    "noti_chuyen_cskcb": "Transferring clinic will result in loss of current data. Do you want to continue?",
    "cisLabel": "Medical exam management",
    "quan_ly_tam_ung": "Advance payment management",
    "quan_ly_cong_no": "Debt management",
    "quan_ly_goi_dv": "Service package management",
    "danh_sach_goi_dv": "List of service packages",
    "quan_ly_dk_goi_dv": "Registered service package management",
    "quan_ly_su_dung_goi_dv": "Service package usage management",
    "kham_dich_vu": "Service examination",
    "goi_dich_vu": "Service package",
    "chi_dinh_goi_dich_vu": "Service package assignment",
    "dk_goi_dv": "Register service package",
    "thong_tin_sd_goi_dv": "Service package usage information",
    "dang_ky_goi_dv": "Service package registration",
    "loai_goi": "Package type",
    "ma_voucher": "Voucher code",
    "tt_goi_dich_vu": "Service package information",
    "lich_su_su_dung": "Usage history",
    "lich_su_thanh_toan": "Payment history",
    "gia_goi_dich_vu": "Service package price",
    "CCCD_Passport": "ID card/Passport",
    "loai_gia": "Price type",
    "don_gia_phan_bo": "Unit price allocation",
    "thanh_tien_phan_bo": "Allocated amount",
    "tong_tien_phan_bo": "Total allocated amount",
    "tong_sl": "Total quantity",
    "kich_hoat_goi_dv": "Package activation, treatment",
    "ngay_hieu_luc": "Effective date",
    "chua_hoat_dong": "Inactive",
    "hoat_dong": "Active",
    "tam_ngung": "Suspended",
    "ky_thuat": "Technology",
    "sap_het_han": "Expiring soon",
    "het_han": "Expired",
    "them_moi_goi_dv": "Add new service package",
    "chinh_sua_goi_dv": "Edit service package",
    "ma_goi": "Package code",
    "ten_goi": "Package name",
    "ngay_tao": "Creation date",
    "gia_ban_goi": "Package selling price",
    "ngay_het_han": "Expiration date",
    "so_ngay_su_dung": "Number of days used",
    "thao_tac": "Action",
    "thong_tin_goi_dv": "Service package information",
    "ma_goi_dv": "Service package code",
    "ten_goi_dv": "Service package name",
    "loai_goi_dv": "Service package type",
    "chi_dinh_goi_dv": "Designated service package",
    "goi_co_san": "Available package",
    "sao_chep": "Copy",
    "ty_le": "Rate",
    "nguoi_cap_nhat": "Updated by",
    "ngay_cap_nhat": "Updated date",
    "thue_VAT": "VAT tax",
    "goi_dv": "Service package",
    "ngay_kich_hoat": "Activation date",
    "ghi_chu": "Note",
    "dinh_muc": "Quota",
    "da_su_dung": "Used",
    "con_lai": "Remaining",
    "so_du_cong_no": "Balance/Debt",
    "ngay_giao_dich": "Transaction date",
    "dich_vu_lieu_trinh_ngay": "Daily service schedule",
    "xem_ket_qua": "View results",
    "ly_do_tam_ung": "Reason for advance payment",
    "so_tien_tam_ung": "Advance payment amount",
    "nguoi_nop_tien": "Payer"
}

export default standard_en;