import { put } from 'redux-saga/effects';
import { setTableLoading } from 'ducks/slices/loadingSlice';
import { takeLatest } from '@redux-saga/core/effects';
import { common_post } from 'helpers';
import { apis } from "../../../constants";
import { getExemption, getExemptionSuccess } from 'ducks/slices/ListData/Exemption.slice';

export function* watchDoExemption() {
    yield takeLatest(getExemption, handleFetchExp)
}

function* handleFetchExp(action) {
    const { NGUON_KHACH_ID, onSuccess = () => {} } = action.payload
    try {
        yield put(setTableLoading(true));
        const res = yield common_post(apis.layQLMienGiamTiepDonNK, { NGUON_KHACH_ID })
        if (res) {
            if (res.status === 'OK') {
                yield put(getExemptionSuccess(Array.isArray(res.data) ? res.data : []));
                onSuccess(Array.isArray(res.data) ? res.data : [])
            }
        }
    } catch (err) {
        console.log(err.message);
    } finally {
        yield put(setTableLoading(false));
    }
}