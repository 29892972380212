import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initState = {
  dsGoiLieuTrinh: [],
  //
  dsDeXuat: [],
  //
  initID: undefined,
  serviceInitList: [],
  supplyInitList: [{}],
  //
  planID: undefined,
  servicePlanList: [],
  supplyPlanList: [],
  planData: [],
  //
  usageID: undefined,
  serviceUsageList: [],
  supplyUsageList: [{}],
  usedList: [],
  //
  loading: false,
  doneFetchInit: false,
  doneFetchPlan: false,
  doneFetchUsage: false,
  //
  tempCustomerData: {},
};

const GoiVatTuSlice = createSlice({
  name: 'GoiVatTu',
  initialState: initState,
  reducers: {
    getDsGoiLieuTrinh: (_, action) => {},
    updateDsGoiLieuTrinh: (state, action) => {
      state.dsGoiLieuTrinh = action.payload?.result || []
    },
    getChiTietGoiLieuTrinh: (_, action) => {},
    //
    savePhacDoLieuTrinh: (_, action) => {},
    setPhacDoID: (state, action) => {
      const { key, value } = action.payload
      state[key] = value
    },
    //
    getPhacDoDieuTri: (_, action) => {},
    updateDsDeXuat: (state, action) => {
      state.dsDeXuat = action.payload
    },
    //
    setServiceInitList: (state, action) => {
      state.serviceInitList = Array.isArray(action.payload) ? action.payload : action.payload()
    },
    setSupplyInitList: (state, action) => {
      state.supplyInitList = Array.isArray(action.payload) ? action.payload : action.payload()
    },
    //
    getKeHoachSuDung: (_, action) => {},
    saveKeHoachSuDung: (_, action) => {},
    setServicePlanList: (state, action) => {
      state.servicePlanList = action.payload
    },
    setSupplyPlanList: (state, action) => {
      state.supplyPlanList = action.payload
    },
    setPlanData: (state, action) => {
      state.planData = _.orderBy(action.payload, ["KE_HOACH_NGAY"])
    },
    //
    setServiceUsageList: (state, action) => {
      state.serviceUsageList = Array.isArray(action.payload) ? action.payload : action.payload()
    },
    setSupplyUsageList: (state, action) => {
      state.supplyUsageList = Array.isArray(action.payload) ? action.payload : action.payload()
    },
    setUsedBundleList: (state, action) => {
      state.usedList = action.payload
    },
    //
    setDoneFetch: (state, action) => {
      state[action.payload] = true
    },
    setLtrLoading: (state, action) => {
      state.loading = action.payload
    },
    //
    setTempCustomerData: (state, action) => {
      state.tempCustomerData = action.payload
    },
    resetGoiVatTuSlice: (state) => {
      return { ...initState, dsGoiLieuTrinh: state.dsGoiLieuTrinh }
    }
  }
});

export const {
  savePhacDoLieuTrinh, getPhacDoDieuTri, setLtrLoading, updateDsDeXuat, setServiceInitList, setSupplyInitList,
  saveKeHoachSuDung, resetGoiVatTuSlice, setPlanData, setServiceUsageList, setSupplyUsageList, setDoneFetch,
  getKeHoachSuDung, setPhacDoID, getDsGoiLieuTrinh, updateDsGoiLieuTrinh, getChiTietGoiLieuTrinh, setUsedBundleList,
  setTempCustomerData, setServicePlanList, setSupplyPlanList
} = GoiVatTuSlice.actions;

export const getLieuTrinhData = (state) => state.servicePacket.GoiVatTu;

export default GoiVatTuSlice.reducer;