import { notification } from "antd";
import { forwardRef, lazy, memo, Suspense, useImperativeHandle, useMemo, useRef, useState } from "react";
import { common_post, HLog, rid } from "../../helpers";
import i18n, { languageKeys } from "../../i18n";
import { LoadingOutlined } from "@ant-design/icons";
import Printd from "printd";
import { stylePhieuTiepDonCLS } from "../PdfTemplates/PhieuTiepDonCLS";
import { stylePhieuTiepDonKhamBenh } from "../PdfTemplates/PhieuTiepDonKhamBenh";
import { stylePhieuThuPhiDv, stylePhieuThuPhiDvAivi, stylePhieuThuPhiDvGL, stylePhieuThuPhiDvIshi } from "../PdfTemplates/QlyVienPhi/PhieuThuPhiDv";
import { styleDonThuoc, styleDonThuocGL } from "../PdfTemplates/DonThuoc";
import { useSelector } from "react-redux";
import { stylePhieuBaoCaoPhieuThu } from "../PdfTemplates/PhieuBaoCaoPhieuThu";
import { stylePhieuBaoCaoVienPhi } from "../PdfTemplates/PhieuBaoCaoVienPhi";
import { stylePhieuBaoCaoTiepDon, stylePhieuBaoCaoTiepDonGL } from "../PdfTemplates/PhieuBaoCaoTiepDon";
import { stylePhieuThanhToan } from "../PdfTemplates/PhieuThanhToan";
import { stylePhieuXuatTra } from "../PdfTemplates/TemplatePhieuXN/PhieuXuatTra";
import { stylePhieuChiTietCongNoNhaCungCap, stylePhieuChiTietCongNoNhaCungCapGL } from "../PdfTemplates/PhieuChiTietCongNoNhaCungCap";
import { stylePhieuXuatBan } from "../PdfTemplates/TemplatePhieuXN/PhieuXuatBan";
import { stylePhieuXuatHuy } from "../PdfTemplates/TemplatePhieuXN/PhieuXuatHuy";
import { stylePhieuBanLeThuoc } from "../PdfTemplates/PhieuBanLeThuoc";
import { styleTheKhoPdf } from "../PdfTemplates/TemplatePhieuXN/TheKhoPdf";
import { stylePhieuTraKqPdf, stylePhieuTraKqPdf_namAu_haThanh_248, stylePhieuTraKqPdfAivi, stylePhieuTraKqPdfGL, stylePhieuTraKqPdfIshi } from "../PdfTemplates/templateRis/PhieuTraKetQua";
import { stylePhieuKhamBenh, stylePhieuKhamBenh5s, stylePhieuKhamBenhGL } from "../PdfTemplates/update_1-11/PhieuKhamBenh";
import { styleDonThuocNew, styleDonThuocNewGL } from "../PdfTemplates/update_1-11/DonThuoc_new";
import { styleChiDinhNew, styleChiDinhNewAivi, styleChiDinhNewIshi, styleChiDinhNewNADK, styleChiDinhNewSCT, styleChiDinhNewShort } from "../PdfTemplates/update_1-11/PhieuChiDinhNew";
import { stylePhieuTraKqXn, stylePhieuTraKqXnGL } from "../PdfTemplates/ketquaxn/KetQuaXn";
import { styleChiTietSoHoaDon, styleChiTietSoHoaDonGL } from "../PdfTemplates/sohoadon/ChiTietSoHoaDon";
import { styleTuongTrinhTT, styleTuongTrinhTTGL } from "components/PdfTemplates/TuongTrinhThuThuat";
import { stylePhieuTamUng, stylePhieuTamUngGL } from "components/PdfTemplates/QlyVienPhi/QlyTamUng/PhieuTamUng.pdf";
import { stylePhieuHoanUng, stylePhieuHoanUngGL } from "components/PdfTemplates/QlyVienPhi/QlyTamUng/PhieuHoanUng.pdf";
import { stylePhieuCongNo } from "../PdfTemplates/QlyVienPhi/QlyCongNo/PhieuCongNo.pdf";
import { stylePhieuHoanTien, stylePhieuHoanTienGL } from "../PdfTemplates/QlyVienPhi/QlyCongNo/PhieuHoanTien.pdf";
import { stylePhieuNhapKho } from "../PdfTemplates/PhieuNhapKho";
import { stylePhieuThuGoiDv, stylePhieuThuGoiDvAivi, stylePhieuThuGoiDvIshi } from "../PdfTemplates/QlyVienPhi/PhieuThuGoiDv.pdf";
import { styleChiTietSoThuHoan, styleChiTietSoThuHoanGL } from "components/PdfTemplates/sohoadon/ChiTietSoThuHoan";
import { styleChiTietSoTamUng, styleChiTietSoTamUngGL } from "../PdfTemplates/sohoadon/ChiTietSoTamUng";
import { styleChiTietSoCongNo, styleChiTietSoCongNoGL } from "../PdfTemplates/sohoadon/ChiTietSoCongNo";
import { stylePhieuTraKqXn5s } from "components/PdfTemplates/ketquaxn/KetQuaXn5s";
import { styleTienTrinhKhoiTao } from "components/PdfTemplates/GoiLieuTrinh/TienTrinhKhoiTao.pdf";
import { styleKeHoachSuDung, styleKeHoachSuDungGL } from "../PdfTemplates/GoiLieuTrinh/KeHoachSuDung.pdf";
import { styleTheoDoiTruyenDich, styleTheoDoiTruyenDichGL } from "components/PdfTemplates/update_1-11/TheoDoiTruyenDich.pdf";
import { styleTiepDonTienSu } from "../PdfTemplates/update_1-11/phieuTiepDonTienSu.pdf";
import { styleBangKeCongNo } from "components/PdfTemplates/QlyVienPhi/QlyCongNo/BangKeCongNo.pdf";
import { styleChiDinhGreenLab } from "../PdfTemplates/update_1-11/PhieuChiDinhGreenLab";
import { stylePhieuCongNoGreenLab } from "../PdfTemplates/QlyVienPhi/QlyCongNo/PhieuCongNoGreenLab.pdf";
import { stylePhieuThuGoiDvGreenLab } from "../PdfTemplates/QlyVienPhi/PhieuThuGoiDvGL.pdf";
import { styleBangKeCongNoGreenLab } from "../PdfTemplates/QlyVienPhi/QlyCongNo/BangKeCongNoGreenLab.pdf";
import { styleLieuTrinhDeXuat } from "components/PdfTemplates/GoiLieuTrinh/LieuTrinhDeXuat.pdf";
import { styleBenhTrinhDieuTri } from "components/PdfTemplates/GoiLieuTrinh/BenhTrinhDieuTri.pdf";
import { styleBarcode } from "components/PdfTemplates/Barcode/Barcode";
import { styleBarcode4 } from "components/PdfTemplates/Barcode/Barcode_4";
import { styleBarcode2 } from "../PdfTemplates/Barcode/Barcode_2.js";
import { styleGiayChuyenVien } from "../PdfTemplates/update_1-11/GiayChuyenVien.pdf";
import { styleInBenhAnNgoaiTru } from "components/PdfTemplates/InBenhAnNgoaiTru";
import { stylePhieuTraKqXnIshi } from "components/PdfTemplates/ketquaxn/PhieuTraKqXnIshi";
import { stylePhieuTraKqPACSPdf } from "../PdfTemplates/templateRis/PhieuTraKetQuaPACS";
import { stylePhieuThuK80 } from "../PdfTemplates/QlyVienPhi/PhieuThuK80.pdf";
import { styleInMaQR } from "components/PdfTemplates/QlyVienPhi/QR.pdf";
import { styleBangKeGoiLieuTrinh } from "components/PdfTemplates/QlyVienPhi/QlyCongNo/BangKeGoiLieuTrinh.pdf";

const noti_new_tab_key = "noti_new_tab_key";

const monitor = {
  phieuKhamBenh: {
    path: import("../PdfTemplates/PhieuTiepDonKhamBenh"),
    style: stylePhieuTiepDonKhamBenh,
  },
  phieuCLS: {
    path: import("../PdfTemplates/PhieuTiepDonCLS"),
    style: stylePhieuTiepDonCLS,
  },
  donThuoc: {
    path: import("../PdfTemplates/DonThuoc"),
    style: styleDonThuoc,
    styleGL: styleDonThuocGL,
  },
  xuatBaoCaoPhieuThu: {
    path: import("../PdfTemplates/PhieuBaoCaoPhieuThu"),
    style: stylePhieuBaoCaoPhieuThu,
  },
  xuatBaoCaoVienPhi: {
    path: import("../PdfTemplates/PhieuBaoCaoVienPhi"),
    style: stylePhieuBaoCaoVienPhi,
  },
  PhieuXuatBan: {
    path: import("../PdfTemplates/TemplatePhieuXN/PhieuXuatBan"),
    style: stylePhieuXuatBan,
  },
  PhieuXuatTra: {
    path: import("../PdfTemplates/TemplatePhieuXN/PhieuXuatTra"),
    style: stylePhieuXuatTra,
  },
  PhieuThanhToan: {
    path: import("../PdfTemplates/PhieuThanhToan"),
    style: stylePhieuThanhToan,
  },

  xuatPhieuBaoCaoTiepDon: {
    path: import("../PdfTemplates/PhieuBaoCaoTiepDon"),
    style: stylePhieuBaoCaoTiepDon,
    styleGL: stylePhieuBaoCaoTiepDonGL,
  },
  phieuThuPhiDV: {
    path: import("../PdfTemplates/QlyVienPhi/PhieuThuPhiDv"),
    style: stylePhieuThuPhiDv,
    styleGL: stylePhieuThuPhiDvGL,
    styleIshi: stylePhieuThuPhiDvIshi,
    styleAivi: stylePhieuThuPhiDvAivi,
  },
  phieuThuK80: {
    path: import("../PdfTemplates/QlyVienPhi/PhieuThuK80.pdf"),
    style: stylePhieuThuK80,
  },
  ChiTietCongNo: {
    path: import("../PdfTemplates/PhieuChiTietCongNoNhaCungCap"),
    style: stylePhieuChiTietCongNoNhaCungCap,
    styleGL: stylePhieuChiTietCongNoNhaCungCapGL,
  },
  PhieuNhapHang: {
    path: import("../PdfTemplates/PhieuNhapKho"),
    style: stylePhieuNhapKho,
  },
  PhieuXuatHuy: {
    path: import("../PdfTemplates/TemplatePhieuXN/PhieuXuatHuy"),
    style: stylePhieuXuatHuy,
  },
  PhieuBanLe: {
    path: import("../PdfTemplates/PhieuBanLeThuoc"),
    style: stylePhieuBanLeThuoc,
  },
  theKhoPDF: {
    path: import("../PdfTemplates/TemplatePhieuXN/TheKhoPdf"),
    style: styleTheKhoPdf,
  },
  PhieuTraKq: {
    path: import("../PdfTemplates/templateRis/PhieuTraKetQua"),
    style: stylePhieuTraKqPdf,
    styleGL: stylePhieuTraKqPdfGL,
    styleIshi: stylePhieuTraKqPdfIshi,
    styleAivi: stylePhieuTraKqPdfAivi,
    styleNamAuHaThanh248: stylePhieuTraKqPdf_namAu_haThanh_248,
  },
  PhieuTraKqPACS: {
    path: import("../PdfTemplates/templateRis/PhieuTraKetQuaPACS"),
    style: stylePhieuTraKqPACSPdf,
  },
  phieuTomTatKham: {
    path: import("../PdfTemplates/update_1-11/PhieuKhamBenh"),
    style: stylePhieuKhamBenh,
    styleGL: stylePhieuKhamBenhGL,
    style5s: stylePhieuKhamBenh5s,
  },
  donThuocMoi: {
    path: import("../PdfTemplates/update_1-11/DonThuoc_new"),
    style: styleDonThuocNew,
    styleGL: styleDonThuocNewGL,
  },
  phieuCLSMoi: {
    path: import("../PdfTemplates/update_1-11/PhieuChiDinhNew"),
    style: styleChiDinhNew,
    styleSCT: styleChiDinhNewSCT,
  },
  // Ishi chỉ định dịch vụ
  phieuTiepDonMoi: {
    path: import("../PdfTemplates/update_1-11/PhieuChiDinhNew"),
    style: styleChiDinhNew,
    styleIshi: styleChiDinhNewIshi,
    styleAivi: styleChiDinhNewAivi,
    styleSCT: styleChiDinhNewSCT,

    styleNamAuHaThanh248: styleChiDinhNewShort, //styleTableSort

  },
  // Ishi đăng ký dịch vụ
  phieuTiepDonTach_Ishi: {
    path: import("../PdfTemplates/update_1-11/PhieuChiDinhNew_Ishi"),
    style: styleChiDinhNew,
    styleIshi: styleChiDinhNewIshi,
  },
  phieuTiepDonTachGL: {
    path: import("../PdfTemplates/update_1-11/PhieuChiDinhGreenLab"),
    style: styleChiDinhGreenLab,
  },
  phieuTiepDonTach5s: {
    path: import("../PdfTemplates/update_1-11/PhieuChiDinhNew5s"),
    style: styleChiDinhNew,
    styleCkxndt1: styleChiDinhNew,
  },
  phieuTraKQXN: {
    path: import("../PdfTemplates/ketquaxn/KetQuaXn"),
    style: stylePhieuTraKqXn,
    styleGL: stylePhieuTraKqXnGL,
  },
  // phieuInXN5s mặc định template
  phieuInXN5s: {
    path: import("../PdfTemplates/ketquaxn/KetQuaXn5s"),
    style: stylePhieuTraKqXn5s,
  },
  phieuInIshi: {
    path: import("../PdfTemplates/ketquaxn/PhieuTraKqXnIshi"),
    style: stylePhieuTraKqXnIshi,
  },
  chiTietSoHoaDon: {
    path: import("../PdfTemplates/sohoadon/ChiTietSoHoaDon"),
    style: styleChiTietSoHoaDon,
    styleGL: styleChiTietSoHoaDonGL,
  },
  tuongTrinhThuThuat: {
    path: import("../PdfTemplates/TuongTrinhThuThuat"),
    style: styleTuongTrinhTT,
    styleGL: styleTuongTrinhTTGL,
  },
  phieuTamUng: {
    path: import("../PdfTemplates/QlyVienPhi/QlyTamUng/PhieuTamUng.pdf"),
    style: stylePhieuTamUng,
    styleGL: stylePhieuTamUngGL,
  },
  phieuHoanUng: {
    path: import("../PdfTemplates/QlyVienPhi/QlyTamUng/PhieuHoanUng.pdf"),
    style: stylePhieuHoanUng,
    styleGL: stylePhieuHoanUngGL,
  },

  phieuCongNo: {
    path: import("../PdfTemplates/QlyVienPhi/QlyCongNo/PhieuCongNo.pdf"),
    style: stylePhieuCongNo,
  },

  bangKeGoiLieuTrinh: {
    path: import("../PdfTemplates/QlyVienPhi/QlyCongNo/BangKeGoiLieuTrinh.pdf"),
    style: styleBangKeGoiLieuTrinh,
  },
  phieuCongNoGL: {
    path: import("../PdfTemplates/QlyVienPhi/QlyCongNo/PhieuCongNoGreenLab.pdf"),
    style: stylePhieuCongNoGreenLab
  },

  phieuHoanTien: {
    path: import("../PdfTemplates/QlyVienPhi/QlyCongNo/PhieuHoanTien.pdf"),
    style: stylePhieuHoanTien,
    styleGL: stylePhieuHoanTienGL,
  },

  phieuThuGoiDv: {
    path: import("../PdfTemplates/QlyVienPhi/PhieuThuGoiDv.pdf"),
    style: stylePhieuThuGoiDv,
    styleIshi: stylePhieuThuGoiDvIshi,
    styleAivi: stylePhieuThuGoiDvAivi,
  },
  phieuThuGoiDvGL: {
    path: import("../PdfTemplates/QlyVienPhi/PhieuThuGoiDvGL.pdf"),
    style: stylePhieuThuGoiDvGreenLab
  },

  soThuHoan: {
    path: import("../PdfTemplates/sohoadon/ChiTietSoThuHoan"),
    style: styleChiTietSoThuHoan,
    styleGL: styleChiTietSoThuHoanGL
  },
  soTamUng: {
    path: import("../PdfTemplates/sohoadon/ChiTietSoTamUng"),
    style: styleChiTietSoTamUng,
    styleGL: styleChiTietSoTamUngGL
  },
  soCongNo: {
    path: import("../PdfTemplates/sohoadon/ChiTietSoCongNo"),
    style: styleChiTietSoCongNo,
    styleGL: styleChiTietSoCongNoGL
  },

  tienTrinhKhoiTao: {
    path: import("../PdfTemplates/GoiLieuTrinh/TienTrinhKhoiTao.pdf"),
    style: styleTienTrinhKhoiTao
  },
  keHoachSD: {
    path: import("../PdfTemplates/GoiLieuTrinh/KeHoachSuDung.pdf"),
    style: styleKeHoachSuDung,
    // styleGL: styleKeHoachSuDungGL,
  },
  theoDoiTruyenDich: {
    path: import("../PdfTemplates/update_1-11/TheoDoiTruyenDich.pdf"),
    style: styleTheoDoiTruyenDich,
    // styleGL: styleTheoDoiTruyenDichGL
  },
  phieuTiepDonTienSu: {
    path: import("../PdfTemplates/update_1-11/phieuTiepDonTienSu.pdf"),
    style: styleTiepDonTienSu
  },
  bangKeCongNo: {
    path: import("../PdfTemplates/QlyVienPhi/QlyCongNo/BangKeCongNo.pdf"),
    style: styleBangKeCongNo
  },
  bangKeCongNoGL: {
    path: import("../PdfTemplates/QlyVienPhi/QlyCongNo/BangKeCongNoGreenLab.pdf"),
    style: styleBangKeCongNoGreenLab
  },
  lieuTrinhDeXuat: {
    path: import("../PdfTemplates/GoiLieuTrinh/LieuTrinhDeXuat.pdf"),
    style: styleLieuTrinhDeXuat
  },
  benhTrinhDieuTri: {
    path: import("../PdfTemplates/GoiLieuTrinh/BenhTrinhDieuTri.pdf"),
    style: styleBenhTrinhDieuTri
  },
  IN_BARCODE1: {
    path: import("../PdfTemplates/Barcode/Barcode.js"),
    style: styleBarcode,
  },
  IN_BARCODE4: {
    path: import("../PdfTemplates/Barcode/Barcode_4.js"),
    style: styleBarcode4,
  },
  IN_BARCODE2: {
    path: import("../PdfTemplates/Barcode/Barcode_2.js"),
    style: styleBarcode2,
  },
  giayChuyenVien: {
    path: import("../PdfTemplates/update_1-11/GiayChuyenVien.pdf"),
    style: styleGiayChuyenVien,
  },
  InBenhAnNgoaiTru: {
    path: import("../PdfTemplates/InBenhAnNgoaiTru"),
    style: styleInBenhAnNgoaiTru,
  },
  QR: {
    path: import("../PdfTemplates/QlyVienPhi/QR.pdf"),
    style: styleInMaQR,
  },
};

//key danh sách các loại phiếu mới
const keysPhieu = {
  PHIEU_TD_TACH: "phieuTiepDonTach", //Phiếu tiếp đón in tách
  PHIEU_CLS_TACH: "phieuCLSTach", //Phiếu CLS in tách
  PHIEU_TD_MOI: "phieuTiepDonMoi", //Phiếu tiếp đón mới
  PHIEU_CLS: "phieuCLS", //Phiếu CLS
  PHIEU_TD_TS: "phieuTiepDonTienSu",

  //phieuTiepDonTach5s
  PHIEU_TD_TACH_5S: "phieuTiepDonTach5s", //Phiếu tiếp đón mới
  PHIEU_CLS_TACH_5S: "phieuCLSTach5s", //Phiếu CLS in tách

  // Custom GreenLab
  PHIEU_TD_TACH_GL: "phieuTiepDonTachGL",
  PHIEU_CLS_TACH_GL: "phieuCLSTachGL",

  //phieuTiepDonTachIshi
  PHIEU_TD_TACH_ISHI: "phieuTiepDonTach_Ishi",
};

const horizontalLandscapeLayout = [
];

const ModalPDF = forwardRef(
  ({ configCheck, chiDinhCls = false, ly_do_kham = "", nguoi_tiep_don = {}, phong_thuc_hien = {}, columns = [], onAfterPrint, ...props }, ref) => {
    const userProfile = useSelector((state) => state.auth.user);
    const [dataSource, setDataSource] = useState([]);
    const pdfRef = useRef();
    const styleRef = useRef([]);

    const componentProps = useMemo(
      () => ({
        chiDinhCls,
        ly_do_kham,
      }),
      [chiDinhCls, ly_do_kham]
    );

    const defaultLandscapeLayout = (temp) => {
      try {
        let result = '';
        let landscapeStyle = `@page {size:landscape;}`;

        if (horizontalLandscapeLayout.includes(temp)) {
          result = landscapeStyle;
        } else { result = '' }

        return result;
      } catch (error) {
        return '';
      }
    };

    useImperativeHandle(ref, () => ({
      async openModal(dataRequest, api, extraData = {}) {
        if (!!dataRequest) {
          HLog("Request Get Payment PDF", dataRequest);

          notification.open({
            key: noti_new_tab_key,
            message: i18n.t(languageKeys.noti_Dang_tai_du_lieu),
            placement: "bottomLeft",
            icon: <LoadingOutlined />,
            duration: 0,
          });

          try {
            const res = await common_post(api, dataRequest);

            if (res.status === "OK") {
              if (!!res.data) {
                let list = res.data;

                setDataSource(() => {
                  if (!Array.isArray(res.data)) {
                    list = [res.data];
                  }
                  let template = res.template;
                  // template = "phieuThuPhiDVGL"
                  let result = [];
                  let itemFirst = list.length > 0 ? list[0] : {}; //lấy ra phần tử đầu tiên của phiếu để kiểm tra template phiếu tách

                  const normalGenSubclinicalPDF = (template, dataProps) => {
                    try {
                      list.forEach((item) => {
                        let dichvu = item.data?.[0];
                        let MA_PHIEU = "";
                        let MA_CUOC_KHAM = "";
                        if (!!dichvu) {
                          MA_PHIEU = dichvu.data[0]?.MA_PHIEU;
                          MA_CUOC_KHAM = dichvu.data[0]?.MA_CUOC_KHAM;
                        }
                        result.push({
                          ...dataProps,
                          dich_vu: [item],
                          tong_thanh_tien: item.THANH_TIEN,
                          MA_PHIEU,
                          MA_CUOC_KHAM,
                          template,
                          ...extraData
                        });
                      });
                    } catch (error) {
                      console.log(error);
                    }
                  }

                  //kiểm tra nếu là tách phiếu tiếp đón, thì duyệt danh sách phiếu và in tách
                  //gán template = phieuTiepDonMoi để in ra có giao diện giống phiếu tiếp đón mặc định
                  if (template === keysPhieu.PHIEU_TD_TACH || template === keysPhieu.PHIEU_TD_TACH_5S || template === keysPhieu.PHIEU_TD_TACH_GL || template === keysPhieu.PHIEU_TD_TACH_ISHI) {
                    // list = res.data[0].dich_vu;
                    // let data = res.data?.[0];
                    if (template === keysPhieu.PHIEU_TD_TACH_5S) {
                      // if (!userProfile.config.NHIEU_PHIEU_CHIDINH_CLS) {
                      //   result = res.data;
                      // } else {
                      //   normalGenRecepPDF(keysPhieu.PHIEU_TD_TACH_5S, res.data, extraData);
                      // }
                      result = normalGenRecepPDF(
                        keysPhieu.PHIEU_TD_TACH_5S, res.data, extraData, !!userProfile.config.NHIEU_PHIEU_CHIDINH_CLS
                      )
                    } else if (template === keysPhieu.PHIEU_TD_TACH_GL) {
                      result = normalGenRecepPDF(
                        keysPhieu.PHIEU_TD_TACH_GL, res.data, extraData, !!userProfile.config.NHIEU_PHIEU_CHIDINH_CLS
                      )
                    } else if (template === keysPhieu.PHIEU_TD_TACH_ISHI) {
                      result = normalGenRecepPDF(
                        keysPhieu.PHIEU_TD_TACH_ISHI, res.data, extraData, !!userProfile.config.NHIEU_PHIEU_CHIDINH_CLS
                      )
                    } else {
                      result = normalGenRecepPDF(keysPhieu.PHIEU_TD_MOI, res.data, extraData, !!userProfile.config.NHIEU_PHIEU_CHIDINH_CLS)
                    }
                    //Nếu là trường hợp phiếu CLS in tách
                  }
                  else if (template === keysPhieu.PHIEU_CLS_TACH || template === keysPhieu.PHIEU_CLS_TACH_5S || template === keysPhieu.PHIEU_CLS_TACH_GL) {
                    const resData = res?.data || [];
                    // list = res.data[0].dich_vu;
                    list = [];
                    for (const rsd of resData) {
                      try {
                        list = list.concat(rsd.dich_vu);
                      } catch (error) {
                        console.log(error);
                      }
                    }
                    let data = res.data[0];
                    if (template === keysPhieu.PHIEU_CLS_TACH_5S) {
                      normalGenSubclinicalPDF(keysPhieu.PHIEU_TD_TACH_5S, data);
                    } else if (template === keysPhieu.PHIEU_CLS_TACH_GL) {
                      normalGenSubclinicalPDF(keysPhieu.PHIEU_TD_TACH_GL, data);
                    } else {
                      //in phiếu tách mặc định đang làm thế
                      if (userProfile.config.NHIEU_PHIEU_CHIDINH_CLS) {
                        normalGenSubclinicalPDF(keysPhieu.PHIEU_TD_MOI, data);
                      } else {
                        //sửa trường hợp in gộp phiếu
                        try {
                          let arrService = []; //lấy ra mảng
                          res.data.forEach(x => {
                            arrService = arrService.concat(x.dich_vu);
                          })
                          let rs = res.data[0] || {};
                          rs.dich_vu = arrService;
                          rs.template = keysPhieu.PHIEU_TD_MOI
                          result = [rs];
                        } catch (error) {
                          normalGenSubclinicalPDF(keysPhieu.PHIEU_TD_MOI, data);
                        }
                      }
                    }
                  }
                  else if (itemFirst && (itemFirst.template === keysPhieu.PHIEU_CLS_TACH || itemFirst.template === keysPhieu.PHIEU_CLS_TACH_5S || itemFirst.template === keysPhieu.PHIEU_CLS_TACH_GL)) {
                    //trường hợp nếu item bên trong là phiếu tách
                    let arrData = res.data; //lấy ra mảng
                    arrData.forEach((item) => {
                      //result.push(tachDvTuMangDv(item, item.dich_vu));
                      result = tachDvTuMangDv(item, item.dich_vu, result);
                    });
                  }
                  else if (template === keysPhieu.PHIEU_TD_TS) {
                    result = normalGenRecepPDF(keysPhieu.PHIEU_TD_TS, res.data, extraData, !!userProfile.config.NHIEU_PHIEU_CHIDINH_CLS)
                    //Nếu là trường hợp phiếu CLS in tách
                  }
                  else if (template === "phieuThuPhiDV" && ["0018nadk", "0018htdk"].includes(userProfile.partner_code)) {
                    result = list.map(i => ({ ...i, template: "phieuThuK80" }))
                  }
                  else {
                    list.forEach((item) => {
                      if (!item.template) item.template = res.template;
                      if (item.template !== "donThuocMoi" || (item.template === "donThuocMoi" && item?.thuoc?.length > 0)) {
                        result.push({ ...item, ...extraData });
                      }
                    });
                  }
                  return Array.isArray(result) ? result?.map(dataRs => ({ ...dataRs, extraData })) : result;
                });

                try {
                  setTimeout(() => {
                    const d = new Printd();
                    d.onAfterPrint((event) => {
                      onAfterPrint && onAfterPrint()
                    })

                    d.print(pdfRef.current, [
                      defaultLandscapeLayout(res.template),
                      ` .pdf-container { min-height: 50vh; }
                        @media print {
                          .pdf-container { break-inside: avoid; }
                        }
                      `,
                      ...styleRef.current,
                    ]);

                    notification.success({
                      key: noti_new_tab_key,
                      message: i18n.t(languageKeys.noti_Tai_du_lieu_thanh_cong),
                      placement: "bottomLeft",
                    });
                  }, 1000);
                } catch (error) {
                  HLog("ModalPDF print error: ", error);

                  notification.error({
                    key: noti_new_tab_key,
                    message: i18n.t(languageKeys.noti_Tai_du_lieu_that_bai),
                    placement: "bottomLeft",
                  });
                }
              }
            }
          } catch (error) {
            HLog(error);
          }
        }
      },
      async openModalWithData(data, template = "", notification_key = "") {
        if (!!data && !!template) {
          HLog("Request Get Payment PDF", data);

          if (!notification_key) {
            notification.open({
              key: noti_new_tab_key,
              message: i18n.t(languageKeys.noti_Dang_tai_du_lieu),
              placement: "bottomLeft",
              icon: <LoadingOutlined />,
              duration: 0,
            });
          }

          try {
            setDataSource(() => {
              let list = data;
              if (!Array.isArray(data)) list = [data];
              HLog(
                "LIST",
                list,
                list.map((item) => {
                  if (!item.template) item.template = template;
                  return item;
                })
              );
              return list.map((item) => {
                if (!item.template) item.template = template;
                return item;
              });
            });

            try {
              setTimeout(() => {
                const d = new Printd();
                d.onAfterPrint((event) => {
                  onAfterPrint && onAfterPrint()
                })
                d.print(pdfRef.current, [
                  defaultLandscapeLayout(template),
                  ` .pdf-container { min-height: 50vh; }
                    @media print {
                      .pdf-container { break-inside: avoid; }
                    }
                  `,
                  ...styleRef.current,
                ]);

                notification.success({
                  key: !!notification_key ? notification_key : noti_new_tab_key,
                  message: i18n.t(languageKeys.noti_Tai_du_lieu_thanh_cong),
                  placement: "bottomLeft",
                });
              }, 1000);
            } catch (error) {
              HLog("ModalPDF print error: ", error);
            }
          } catch (error) {
            HLog(error);
          }
        }
      },
    }));

    //xử lí tách các dịch vụ cùng phiếu ra thành 1 phiếu riêng trong mảng dịch vụ, mục đích dùng để in tách
    //data: chính là dữ liệu tổng hợp dùng để + vào phiếu tách
    const tachDvTuMangDv = (data, arrDichvu, result) => {
      arrDichvu.forEach((itemDv) => {
        let dichvu = itemDv.data[0];
        let MA_PHIEU = "";
        let MA_CUOC_KHAM = "";
        if (!!dichvu) {
          MA_PHIEU = dichvu.data[0].MA_PHIEU;
          MA_CUOC_KHAM = dichvu.data[0]?.MA_CUOC_KHAM;
        }

        result.push({ ...data, dich_vu: [itemDv], MA_PHIEU, template: keysPhieu.PHIEU_TD_MOI, MA_CUOC_KHAM, tong_thanh_tien: itemDv.THANH_TIEN });
      });
      return result;
    };

    const renderPhieu = useMemo(() => {
      let styleList = [];
      let arrPhieu = [];
      // HLog("renderPhieu configCheck: ", configCheck, " dataSource: ", dataSource);
      // kiểm tra config
      // if (!!configCheck) {
      //   // nếu config cần check là NHIEU_PHIEU_CHIDINH_CLS và config của viện là false
      //   if (configCheck === "NHIEU_PHIEU_CHIDINH_CLS" && !userProfile.config.NHIEU_PHIEU_CHIDINH_CLS && dataSource.length > 0) {
      //     HLog("renderPhieu dataSource: ", dataSource, " configCheck: ", configCheck);
      //     let Component = <></>;
      //     let firstItem = {};
      //     dataSource.forEach((data, index) => {
      //       if (index === 0) {
      //         Component = lazy(() => monitor[data.template].path);
      //         styleList.push(monitor[data.template].style);
      //         firstItem = data;
      //         firstItem.dich_vu = firstItem.dich_vu.map((item) => ({
      //           name: item,
      //           room: firstItem.hospital.ten_phong,
      //         }));
      //       } else {
      //         firstItem.dich_vu = [
      //           ...firstItem.dich_vu,
      //           ...data.dich_vu.map((item) => ({
      //             name: item,
      //             room: data.hospital.ten_phong,
      //           })),
      //         ];
      //       }
      //     });
      //     arrPhieu.push(
      //       <div className="pdf-container">
      //         <Suspense fallback={<></>}>
      //           <Component
      //             data={dataSource}
      //             rid={rid(5)}
      //             combineAllRoom
      //             nguoi_tiep_don={nguoi_tiep_don}
      //             phong_thuc_hien={phong_thuc_hien}
      //             {...componentProps}
      //             {...props}
      //           />
      //         </Suspense>
      //       </div>
      //     );
      //   }
      // }

      // nếu mà đống điều kiện ở trên không được check...
      if (arrPhieu.length === 0) {
        arrPhieu = dataSource.map((data) => {
          //HLog(" arrPhieu empty...data: ", data)
          const Component = lazy(() => monitor[data.template].path);
          let partner_code = userProfile?.partner_code

          switch (partner_code) {
            case "isgisg123":
              if (monitor[data.template].hasOwnProperty("styleIshi")) {
                styleList.push(monitor[data.template].styleIshi);
              } else {
                styleList.push(monitor[data.template].style);
              }
              break;

            case "0018nadk":
            case "0018dk248":
            case "0018htdk":
              if (monitor[data.template].hasOwnProperty("styleNamAuHaThanh248")) {
                styleList.push(monitor[data.template].styleNamAuHaThanh248);
              } else {
                styleList.push(monitor[data.template].style);
              }
              break;

            case "aivi052428":
              if (monitor[data.template].hasOwnProperty("styleAivi")) {
                styleList.push(monitor[data.template].styleAivi);
              } else {
                styleList.push(monitor[data.template].style);
              }
              break;

            case "greenlabuh":
              if (monitor[data.template].hasOwnProperty("styleGL")) {
                styleList.push(monitor[data.template].styleGL);
              } else {
                styleList.push(monitor[data.template].style);
              }
              break;

            case "0029sct":
              // case "noiquoctuan5":
              if (monitor[data.template].hasOwnProperty("styleSCT")) {
                styleList.push(monitor[data.template].styleSCT);
              } else {
                styleList.push(monitor[data.template].style);
              }
              break;

              case "pkdk5s12":
              if (monitor[data.template].hasOwnProperty("style5s")) {
                styleList.push(monitor[data.template].style5s);
              } else {
                styleList.push(monitor[data.template].style);
              }
              break;

            default:
              styleList.push(monitor[data.template].style);
              break;
          }
          // styleList.push(monitor[data.template].style);
          return (
            <div className="pdf-container" key={rid(5)}>
              <Suspense fallback={<></>}>
                <Component
                  data={data}
                  rid={rid(5)}
                  nguoi_tiep_don={nguoi_tiep_don}
                  phong_thuc_hien={phong_thuc_hien}
                  columns={columns}
                  {...componentProps}
                  {...props}
                />
              </Suspense>
            </div>
          );
        });
      }

      styleRef.current = styleList;

      return arrPhieu;
    }, [columns, componentProps, configCheck, dataSource, nguoi_tiep_don, phong_thuc_hien, props, userProfile.config.NHIEU_PHIEU_CHIDINH_CLS]);

    return (
      <div ref={pdfRef} className="print-src">
        {renderPhieu}
      </div>
    );
  }
);

export default memo(ModalPDF);


// Xử lý tách ng chỉ định
const normalGenRecepPDF = (template, source, extraData, isMany) => {

  let result = []
  const list = source[0].dich_vu;
  const data = source?.[0];

  const calcTotal = (childService) => {
    return childService.SO_LUONG * (childService.DON_GIA || childService.GIA_DICH_VU)
  }
  const calcExemp = (childService) => {
    return childService.MIEN_GIAM_TIEP_DON
      ? childService.MIEN_GIAM_TIEP_DON > 100
        ? childService.MIEN_GIAM_TIEP_DON
        : calcTotal(childService) / 100 * childService.MIEN_GIAM_TIEP_DON
      : childService.IS_GOI_DV
        ? calcTotal(childService)
        : 0
  }
  const TOTAL = list
    ?.reduce((prev, curr) => prev + curr?.data
      ?.reduce((acc, obj) => acc + obj?.data
        ?.reduce((last, now) => last + calcTotal(now)
          , 0)
        , 0)
      , 0)
  const EXEMP = list
    ?.reduce((prev, curr) => prev + curr?.data
      ?.reduce((acc, obj) => acc + obj?.data
        ?.reduce((last, now) => last + calcExemp(now)
          , 0)
        , 0)
      , 0)

  // Format
  list
    .flatMap(i => i.data).flatMap(i => i.data)
    .forEach(dv => {

      const item = {
        ...dv,
        MIEN_GIAM_TIEP_DON: !!dv.IS_GOI_DV ? 100 : dv.MIEN_GIAM_TIEP_DON
      }

      const index = result.findIndex(a => a.dich_vu.find(b => b.data.find(c => c.data
        .find(i => (i.NGUOI_SUA_ID ?? i.NGUOI_CHI_DINH_ID) === (item.NGUOI_SUA_ID ?? item.NGUOI_CHI_DINH_ID))
      )))

      if (index >= 0) {
        const nameIdx = result[index].dich_vu.findIndex(i => i.PHONG_ID === item.PHONG_ID)

        if (nameIdx >= 0) {
          const typeIdx = result[index].dich_vu[nameIdx].data.findIndex(i => i.LOAI_DV_ID === item.LOAI_DV_ID)
          if (typeIdx >= 0) {
            result[index].dich_vu[nameIdx].data[typeIdx].data.push(item)
          } else {
            result[index].dich_vu[nameIdx].data.push({
              TEN_LOAI_DV: item.TEN_LOAI_DV,
              LOAI_DV_ID: item.LOAI_DV_ID,
              data: [item]
            })
          }
        } else {
          result[index].dich_vu.push({
            PHONG_THUC_HIEN: item.TEN_KHOA_PHONG,
            PHONG_ID: item.PHONG_ID,
            data: [{
              TEN_LOAI_DV: item.TEN_LOAI_DV,
              LOAI_DV_ID: item.LOAI_DV_ID,
              data: [item]
            }]
          })
        }

      } else {
        result.push({
          ...data,
          template,
          tong_sau_mien_giam_dv: (TOTAL - EXEMP) || 0,
          dich_vu: [{
            PHONG_THUC_HIEN: item.TEN_KHOA_PHONG,
            PHONG_ID: item.PHONG_ID,
            data: [{
              TEN_LOAI_DV: item.TEN_LOAI_DV,
              LOAI_DV_ID: item.LOAI_DV_ID,
              data: [item]
            }]
          }],
          nguoi_hien_thi: item.NGUOI_SUA_ID ? item.CHU_KY_NGUOI_SUA : item.CHU_KY_NGUOI_TAO,
          nguoi_tiep_don: item.NGUOI_SUA_ID ? item.CHU_KY_NGUOI_SUA : item.CHU_KY_NGUOI_TAO,
          ...extraData,
        })
      }
    })

  // In gốp phiếu
  if (!isMany) {
    return result;

    // In tách phiếu
  } else {
    let arr = []
    result.forEach(item => {
      item.dich_vu.forEach(i => {
        const dv = i.data?.[0].data?.[0] || {}
        arr.push({
          ...item,
          dich_vu: [i],
          tong_thanh_tien: i.THANH_TIEN,
          MA_PHIEU: dv.MA_PHIEU,
          MA_CUOC_KHAM: dv.MA_CUOC_KHAM,
          template,
          ...extraData
        });
      })
    })
    return arr
  }
}