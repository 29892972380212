import moment from "moment";
import React from "react";
import {
  layDiaChi,
  rid as _rid,
  formatCurrency,
  formatDateOfBirth,
  getSexFromString,
} from "../../../helpers";
import { useSelector } from "react-redux";
import i18n, { languageKeys, languages } from "../../../i18n";
import JsBarcode from "jsbarcode";
import { uniq } from "lodash";
import Constants from "constants/Constants";
import Images from "components/Images/Images";
import { enDateFormat } from "constants/keys";

export const styleTiepDonTienSu = `
#phieu-td-tien-su * { font-size: 12px; line-height: 1rem; font-family: "Times New Roman", Times, serif; }
#phieu-td-tien-su .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#phieu-td-tien-su .header { display: flex; gap: 10px; position:relative; font-size: 10px;}
#phieu-td-tien-su .content-header { position:relative; width:70% }
#phieu-td-tien-su .sub-barcode-text { font-size:12px;position:absolute;top:50px; left:50%; line-height: 1.2em; transform: translateX(-50%);}
#phieu-td-tien-su .flex {
    display: flex;
  }
#phieu-td-tien-su .flex.justify {
    justify-content: space-around;
  }

#phieu-td-tien-su .tel {
    margin-right: 50px;
  }
#phieu-td-tien-su .txt-700 {
    font-weight: 700;
  }
#phieu-td-tien-su .column {
  float: left;
  width: 33.33%;
}
#phieu-td-tien-su .container-title {
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    width: 90%;
    margin: 1.8rem auto 1.4rem auto;
  }
#phieu-td-tien-su .container__title--main {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }
#phieu-td-tien-su .container-wrapper-flex {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  #phieu-td-tien-su .container-wrapper-flex .col-3{
    width: 30%;
  }

  #phieu-td-tien-su .container-wrapper-flex .col-4{
    width: 40%;
  }

  #phieu-td-tien-su .thongtin-khambenh {
    width: 100%;
    display: flex;
    margin-top: 12px;
  }

  #phieu-td-tien-su .thongtin-khambenh .left {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  #phieu-td-tien-su .thongtin-khambenh .right {
    width: 35%;
  }

  #phieu-td-tien-su .table {
    margin-block: 0.6rem;
    width: 100%;
}

  #phieu-td-tien-su .table * {
    font-size: 12px;
}

#phieu-td-tien-su .table,
.table th, 
.table td {
    border: 1px solid black;
    border-collapse: collapse;
}

#phieu-td-tien-su .table th,
.table td {
    padding: 5px 10px;
}

  #phieu-td-tien-su hr.solid {
    border-top: 1px solid #000;
  }

  #phieu-td-tien-su .footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
  }

  #phieu-td-tien-su .footer .footer-confirm {
    margin-right: 44px;
    text-align: center;
  }

  #phieu-td-tien-su .name-space {
    height: 80px;
  }
  
  #phieu-td-tien-su .upperTxt{
    text-transform: uppercase;
  }
  #phieu-td-tien-su .txt-size-m{
    font-size: 12px;
  }

  #phieu-td-tien-su .barcode-top-right {
    position: absolute;
    width:30%;
    right:0;
    top: 0;
  }

  #phieu-td-tien-su .footer .signature {
    margin-right: 44px;
    text-align: center;
  }

@media print {
  #phieu-td-tien-su, #phieu-td-tien-su .foot-break { break-inside: avoid; }
  #phieu-td-tien-su { page-break-after: always;}
}
`;

const arrayPartnerCode = ['dth']

const PhieuTiepDonTienSu = ({ data, rid, chiDinhCls }) => {

  const userProfile = useSelector((state) => state.auth.user);
  let { dich_vu, chi_so_sinh_ton, tien_su_benh } = data;

  const checkHoanThanh = (value) => {
    return [...Constants.ARRAY_TRANG_THAI_XONG, "DANG_THUC_HIEN", "DANG_KHAM"].includes(value || "")
  }
  React.useEffect(() => {
    let MA_PHIEU = null; //lấy ra mã phiếu để kiểm tra, nếu có mã phiếu thì in mã phiếu, ngược lại in mã bệnh nhân
    if (arrayPartnerCode.includes(userProfile.partner_code)) {
      MA_PHIEU = data.MA_PHIEU
    } else {
      MA_PHIEU = data.MA_CUOC_KHAM || dich_vu?.[0]?.data?.[0]?.data?.[0]?.MA_CUOC_KHAM
    }
    if (!!rid && !!MA_PHIEU) {
      //in mã phiếu
      JsBarcode(`#barcode-${rid}`)
        .options({ font: "OCR-B" })
        .CODE128(MA_PHIEU, {
          text: ` `,
          fontSize: 10,
          width: 1.2,
          height: 40,
          textMargin: 0,
          margin: 0,
          padding: 0,
        })
        .render();
    }
  }, [data.MA_PHIEU, data?.MA_CUOC_KHAM, rid]);

  const calcTotal = (childService) => {
    return childService.SO_LUONG * (childService.DON_GIA || childService.GIA_DICH_VU)
  }

  const calcSvExp = (childService) => {
    return childService.MIEN_GIAM_TIEP_DON
      ? (childService.MIEN_GIAM_TIEP_DON > 100
        ? childService.MIEN_GIAM_TIEP_DON
        : (calcTotal(childService) / 100 * childService.MIEN_GIAM_TIEP_DON))
      : 0
  }

  const calcExemp = (childService) => {
    const MGTD = calcSvExp(childService)
    const TTT = calcTotal(childService) - MGTD
    return data.mien_giam_tong_hoa_don
      ? data.mien_giam_tong_hoa_don > 100
        ? MGTD + (TTT * data.mien_giam_tong_hoa_don / tong_sau_mien_giam_dv)
        : MGTD + (TTT / 100 * data.mien_giam_tong_hoa_don)
      : MGTD
  }

  const TOTAL = dich_vu
    ?.reduce((prev, curr) => prev + curr?.data
      ?.reduce((acc, obj) => acc + obj?.data
        ?.reduce((last, now) => last + calcTotal(now)
          , 0), 0), 0)

  const tong_sau_mien_giam_dv = userProfile.config.NHIEU_PHIEU_CHIDINH_CLS
    ? data.tong_sau_mien_giam_dv
    : TOTAL - dich_vu
      ?.reduce((prev, curr) => prev + curr?.data
        ?.reduce((acc, obj) => acc + obj?.data
          ?.reduce((last, now) => last + calcSvExp(now)
            , 0), 0), 0)

  const EXEMP = dich_vu
    ?.reduce((prev, curr) => prev + curr?.data
      ?.reduce((acc, obj) => acc + obj?.data
        ?.reduce((last, now) => last + calcExemp(now)
          , 0), 0), 0)

  return (
    <div id="phieu-td-tien-su">
      <div className="header">
        {!!userProfile.benh_vien.ANH_DAI_DIEN && (
          <Images className="logo" imgId={userProfile.benh_vien.ANH_DAI_DIEN} />
        )}
        <div className="content-header">
          <h2 style={{ margin: 0, fontSize: 10 }} className="upperTxt">
            {userProfile?.benh_vien?.TEN_CSKCB}
          </h2>
          <div style={{ wordWrap: 'break-word' }}>
            <span className="txt-700">{i18n.t(languageKeys.dia_chi)}:</span> {layDiaChi(userProfile.benh_vien)}
          </div>
          <div className="flex">
            <div className="tel">
              <span className="txt-700">{i18n.t(languageKeys.dien_thoai)}: </span> {convertString(userProfile.benh_vien.SO_DIEN_THOAI)}
            </div>
            <div>
              <span className="txt-700">Website:</span> {userProfile?.benh_vien?.WEBSITE || ''}
            </div>
          </div>
          <div>
            <span className="txt-700">Email:</span> {convertString(userProfile.benh_vien.EMAIL)}
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start', textAlign: 'center', position: 'relative', minWidth: '150px' }}>
          {(((data.MA_CUOC_KHAM || dich_vu?.[0]?.data?.[0]?.data?.[0]?.MA_CUOC_KHAM) && !arrayPartnerCode.includes(userProfile.partner_code)) || (data.MA_PHIEU && arrayPartnerCode.includes(userProfile.partner_code))) && <span><svg id={`barcode-${rid}`}></svg></span>}
          <div className="sub-barcode-text">
            {((data.MA_CUOC_KHAM || dich_vu?.[0]?.data?.[0]?.data?.[0]?.MA_CUOC_KHAM) && !arrayPartnerCode.includes(userProfile.partner_code)) && (
              <div style={{ width: 300, textAlign: 'center' }}>
                {i18n.t(languageKeys.ma_ca_kham)}: <b>{data.MA_CUOC_KHAM}</b>
              </div>
            )}
            {(data.MA_PHIEU && arrayPartnerCode.includes(userProfile.partner_code)) && (
              <div style={{ width: 300, textAlign: 'center' }}>
                {i18n.t(languageKeys.field_So_phieu)}: <b>{data.MA_PHIEU}</b>
              </div>
            )}
            <div style={{ width: 300, textAlign: 'center' }}>
              {i18n.t(languageKeys.ma_kh)}:<b> {data.benh_nhan.ma_benh_nhan}</b>
            </div>
          </div>
        </div>

      </div>
      <div className="container">
        <div className="container-title">{i18n.t(languageKeys.title_phieu_chi_dinh_dich_vu)}</div>
        <div className="container__chapter--1">
          <div className="container__ttbn--wrapper">
            <div className="container-wrapper-flex">
              <div className="col-3">
                <div>
                  {i18n.t(languageKeys.ten_benh_nhan)}: <span className="txt-700 txt-size-m"> {data.benh_nhan?.ten_benh_nhan} </span>
                </div>
                <div>
                  {i18n.t(languageKeys.field_Gioi_tinh)}: {getSexFromString(data.benh_nhan?.gioi_tinh)}
                </div>
              </div>
              <div className="col-4">
                <div>
                  {i18n.t(languageKeys.field_So_dien_thoai)}: <span className="txt-700">{data.benh_nhan?.sdt}</span>
                </div>
                <div>
                  {i18n.t(languageKeys.field_Doi_tuong)}: {data.resDT
                    ? uniq(data.resDT?.flatMap(i => i.TEN_DOI_TUONG)).join(", ")
                    : uniq(data.dich_vu?.flatMap(i => i.data).flatMap(i => i.data).map(i => i.TEN_DOI_TUONG)).join(", ")
                  }
                </div>
              </div>
              <div className="col-3">
                <div>
                  {i18n.t(languageKeys.field_Ngay_sinh)}: {formatDateOfBirth(data.benh_nhan?.ngay_sinh)}
                </div>
                <div>
                  {/* {i18n.t(languageKeys.field_So_dien_thoai)}:<span className="txt-700"> {` ${data.benh_nhan?.sdt}`}</span> */}
                </div>
              </div>
            </div>
          </div>
          <div className="dia-chi">
            {i18n.t(languageKeys.dia_chi)}: {data.benh_nhan?.dia_chi}
          </div>
          <div>
            {i18n.t(languageKeys.noi_chi_dinh)}: {!data.ten_phong ? chiDinhCls ? "" : i18n.t(languageKeys.bo_phan_tiep_don) : data.ten_phong}
          </div>
        </div>

        <div style={{ marginTop: '6px' }}>
          <div className="container__title--main"> I. {i18n.t(languageKeys.tien_su_benh).toUpperCase()}: </div>
          <div>
            <div className="txt-size-m">1. {i18n.t(languageKeys.field_Tien_su_ban_than)}: {tien_su_benh.tien_su_ban_than}</div>
            <div className="txt-size-m">2. {i18n.t(languageKeys.tien_su_san_khoa)}: {tien_su_benh.tien_su_san_khoa}</div>
            <div className="txt-size-m">3. {i18n.t(languageKeys.tien_su_di_ung)}: {tien_su_benh.tien_su_di_ung}</div>
            <div className="txt-size-m">4. {i18n.t(languageKeys.tai_nan_thuong_tich)}: {tien_su_benh.tai_nan_thuong_tich}</div>
            <div className="txt-size-m">5. {i18n.t(languageKeys.field_Tien_su_gia_dinh)}: {tien_su_benh.tien_su_gia_dinh}</div>
            <div className="txt-size-m">6. {i18n.t(languageKeys.tien_su_benh_khac)}: {tien_su_benh.tien_su_benh_khac}</div>
          </div>
        </div>

        <div className="thongtin-khambenh" style={{ display: "flex", flexDirection: "column", marginBottom: 10 }}>
          <div className="container__title--main">II. {i18n.t(languageKeys.field_Chi_so_sinh_ton)?.toUpperCase()}</div>
          <div className="container-row">
            <div className="row">
              <div className="column txt-size-m">{i18n.t(languageKeys.field_Nhiet_do)} {i18n.t(languageKeys.field_Do_C)}: {convertString(chi_so_sinh_ton.nhiet_do)} </div>
              <div className="column txt-size-m">{i18n.t(languageKeys.field_Mach)} {i18n.t(languageKeys.field_Lan_phut)}: {convertString(chi_so_sinh_ton.mach)} </div>
              <div className="column txt-size-m">{i18n.t(languageKeys.field_Huyet_ap)} (mmHg):
                {!!chi_so_sinh_ton.huyet_ap && Array.isArray(chi_so_sinh_ton.huyet_ap)
                  ? ` ${convertString(chi_so_sinh_ton.huyet_ap[0])} / ${convertString(chi_so_sinh_ton.huyet_ap[1])}`
                  : ""}
              </div>
              <div className="column txt-size-m">{i18n.t(languageKeys.field_Nhip_tho)} {i18n.t(languageKeys.field_Lan_phut)}: {convertString(chi_so_sinh_ton.nhip_tho)} </div>
              <div className="column txt-size-m">{i18n.t(languageKeys.chieu_cao)} ( cm): {convertString(chi_so_sinh_ton.chieu_cao)} </div>
              <div className="column txt-size-m">{i18n.t(languageKeys.can_nang)} (Kg): {convertString(chi_so_sinh_ton.can_nang)} </div>
            </div>
          </div>
        </div>

        <div>
          <div className="container__title--main">III. MONG MUỐN CỦA KHÁCH HÀNG</div>
          <table className="table">
            <thead>
              <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.stt)}</th>
              <th style={{ textAlign: "start", width: "25%", maxWidth: "30%" }}>{i18n.t(languageKeys.field_Ten_dich_vu)}</th>
              <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_So_luong)}</th>
              <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_don_gia)}</th>
              <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_tong_tien)}</th>
              <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_doi_tuong_mien_giam)}</th>
              <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.vien_phi_thanh_tien)}</th>
              <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_Da_TT)}</th>
              <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.dang_TH)}</th>
            </thead>
            <tbody>
              {userProfile.config.NHIEU_PHIEU_CHIDINH_CLS
                // Tách phiếu
                ? dich_vu[0].data.map((item, index) => {
                  return (
                    <>
                      <tr key={_rid()}>
                        <td colSpan={4} style={{ textAlign: "start", fontWeight: 700 }}>
                          {`${index + 1}. ${item.TEN_LOAI_DV}`}
                        </td>
                        <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                          {formatCurrency(item?.data?.reduce((acc, curr) => acc + calcTotal(curr), 0), "", true)}
                        </td>
                        <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                          {formatCurrency(Math.round(item?.data?.reduce((acc, curr) => acc + calcExemp(curr), 0)), "", true)}
                        </td>
                        <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                          {formatCurrency(Math.round(item?.data?.reduce((acc, curr) => acc + (calcTotal(curr) - calcExemp(curr)), 0)), "", true)}
                        </td>
                        <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                        </td>
                        <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                        </td>
                      </tr>
                      {!!item.data &&
                        item?.data?.map((childService, id) => {
                          return (
                            <>
                              <tr key={_rid()}>
                                <td style={{ textAlign: 'center' }}>{id + 1}</td>
                                <td style={{ width: "30%" }}>{childService.TEN_DICHVU || childService?.TEN_DICH_VU_KEM_THEO}</td>
                                <td style={{ textAlign: "center" }}>{childService.SO_LUONG}</td>
                                <td style={{ textAlign: "end" }}>
                                  {childService.DON_GIA
                                    ? formatCurrency(childService.DON_GIA, "", true)
                                    : null || formatCurrency(childService.GIA_DICH_VU, "", true)}
                                </td>
                                <td style={{ textAlign: "end" }}>{formatCurrency(calcTotal(childService), "", true)}</td>
                                <td style={{ textAlign: "end" }}>{formatCurrency(Math.round(calcExemp(childService)), "", true)}</td>
                                <td style={{ textAlign: "end" }}>
                                  {formatCurrency(calcTotal(childService) - Math.round(calcExemp(childService)), "", true)}
                                </td>
                                <td style={{ textAlign: "center", fontWeight: 700 }}>{(childService.TRANG_THAI_TT == 1 || childService.THANH_TOAN == 1) && "✔"}</td>
                                <td style={{ textAlign: "center", fontWeight: 700 }}>{checkHoanThanh(childService?.TRANG_THAI_TH) && "✔"}</td>
                              </tr>
                              {(childService.DV_KEM_THEO?.length && userProfile.config.HIEN_THI_KEM_THEO)
                                ? childService.DV_KEM_THEO.map((dvkt, num) => (
                                  <tr key={num}>
                                    <td></td>
                                    <td style={{ width: "30%" }}>{dvkt.TEN_DICHVU || dvkt?.TEN_DICH_VU_KEM_THEO}</td>
                                    <td style={{ textAlign: "center" }}>-</td>
                                    <td style={{ textAlign: "center" }}>-</td>
                                    <td style={{ textAlign: "center" }}>-</td>
                                    <td style={{ textAlign: "center" }}>-</td>
                                    <td style={{ textAlign: "center" }}>-</td>
                                    <td style={{ textAlign: "center", fontWeight: 700 }}>{(dvkt.TRANG_THAI_TT == 1 || dvkt.THANH_TOAN == 1) && "✔"}</td>
                                    <td style={{ textAlign: "center", fontWeight: 700 }}>{checkHoanThanh(dvkt?.TRANG_THAI_TH) && "✔"}</td>
                                  </tr>
                                )) : <></>}
                            </>
                          );
                        })}
                    </>
                  );
                })
                // Gộp phiếu
                : dich_vu.map((dv, num) => {
                  return dv.data.map((item, index) => {
                    // HLog("item: ", item)
                    return (
                      <>
                        <tr key={_rid()}>
                          <td colSpan={4} style={{ textAlign: "start", fontWeight: 700 }}>
                            {`${num + 1}. ${item.TEN_LOAI_DV}`}
                          </td>
                          <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                            {formatCurrency(item?.data?.reduce((acc, curr) => acc + calcTotal(curr), 0), "", true)}
                          </td>
                          <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                            {formatCurrency(Math.round(item?.data?.reduce((acc, curr) => acc + calcExemp(curr), 0)), "", true)}
                          </td>
                          <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                            {formatCurrency(Math.round(item?.data?.reduce((acc, curr) => acc + (calcTotal(curr) - calcExemp(curr)), 0)), "", true)}
                          </td>
                          <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                          </td>
                          <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>
                          </td>
                        </tr>
                        {!!item.data &&
                          item?.data?.map((childService, id) => {
                            return (
                              <>
                                <tr key={_rid()}>
                                  <td style={{ textAlign: "center" }}>{id + 1}</td>
                                  <td style={{ width: "30%" }}>{childService.TEN_DICHVU || childService?.TEN_DICH_VU_KEM_THEO}</td>
                                  <td style={{ textAlign: "center" }}>{childService.SO_LUONG}</td>
                                  <td style={{ textAlign: "end" }}>
                                    {childService.DON_GIA
                                      ? formatCurrency(childService.DON_GIA, "", true)
                                      : null || formatCurrency(childService.GIA_DICH_VU, "", true)}
                                  </td>
                                  <td style={{ textAlign: "end" }}>{formatCurrency(calcTotal(childService), "", true)}</td>
                                  <td style={{ textAlign: "end" }}>{formatCurrency(Math.round(calcExemp(childService)), "", true)}</td>
                                  <td style={{ textAlign: "end" }}>
                                    {formatCurrency(calcTotal(childService) - Math.round(calcExemp(childService)), "", true)}
                                  </td>
                                  <td colSpan={1} style={{ textAlign: "center", fontWeight: 700 }}>{(childService.TRANG_THAI_TT == 1 || childService.THANH_TOAN == 1) && "✔"}</td>
                                  <td colSpan={1} style={{ textAlign: "center", fontWeight: 700 }}>{checkHoanThanh(childService.TRANG_THAI_TH) && "✔"}</td>
                                </tr>
                                {(childService.DV_KEM_THEO?.length && userProfile.config.HIEN_THI_KEM_THEO)
                                  ? childService.DV_KEM_THEO.map((dvkt, num) => (
                                    <tr key={num}>
                                      <td></td>
                                      <td style={{ width: "30%" }}>{dvkt.TEN_DICHVU || dvkt?.TEN_DICH_VU_KEM_THEO}</td>
                                      <td style={{ textAlign: "center" }}>-</td>
                                      <td style={{ textAlign: "center" }}>-</td>
                                      <td style={{ textAlign: "center" }}>-</td>
                                      <td style={{ textAlign: "center" }}>-</td>
                                      <td style={{ textAlign: "center" }}>-</td>
                                      <td colSpan={1} style={{ textAlign: "center", fontWeight: 700 }}>{(dvkt.TRANG_THAI_TT == 1 || dvkt.THANH_TOAN == 1) && "✔"}</td>
                                      <td colSpan={1} style={{ textAlign: "center", fontWeight: 700 }}>{checkHoanThanh(dvkt.TRANG_THAI_TH) && "✔"}</td>
                                    </tr>
                                  )) : <></>}
                              </>
                            );
                          })}
                      </>
                    );
                  })
                })
              }
              <tr key={_rid()}>
                <td colSpan={4} style={{ textAlign: "end", fontWeight: 700 }}>
                  {i18n.t(languageKeys.tong)}:
                </td>
                <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>{formatCurrency(TOTAL, "", true)}</td>
                <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>{formatCurrency(Math.round(EXEMP), "", true)}</td>
                <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}>{formatCurrency(TOTAL - Math.round(EXEMP), "", true)}</td>
                <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}></td>
                <td colSpan={1} style={{ textAlign: "end", fontWeight: 700 }}></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <strong style={{ fontStyle: 'italic' }}>{i18n.t(languageKeys.da_duoc_tu_van_day_du_v2)}</strong>
      <div className="footer">
        <div style={{ textAlign: "center" }}>
          <div></div>
        </div>
        <div className="signature">
          {i18n.language === languages.tieng_viet ? (
            data?.ngay_tao ? (
              <i>
                {data?.gio}, {data.ngay_tao}
              </i>
            ) : (
              <i>
                {moment().format("HH:mm") + ", "}
                Ngày {moment().date()},tháng {moment().month() + 1}, năm {moment().year()}
              </i>
            )
          ) : (
            <i> {moment().format(enDateFormat)}</i>
          )}
          <div className="txt-700">{i18n.t(languageKeys.nguoi_chi_dinh)}</div>
          <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
          <div className="name-space"></div>
          <div className="txt-700">{data.nguoi_hien_thi || data.bac_si_chi_dinh || data.nguoi_tiep_don}</div>
        </div>
      </div>
    </div>
  );
};

let convertString = (field) => {
  let text = "";

  if (!!field && typeof field === "string") {
    text = field;
  } else if (!!field && typeof field === "number") {
    text = `${field}`;
  }
  return text;
};

export default PhieuTiepDonTienSu;
