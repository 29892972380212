import { memo } from "react";
import { useSelector } from "react-redux";
import { rid, formatNumberToPrice, getFullName, layDiaChi } from "../../../helpers";
import moment from "moment";
import i18n, { languageKeys, languages } from "../../../i18n";
import Images from "components/Images/Images";
import { enDateFormat } from "constants/keys";

export const styleChiTietSoCongNo = `
#so-cong-no * { font-size: 12px; line-height: 1rem; font-family: "Times New Roman", Times, serif; }
#so-cong-no .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#so-cong-no .header { display: flex; gap: 10px; font-size: 10px;}
#so-cong-no .hospital-name { text-transform: uppercase; font-weight: bold; }
#so-cong-no .thong-tin-phieu { display:flex ; }
#so-cong-no .title { text-transform: uppercase; font-size: 14px; font-weight: bold; text-align: center; width: 90%; margin: 2.5rem auto 0.25rem auto; }
#so-cong-no .table { margin-block: 0.75rem; }
#so-cong-no .table * { font-size: 12px; }
#so-cong-no .table { page-break-inside:auto; }
#so-cong-no tr    { page-break-inside:avoid; page-break-after:auto; }
#so-cong-no .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#so-cong-no .table th, .table td { padding: 5px 10px; }
#so-cong-no .table th { text-align: start; }
@media print {
   #so-cong-no, #so-cong-no .foot-break { break-inside: avoid; }
   #so-cong-no{page-break-after: always;}
}
#so-cong-no .loai { text-transform: uppercase; font-size: 14px; font-weight: bold; text-align: center; width: 90%; margin: 2.5rem auto 1rem auto;}
#so-cong-no .time {  font-size: 12px; margin-bottom : 10px;width : 100%;  text-align: center; display:flex,flex-direction: row; justify-content : center}
#so-cong-no .time-bold {  font-size: 12px; margin-bottom : 10px, font-weight: bold;}
#so-cong-no .text--bold{    font-weight: bold !important;   }
#so-cong-no .align-center{ text-align: center!important; }
#so-cong-no .align-right{ text-align: right!important; }
#so-cong-no .phone-website { display: flex; gap: 24px; align-items: center }
`;

export const styleChiTietSoCongNoGL = `
#so-cong-no * { font-size: 14pt; font-family: "Times New Roman", Times, serif; }
#so-cong-no .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#so-cong-no .header { display: flex; gap: 10px; font-size: 14pt;}
#so-cong-no .hospital-name { text-transform: uppercase; font-weight: bold; font-size: 16pt;}
#so-cong-no .thong-tin-phieu { display:flex ; }
#so-cong-no .title { text-transform: uppercase; font-size: 14pt; font-weight: bold; text-align: center; width: 90%; margin: 2.5rem auto 0.25rem auto; }
#so-cong-no .table { margin-block: 0.75rem; }
#so-cong-no .table * { font-size: 14pt; }
#so-cong-no .table { page-break-inside:auto; }
#so-cong-no tr    { page-break-inside:avoid; page-break-after:auto; }
#so-cong-no .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#so-cong-no .table th, .table td { padding: 5px 10px; }
#so-cong-no .table th { text-align: start; }
@media print {
   #so-cong-no, #so-cong-no .foot-break { break-inside: avoid; }
   #so-cong-no{page-break-after: always;}
}
#so-cong-no .loai { text-transform: uppercase; font-size: 14pt; font-weight: bold; text-align: center; width: 90%; margin: 2.5rem auto 1rem auto;}
#so-cong-no .time {  font-size: 14pt; margin-bottom : 10px;width : 100%;  text-align: center; display:flex,flex-direction: row; justify-content : center}
#so-cong-no .time-bold {  font-size: 14pt; margin-bottom : 10px, font-weight: bold;}
#so-cong-no .text--bold{    font-weight: bold !important;   }
#so-cong-no .align-center{ text-align: center!important; }
#so-cong-no .align-right{ text-align: right!important; }
#so-cong-no .phone-website { display: flex; gap: 24px; align-items: center }
`;
const ChiTietSoCongNo = ( dataExport ) => {
  const userProfile = useSelector((state) => state.auth.user);

  const { data, from, to, ten_so, loai_so } = dataExport.data;

  const totalMoney = (data && data?.length) ?
    data?.reduce((total, item) =>
      (!!item.TONG_CHI_PHI ? (total += Number(item.TONG_CHI_PHI)) : total)
      , 0) : 0

  const totalExemption = (data && data?.length) ?
    data?.reduce((total, item) =>
      (!!item.MIEN_GIAM ? (total += Number(item.MIEN_GIAM)) : total)
      , 0) : 0

  const totalPaid = (data && data?.length) ?
    data?.reduce((total, item) =>
      (!!item.BENH_NHAN_DUA ? (total += Number(item.BENH_NHAN_DUA)) : total)
      , 0) : 0

  const totalPayment = (data && data?.length) ?
    data?.reduce((total, item) =>
      (!!item.TONG_THANH_TOAN ? (total += Number(item.TONG_THANH_TOAN)) : total)
      , 0) : 0

  const totalDebitBalance = (data && data?.length) ?
    data?.reduce((total, item) =>
      (!!item.SO_DU_CONG_NO ? (total += Number(item.SO_DU_CONG_NO)) : total)
      , 0) : 0


  return (
    <div id="so-cong-no">
      <div className="header">
        {userProfile.benh_vien.ANH_DAI_DIEN && <Images className="logo" imgId={userProfile.benh_vien.ANH_DAI_DIEN}/>}

        <div>
          <div className="hospital-name">{userProfile.benh_vien.TEN_CSKCB}</div>
          <div>
            {i18n.t(languageKeys.dia_chi)}: {`${layDiaChi(userProfile.benh_vien)}`}
          </div>
          <div className="phone-website">
            <div>
              {i18n.t(languageKeys.dien_thoai)}: {userProfile.benh_vien.SO_DIEN_THOAI || ""}
            </div>
          </div>
          <div>
              Website: {userProfile?.benh_vien?.WEBSITE || ''}
            </div>
          <div>
            Email: {userProfile?.benh_vien?.EMAIL || ''}
          </div>
        </div>
      </div>

      {/* <div className="title">{'Sổ chẩn đoán hình ảnh'.toUpperCase()}</div> */}
      <div className="loai">{`${ten_so}`.toUpperCase()}</div>
      <div className="time">{i18n.t(languageKeys.thoi_gian)}: <span className="time-bold">
        {`${i18n.t(languageKeys.tu)} ${from} ${i18n.t(languageKeys.field_Den)} ${to}`}
      </span></div>

      <table style={{ width: "100%" }} className="table">
        <thead>
          <tr>
            <th className="align-center" style={{ width: "28px" }}>{i18n.t(languageKeys.stt)}</th>
            <th className="align-center">{i18n.t(languageKeys.field_Ma_phieu)}</th>
            <th className="align-center">{i18n.t(languageKeys.ten_bn)}</th>
            <th className="align-center">{i18n.t(languageKeys.field_Ngay_tao)}</th>
            <th className="align-center">{i18n.t(languageKeys.nguoi_tao)}</th>
            <th className="align-center">{i18n.t(languageKeys.tong_tien)}</th>
            <th className="align-center">{i18n.t(languageKeys.field_Mien_giam)}</th>
            <th className="align-center">{i18n.t(languageKeys.tong_tt)}</th>
            <th className="align-center">{i18n.t(languageKeys.da_thanh_toan)}</th>
            <th className="align-center">{i18n.t(languageKeys.so_du_cong_no)}</th>
            <th className="align-center">{i18n.t(languageKeys.loai_phieu)}</th>
            <th className="align-center">{i18n.t(languageKeys.field_hinh_thuc_thanh_toan)}</th>
            <th className="align-center">{i18n.t(languageKeys.field_Ly_do_mien_giam)}</th>
            <th className="align-center">{i18n.t(languageKeys.ghi_chu)}</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, idx) => {
            return (
              <tr key={rid()}>
                <td className="align-center">{idx + 1 >= 10 ? idx + 1 : `0${idx + 1}`}</td>
                <td>{item?.SO_PHIEU}</td>
                <td>{item?.TEN}</td>
                <td>{moment(item?.NGAY, "YYYYMMDD").format("DD/MM/YYYY")}</td>
                <td>{item?.NGUOI_TAO}</td>
                <td className="align-right">{formatNumberToPrice(item?.TONG_CHI_PHI)}</td>
                <td className="align-right">{formatNumberToPrice(item?.MIEN_GIAM)}</td>
                <td className="align-right">{formatNumberToPrice(item?.TONG_THANH_TOAN)}</td>
                <td className="align-right">{formatNumberToPrice(item?.BENH_NHAN_DUA)}</td>
                <td className="align-right">{formatNumberToPrice(item?.SO_DU_CONG_NO)}</td>
                <td>{item?.NHOM === 'THANH_TOAN' ? i18n.t(languageKeys.phieu_thu) : item?.NHOM === 'HOAN_TIEN' ? i18n.t(languageKeys.phieu_hoan) : i18n.t(languageKeys.phieu_huy)}</td>
                <td>{item?.TEN_HINH_THUC_THANH_TOAN}</td>
                <td>{item?.LY_DO_MIEN_GIAM}</td>
                <td>{item?.GHI_CHU}</td>
              </tr>
              // <>
              //   <tr key={rid()}>
              //     <td />
              //     <td />
              //     <td>{item?.TEN}</td>
              //     <td>{moment(item?.NGAY, "YYYYMMDD").format("DD/MM/YYYY")}</td>
              //     <td>{item?.NGUOI_TAO}</td>
              //     <td className="align-right">{formatNumberToPrice(item?.TONG_CHI_PHI)}</td>
              //     <td className="align-right">{formatNumberToPrice(item?.MIEN_GIAM)}</td>
              //     <td className="align-right">{formatNumberToPrice(item?.TONG_THANH_TOAN)}</td>
              //     <td className="align-right">{formatNumberToPrice(item?.BENH_NHAN_DUA)}</td>
              //     <td className="align-right">{formatNumberToPrice(item?.SO_DU_CONG_NO)}</td>
              //     <td />
              //     <td />
              //     <td />
              //     <td />
              //   </tr>
              //   {item.PHIEU_THU.map((i, idx) => (
              //     <tr key={rid()}>
              //       <td className="align-center">{idx + 1 >= 10 ? idx + 1 : `0${idx + 1}`}</td>
              //       <td>{i?.SO_PHIEU}</td>
              //       <td>{i?.TEN}</td>
              //       <td>{moment(i?.NGAY, "YYYYMMDD").format("DD/MM/YYYY")}</td>
              //       <td>{i?.NGUOI_TAO}</td>
              //       <td className="align-right">{formatNumberToPrice(i?.TONG_CHI_PHI)}</td>
              //       <td className="align-right">{formatNumberToPrice(i?.MIEN_GIAM)}</td>
              //       <td className="align-right">{formatNumberToPrice(i?.TONG_THANH_TOAN)}</td>
              //       <td className="align-right">{formatNumberToPrice(i?.BENH_NHAN_DUA)}</td>
              //       <td className="align-right">{formatNumberToPrice(i?.SO_DU_CONG_NO)}</td>
              //       <td>
              //         {i?.NHOM === 'THANH_TOAN'
              //           ? i18n.t(languageKeys.phieu_thu)
              //           : item?.NHOM === 'HOAN_TIEN' ? i18n.t(languageKeys.phieu_hoan) : i18n.t(languageKeys.phieu_huy)}
              //       </td>
              //       <td>{i?.TEN_HINH_THUC_THANH_TOAN}</td>
              //       <td>{i?.LY_DO_MIEN_GIAM}</td>
              //       <td>{i?.GHI_CHU}</td>
              //     </tr>
              //   ))}
              // </>
            );
          })}
          <tr>
            <td className="align-right text--bold" colSpan={5}>{i18n.t(languageKeys.common_Tong)}</td>
            <td className="align-right text--bold">{formatNumberToPrice(totalMoney)}</td>
            <td className="align-right text--bold">{formatNumberToPrice(totalExemption)}</td>
            <td className="align-right text--bold">{formatNumberToPrice(totalPayment)}</td>
            <td className="align-right text--bold">{formatNumberToPrice(totalPaid)}</td>
            <td className="align-right text--bold">{formatNumberToPrice(totalDebitBalance)}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <div className="foot-break" style={{ marginTop: '20px' }}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {i18n.language === languages.tieng_viet ? (
            <i>
              {moment().format("HH:mm") + ", "}
              {i18n.t(languageKeys.common_ngay)} {moment().date()},{i18n.t(languageKeys.common_thang)} {moment().month() + 1}, {i18n.t(languageKeys.common_nam)} {moment().year()}
            </i>
          ) : (
            <i style={{ marginRight: "40px" }}> {moment().format(enDateFormat)}</i>
          )}
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ textAlign: "center", marginLeft: 40 }}>
            <div>
              <b>{i18n.t(languageKeys.nguoi_lap_bang)}</b>
            </div>
            <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
            <div style={{ marginTop: 80, fontWeight: "bold" }}></div>
            <div style={{ marginTop: 80, fontWeight: "bold" }}>{userProfile.CHU_KY}</div>

          </div>

          <div style={{ textAlign: "center", marginLeft: 40 }}>
            {/* <div>
              <b>{i18n.t(languageKeys.truong_khoa_chan_doan_hinh_anh)}</b>
            </div>
            <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
            <div style={{ marginTop: 80, fontWeight: "bold" }}></div> */}
          </div>

          <div style={{ textAlign: "center", marginRight: 40 }}>
            <div>
              <b>{i18n.t(languageKeys.txt_ke_toan)}</b>
            </div>
            <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
            {/* <div style={{ marginTop: 80, fontWeight: "bold" }}>{userProfile.CHU_KY}</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ChiTietSoCongNo);
