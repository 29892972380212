import createSagaMiddleware from "@redux-saga/core";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import rootSaga from "./sagas/rootSaga";
import {
  authSlice,
  dataSlice,
  eventSlice,
  snapShot,
  pdfSlice,
  titlePageSlice,
  risReloadSilce,
  khamBenhSlice,
  QlTiepDonReducer,
  PacketServiceReducer,
  loadingReducer,
  QlyVienPhiReducer,
  ListDataReducer,
  pdfSliceLis,
  ChatAiSlice,
  FeatureReducer
} from "./slices";
import HospitalFeeManagementReducer from "./slices/QlyVienPhi";

const reducer = combineReducers({
  auth: authSlice,
  eventSlice,
  titlePage: titlePageSlice,
  data: dataSlice,
  pdf: pdfSlice,
  pdfLis: pdfSliceLis,
  snapShot: snapShot,
  risReloadSilce: risReloadSilce,
  khamBenh: khamBenhSlice,
  receipt:QlTiepDonReducer,
  servicePacket: PacketServiceReducer,
  loading: loadingReducer,
  hospitalFeeManagement: HospitalFeeManagementReducer,
  listData: ListDataReducer,
  QlyVienPhi: QlyVienPhiReducer,
  ChatAiStore:ChatAiSlice,
  features: FeatureReducer,
});

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      thunk: false,
      immutableCheck: false,
      serializableCheck: false,
    }),
    sagaMiddleware,
  ],
});

sagaMiddleware.run(rootSaga);

export default store;
