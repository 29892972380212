import { HLog, capitalizeFirstLetter, convertGender } from "helpers";
import { ds_chuyen_khoa } from "./constant";
import i18n, { languageKeys } from "../../i18n";
import { notification } from "antd";
/**
 *
 * @param {String} string keyword đầu vào
 * @returns {String} chuỗi ký tự theo form
 */
export const genarateHeaderText = (string) => {
  let cp_str = string.trim();
  let result = "";
  try {
    result = cp_str;
    result = "Nếu bạn đang gặp phải tình trạng " + cp_str + ", bạn có thể cân nhắc việc thăm khám tại các chuyên khoa sau:";
  } catch (error) {}
};

/**
 *
 * @param {String} mess chuỗi api asking trả về
 * @param {Array<String>} specialist Danh sách dịch vụ chuyên khoa
 * @returns {Array} Danh sách dịch vụ hiển thị
 */

export const genarateSpecialist = (mess, specialist) => {
  try {
    if (!mess) return [];
    let result = "";
    let splitSpecs = [];
    specialist.forEach((ck) => {
      if (mess.includes(ck)) splitSpecs = [...splitSpecs, ck];
    });

    if (!splitSpecs.length) return [mess];

    result = splitSpecs.map((ck, id) => `${capitalizeFirstLetter(ck)}`);
    return result;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getkeywordSpecialist = (mess, specialist) => {
  try {
  } catch (error) {}
};

/**
 * @description Customize câu hỏi cho chat bot
 * @param {string} mess Lý do khám.
 * @returns {string} Câu hỏi khám bệnh được gợi ý.
 */
export const suggestQuestionSpecialist = (mess) => {
  let result = "";
  const specialList = ds_chuyen_khoa.map((i) => i.TEN).join(", ");
  result = `${mess} thì đi khám chuyên khoa nào trong các chuyên khoa của tôi,  chỉ trả lời chuyên khoa và xuống dòng giữa các chuyên khoa`;
  return result;
};

/**
 *
 * @param {{ [key]: string, TEN:string, NGAY_SINH:String, GIOI_TINH:String }} info Thông tin bệnh nhân
 * @param {{CHAN_DOAN_LAM_SANG: String, HAM_DUOI_SO_BO: String, HAM_TREN_SO_BO: String}} examInfo thông tin khám của bệnh nhân
 */
export const suggestQuestionService = (info, examInfo) => {
  try {
    if (!info) throw new Error(i18n.t(languageKeys.chua_chon_benh_nhan_vao_kham));
    let { TEN, NGAY_SINH, GIOI_TINH } = info;
    let { CHAN_DOAN_LAM_SANG, HAM_DUOI_SO_BO, HAM_TREN_SO_BO } = examInfo;
    const tinhTuoi = () => {
      try {
        let age = "";
        let birth = NGAY_SINH;

        let year = birth?.length > 4 ? birth?.slice(birth.length - 4, birth.length) : birth;
        age = new Date().getFullYear() - +year;
        if (age < 0 || age > 120) return "";
        return `${age} tuổi`;
      } catch (error) {
        console.log(error);
        return "";
      }
    };
    let result = "";
    let tooth =
      !!HAM_DUOI_SO_BO || !!HAM_TREN_SO_BO
        ? `${HAM_DUOI_SO_BO ? `răng dưới bị ${HAM_DUOI_SO_BO}` : ""}, ${HAM_TREN_SO_BO ? ` răng trên bị ${HAM_TREN_SO_BO}` : ""}`
        : "";
    result = `${TEN} ${tinhTuoi(NGAY_SINH)} ${convertGender(GIOI_TINH)}, ${
      CHAN_DOAN_LAM_SANG ? CHAN_DOAN_LAM_SANG : tooth
    } cần chỉ định dịch vụ cận lâm sàng gì,tô đậm chuyên khoa, sử dụng html`;

    return result;
  } catch (error) {
    notification.error({ message: error.message, placement: "bottomLeft" });
    return "";
  }
};

/**
 *
 * @param {{ [key]: string, TEN:string, NGAY_SINH:String, GIOI_TINH:String }} info Thông tin bệnh nhân
 * @param {{CHAN_DOAN_XAC_DINH: String, data_benh : Array}} examInfo thông tin khám của bệnh nhân
 */
export const suggestQuestionMed = (info, examInfo) => {
  try {
    if (!info) throw new Error(i18n.t(languageKeys.chua_chon_benh_nhan_vao_kham));
    let { TEN, NGAY_SINH, GIOI_TINH } = info;
    let { CHAN_DOAN_XAC_DINH, data_benh } = examInfo;
    const tinhTuoi = () => {
      try {
        let age = "";
        let birth = NGAY_SINH;

        console.log(birth);
        let year = birth?.length > 4 ? birth?.slice(birth.length - 4, birth.length) : birth;
        age = new Date().getFullYear() - +year;
        if (age < 0 || age > 120) return "";
        return `${age} tuổi`;
      } catch (error) {
        console.log(error);
        return "";
      }
    };
    let result = "";

    let string_benh = data_benh.map((i) => i.TEN_ICD).join(", ");
    result = `${TEN} ${tinhTuoi(NGAY_SINH)} ${convertGender(GIOI_TINH)},${CHAN_DOAN_XAC_DINH ? CHAN_DOAN_XAC_DINH + ", " : ""} ${
      string_benh && string_benh?.length ? string_benh + "," : ""
    } thì sử dụng thuốc gì`;

    return result;
  } catch (error) {
    notification.error({ message: error.message, placement: "bottomLeft" });
    return "";
  }
};
