import { SearchOutlined } from "@ant-design/icons";
import { Dropdown, Input, Row, Table } from "antd";
import { useState, useEffect, useRef, useCallback } from "react";
import i18n, { languageKeys } from "../../i18n";
import { HLog } from "../../helpers";
import style from "./searchPopupTable.module.less";
import { debounce } from "lodash";

const SearchPopupTable = ({
  columns = [],
  dataSource = [],
  doSearch = async () => {},
  totalNum,
  onClickRow = () => {},
  width,
  scrollY,
  loadMore = () => {},
  focusCall = true,
  loading = false,
  multipleRow = true,
  resetText = () => {}
}) => {
  const [searchValue, setSearchValue] = useState("");
  const table = useRef();
  //const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const inputRef = useRef();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const load = useCallback(
    debounce((value) => loadMore(value), 2000),
    []
  );
    
  useEffect(() => {
    var tableContent = document.querySelector(".ant-table-body");
    if (tableContent) {
      tableContent.addEventListener(
        "scroll",
        (event) => {
          // checking whether a selector is well defined
          let maxScroll = event.target.scrollHeight - event.target.clientHeight;
          let currentScroll = event.target.scrollTop;
          if (currentScroll === maxScroll) {
            load(searchValue);
          }
        },
        { passive: true }
      );
    }
  });

  // Hàm xử lý search data
  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchValue(value);
    //setLoading(true);
    await doSearch(value);
    //ssetLoading(false);
  };
  // Hàm xử lý khi input focus
  const handleFocus = () => {
    // setVisible(true)
    focusCall && doSearch(searchValue, 1);
  };
  // Hàm xử lý click vào dòng của bảng
  const onRow = (record) => ({
    onClick: () => {
      HLog("Click table row", record);
     
      if (multipleRow) {
        setSearchValue("");
        onClickRow(record);
        inputRef.current?.focus();
      }else{
        onClickRow(record);
        setSearchValue(record.Thuoc_Ten)
        setVisible(false)
      }
    },
  });

  return (
    <Dropdown
      trigger="click"
      placement="bottomRight"
      visible={visible}
      onVisibleChange={setVisible}
      overlay={
        <div className={style["popup"]}>
          {!!totalNum && (
            <Row className={style["head"]}>
              <h4>{i18n.t(languageKeys.field_Ket_qua_tim_kiem)}</h4>
              <div className={style["total-num"]}>{totalNum}</div>
            </Row>
          )}

          <Table
            ref={table}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            onRow={onRow}
            className={style["table"]}
            scroll={{ y: scrollY, scrollToFirstRowOnChange: false }}
            style={{ width: width }}
            loading={loading}
          />
        </div>
      }
    >
      <Input
        prefix={<SearchOutlined className={style["search-icon"]} />}
        placeholder={i18n.t(languageKeys.common_Tim_kiem)}
        value={searchValue}
        onChange={handleSearch}
        onFocus={handleFocus}
        onBlur={() => setSearchValue("")}
        ref={inputRef}
        allowClear
      />
    </Dropdown>
  );
};

export default SearchPopupTable;
