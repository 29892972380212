import { createSlice } from "@reduxjs/toolkit";
import { deepEqual } from "../../helpers";

const initState = {
    disabledVienphi: false,
    memo_data: {},
    temp_data: {},
    isChange: false,
    loading: false,
    disabledModalTarget: false,
    disabledModalDate: false,
    modeQR: false,
};

const snapShot = createSlice({
    name: "snapShot",
    initialState: initState,
    reducers: {
        takeSnapshot: (state, { payload }) => {
            state.memo_data = payload
        },
        resetRepo: (state, { payload }) => {
            state.memo_data = {}
        },
        updateSnapshot: (state, { payload }) => {
            state.memo_data = { ...state.memo_data, ...payload }
        },
        getIsChange: (state, { payload }) => {
            state.isChange = deepEqual(state.memo_data, { ...payload })
        },
        setIsChange: (state, { payload }) => {
            state.isChange = payload
        },
        setTempData: (state, { payload }) => {
            state.temp_data = payload;
        },
        addTempData: (state, { payload }) => {
            state.temp_data = { ...state.temp_data, ...payload };
        },
        setLoading: (state, { payload }) => {
            state.loading = payload
        },
        setDisabled: (state, { payload }) => {
            state.disabledVienphi = payload;
        },
        setDisabledModalTarget: (state, { payload }) => {
            state.disabledModalTarget = payload;
        },
        setDisabledModalDate: (state, { payload }) => {
            state.disabledModalDate = payload;
        },
        setModeQR: (state, { payload }) => {
            state.modeQR = payload;
        },
    },
});

export const { actions: snapshotActions } = snapShot;

export const { takeSnapshot, resetRepo, updateSnapshot, getIsChange, setIsChange, setTempData, addTempData, setLoading, setDisabledModalTarget, setDisabledModalDate, setModeQR } = snapShot.actions;

export default snapShot.reducer;
