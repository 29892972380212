const Constants = {
  VERSION_NAME: "1.0.1", //version cho android
  LIMIT_LOAD_PATIENT: 50, //giới hạn số lượng bệnh nhân chờ khám

  status_CLS_cho_kham: "CHO_KHAM", // trạng thái kết quả khám cls - Chờ kết quả
  status_CLS_kham_xong: "KHAM_XONG", // trạng thái kết quả khám cls - Hoàn thành
  status_CLS_huy: "HUY",

  //eventsource
  EVENT_XAC_NHAN_LUOT_DIEU_TRI: "XAC_NHAN_LUOT_DIEU_TRI", //Event khi tiếp tân nhấn nút xác nhận lượt điều trị, reload màn cuộc hẹn sắp tới
  EVENT_TIEP_DON_LUOT_DIEU_TRI: "TIEP_DON_LUOT_DIEU_TRI", //Event khi tiếp tân nhấn nút tiếp đón, reload màn tiếp đón
  EVENT_THANH_TOAN_LUOT_DIEU_TRI: "THANH_TOAN_CUOC_KHAM", //Event khi tiếp tân nhấn nút thanh toán, reload màn thanh toán
  EVENT_LUOT_DIEU_TRI_CHI_DINH_CLS: "LUOT_DIEU_TRI_CHI_DINH_CLS", //Event khi bác sĩ kê chỉ định CLS
  EVENT_LUOT_DIEU_TRI_VAO_KHAM: "LUOT_DIEU_TRI_VAO_KHAM", //Reload màn ds bệnh nhân chờ khám ở giao diện khám bệnh
  EVENT_LUOT_DIEU_TRI_KHAM_XONG: "LUOT_DIEU_TRI_KHAM_XONG", //Reload ds bệnh nhân đã khám xong ở giao diện khám bệnh
  EVENT_TAO_DON_THUOC: "TAO_DON_THUOC", //Event khi bác sĩ kê chỉ định CLS\
  EVENT_RELOAD_DON_THUOC: "RELOAD_DON_THUOC",
  CUOC_KHAM_VAO_KHAM: "CUOC_KHAM_VAO_KHAM", //reload màn hình tiếp đón để update trạng thái vào khám
  CUOC_KHAM_KHAM_XONG: "CUOC_KHAM_KHAM_XONG", //reload màn tiếp đón để update trạng thái khám xong
  CAP_NHAT_THANH_TOAN: "CAP_NHAT_THANH_TOAN", // cập nhật trạng thái thanh toán
  TIEP_DON_CUOC_KHAM: "TIEP_DON_CUOC_KHAM", // reload ds tiếp đón khi nhấn tiếp đón cuộc hẹn
  CUOC_KHAM_DA_THANH_TOAN: "CUOC_KHAM_DA_THANH_TOAN", // reload viện phí và trạng thái thanh toán
  CAP_NHAT_CHI_DINH_RIS: "CAP_NHAT_CHI_DINH_RIS", // reload hàng chờ ris
  //các event liên quan đến sửa cuộc hẹn
  THEM_LUOT_DIEU_TRI: "THEM_LUOT_DIEU_TRI", //Trường hợp thêm lượt điều trị trong cuộc hẹn
  SUA_LUOT_DIEU_TRI: "SUA_LUOT_DIEU_TRI", //Trường hợp sửa lượt điều trị trong cuộc hẹn
  XOA_LUOT_DIEU_TRI: "XOA_LUOT_DIEU_TRI", //Xoá lượt điều trị trong cuộc hẹn
  LUOT_DIEU_TRI_HOAN_THANH: "LUOT_DIEU_TRI_HOAN_THANH", //trường hợp hoàn thành lượt điều trị
  CUOC_KHAM_CHI_DINH_CLS: "CUOC_KHAM_CHI_DINH_CLS", // hợp thêm lượt chỉ định cận lâm sàng
  HOAN_TIEN_CUOC_KHAM: "HOAN_TIEN_CUOC_KHAM", // hoàn tiền cuộc khám
  CUOC_KHAM_HOAN_THANH: "CUOC_KHAM_HOAN_THANH",

  LIMIT_HANG_CHO_BAN_THUOC: 15, // limit load danh sách hàng chờ bán thuốc

  //Mã loại BHYT
  XET_NGHIEM: "XET_NGHIEM",

  //KEY Filte tuổi
  DUOI_MOT_TUOI: "DUOI_MOT_TUOI", //dưới 1 tuổi
  TU_MOT_DEN_MUOI_HAI_TUOI: "TU_MOT_DEN_MUOI_HAI_TUOI", //từ 1 tuổi đến 12 tuổi
  TU_MUOI_HAI_DEN_MUOI_TAM_TUOI: "TU_MUOI_HAI_DEN_MUOI_TAM_TUOI", //từ 12 đến 18 tuổi
  TU_MUOI_TAM_DEN_SAU_TU_TUOI: "TU_MUOI_TAM_DEN_SAU_TU_TUOI", //từ 18 đến 60 tuổi
  TREN_SAU_TU_TUOI: "TREN_SAU_TU_TUOI", //trên 64 tuổi
  LUOT_DIEU_TRI_DA_TRA_KET_QUA: "LUOT_DIEU_TRI_DA_TRA_KET_QUA",

  INDEX_DB_NAME: "deepcare",
  LUOT_DIEU_TRI_DA_TRA_KET_QUA: "LUOT_DIEU_TRI_DA_TRA_KET_QUA",
  LUOT_DIEU_TRI_DA_THUC_HIEN: "LUOT_DIEU_TRI_DA_THUC_HIEN",
  TRA_KET_QUA_XET_NGHIEM:"TRA_KET_QUA_XET_NGHIEM",
  HUY_TRA_KET_QUA_XET_NGHIEM:"HUY_TRA_KET_QUA_XET_NGHIEM",
  THUC_HIEN_XET_NGHIEM:"THUC_HIEN_XET_NGHIEM",
  HUY_THUC_HIEN_XET_NGHIEM:"HUY_THUC_HIEN_XET_NGHIEM",
  NHAN_KQ_CDHA_VIN:"NHAN_KQ_CDHA_VIN", // noti nhận kết quả chẩn đoán hình ảnh module RIS
  NHAN_KQ_CDHA_CHI_DINH_VIN:"NHAN_KQ_CDHA_CHI_DINH_VIN", // noti reload sau khi nhận  kết quả module RIS
  AI_DA_PHAN_TICH:"AI_DA_PHAN_TICH", // noti thông báo AI đã phân tích hình ảnh xong
  //ARRAY_LOAI_DV_THUC_HIEN lấy trong file  excel 
  ARRAY_LOAI_DV_THUC_HIEN : ["TDCN_NOISOITIEUHOA","TDCN_NOISOITAIMUIHONG","TDCN_SOICOTUCUNG","TDCN_DIENTIM","TDCN_DIENNAO","CDHA_SIEUAM","CDHA_LOANGXUONG"],
  ARRAY_TRANG_THAI_XONG: ["KHAM_XONG","HOAN_THANH","DA_THUC_HIEN"],
  PROPOSE_BMI :35,
  PROPOSE_AGE :50,
  PROPOSE_VONG_CO :40,
  PROPOSE_SEX :"nam",
  NHOM_NHAN_SU_TU_VAN:[
    "MARKETING",
    "MKT_LEADER",
    "SALE",
    "CSKH",
    "CSKH_LEADER",
    "TELESALES"
],
REGEXNAMSINH_DDMMYYYY: /^(0[1-9]|[12][0-9]|3[01])(0[1-9]|1[0-2])\d{4}$/,

};

export default Constants;
