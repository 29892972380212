import { Button, Col, Row, Table as TableAntd, Tooltip } from "antd";
import table from "./table.module.less";
import cn from "classnames";
import { HLog } from "../../helpers";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import i18n, { languageKeys } from "../../i18n";
import { useState } from "react";

const Table = ({
  columns = [], // Danh sách cột
  dataSource = [], // Danh sách bản ghi (đã add key)
  loading = false, // Loading khi cập nhật danh sách dữ liệu
  onClickRow, // Hàm xử lý khi bấm vào dòng bản ghi
  onSelectRows, // Hàm xử lý khi tích chọn (nhiều) dòng
  selectedRows = [], // Danh sách các dòng đã được tích chọn
  className,
  showPagination = false,
  currentPage = 0,
  totalResult = 0,
  limit = 0,
  onNext = () => {},
  onPrev = () => {},
  rowPropsConfig = () => {},
  sizeTable = "small",
  showGap = true,
  classRow = false,
  pointingRow = false,
  pointingIndex = -1,
  classnameRow = () => {},
  checkStrictly= false,
  pointingRowClassName = "",
  smallGap=false,
  pagination = false,
  classNameTable = "",
  ...props
}) => {
  const [visibleTooltipPrev, setVisibleTooltipPrev] = useState(false);
  const [visibleTooltipNext, setVisibleTooltipNext] = useState(false);

  const onRow = (record) => ({
    onClick: () => {
      console.log("Click table row", record);
      !!onClickRow && onClickRow(record);
    },
  });

  let totalPerLimit = totalResult > limit
  ? `${1 + (currentPage - 1) * limit} ${
      1 + (currentPage - 1) * limit !== (currentPage * limit < totalResult ? currentPage * limit : totalResult)
        ? `- ${currentPage * limit < totalResult ? currentPage * limit : totalResult}`
        : ""
    } ${i18n.t(languageKeys.common_trong)} ${totalResult}`
  : `${totalResult} ${i18n.t(languageKeys.field_Ket_qua)}`;
  // HLog("CLASS NAME ROW",!!onClickRow ? (table["clickable"]): (!!classRow ? (record)=>classnameRow(record) : ""))
  return (
    // <div className={className}>

    <div style={{ height: "100%", flexDirection: "column", justifyContent: "space-between", display: "flex" }} className={className}>
      <TableAntd
        {...props}
        // size="small"
        className={cn(
          table["container"],
          dataSource.length === 1 && table["only-one"],
          showGap ? (smallGap ? table["show-gap--sm"] : table["show-gap"]) : "",
          classNameTable
        )}
        size={sizeTable}
        columns={columns}
        dataSource={dataSource}
        pagination={
          pagination && {
            itemRender: (page, type, ele) => {
              return (
                <div>
                  <span style={{ position: "absolute", width: "fit-content", marginRight: 60, right: 0 }}>{type === "prev" && totalPerLimit}</span>
                  {ele}
                </div>
              );
            },
            ...pagination,
          }
        }
        loading={loading}
        onRow={onRow}
        rowClassName={(record, index) =>
          !!onClickRow
            ? cn(
                table["clickable"],
                pointingRow && index === pointingIndex ? table["row-hover"] : "",
                pointingRow && index === pointingIndex ? pointingRowClassName : ""
              )
            : !!classRow
            ? classnameRow(record)
            : ""
        }
        rowSelection={
          !!onSelectRows
            ? {
                onChange: (_, rows) => {
                  HLog(_, "Select rows", rows);
                  onSelectRows(rows);
                },
                checkStrictly: checkStrictly,
                selectedRowKeys: selectedRows.map((row) => row?.[!!props.rowKey ? props.rowKey : "key"]),
                getCheckboxProps: rowPropsConfig,
              }
            : props.rowSelection
        }
      />

      {showPagination && (
        <Row align="middle" justify="end" className={table["pagination"]}>
          <Col className={table["text"]}>
            {totalResult > limit
              ? `${1 + (currentPage - 1) * limit} ${
                  1 + (currentPage - 1) * limit !== (currentPage * limit < totalResult ? currentPage * limit : totalResult)
                    ? `- ${currentPage * limit < totalResult ? currentPage * limit : totalResult}`
                    : ""
                } ${i18n.t(languageKeys.common_trong)} ${totalResult}`
              : `${totalResult} ${i18n.t(languageKeys.field_Ket_qua)}`}
          </Col>

          <Col style={{ marginRight: 5 }}>
            <Tooltip placement="topLeft" title={i18n.t(languageKeys.tip_trang_sau)} open={visibleTooltipPrev} onOpenChange={setVisibleTooltipPrev}>
              <Button
                icon={<LeftOutlined />}
                onClick={() => {
                  onPrev();
                  setVisibleTooltipPrev(false);
                }}
                disabled={totalResult > 0 ? currentPage <= 1 : true}
                className={table["button"]}
              ></Button>
            </Tooltip>
          </Col>

          <Col>
            <Tooltip
              placement="topLeft"
              title={i18n.t(languageKeys.tip_trang_ke_tiep)}
              open={visibleTooltipNext}
              onOpenChange={setVisibleTooltipNext}
            >
              <Button
                icon={<RightOutlined />}
                onClick={() => {
                  onNext();
                  setVisibleTooltipNext(false);
                }}
                disabled={totalResult > 0 ? currentPage * limit >= totalResult : true}
                className={table["button"]}
              ></Button>
            </Tooltip>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Table;
