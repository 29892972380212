import { createSlice } from '@reduxjs/toolkit';

const initState = {
  data: [],
  currentPage: 1,
  totalResult: 0,
  loading: false,
};

const SupplySlice = createSlice({
  name: 'supply',
  initialState: initState,
  reducers: {
    doFetchSuppliesFromStorage: (_, action) => {},
    fetchSuppliesFromStorageSuccess: (state, action) => {
      const { current_page, result, SO_LUONG_VAT_TU } = action.payload;
      state.data = result
      state.currentPage = current_page
      state.totalResult = SO_LUONG_VAT_TU
    },
    doFetchMedSupplies: (_, action) => {},
    fetchMedSuppliesSuccess: (state, action) => {
      const { current_page, result, total } = action.payload;
      state.data = result
      state.currentPage = current_page
      state.totalResult = total
    },
    setSupplyLoading: (state, action) => {
      state.loading = action.payload
    }
  }
});

export const { doFetchSuppliesFromStorage, fetchSuppliesFromStorageSuccess, doFetchMedSupplies, fetchMedSuppliesSuccess, setSupplyLoading } = SupplySlice.actions;

export const getSupplies = (state) => state.listData.supply;

export default SupplySlice.reducer;