import { formatCurrency2, getGender2, layDiaChi } from "helpers";
import i18n, { languageKeys, languages } from "../../../../i18n";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import JsBarcode from "jsbarcode";
import { nanoid } from "@reduxjs/toolkit";
import { Images } from "../../../../components";
import moment from "moment";


export const styleBangKeGoiLieuTrinh = `
#phieuCongNo * {
    font-size: 12px;
    line-height: 1rem;
    font-family: "Times New Roman", Times, serif;
}

#phieuCongNo .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}

#phieuCongNo .header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: '80px';
  font-size: 10px;
}

#phieuCongNo .header-left {
  min-width: 75%;
  display: flex;
  gap: 10px;
  flex-direction: row;
}

#phieuCongNo .header-right {
  min-width: 25%;
  position: relative;
  display: flex;
  flex-direction: column ;
  align-items: center ;
  justify-content: center ;
  text-align:  center ;
  position:  relative ;
  min-width:  150px ;
}

#phieuCongNo .header-department {
    text-transform: uppercase;
    font-weight: bold;
}

#phieuCongNo .header-date {
    font-style: italic;
}

#phieuCongNo .hospital-name {
    text-transform: uppercase;
    font-weight: bold;
}


#phieuCongNo .title {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  width: 90%;
  margin: 2.5rem auto 0.25rem auto;
}

#phieuCongNo .sub-title {
  margin-block: 0.5rem;
  font-size: 12px;
  font-weight: bold;
}

#phieuCongNo .info-customer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

#phieuCongNo .info-customer-item:first-child {
  min-width: 40%;
}

#phieuCongNo .date-time {
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    font-weight: 400;
    font-style: italic;
}

#phieuCongNo .report {
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    font-weight: bold;
    font-style: italic;
}

#phieuCongNo .table {
    margin-block: 1rem;
    width: 100%;
}

#phieuCongNo .table * {
    font-size: 12px;
}

#phieuCongNo .table,
.table th, 
.table td {
    border: 1px solid black;
    border-collapse: collapse;
}

#phieuCongNo .table th,
.table td {
    padding: 5px 10px;
}

#phieuCongNo .date-time-footer{
    margin-top: 40px;
    font-size: 14px;
    text-align: end;
    padding-right: 20px;
}
#phieuCongNo .footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 50px;
}
#phieuCongNo .footer-chu-ky{
    text-align: center;
    font-size: 12px;
    font-weight: 700;
}
#phieuCongNo .ky-ten {
    font-style: italic;
    font-size: 12px;
    font-weight: normal;
}

#phieuCongNo .sub-barcode-text {
  font-size:12px;
  position:absolute;
  top:50px;
  left:50%;
  line-height: 1.2em;
  transform: translateX(-50%);
}

#phieuCongNo .phone-website { display: flex; gap: 24px; align-items: center }

@media print {
    #phieuCongNo, #phieuCongNo .foot-break {
        break-inside: avoid;
    }
    #phieuCongNo { page-break-after: always}
`;


const BangKeGoiLieuTrinh = ({ data, rid }) => {
  console.log(`BangKeGoiLieuTrinh`, );

  const userProfile = useSelector((state) => state.auth.user);
  const { hospital, benh_nhan, hoa_don } = data

  let dv_goi = data.dich_vu

  useEffect(() => {
    if (!!rid && !!benh_nhan.ma_benh_nhan) {
      JsBarcode(`#barcode-${rid}`)
        .options({ font: "OCR-B" })
        .CODE128(benh_nhan.ma_benh_nhan, {
          text: ` `,
          fontSize: 10,
          width: 1.2,
          height: 40,
          textMargin: 0,
          margin: 0,
          padding: 0,
        })
        .render();
    }
  }, [benh_nhan.ma_benh_nhan, rid]);

  try {

    const MIEN_GIAM = (item) => {
      return Number(item.TONG_TIEN_GIAM) > 0 ?( Number(item.TONG_TIEN_GIAM) || 0) : (Number(item.TONG_TIEN_GIAM_TIEP_DON) || 0)
    }

    return Array(1).fill(null).map((_, index) => (
      <div id="phieuCongNo" key={index}>
        <div className="header">
          <div className="header-left">
            {userProfile.benh_vien.ANH_DAI_DIEN && (
              <Images className="logo" imgId={hospital.avatar}/>
            )}
            <div>
              <div className="hospital-name">{hospital.name}</div>
              <div>
                {i18n.t(languageKeys.dia_chi)} : {hospital.address}
              </div>
              <div className="phone-website">
                <div>
                  {i18n.t(languageKeys.dien_thoai)}:{' '}
                  {hospital.phone || ''}
                </div>
                <div>
                  Website: {userProfile?.benh_vien?.WEBSITE || ''}
                </div>
              </div>
              <div>
                Email: {userProfile?.benh_vien?.EMAIL || ''}
              </div>
            </div>
          </div>

          <div className="header-right">
            <svg id={`barcode-${rid}`}></svg>
            <div className="sub-barcode-text">
              <div style={{ width: '300px', textAlign: 'center' }}>{i18n.t(languageKeys.field_Ma_BN)}: <b>{benh_nhan.ma_benh_nhan}</b></div>
              <div style={{ width: '300px', textAlign: 'center' }}>{i18n.t(languageKeys.field_Ma_phieu)}: <b>{benh_nhan.SO_PHIEU}</b></div>
            </div>
          </div>
        </div>

        <div className="title">{i18n.t(languageKeys.bang_ke_thanh_toan_goi)}</div>

        <div className="info-customer">
          <div style={{ width: '60%' }}>
            <div>{i18n.t(languageKeys.ho_va_ten_khach_hang)}: {benh_nhan.ten_benh_nhan} </div>
            <div>{i18n.t(languageKeys.field_So_dien_thoai)}: {benh_nhan.sdt}</div>
            <div>{i18n.t(languageKeys.dia_chi)}: {benh_nhan.dia_chi}</div>
            <div>{i18n.t(languageKeys.goi_lieu_trinh)}: {hoa_don.TEN_GOI_DV}</div>
          </div>
          <div style={{ width: '40%' }}>
            <div>{i18n.t(languageKeys.field_Ngay_sinh)}: {benh_nhan.ngay_sinh}</div>
            <div>{i18n.t(languageKeys.field_Gioi_tinh)}: {getGender2(benh_nhan.gioi_tinh)}</div>
          </div>
        </div>

          <h2>{i18n.t(languageKeys.thong_tin_goi_lieu_trinh)}</h2>
        <table className="table">
          <thead>
            <th style={{ textAlign: "center" }}>{i18n.t(languageKeys.stt)}</th>
            <th style={{ textAlign: "center" }}>{i18n.t(languageKeys.field_Ten_dich_vu)}</th>
            <th style={{ textAlign: "center" }}>{i18n.t(languageKeys.sl)}</th>
            <th style={{ textAlign: "center" }}>{i18n.t(languageKeys.field_don_gia)}</th>
            {/* <th style={{ textAlign: "center" }}>{i18n.t(languageKeys.field_tong_tien)}</th> */}
            <th style={{ textAlign: "center" }}>{i18n.t(languageKeys.field_doi_tuong_mien_giam)}</th>
            <th style={{ textAlign: "center" }}>{i18n.t(languageKeys.vien_phi_thanh_tien)}</th>
          </thead>

          <tbody>
            {dv_goi?.map((item, index) => {
              return (
                <>
                  <tr key={nanoid()}>
                    <td style={{ textAlign: "center", width: '5%' }}>
                      {index + 1}
                    </td>
                    <td style={{ width: '30%' }}>
                      {item.TEN_DICHVU}
                    </td>
                    <td style={{ width: '5%', textAlign: 'center' }}>
                      {item.SO_LUONG}
                    </td>
                    <td style={{ width: '15%', textAlign: 'end' }}>
                      {formatCurrency2((item.GIA_DICH_VU || item.DON_GIA), '', true)}
                    </td>
                    {/* <td style={{ width: '15%', textAlign: 'end' }}>
                      {formatCurrency2((item.GIA_DICH_VU || item.DON_GIA) * item.SO_LUONG, '', true)}
                    </td> */}
                    <td style={{ width: '15%', textAlign: 'end' }}>
                      {formatCurrency2(MIEN_GIAM(item), '', true)}
                    </td>
                    <td style={{ width: '15%', textAlign: 'end' }}>
                      {formatCurrency2(((item.GIA_DICH_VU || item.DON_GIA) * item.SO_LUONG) - MIEN_GIAM(item), '', true)}
                    </td>
                  </tr>
                  {item?.DV_KEM_THEO?.length > 0 && item?.DV_KEM_THEO?.map(i => {
                    return (
                      <tr key={nanoid()}>
                        <td></td>
                        <td style={{ width: '30%' }}>
                          <div style={{ marginLeft: 10 }}>
                            {i.TEN_DV}
                          </div>
                        </td>
                        <td style={{ width: '5%', textAlign: 'center' }}>
                          {i.SO_LUONG}
                        </td>
                        <td style={{ width: '15%', textAlign: 'end' }}>
                          {formatCurrency2((i.DON_GIA || i.GIA_DICH_VU), '', true)}
                        </td>
                        <td style={{ width: '15%', textAlign: 'end' }}>
                          {formatCurrency2((i.DON_GIA || i.GIA_DICH_VU) * i.SO_LUONG, '', true)}
                        </td>
                        <td style={{ width: '15%', textAlign: 'end' }}>
                          {formatCurrency2(MIEN_GIAM(i), '', true)}
                        </td>
                        <td style={{ width: '15%', textAlign: 'end' }}>
                          {formatCurrency2(((i.GIA_DICH_VU || item.DON_GIA) * i.SO_LUONG) - MIEN_GIAM(i), '', true)}
                        </td>
                      </tr>
                    )
                  })}
                </>
              )
            })}

          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2}>{i18n.t(languageKeys.tong_cong)}</td>
              <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{dv_goi.reduce((total, curr) => total += curr.SO_LUONG, 0)}</td>
              <td style={{ textAlign: 'end', fontWeight: 'bold' }}>{formatCurrency2(dv_goi.reduce((total, curr) => total += (curr?.DON_GIA || curr?.GIA_DICH_VU || 0), 0), '', true)}</td>
              {/* <td style={{ textAlign: 'end', fontWeight: 'bold' }}>{formatCurrency2(dv_goi.reduce((total, curr) => total += ((curr.DON_GIA || curr.GIA_DICH_VU) * curr.SO_LUONG), 0), '', true)}</td> */}
              <td style={{ textAlign: 'end', fontWeight: 'bold' }}>{formatCurrency2(dv_goi.reduce((total, curr) => total += MIEN_GIAM(curr), 0), '', true)}</td>
              <td style={{ textAlign: 'end', fontWeight: 'bold' }}>{formatCurrency2(dv_goi.reduce((total, curr) => total += (((curr.GIA_DICH_VU || curr.DON_GIA) * curr.SO_LUONG) - MIEN_GIAM(curr)), 0), '', true)}</td>
            </tr>
          </tfoot>
        </table>

        <div style={{ padding: '0px 30px', display: "flex", justifyContent: 'space-between', marginTop: 10 }}>
          <div>{i18n.t(languageKeys.total_money)}: <span style={{ fontWeight: 'bold' }}>{formatCurrency2(hoa_don.TONG_CHI_PHI_GOI, 'đ', true)}</span></div>
          <div>{i18n.t(languageKeys.totalExemptions)}: <span style={{ fontWeight: 'bold' }}>{formatCurrency2(hoa_don.MIEN_GIAM_HOA_DON, 'đ', true)}</span></div>
          <div>{i18n.t(languageKeys.vien_phi_thanh_tien)}: <span style={{ fontWeight: 'bold' }}>{formatCurrency2(hoa_don.TONG_THANH_TOAN_GOI, 'đ', true)}</span></div>
        </div>


        <div style={{ padding: '0px 30px', display: "flex", justifyContent: 'space-between', marginTop: 10 }}>
          <div>{i18n.t(languageKeys.paid_2)}: <span style={{ fontWeight: 'bold' }}>{formatCurrency2(hoa_don.TONG_THANH_TOAN_GOI, 'đ', true)}</span></div>
          <div>{i18n.t(languageKeys.debtS)}: <span style={{ fontWeight: 'bold' }}>{formatCurrency2(0, 'đ', true)}</span></div>
          <div style={{opacity:0}}>{i18n.t(languageKeys.so_du_cong_no)}: <span style={{ fontWeight: 'bold' }}>0</span></div>
        </div>
        <div className="date-time-footer">
          {
            i18n.language === languages.tieng_viet
              ? `${moment().format('HH:MM')}, ${i18n.t(languageKeys.common_ngay)} ${moment().date()}, ${i18n.t(languageKeys.common_thang)} ${moment().month() + 1}, ${i18n.t(languageKeys.common_nam)} ${moment().year()}`
              : <div style={{ paddingRight: 60 }}>{`${moment().format('HH:MM')} ${moment().format('MMMM D, YYYY')}`}</div>
          }
        </div>
        <div className="footer">
          <div>
            <div className="footer-chu-ky">
              {i18n.t(languageKeys.khach_hang)}
              <div className="ky-ten">{i18n.t(languageKeys.ky_ghi_ro_ho_ten)}</div>
              <div style={{ marginTop: 50 }}>{benh_nhan?.ten_benh_nhan || ""}</div>
            </div>
          </div>
          <div>
            <div className="footer-chu-ky">
              {i18n.t(languageKeys.data_Thu_ngan)}
              <div className="ky-ten">{i18n.t(languageKeys.ky_ghi_ro_ho_ten)}</div>
              <div style={{ marginTop: 50 }}>{data?.ten_nhan_su || ""}</div>
            </div>
          </div>
        </div>
      </div>
    ))
  } catch (error) {
    console.log(error);
    return (
      <div id="phieuCongNo" style={{ width: '100%' }}>
        {/* <Result
          style={{ display: 'block', margin: 'auto', paddingInline: '30%', textAlign: 'center' }}
          status="500"
          title="Có lỗi xảy ra"
        /> */}
      </div>
    );
  }
};

export default BangKeGoiLieuTrinh
