import { Col, Row, Table as AntdTable } from 'antd';
import style from './table.module.less';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import i18n, { languageKeys, languages } from '../../i18n';
import cn from 'classnames';
import { PAGE_SIZE_OPTIONS } from 'constants/keys';

const Table = ({
  columns = [],
  dataSource = [],
  loading = false,
  className,
  size,
  bordered = false,
  totalResult = 0,
  currentPage = 1,
  header = {
    showHeader: false,
    title: undefined,
    actions: undefined,
    justify: undefined,
    suffixTitle: undefined
  },
  rowKey,
  showGap = true,
  scroll = {},
  pagination = {
    showPagination: false,
    showSizeChanger: true,
    center: false,
    // onChangePageSize: () => {},
    // total: 0,
    pageSize: 10
  },
  onClickRow,
  onSelectRows,
  selectedRows = [],
  rowPropsConfig = () => {},
  rowClassName = () => {},
  onChangeSelected = () => {},
  rowSelection,
  isCheckAll = false,
  tableHeight,
  ...props
}) => {
  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <div className={style['navigateBtn']} style={{ marginRight: 10, height: '100%' }}>
          <LeftOutlined />
          <span className="label">{i18n.t(languageKeys.truoc)}</span>
        </div>
      );
    }
    if (type === 'next') {
      return (
        <div className={style['navigateBtn']} style={{ marginLeft: 10, height: '100%' }}>
          <span className="label">{i18n.t(languageKeys.tiep)}</span> <RightOutlined />
        </div>
      );
    }
    return originalElement;
  };

  const onRow = (record) => ({
    onClick: () => {
      // console.log('[INFO] Table click row', record);
      !!onClickRow && onClickRow(record);
    }
  });

  return (
    <AntdTable
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      style={{height: tableHeight ? tableHeight : 'auto'}}
      className={cn(
        style['styleTable'],
        pagination?.showPagination && pagination.center && style['stylePaginationCenter'],
        showGap && style['show-gap'],
        i18n.language === languages.tieng_viet ? style['langVN'] : style['langEN'],
        className
      )}
      size={size || 'small'}
      bordered={bordered}
      rowKey={rowKey}
      caption={
        <>
          {header?.showHeader &&
            (() =>
              header.custom ? (
                header.custom
              ) : (
                <Row justify={header?.justify || 'space-between'} align="middle">
                  <Col>
                    <Row align="middle" gutter={20}>
                      <Col>
                        <h2>{header?.title || 'Title'}</h2>
                      </Col>

                      {!!header?.suffixTitle && <Col>{header.suffixTitle}</Col>}
                    </Row>
                  </Col>

                  <Col>{header?.actions}</Col>
                </Row>
              ))}
        </>
      }
      pagination={
        pagination.showPagination && {
          showSizeChanger: pagination?.showSizeChanger || true,
          itemRender,
          current: currentPage,
          total: totalResult,
          position: ['bottomRight'],
          locale: { items_per_page: '' },
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          defaultPageSize: PAGE_SIZE_OPTIONS[0],
          ...pagination
        }
      }
      scroll={scroll}
      rowSelection={
        onSelectRows
          ? {
              onChange: (_, rows) => {
                console.log('Select rows', rows);
                onSelectRows(rows);
              },
              checkStrictly: false,
              selectedRowKeys: selectedRows.map((row) => row.key || row[rowKey]),
              getCheckboxProps: rowPropsConfig,
              hideSelectAll: isCheckAll
            }
          : rowSelection
      }
      onRow={onRow}
      rowClassName={rowClassName}
      {...props}
    />
  );
};

export default Table;
