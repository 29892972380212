import { Button } from "antd";
import { paths } from "../../constants";
import { useLocation, useHistory } from "react-router-dom";
import style from "./style.module.less";
import i18n, { languageKeys } from "../../i18n";
import { Medico, MoneyWithdrawal, Plus, Stethoscope } from "assets/svg";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { handleCheckPermissions } from "helpers";
import { featureKeys } from "constants/keys";

const FastSwitchButtons = ({
    handleRecep,
    handlePayment,
    handleGoToExam,
    handleGoToSubclinical,
    loading,
    showRecep,
    showPayment,
    showExam,
    disabledPayment,
    disabledExam,
    disabledSubclinical
}) => {

    const history = useHistory();
    const { pathname } = useLocation();

    const userProfile = useSelector((state) => state.auth.user);
    const checkRoleRecep = useMemo(() => {
        return handleCheckPermissions(userProfile, "DON_TIEP")?.THEM_MOI
    }, [userProfile])
    const checkRolePayment = useMemo(() => {
        return handleCheckPermissions(userProfile, featureKeys.quan_ly_dich_vu_can_thanh_toan)?.THANH_TOAN
    }, [userProfile])
    const checkRoleExam = useMemo(() => {
        return handleCheckPermissions(userProfile, featureKeys.thong_tin_kham)?.SUA
    }, [userProfile])

    const checkRouteRecep = [paths.quan_ly_kham_benh, paths.quan_ly_dv_chi_dinh, paths.phauthuat_thu_thuat].includes(pathname) || showRecep

    return (
        <div className={style.container}>
            {(checkRouteRecep && checkRoleRecep) && (
                <Button
                    className={style.niceBtn}
                    style={{ background: 'rgba(44, 178, 165, 1)' }}
                    type="primary"
                    icon={<Plus />}
                    onClick={handleRecep
                        ? handleRecep
                        : () => history.push(`${paths.quan_ly_tiep_don}?tiep_don_moi=open`)
                    }
                    loading={loading}
                >
                   {i18n.t(languageKeys.tiep_don_moi)}
                </Button>
            )}
            {(showPayment && checkRolePayment) && (
                <Button
                    className={style.niceBtn}
                    style={{ background: 'rgba(246, 164, 71, 1)' }}
                    type="primary"
                    icon={<MoneyWithdrawal />}
                    onClick={handlePayment}
                    loading={loading}
                    disabled={disabledPayment}
                >
                    {i18n.t(languageKeys.thanh_toan)}
                </Button>
            )}
            {(showExam && checkRoleExam) && (
                <>
                    <Button
                        className={style.niceBtn}
                        style={{ background: 'rgba(126, 95, 247, 1)' }}
                        type="primary"
                        icon={<Stethoscope />}
                        onClick={handleGoToExam}
                        loading={loading}
                        disabled={disabledExam}
                    >
                        {i18n.t(languageKeys.loai_khoa_kham_benh)}
                    </Button>
                    <Button
                        className={style.niceBtn}
                        style={{ background: 'rgba(101, 118, 255, 1)' }}
                        type="primary"
                        icon={<Medico />}
                        onClick={handleGoToSubclinical}
                        loading={loading}
                        disabled={disabledSubclinical}
                    >
                         {i18n.t(languageKeys.thuc_hien_dv_cls)}
                    </Button>
                </>
            )}
        </div>
    )
}

export default FastSwitchButtons;