import { notification } from "antd";
import { doSaveDevelopTxt, fetchPacketUsageHistory, fetchPacketUsageHistorySuccess } from "ducks/slices/GoiDichVu/ThongTinSDGoiDv/packetUsageHistory.slice";
import i18n, { languageKeys } from "../../../../i18n";
import { put, select, takeLatest } from "redux-saga/effects";
import { setTableLoading } from "ducks/slices/loadingSlice";
import { getLSSDGoiDv } from "../apiGoiDichVu";
import { common_post } from "helpers";
import { apis } from "../../../../constants";

export function* watchPacketUsageHistory() {
    yield takeLatest(fetchPacketUsageHistory, handleFetchPacketUsageHistory)
    yield takeLatest(doSaveDevelopTxt, handleSaveDevelopTxt)
}

function* handleFetchPacketUsageHistory({ payload }) {
    try {
        yield put(setTableLoading(true))
        const userProfile = yield select(state => state.auth.user)
        const res = yield getLSSDGoiDv({
            BENH_VIEN_ID: userProfile?.BENH_VIEN_ID,
            parter_code: userProfile?.parter_code,
            ID: payload.ID,
            GOI_DV_ID: payload.GOI_DV_ID,
            NHOM_GOI: payload.NHOM_GOI,
        })

        if (!!res) {
            if (res.status === 'OK') {
                yield put(fetchPacketUsageHistorySuccess(res))
            } else {
                notification.error({
                    message: i18n.t(languageKeys.noti_lay_danh_sach_that_bai),
                    placement: 'bottomLeft'
                });
            }
        } else {
            notification.error({
                message: i18n.t(languageKeys.noti_lay_danh_sach_that_bai),
                placement: 'bottomLeft'
            });
        }
    } catch (err) {
        notification.error({
            message: i18n.t(languageKeys.noti_lay_danh_sach_that_bai),
            placement: 'bottomLeft'
        });
    } finally {
        yield put(setTableLoading(false))
    }
}

function* handleSaveDevelopTxt({ payload }) {
    try {
        yield put(setTableLoading(true))
        const res = yield common_post(apis.luuDienBienCuaBenh, payload)
        if (!!res) {
            if (res.status === 'OK') {
                notification.success({
                    message: i18n.t(languageKeys.cap_nhat_thanh_cong),
                    placement: 'bottomLeft'
                });
            } else {
                notification.error({
                    message: i18n.t(languageKeys.cap_nhat_that_bai),
                    placement: 'bottomLeft'
                });
            }
        } else {
            notification.error({
                message: i18n.t(languageKeys.cap_nhat_that_bai),
                placement: 'bottomLeft'
            });
        }
    } catch (err) {
        notification.error({
            message: i18n.t(languageKeys.cap_nhat_that_bai),
            placement: 'bottomLeft'
        });
    } finally {
        yield put(setTableLoading(false))
    }
}