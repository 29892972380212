import { combineReducers } from "@reduxjs/toolkit";
import SharedDataSlice from "../ListData/SharedData.slice";
import CityDistrictWardSlice from "./CityDistrictWard.slice";
import ExemptionSlice from "./Exemption.slice";
import SupplySlice from "./Supply.slice";

const ListDataReducer = combineReducers({
    sharedData: SharedDataSlice,
    CityDistrictWard: CityDistrictWardSlice,
    exemption: ExemptionSlice,
    supply: SupplySlice,
  });
  
export default ListDataReducer;