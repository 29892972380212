import { all, fork } from "redux-saga/effects";
import { watchSignUpForAServicePack } from "./DKGoiDichVuSagas";
import { watchServicePackType } from "./LoaiGoiDvlSagas";
import { watchServicePacketList } from "./DSGoiDichVu/DSGoiDichVu.saga";
import { watchServicePackageUsage } from "./ThongTinSDGoiDv/servicePackageUsage.saga";
import { watchDetailServicePackageUsage } from "./ThongTinSDGoiDv/detailServicePackageUsage.saga";
import { watchPacketUsageHistory } from "./ThongTinSDGoiDv/packetUsageHistory.saga";
import { watchExamHistory } from "./ThongTinSDGoiDv/examHistory.saga";
import { watchPrescriptionByExamination } from "./ThongTinSDGoiDv/prescriptionByExamination.saga";
import { watchPacketUsagePaymentHistory } from "./ThongTinSDGoiDv/paymentHistoryPackageUsage.saga";
import { watchDoGoiVatTu } from "./GoiVatTu.saga";

export default function* watchPacketService() {
  yield all([
    fork(watchSignUpForAServicePack),
    fork(watchServicePackType),
    fork(watchServicePackageUsage),
    fork(watchServicePacketList),
    fork(watchDetailServicePackageUsage),
    fork(watchPacketUsageHistory),
    fork(watchExamHistory),
    fork(watchPrescriptionByExamination),
    fork(watchPacketUsagePaymentHistory),
    fork(watchDoGoiVatTu),
  ]);
}
