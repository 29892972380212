import { takeLatest, put, putResolve } from "redux-saga/effects";
import { HLog, common_post } from "../../../helpers";
import { apis } from "../../../constants";
import { TiepDonActions } from "ducks/slices/QlTiepdon/TiepDon.slice";

export function* watchDoReceipt() {
  yield takeLatest(TiepDonActions.doFetchDsGoiDvBenhNhan, handleFetchServicePack);
  yield takeLatest(TiepDonActions.doGetChiTietLichKhamGoi, handleFetchExamPack);
}

function* handleFetchServicePack({ payload }) {
  try {
    yield put(TiepDonActions.setSiderLoading(true));
    let { selectedPatient, lichKhamInfo, isGetAll = false, callback = () => { }, RLS_BN_GOI_ID = [] } = payload;

    let BENH_NHAN_ID = selectedPatient.BENH_NHAN_ID || selectedPatient.ID;
    if (!BENH_NHAN_ID) throw new Error("Không có bệnh nhân ID");
    let res = yield common_post(apis.layDsGoiDvBenhNhan, {
      BENH_NHAN_ID,
      exclude_phong: 1,
      ARR_TRANG_THAI: lichKhamInfo ? [] : isGetAll ? ['ALL'] : ['HOAT_DONG'],
      LICH_KHAM_ID: lichKhamInfo?.LICH_KHAM_ID || lichKhamInfo,
      RLS_BN_GOI_ID
    });

    if (res && res.status === "OK") {
      let { result } = res;
      yield callback();
      yield put(TiepDonActions.setSiderLoading(false));
      yield put(TiepDonActions.setDsGoiDvBenhNhan(res.result));
      if (payload.GOI_DV_ID) {
        let { GOI_DV_ID } = payload;
        if (GOI_DV_ID) {
          let findPack = result.find(p => (p.GOI_ID === GOI_DV_ID) || p.ID === GOI_DV_ID);
          if (findPack) { findPack = JSON.stringify(findPack) }
          yield putResolve(TiepDonActions.setSelectedPacket(findPack));
        }
      }
    }

  } catch (e) {
    HLog(e);
  } finally {
    yield put(TiepDonActions.setSiderLoading(false));
  }
}
// layDsDichVuTheoLichKhamID
function* handleFetchExamPack(action) {
  try {
    yield put(TiepDonActions.setSiderLoading(true));

    const { payload } = action;
    let BENH_NHAN_ID = payload.BENH_NHAN_ID;
    let req = {
      "LICH_KHAM_ID": payload.LICH_KHAM_ID,
      "GOI_DV_ID": payload.GOI_DV_ID,
    }
    let res = yield common_post(apis.tiep_don_chi_tiet_lich_kham, { ...req });

    if (res && res.status === "OK") {
      // yield put(TiepDonActions.setSiderLoading(false));
      // yield put(TiepDonActions.setDsGoiDvBenhNhan(res.result));
    }
  } catch (e) {
    HLog(e);
  } finally {
    yield put(TiepDonActions.setSiderLoading(false));
  }
}

