import axios from "axios";
import React from "react";
import _ from "lodash";
import { apis, keys } from "../../constants";
import { base64ImageToBlob } from "helpers";
import i18n, { languageKeys } from "../../i18n";
import { img_response_type } from "constants/keys";

const checkIsPrefixBase64Img = (stringBase64) => {
  try {
    if (stringBase64.includes("data:image")) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    throw Error("Error check prefix base64");
  }
};

/**
 * @param {PropsUseImages}
 */
export const useImages = () => {
  const [ currentImage, setCurrentImage ] = React.useState({});
  try {
    const genBlobUrlFromBase64 = (BASE64) => {
      try {
        const imgBlob = base64ImageToBlob(!checkIsPrefixBase64Img(BASE64) ? "data:image/*;base64," + BASE64 : BASE64);
        const imgUrl = URL.createObjectURL(imgBlob);
        return imgUrl;
      } catch (error) {
        console.log(error);
      }
    };

    /**
     * @namespace FuctionGetImages
     * @description Gọi api lấy ảnh theo ID, sau đó thực hiện callback
     * @param {string} ID ID ảnh được lưu trữ
     * @param {(...args) => any} handleImageAfterFetch Xử lý dữ liệu sau khi lấy base64 về
     * @returns void
     */
    const getImage = async (ID, handleImageAfterFetch, fromService = 'IMAGE') => {
      try {
        const keycloakToken = localStorage.getItem(keys.access_token);
        if(ID === currentImage?.ID) {
          if (_.isFunction(handleImageAfterFetch)) {
            return handleImageAfterFetch(currentImage?.BASE64);
          } else {
            throw new Error('Không xử lý tiếp à ???');
          }
        }
        const isFetchImage = (fromService === 'IMAGE');
        const endpointService =(fromService === 'IMAGE') ? apis.layAnh : apis.layFile;
        
        const config = {
          url: endpointService,
          method: isFetchImage ? "GET" : 'POST',

          headers: { Authorization: "Bearer " + keycloakToken },
        };

        if(isFetchImage) {
          config.params = { ID, response_type : img_response_type.base64 }
        } else {
          config.data = { ID }
        }

        const { data: rs } = await axios(config);

        if (rs && rs.status === 'OK') {
          const BASE64 = isFetchImage ? rs.result : rs.result?.[0]?.BASE64
          setCurrentImage({
            ID,
            BASE64
          })
          if (_.isFunction(handleImageAfterFetch)) {
            handleImageAfterFetch(BASE64);
          }
        } else {
          throw new Error(i18n.t(languageKeys.thao_tac_that_bai));
        }
      } catch (error) {
        console.log("%c Errror get Private Image ! ", "background: #222; color: #bada55", error);
      }
    };
    return { getImage, genBlobUrlFromBase64 };
  } catch (error) {
    console.log("%c Errror get Private Image ! ", "background: #222; color: #bada55");
    return () => {};
  }
};

const Images = ({ alt = "CIS image", imgId, fromService, ...props }) => {
  const imgRef = React.useRef();
  const { getImage, genBlobUrlFromBase64 } = useImages();

  React.useEffect(() => {
    if (imgId && imgRef.current) {
      getImage(imgId, (base64) => {
        imgRef.current.src = genBlobUrlFromBase64(base64);
      }, fromService);
    }
  }, [imgId]);

  return imgId ? <img {...props} ref={imgRef} alt={alt} /> : <></>;
};

export default React.memo(Images);

/**
 * @typedef PropsUseImages
 * @property {import("react").MutableRefObject<any>} imgRef
 */