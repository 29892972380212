import { Avatar, Badge, Popover } from "antd";
import { Notification } from "assets/svg";
import React from "react";

const CommonNotification = ({ count = 0, shape = "square", size = "small", customContent, content }) => {
  let DefaultContent = () => {
    return <>{content}</>;
  };

  return (
    <Popover placement="topRight" content={!customContent ? <DefaultContent /> : customContent} overlayInnerStyle={{ borderRadius: "15px"}}>
      <div style={{ marginRight: 12 }}>
        <Badge count={count}>
          <Avatar shape={shape} size={size} icon={<Notification />} style={{ background: "transparent",cursor:"pointer" }} />
        </Badge>
      </div>
    </Popover>
  );
};

export default CommonNotification;
